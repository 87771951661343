import React from 'react'

import bowser from 'bowser'
import Bem from 'react-bem-helper'
import { connect } from 'react-redux'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Link from 'app/components/Link/Link'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import { breakpoints } from 'app/utils/constants'
import ChatBar from 'app/components/ChatBar/ChatBar'
import LinkListModel from 'app/models/LinkList/LinkList'
import { createSetBrowserType } from 'app/actions/shared'
import { canUseDOM, isLarge } from 'app/utils/env-helpers'
import SearchBar, { SearchBarModel } from 'app/components/SearchBar/SearchBar'
import GlobalAlertMessageGroup, {
  GlobalAlertMessageGroupModel
} from 'app/modules/shared/GlobalAlertMessageGroup/GlobalAlertMessageGroup'

import GlobalNavMenu from './GlobalNavMenu/GlobalNavMenu'
import GlobalNavToggle from './GlobalNavToggle/GlobalNavToggle'

import './GlobalNav.scss'

export class GlobalNavModel extends Model {
  constructor(d) {
    super(d)

    this.logo = d.logo ? new LinkModel(d.logo) : null
    this.categories = d.categories
      ? d.categories?.map(cat => (cat ? new LinkListModel(cat) : null))
      : null
    this.extras = d.extras
      ? d.extras?.map(extra => (extra ? new LinkModel(extra) : null))
      : null
    this.links = d.links
      ? d.links?.map(link => (link ? new LinkModel(link) : null))
      : []
    this.personalSiteLink = d.personalSiteLink
      ? new LinkModel(d.personalSiteLink)
      : null
  }

  static props() {
    return {
      name: String,
      logo: {
        type: LinkModel.shape,
        default: LinkModel.defaultProps()
      },
      categories: {
        type: LinkListModel.arrayOf,
        default: [LinkListModel.defaultProps()]
      },
      hideCategoriesInDesktopView: {
        type: Boolean,
        default: false
      },
      extras: {
        type: LinkModel.arrayOf,
        default: [LinkModel.defaultProps()]
      },
      links: {
        type: LinkModel.arrayOf,
        default: [LinkModel.defaultProps()]
      },
      personalSiteLink: {
        type: LinkModel.shape,
        default: LinkModel.defaultProps()
      },
      menuId: {
        type: String,
        default: 'global-nav-main-menu'
      },
      searchBar: SearchBarModel.shape,
      alertTicker: GlobalAlertMessageGroupModel.shape
    }
  }
}

function Extra({ extra, name, className }) {
  const clsName = extra && extra.analyticsEvent === 'chatAction' ? 'chat' : ''
  return (
    <li className={className}>
      <Link
        analyticsValue={`${slug(name)}|${slug(
          (extra && extra.ariaLabel) || ''
        )}`}
        link={extra}
        className={clsName}
      />
    </li>
  )
}

function Extras({ name, extras, onExpandableOpen, onExpandableClose }) {
  if (!extras) {
    return null
  }

  const classes = new Bem({ name: 'global-nav', prefix: '' })

  if (extras.length === 1) {
    return (
      <ul {...classes({ element: 'extras' })}>
        {extras.map((extra, i) => (
          <Extra
            key={uuid(i)}
            {...extra}
            name={name}
            {...classes({ element: 'extra' })}
          />
        ))}
      </ul>
    )
  }

  return (
    <div {...classes({ element: 'chat' })}>
      <ChatBar
        name={name}
        onOpen={onExpandableOpen}
        onClose={onExpandableClose}
        links={extras}
      />
    </div>
  )
}

class GlobalNav extends Component {
  constructor(props) {
    super(props, 'global-nav')
    this.state = {
      overlay: false,
      isOpen: false,
      isExpandableOpen: false,
      isLoading: false,
      isLocationOpen: false
    }
  }

  componentDidMount() {
    // TODO: This is a temporary solution for breakpoint change.
    // We will need to implement a global, optimized solution.
    if (global && global.addEventListener) {
      global.addEventListener('resize', this.onResize)
      this.onResize()
    }

    setTimeout(() => {
      if (navigator && navigator.userAgent) {
        const browser = bowser.getParser(window.navigator.userAgent)
        const name = browser.getBrowser().name || 'unknown'
        this.props.createSetBrowserType(name.toLowerCase())
      }
    }, 0)
  }

  componentWillUnmount() {
    if (global && global.addEventListener) {
      global.removeEventListener('resize', this.onResize)
    }
  }

  onResize = () => {
    const width =
      global.innerWidth ||
      global.document.documentElement.clientWidth ||
      global.document.body.clientWidth

    let breakpoint
    for (const size in breakpoints) {
      if (width >= breakpoints[size].min && width <= breakpoints[size].max) {
        breakpoint = size
        break
      }
    }

    if (breakpoint === 'large') {
      this.setState({ isOpen: false })
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onExpandableOpen = () => {
    this.setState({ isExpandableOpen: true })
  }

  onExpandableClose = () => {
    this.setState({ isExpandableOpen: false })
  }

  isItSupportPages = () => {
    return (
      this.props.request &&
      this.props.request.path &&
      this.props.request.path.startsWith('/support')
    )
  }

  menuSelected = selection => {
    this.setState({ overlay: selection })
  }

  m() {
    return {
      scotch: true
    }
  }

  render() {
    const { alertTicker } = this.props
    const support = this.isItSupportPages()

    return (
      <>
        <section>
          <nav
            className={this.b()}
            role="navigation"
            aria-label="Global Navigation"
          >
            <div className={this.e('background')} />
            <div
              className={this.e('overlay')}
              style={{
                display:
                  canUseDOM() && isLarge() && this.state.overlay !== false
                    ? 'block'
                    : 'none'
              }}
              onClick={() => this.menuSelected(false)}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            />

            <div className={this.e('container')}>
              {this.props.extras && (
                <div className={this.e('expandables')}>
                  {this.props.searchBar && !support && (
                    <div className={this.e('search')}>
                      <SearchBar
                        {...this.props.searchBar}
                        onOpen={this.onExpandableOpen}
                        onClose={this.onExpandableClose}
                      />
                    </div>
                  )}
                  <Extras
                    name={this.props.name}
                    extras={this.props.extras}
                    onExpandableOpen={this.onExpandableOpen}
                    onExpandableClose={this.onExpandableClose}
                  />
                </div>
              )}
              <div className={this.e('logo')}>
                <Link
                  className={this.e('logo-link')}
                  link={this.props.logo}
                  analyticsValue={`${slug(this.props.name)}|${slug(
                    this.props.logo.ariaLabel
                  )}`}
                />
              </div>
              <div
                className={this.e('global-nav-menu', {
                  opened: this.state.isExpandableOpen
                })}
              >
                <GlobalNavMenu
                  id={this.props.menuId}
                  parentName={this.props.name}
                  dropdowns={this.props.categories}
                  links={this.props.links}
                  personalSiteLink={this.props.personalSiteLink}
                  isOpen={this.state.isOpen}
                  searchBar={!support ? this.props.searchBar : null}
                  hideCategoriesInDesktopView={
                    this.props.hideCategoriesInDesktopView
                  }
                  menuSelected={this.menuSelected}
                  hideMenu={this.state.overlay}
                />
              </div>
              {(this.props.categories?.length > 0 ||
                this.props.links?.length > 0) && (
                <div className={this.e('toggle')}>
                  <GlobalNavToggle
                    onToggle={this.toggle}
                    menuId={this.props.menuId}
                    isActive={this.state.isOpen}
                  />
                </div>
              )}
            </div>
          </nav>
        </section>
        {alertTicker?.alertMessages && (
          <GlobalAlertMessageGroup
            alertMessages={alertTicker.alertMessages}
            background={alertTicker.background}
            name={alertTicker.name}
          />
        )}
      </>
    )
  }
}

GlobalNav.props(GlobalNavModel)

let globalNav = connect(
  state => ({
    request: state.self
  }),
  {
    createSetBrowserType: createSetBrowserType
  }
)(GlobalNav)

export default globalNav
