import React, { useState, useEffect } from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import ImageModel from 'app/models/Image/Image'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import ProductOfferModel from 'app/models/ProductOffer/ProductOffer'
import ResponsiveImage from 'app/components/ResponsiveImage/ResponsiveImage'
import ProductSpecList from 'app/components/ProductPlanCard/ProductSpecList'
import PriceInformation from 'app/components/PriceInformation/PriceInformationNew'

import './HorizontalPlanCard.scss'

const _entryId = new WeakMap()

export class PromotionalPlanCardModel extends Model {
  constructor(d) {
    super(d)

    this.productOffer = new ProductOfferModel(d.productOffer)
    this.leadGenCta = d.categoryLandingCta
    this.categoryPageCta = d.productPlanPage

    // Private
    _entryId.set(this, d.entryId || false)
  }

  static props() {
    return {
      name: {
        type: String,
        required: true,
        default: 'Product Plan Card'
      },
      productPrefix: {
        type: String,
        default: 'Product Prefix'
      },
      description: {
        type: String,
        default: 'Description'
      },
      productOffer: {
        type: ProductOfferModel.shape.isRequired,
        default: {}
      },
      leadGenCta: LinkModel.shape,
      categoryPageCta: LinkModel.shape,
      isFeatured: {
        type: Boolean,
        default: false
      },
      image: { type: ImageModel.shape },
      variant: { type: String, default: '' }
    }
  }
}

export default function HorizontalPlanCard({
  productOffer,
  productPrefix,
  description,
  categoryLandingCta,
  productPlanPage,
  isFeatured,
  variant,
  image
}) {
  const b = Bem('horizontal-plan-card')
  const [pageName, setPageName] = useState('')
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPageName(window.location.pathname)
    }
  }, [])

  const productPlanPageLink = new LinkModel(productPlanPage)
  const categoryPageLink = new LinkModel(categoryLandingCta)
  const offerSpecs = productOffer.offerSpecs
    ? productOffer.offerSpecs.reduce((acc, offer) => {
        if (offer && offer.name) {
          acc.push(offer)
        }
        return acc
      }, [])
    : []

  const headerVariation = productOffer.headerVariation || 'black'

  return (
    <div
      className={b
        .m({ featured: isFeatured, scotch: true, [variant]: true })
        .classes()}
    >
      <div
        className={b
          .e('header')
          .m({ [headerVariation]: true })
          .classes()}
      >
        {productPrefix && productPrefix.trim() && (
          <div className={b.e('banner').classes()}>
            <RichText isMarkdown markup={productPrefix} />
          </div>
        )}
        <div className={b.e('product-name').classes()}>
          <RichText isMarkdown markup={productOffer?.stylizedName || ''} />
        </div>
      </div>
      <div className={b.e('description-container').classes()}>
        {image && (
          <ResponsiveImage className={b.e('image').classes()} {...image} />
        )}

        {offerSpecs.length > 0 && (
          <ProductSpecList items={productOffer.offerSpecs} />
        )}

        {description && (
          <div className={b.e('description').classes()}>
            <RichText isMarkdown markup={description} />
          </div>
        )}

        {productPlanPage && (
          <Button
            {...productPlanPageLink.asButton}
            design="link-caret"
            className={b.e('product-plan-link').classes()}
            analyticsEvent={productPlanPageLink.determineAnalyticsEvent()}
            analyticsValue={`content|${slug(
              pageName
            )}|horizontal-plan-card|${slug(productOffer?.stylizedName)}|${slug(
              productPlanPageLink.text
            )}`}
          />
        )}
      </div>

      <div className={b.e('price-information').classes()}>
        <PriceInformation align="left" isPromo productOffer={productOffer} />

        <div className={b.e('buttons').classes()}>
          {categoryLandingCta && (
            <Button
              {...categoryPageLink.asButton}
              design="scotch-primary"
              variant="light"
              analyticsEvent={categoryPageLink.determineAnalyticsEvent()}
              analyticsValue={`content|${slug(
                pageName
              )}|horizontal-plan-card|${slug(productOffer?.stylizedName)}|${slug(
                categoryPageLink.text
              )}`}
            />
          )}
        </div>
      </div>
    </div>
  )
}
