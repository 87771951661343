import axios from 'axios'

// SYNC
export const SET_SUBMITTING_LEAD_FORM_DATA = 'SET_SUBMITTING_LEAD_FORM_DATA'
export const SET_LEAD_FORM_DATA = 'SET_LEAD_FORM_DATA'

export const setSubmittingLeadFormData = flag => ({
  type: SET_SUBMITTING_LEAD_FORM_DATA,
  data: flag
})
export const createSetLeadFormData = data => ({
  type: SET_LEAD_FORM_DATA,
  data
})

// ASYNC

export const submitLeadForm =
  (data, cbs = { success: () => {}, failure: () => {} }) =>
  async dispatch => {
    const formSubmission = new FormSubmission(data)
    // axios[formSubmission.action](formSubmission.url, formSubmission.data)
    //   .then(res => {
    //     dispatch(createSetLeadFormData(res.data))
    //     if (cbs.success) {
    //       cbs.success(res.data)
    //     }
    //   })
    //   .catch(err => {
    //     console.log('submitLeadForm form: ', formSubmission)
    //     console.log('submitLeadForm exception: ', err)
    //     const data = err && err.response ? err.response.data : {}
    //     dispatch(createSetLeadFormData(data))
    //     if (cbs.failure) {
    //       cbs.failure(data)
    //     }
    //   })

    try {
      dispatch(setSubmittingLeadFormData(true))
      const res = await axios[formSubmission.action](
        formSubmission.url,
        formSubmission.data
      )
      // console.log(
      //   '------- res.data --------->',
      //   JSON.stringify(formSubmission.data, null, 2),
      //   JSON.stringify(res.data, null, 2)
      // )
      dispatch(createSetLeadFormData(res.data))
      if (cbs.success && res.data.statusCode !== 'FAILED') {
        cbs.success(res.data)
      } else {
        cbs.failure(res.data)
      }
    } catch (err) {
      // console.log('submitLeadForm form: ', formSubmission)
      // console.log('submitLeadForm exception: ', err)
      dispatch(setSubmittingLeadFormData(false))
      const data = err && err.response ? err.response.data : {}
      dispatch(createSetLeadFormData(data))
      if (cbs.failure) {
        cbs.failure(data)
      }
    }
  }

export class FormSubmission {
  constructor(d, url = false, action = false) {
    let formUrl

    if (typeof window !== undefined) {
      formUrl = `${location.href}`
    }
    const FORM_URL = '/lead-form-submit'
    const FORM_ACTION = 'post'
    this.url = url || FORM_URL
    this.action = action || FORM_ACTION
    this.formData = {
      formType: 'sales',
      ...d,
      formUrl
    }
  }

  addData(name, val) {
    this.formData = {
      ...this.formData,
      [name]: val
    }
  }

  get data() {
    return this.formData
  }
}
