import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import { IconModel } from 'app/components/Icon/Icon'
import ProductOfferModel from 'app/models/ProductOffer/ProductOffer'
import { ProductFeatureListItemModel } from 'app/components/ProductFeatureListItem/ProductFeatureListItem'

const toProductOffers = item => new ProductOfferModel(item)
const toOfferFeatures = item => new ProductFeatureListItemModel(item)

export default class ProductLine extends Model {
  constructor(d) {
    super(d)
    this.productOffers = d.productOffers
      ? d.productOffers.map(toProductOffers)
      : []
    this.lineFeatures = d.lineFeatures
      ? d.lineFeatures.map(toOfferFeatures)
      : []
    this.productPlanPage = d.productPlanPage
      ? new LinkModel(d.productPlanPage)
      : undefined
    this.icon = d.icon ? new IconModel(d.icon) : undefined
  }

  static props() {
    return {
      name: { type: String, required: true },
      productLineCode: { type: String, required: false },
      stylizedName: { type: String, required: true },
      shortDescription: { type: String, required: true },
      productPlanPage: { type: LinkModel.shape, required: true },
      icon: { type: IconModel.shape, required: true },
      productOffers: ProductOfferModel.arrayOf,
      lineFeatures: ProductFeatureListItemModel.arrayOf.isRequired
    }
  }

  get productName() {
    return this.stylizedName.replace(/[^0-9a-zA-Z\s]/gi, '')
  }

  get lowestPrice() {
    let lowPrice = { price: 'XX.XX', text: 'Error getting low price' }

    if (!this.productOffers.length) {
      return lowPrice
    }

    this.productOffers.forEach(offer => {
      offer.pricing.forEach(pricePoint => {
        // First time, set the price equal to the first price
        if (lowPrice.price === 'XX.XX') {
          lowPrice = pricePoint
        }

        const thisPrice = parseInt(pricePoint.price, 10)
        const currentLow = parseInt(lowPrice.price, 10)

        // If we cannot compare as numbers, return
        if (isNaN(thisPrice) || isNaN(currentLow)) {
          return
        }

        if (thisPrice < currentLow) {
          lowPrice = pricePoint
        }
      })
    })
    return lowPrice
  }
}
