import React, { useEffect, useState } from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Bem from 'app/utils/bem-helper'
import Chevron from 'app/components/Chevron/Chevron'
import RichText from 'app/components/RichText/RichText'
import { ReactHeight } from '@shaw/react-component-library'
import LegalDisclaimer from './LegalDisclaimer'

import './CollapsableRichText.scss'
// Module model definition
export class CollapsableRichTextModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        required: true,
        default: 'collapsable-rich-text'
      },
      label: {
        type: String,
        required: true,
        default: 'Sample Label'
      },
      disclaimers: { type: Array, default: [] },
      text: {
        type: String,
        default: ''
      }
    }
  }
}

// Module definition
export default function CollapsableRichText(
  props = CollapsableRichTextModel.defaultProps()
) {
  const { text, name, label, disclaimers } = props
  const [height, setHeight] = useState(0)
  const [dirty, setDirty] = useState(true)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (global && global.addEventListener) {
      global.addEventListener('resize', handleResize)
    }

    return () => {
      if (global && global.removeEventListener) {
        global.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  function toggle() {
    setOpen(!open)
  }

  function getStyle() {
    return open ? { maxHeight: height, opacity: 1 } : {}
  }

  function handleResize() {
    setDirty(true)
  }

  function onHeightReady(height) {
    setHeight(height)
    setDirty(false)
  }

  const b = Bem('collapsable-rich-text')
  return (
    <div className={b.classes()}>
      <div className={b.e('container').classes()}>
        <button
          className={b.e('button').classes()}
          data-event="helpfulAction"
          data-value={`${slug(name)}|${open ? 'contract' : 'expand'}`}
          onClick={toggle}
          aria-haspopup="true"
        >
          <span>{label}</span>
          <Chevron
            className={b.e('chevron').classes()}
            open={open}
            small
            fast
          />
        </button>
        <div style={getStyle()} className={b.e('content').classes()}>
          <ReactHeight dirty={dirty} onHeightReady={onHeightReady}>
            <div className={b.e('rich-text').classes()}>
              <RichText markup={text} renderers={{ root: 'div', p: 'p' }} />
              {disclaimers?.map(disclaimer => (
                <LegalDisclaimer
                  key={disclaimer.entryId}
                  symbol={disclaimer.symbol}
                  body={disclaimer.body}
                />
              ))}
            </div>
          </ReactHeight>
        </div>
      </div>
    </div>
  )
}

CollapsableRichText.propTypes = CollapsableRichTextModel.propTypes()
