import React from 'react'

import { withCookies } from 'react-cookie'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import formatters from 'app/utils/form-data-format'
import RichText from 'app/components/RichText/RichText'
import { DisclaimerCta } from '@shaw/react-component-library'
import ProductOfferModel from 'app/models/ProductOffer/ProductOffer'
import ProductFeatureListItem from 'app/components/ProductFeatureListItem/ProductFeatureListItem'
import ResponsiveImage, {
  ResponsiveImageModel
} from 'app/components/ResponsiveImage/ResponsiveImage'
import ProductOfferAddOnCard, {
  ProductOfferAddOnCardModel
} from 'app/components/ProductOfferAddOnCard/ProductOfferAddOnCard'

import './ProductOfferFull.scss'

// Component model definition
export class ProductOfferFullModel extends Model {
  static props() {
    return {
      productOffer: {
        type: ProductOfferModel.shape,
        required: true,
        default: ProductOfferModel.defaultProps()
      },
      canonicalProductOffer: {
        type: ProductOfferModel.shape,
        required: false
      },
      superscript: {
        type: String,
        required: true,
        default: '*'
      },
      primaryCta: {
        type: LinkModel.shape,
        required: true,
        default: LinkModel.defaultProps()
      },
      secondaryCta: {
        type: LinkModel.shape,
        required: true,
        default: LinkModel.defaultProps()
      },
      image: ResponsiveImageModel.shape,
      imageOrientation: {
        type: String,
        default: 'Right'
      },
      white: {
        type: Boolean,
        required: true,
        default: true
      },
      addOnCard: ProductOfferAddOnCardModel.shape
    }
  }
}

// Component definition
export class ProductOfferFull extends Component {
  constructor(props) {
    super(props, 'product-offer-full')
    this.province = props.cookies ? props.cookies.get('queryLocation') : 'ab'
  }

  m() {
    return {
      vertical: true,
      image: this.props.image !== undefined,
      white: this.props.white,
      imageRight: this.props.imageOrientation === 'Right',
      hasAddOns: this.props.addOnCard !== undefined
    }
  }

  createPriceElement(price, suffix, bold, key) {
    const priceDollars = price.split('.')[0]
    const priceCents = price.split('.')[1]

    const priceStyle = { fontWeight: bold ? 'bold' : '400' }

    return (
      <div key={key} className={this.e('price-block')}>
        <p style={priceStyle} className={this.e('price-block__price')}>
          <span className={this.e('price-block__price-symbol')}>$</span>
          {priceDollars}
          <span className={this.e('price-block__cents-per-container')}>
            <span className={this.e('price-block__price-cents')}>
              {priceCents}
            </span>
            <span className={this.e('price-block__per-text')}>
              {`/mo`}
              {bold && (
                <DisclaimerCta href={this.props.superscript}>
                  {this.props.superscript}
                </DisclaimerCta>
              )}
            </span>
          </span>
        </p>
        <p className={this.e('price-block__text-below')}>{suffix}</p>
      </div>
    )
  }

  renderPrices() {
    const {
      regularPrice,
      standardPrice,
      firstYearPrice,
      otherYearPrice,
      pricingText,
      regionalPricing
    } = this.props.productOffer

    const regionalPrices =
      regionalPricing &&
      regionalPricing.length &&
      regionalPricing.reduce((accumulator, price) => {
        const province = price.province.toLowerCase()
        accumulator[province] = {
          regularPrice: price.regularPrice,
          standardPrice: price.standardPrice,
          firstYearPrice: price.firstYearPrice,
          otherYearPrice: price.otherYearPrice,
          pricingText: {
            regularPriceText: price.regularPriceText,
            standardPriceText: price.standardPriceText,
            firstYearPriceText: price.firstYearPriceText,
            otherYearPriceText: price.otherYearPriceText
          }
        }
        return accumulator
      }, {})
    let prices = []
    const regionalPrice = regionalPrices && regionalPrices[this.province]
    if (regionalPrice) {
      if (
        regionalPrice.firstYearPrice &&
        regionalPrice.otherYearPrice &&
        regionalPrice.regularPrice
      ) {
        prices.push(
          this.createPriceElement(
            regionalPrice.firstYearPrice,
            pricingText ? regionalPrice.pricingText.firstYearPriceText : '',
            true,
            'first-year'
          )
        )
        prices.push(
          this.createPriceElement(
            regionalPrice.otherYearPrice,
            pricingText ? regionalPrice.pricingText.otherYearPriceText : '',
            false,
            'other-years'
          )
        )
      } else if (regionalPrice.standardPrice) {
        prices.push(
          this.createPriceElement(
            regionalPrice.standardPrice,
            pricingText ? regionalPrice.pricingText.standardPriceText : '',
            true,
            'std-price'
          )
        )
      }

      if (regionalPrice.regularPrice) {
        const bold = prices.length === 0
        prices.push(
          this.createPriceElement(
            regionalPrice.regularPrice,
            pricingText ? regionalPrice.pricingText.regularPriceText : '',
            bold,
            'regular-price'
          )
        )
      }
    } else {
      if (firstYearPrice && otherYearPrice && regularPrice) {
        prices.push(
          this.createPriceElement(
            firstYearPrice,
            pricingText ? pricingText.firstYearPriceText : '',
            true,
            'first-year'
          )
        )
        prices.push(
          this.createPriceElement(
            otherYearPrice,
            pricingText ? pricingText.otherYearPriceText : '',
            false,
            'other-years'
          )
        )
      } else if (standardPrice) {
        prices.push(
          this.createPriceElement(
            standardPrice,
            pricingText ? pricingText.standardPriceText : '',
            true,
            'std-price'
          )
        )
      }

      if (regularPrice) {
        const bold = prices.length === 0
        prices.push(
          this.createPriceElement(
            regularPrice,
            pricingText ? pricingText.regularPriceText : '',
            bold,
            'regular-price'
          )
        )
      }
    }

    if (prices.length === 0) {
      return null
      // prices.push(this.createPriceElement('XX.XX', 'error in content', true, 'xx-xx'))
    }

    return <div className={this.e('price-points')}>{prices}</div>
  }

  renderLimitedOfferFlag(pricingText, className) {
    return (
      <div className={this.e(`limited-offer-flag-container ${className}`)}>
        {pricingText && pricingText.promoBannerText && (
          <div className={this.e('limited-offer-flag')}>
            <RichText
              renderers={{ root: 'span' }}
              className={this.e('limited-offer-text')}
              markup={pricingText.promoBannerText}
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { name, productOffer, image, primaryCta, secondaryCta, addOnCard } =
      this.props

    const {
      stylizedName,
      shortDescription,
      offerFeatures,
      pricingText,
      isActivePromo
    } = productOffer

    const primaryLink = new LinkModel(primaryCta)
    const secondaryLink = new LinkModel(secondaryCta)
    const linkDelim =
      primaryLink.asButton.link && primaryLink.asButton.link.indexOf('?') !== -1
        ? '&'
        : '?'
    const procutName =
      productOffer && productOffer.productName
        ? formatters.normalizeName(productOffer.productName)
        : ''
    const reccSolutions = `reccSolutions=${procutName}`
    const link = primaryLink.asButton.link
      ? `${primaryLink.asButton.link}${linkDelim}${reccSolutions}`
      : ''

    const primaryButton = {
      ...primaryLink.asButton,
      link,
      design: 'primary',
      analyticsEvent: primaryLink.determineAnalyticsEvent(),
      analyticsValue: `${slug(name)}|${slug(primaryLink.text)}`
    }

    const secondaryButton = {
      ...secondaryLink.asButton,
      design: 'secondary',
      analyticsEvent: secondaryLink.determineAnalyticsEvent(),
      analyticsValue: `${slug(name)}|${slug(secondaryLink.text)}`
    }

    return (
      <div className={this.b()}>
        <div className={this.e('container')}>
          <div className={this.e('offer-container')}>
            <div className={this.e('image-container')}>
              {image && (
                <ResponsiveImage {...image} className={this.e('image')} />
              )}
            </div>
            <div className={this.e('product-offer-container')}>
              <div className={this.e('product-offer')}>
                <div className={this.e('block')}>
                  {isActivePromo &&
                    pricingText &&
                    this.renderLimitedOfferFlag(pricingText, 'image-flag')}
                  <div className={this.e('title-container')}>
                    <RichText
                      className={this.e('title')}
                      markup={stylizedName}
                      isMarkdown
                    />
                  </div>
                  <p className={this.e('short-description')}>
                    {shortDescription}
                  </p>
                  <div className={this.e('features')}>
                    <ul className={this.e('features-list')}>
                      {offerFeatures &&
                        offerFeatures.map(feature => (
                          <ProductFeatureListItem key={uuid()} {...feature} />
                        ))}
                    </ul>
                  </div>
                </div>
                <div className={this.e('block')}>
                  {isActivePromo &&
                    this.renderLimitedOfferFlag(pricingText, 'no-image-flag')}
                  {this.renderPrices()}
                  {isActivePromo && pricingText && (
                    <span className={this.e('new-customer-label')}>
                      {pricingText.newCustomerText}
                    </span>
                  )}
                  <div className={this.e('button-container')}>
                    {primaryCta && (
                      <Button className={this.e('button')} {...primaryButton} />
                    )}
                    {secondaryCta && (
                      <Button
                        className={this.e('button')}
                        {...secondaryButton}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={this.e('add-on-container')}>
            {addOnCard && <ProductOfferAddOnCard {...addOnCard} />}
          </div>
        </div>
      </div>
    )
  }
}

ProductOfferFull.props(ProductOfferFullModel)
export default withCookies(ProductOfferFull)
