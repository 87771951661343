import React from 'react'

import PropTypes from 'prop-types'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Card from 'app/components/Card/Card'
import Icon from 'app/components/Icon/Icon'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import StickyNav from 'app/components/StickyNav/StickyNav'
import ContactsBlockModel from 'app/models/ContactsBlock/ContactsBlock'
import { BasicIconBoxModel } from 'app/components/BasicIconBox/BasicIconBox'

import ResponsiveImage from '../../../components/ResponsiveImage/ResponsiveImage'

import './GetInTouch.scss'

// Module model definition
export class GetInTouchModel extends Model {
  constructor(d) {
    super(d)
    this.cta = d && d.cta ? new LinkModel(d.cta) : null
  }
  static props() {
    return {
      blocks: { type: ContactsBlockModel.arrayOf, required: false },
      ourLocation: {
        type: PropTypes.shape(BasicIconBoxModel.propTypes()),
        required: false
      },
      footerText: String,
      cta: { type: LinkModel.shape, required: false }
    }
  }
}

// Module definition
export default class GetInTouch extends Component {
  constructor(props) {
    super(props, 'get-in-touch')
  }

  renderStickyNav() {
    if (!this.props.blocks) {
      return null
    }

    const items = this.props.blocks.map(b => {
      return new LinkModel({
        href: `#${slug(b.title)}`,
        label: b.title
      })
    })

    return (
      <StickyNav items={items} bgStyle="color-pale-grey" itemsStyle="narrow" />
    )
  }

  renderTypeContacts(type, contacts) {
    if (!contacts) {
      return null
    }

    const items = contacts.map(e => {
      const btnModel = new LinkModel(e)

      const button = {
        ...btnModel.asButton,
        type: 'link',
        design: 'link',
        analyticsEvent: btnModel.determineAnalyticsEvent(),
        analyticsValue: `${slug(btnModel.name)}|${slug(btnModel.text)}`
        // analyticsValue: parentName
        // ? `${slug(parentName)}|${slug(name)}|${slug(link.text)}`
        // : `${slug(name)}|${slug(link.text)}`
      }

      return (
        <div key={uuid()} className={this.e('contact')}>
          <p className={this.e('contact-name')}>{e.additionalLabel}</p>
          <p className={this.e('contact-value')}>
            <Button {...button} />
          </p>
        </div>
      )
    })

    return (
      <div className={this.e('contacts-group', type)}>
        <div className={this.e('group-icon')}>
          <Icon type={type} />
        </div>
        <div className={this.e('group-items')}>{items}</div>
      </div>
    )
  }

  renderContacts(b) {
    if (!b.contacts) {
      return (
        <div className={this.e('contacts')}>
          <div className={this.e('contact')}>
            There is no contacts to display
          </div>
        </div>
      )
    }

    const phones = b.contacts.filter(c => {
      return c.href.startsWith('tel:')
    })
    const emails = b.contacts.filter(c => {
      return c.href.startsWith('mailto:')
    })
    const links = b.contacts.filter(c => {
      return !(c.href.startsWith('mailto:') || c.href.startsWith('tel:'))
    })

    return (
      <div className={this.e('contacts')}>
        {phones.length > 0 && this.renderTypeContacts('phone', phones)}
        {emails.length > 0 && this.renderTypeContacts('email', emails)}
        {links.length > 0 && this.renderTypeContacts('email', links)}
      </div>
    )
  }

  renderBlock(b, idx) {
    const odd = idx % 2 === 1
    const oddCss = odd ? 'odd' : 'even'
    const img = b.image != null && b.image.length > 0 ? b.image[0] : null

    return (
      <div key={idx}>
        <button id={slug(b.title)} />
        <div className={this.e('block', oddCss)}>
          <div className={this.e('block-body')}>
            {odd && (
              <div className={this.e('block-half', 'img')}>
                {img && <ResponsiveImage {...img} />}
              </div>
            )}
            <div className={this.e('block-half')}>
              <h3>
                <RichText markup={b.title} />
              </h3>
              {this.renderContacts(b)}
            </div>
            {!odd && (
              <div className={this.e('block-half', 'img')}>
                {img && <ResponsiveImage {...img} />}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  renderBlocks() {
    if (!this.props.blocks) {
      return (
        <div className={this.e('blocks')}>
          <div className={this.e('block')}>
            There is no any blocks to display
          </div>
        </div>
      )
    }

    const blocks = this.props.blocks.map((b, idx) => {
      return this.renderBlock(b, idx)
    })

    return <div className={this.e('blocks')}>{blocks}</div>
  }

  renderAddress() {
    const { ourLocation } = this.props

    if (!ourLocation) {
      return
    }

    return (
      <div className={this.e('address')}>
        <Card
          className={this.e('address-card')}
          icon={ourLocation.icon}
          title={ourLocation.title}
        >
          <div className={this.e('address-title')}>
            <h3>
              <RichText markup={ourLocation.title} />
            </h3>
          </div>
          <div className={this.e('address-description')}>
            <RichText markup={ourLocation.description} />
          </div>
        </Card>
      </div>
    )
  }

  renderFooterText() {
    return (
      <div className={this.e('footer-text')}>
        <div className={this.e('footer-body')}>
          <RichText markup={this.props.footerText} />
          {this.renderCTA()}
        </div>
      </div>
    )
  }

  renderCTA() {
    const button = {
      ...this.props.cta.asButton,
      type: 'link',
      design: 'link-caret'
      // analyticsEvent: link.determineAnalyticsEvent(),
      // analyticsValue: parentName
      // ? `${slug(parentName)}|${slug(name)}|${slug(link.text)}`
      // : `${slug(name)}|${slug(link.text)}`
    }

    return (
      <div className={this.e('cta-container')}>
        <Button {...button} />
      </div>
    )
  }

  render() {
    return (
      <div className={this.b()}>
        <div className={this.e('container')}>
          {this.renderStickyNav()}
          {this.renderBlocks()}
          {this.renderAddress()}
          {this.renderFooterText()}
        </div>
      </div>
    )
  }
}

GetInTouch.propTypes = GetInTouchModel.propTypes()
GetInTouch.defaultProps = GetInTouchModel.defaultProps()
