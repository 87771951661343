import React, { useEffect, useState } from 'react'

import Bem from 'react-bem-helper'
import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import { ClientDataLayer } from 'app/utils/DataLayer'
import { ComparisonTable as Table } from '@shaw/react-component-library'

import './ComparisonTable.scss'

export class ComparisonTableModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: false
      },

      description: {
        type: String,
        required: false
      },
      productsTitle: {
        type: String,
        required: true,
        default: 'Products'
      },
      products: {
        type: Array
      },
      features: { type: Array }
    }
  }
}

export default function ComparisonTable({
  title,
  description,
  productsTitle,
  products,
  features
}) {
  const [offset, setOffset] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const elem = document.querySelector('.product-plan-sticky-nav')
      if (elem) {
        setOffset(true)
      }
    }
  }, [])
  const classes = new Bem({
    name: 'comparison-table',
    prefix: ''
  })

  const trackUserClick = data => {
    const cdl = new ClientDataLayer()
    cdl.directCall('navigationAction', {
      navigationAction: `comparison-table|accordion|${slug(data.name)}-${
        data.opened ? 'opened' : 'closed'
      }|${data.index}`
    })
  }

  const trackUserProductChange = data => {
    const cdl = new ClientDataLayer()
    cdl.directCall('navigationAction', {
      navigationAction: `comparison-table|dropdown|${slug(data.name)}`
    })
  }

  return (
    <div {...classes({ modifier: offset ? 'offset' : '' })}>
      <Table
        theme="scotch"
        title={title}
        description={description}
        productsTitle={productsTitle}
        products={products}
        features={features}
        accordionCallback={trackUserClick}
        productChangeCallback={trackUserProductChange}
      />
    </div>
  )
}

ComparisonTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}
