import React from 'react'

import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import { IconModel } from 'app/components/Icon/Icon'
import ContactCard, {
  ContactCardModel
} from 'app/components/ContactCard/ContactCard'
import ResponsiveImage, {
  ResponsiveImageModel
} from 'app/components/ResponsiveImage/ResponsiveImage'

import './OutstandingCtaWithText.scss'

// Module model definition
export class OutstandingCtaWithTextModel extends Model {
  constructor(d) {
    super(d)
    this.icon = new IconModel(d.icon)
    this.cta = d.cta ? new LinkModel(d.cta) : null
    this.image = d.image ? new ResponsiveImageModel(d.image) : null
    this.contactCard = new ContactCardModel(
      {
        name: `card-${d.name}`,
        icon: d.icon,
        title: d.title,
        description: d.description,
        links: [this.cta]
      },
      d.name
    )

    this.container = React.createRef()
  }

  static props() {
    return {
      name: String,
      icon: {
        type: IconModel.shape,
        required: true,
        default: IconModel.defaultProps()
      },
      title: String,
      description: String,
      cta: LinkModel.shape,
      image: ResponsiveImageModel.shape
    }
  }
}

export default class OutstandingCtaWithText extends Component {
  constructor(props) {
    super(props, 'outstanding-cta-with-text')
    this.state = {
      paddingBottom: '0px',
      imageType: 'large'
    }
    this.intervalId = 0
  }

  handleImageSwitch(imageType) {
    this.setState({ imageType })
  }

  render() {
    return (
      <div className={this.b()}>
        <div className={this.e('container')} ref={this.container}>
          <div className={this.e('content')}>
            <ContactCard
              className={this.e('card')}
              {...this.props.contactCard}
            />
          </div>
        </div>

        <ResponsiveImage
          {...this.props.image}
          className={this.e('background')}
          onSwitch={this.handleImageSwitch}
        />
      </div>
    )
  }
}

OutstandingCtaWithText.props(OutstandingCtaWithTextModel)
