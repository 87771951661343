/* eslint no-console: 0 */

/**
 * Used to model a registry for analytics factory functions.
 */
class AnalyticsEventRegistry {
  constructor() {
    this._registeredFactories = {}
    this.register = this.register.bind(this)
    this.get = this.get.bind(this)
    this.execute = this.execute.bind(this)
  }

  /**
   * Register a function which returns an analytics dataLayer object.
   * @param config {object} looks exactly like a reducer config object. Keys should be
   * constants and values should be factory functions which take state and data as arguments.
   * State will be the current app state and data will be additional data specified on the action
   * itself.
   */
  register(config) {
    for (const type in config) {
      if (
        Object.hasOwnProperty.call(config, type) &&
        !Object.hasOwnProperty.call(this._registeredFactories, type)
      ) {
        this._registeredFactories[type] = config[type]
      } else if (Object.hasOwnProperty.call(this._registeredFactories, type)) {
        console.warn(
          `You have attempted to use ${type} as the key for two analytics events.
        Please ensure that you are using unique constants for each analytics event.  This analytics
        event has not been registered.  Failed on: `,
          config[type]
        )
      }
    }
  }

  /**
   * Gets the analytics factory function for a given name.
   * @param name {string} name of the analytics factory you want to get
   */
  get(name) {
    return this._registeredFactories[name]
  }

  /**
   * Executes the analytics factory function for a given name and returns dataLayer object.
   * @param name {string} name of the analytics factory you want to get
   * @param getState {function} from store
   * @param data {object} from action
   * @returns dataLayer {object}
   */
  execute(name, getState, data) {
    return this._registeredFactories[name](getState(), data)
  }
}

const aer = new AnalyticsEventRegistry()
export default aer
