import React from 'react'

import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import { canUseDOM, isSmall } from 'app/utils/env-helpers'

import ResponsiveImage, {
  ResponsiveImageModel
} from '../../../components/ResponsiveImage/ResponsiveImage'

import './CaseStudyImagesInRow.scss'

// Module model definition
export class CaseStudyImagesInRowModel extends Model {
  constructor(d) {
    super(d)
    this.images = d.images
      ? d.images.map(img => {
          return new ResponsiveImageModel(img)
        })
      : []
  }
  static props() {
    return {
      name: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.shape(ResponsiveImageModel.props()))
        .isRequired
    }
  }
}

// Module definition
export default class CaseStudyImagesInRow extends Component {
  constructor(props) {
    super(props, 'images-in-row')
  }

  smallScreen() {
    return canUseDOM() && isSmall()
  }

  renderImages() {
    if (!this.props.images) {
      return <p>There are no images to display.</p>
    }

    const imgs = this.props.images.map((img, idx) => {
      return (
        <div
          data-testid="image-container"
          key={`images-${idx}`}
          className={this.e('image-item')}
        >
          <ResponsiveImage {...img} />
        </div>
      )
    })

    return <div className={this.e('images-row')}>{imgs}</div>
  }

  render() {
    return (
      <div className={this.b()}>
        <div className={this.e('container')}>{this.renderImages()}</div>
      </div>
    )
  }
}

CaseStudyImagesInRow.propTypes = CaseStudyImagesInRowModel.props()
