import React, { useState, useEffect } from 'react'

import { isSupportedBrowser } from 'app/modules/browserSupport'
import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import RichText from 'app/components/RichText/RichText'
import Icon, { IconModel } from 'app/components/Icon/Icon'

import './GlobalAlertMessage.scss'

// Module model definition
export class GlobalAlertMessageModel extends Model {
  static props() {
    return {
      name: String,
      showIcon: { type: Boolean, default: true, required: false },
      detectBrowser: Boolean,
      message: {
        type: String,
        required: true,
        default:
          'Dolore labore enim in ad est nulla laborum ex aute et velit irure duis ea nostrud ullamco duis nisi.'
      },
      cta: {
        type: LinkModel.shape,
        default: null
      },
      icon: {
        type: IconModel.shape,
        default: IconModel.defaultProps()
      },
      handleClick: { type: Function, default: () => {} }
    }
  }
}

// Module definition
export default function GlobalAlertMessage(
  props = GlobalAlertMessageModel.defaultProps()
) {
  const [showAlert, setShowAlert] = useState(props.detectBrowser ? false : true)
  useEffect(() => {
    if (props.detectBrowser) {
      setShowAlert(!isSupportedBrowser())
    }
  }, [])

  const b = Bem('global-alert-message')
  const link = new LinkModel(props.cta)
  const buttonMarkup = `<Button
      type="link"
      design="link-caret-white"
      withCaret
      text={${link.text}}
      link={${link.url}}
      target={${link.targetWindow}}
      analyticsEvent="navigationAction"
      analyticsValue={${slug(props.name)}|${slug(link.text)}} />`
  const showCta = props.cta && link.url
  return (
    <div
      className={b
        .and(`${props.className} ${showAlert ? 'show' : 'hide'}`)
        .classes()}
    >
      <div className={b.e('container').classes()}>
        {props.showIcon && (
          <button className={b.e('icon').classes()} onClick={props.handleClick}>
            <Icon {...props.icon} />
          </button>
        )}

        <RichText
          className={b.e('message').classes()}
          markup={`${props.message}  ${showCta ? buttonMarkup : ''}`}
          renderers={{ root: 'div' }}
        />
      </div>
    </div>
  )
}

GlobalAlertMessage.propTypes = GlobalAlertMessageModel.propTypes()
