import React from 'react'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import Component from 'app/components/Component'
import GradientHero, {
  GradientHeroModel
} from 'app/components/GradientHero/GradientHero'
import ProductOfferCard, {
  ProductOfferCardModel
} from 'app/components/ProductOfferCard/ProductOfferCard'

import './HeroAndCards.scss'

// Module model definition
export class HeroAndCardsModel extends Model {
  constructor(d) {
    super(d)
    // will be either 'First' or 'Last'
    this.horizontalCardPlacement = d.horizontalCardPlacement
    this.hero = new GradientHeroModel(d.hero)
    this.cards = []
    this.name = d.name

    d.cards.forEach(card => {
      this.cards.push(new ProductOfferCardModel(card, this.name))
    })
  }

  static props() {
    return {
      horizontalCardPlacement: String,
      cards: ProductOfferCardModel.arrayOf,
      hero: GradientHeroModel.shape,
      name: String,
      hidePriceLine: Boolean,
      eyebrow: String,
      title: String,
      subtitle: String,
      theme: String
    }
  }

  static defaultProps() {
    return {
      name: 'HeroAndCards',
      horizontalCardPlacement: 'First',
      cards: [
        ProductOfferCardModel.defaultProps(),
        ProductOfferCardModel.defaultProps(),
        ProductOfferCardModel.defaultProps()
      ],
      hero: GradientHeroModel.defaultProps(),
      hidePriceLine: false,
      theme: 'scotch'
    }
  }
}

// Module definition
export default class HeroAndCards extends Component {
  constructor(props) {
    super(props, 'hero-and-cards')

    this.containerClass = this.e('container')
    this.cardsContainerClass = this.bem
      .e('cards-container')
      .and(this.containerClass)
      .c()
    this.cardContainerClass = this.bem
      .e('card-container')
      .and(this.containerClass)
      .c()
  }

  /**
   * Based on props, choose the correct reducing function to group product offer cards
   * @param props {Props object} the props for this component
   * @param opts {object} contains reducing functions, more easily testable
   * @returns {function} the correct reducing function
   */
  static applyCorrectGrouping(props, opts) {
    if (props.cards.length % 2 === 0) {
      return opts.groupCardsByTwo
    } else if (props.horizontalCardPlacement === 'First') {
      return opts.groupCardsHorizontalFirst
    } else {
      return opts.groupCardsHorizontalLast
    }
  }

  /**
   * A reducing function for grouping cards in groups of 2
   * @param list {array} the result of the previous reduction
   * @param next {object} next item in array
   * @param i {number} iterator
   * @returns {Array}
   */
  static groupCardsByTwo(list, next, i) {
    const newList = Array.from(list)
    const lastItem = i > 0 ? newList[newList.length - 1] : false
    // if this is an even index (begins a row)
    if (i % 2 === 0) {
      newList.push([next])
      // if this is an odd index (ends a row)
    } else {
      lastItem.push(next)
    }

    return newList
  }

  /**
   * A reducing function for grouping cards with the last card being horizontal
   * @param list {array} the result of the previous reduction
   * @param next {object} next item in array
   * @param i {number} iterator
   * @param arr {Array} the array we're reducing
   * @returns {Array}
   */
  static groupCardsHorizontalLast(list, next, i, arr) {
    const newList = Array.from(list)
    const lastItem = i > 0 ? newList[newList.length - 1] : false

    // if this is the last item
    if (arr.length - 1 === i) {
      newList.push([next])
      // if this is an even index (begins a row)
    } else if (i % 2 === 0) {
      newList.push([next])
      // if this is an odd index (ends a row)
    } else {
      lastItem.push(next)
    }

    return newList
  }

  /**
   * A reducing function for grouping cards with the first card being horizontal
   * @param list {array} the result of the previous reduction
   * @param next {object} next item in array
   * @param i {number} iterator
   * @returns {Array}
   */
  static groupCardsHorizontalFirst(list, next, i) {
    const newList = Array.from(list)
    const lastItem = i > 0 ? newList[newList.length - 1] : false

    // if this is the first item
    if (i === 0) {
      newList.push([next])
      // if this is an odd index (begins a row)
    } else if (i % 2 === 1) {
      newList.push([next])
      // if this is an even index (ends a row)
    } else {
      lastItem.push(next)
    }

    return newList
  }

  /**
   * If a given grouping has only one item, that should be displayed horizontally.  This function
   * applies that property isHorizontal
   * @param group {object}
   * @param i {number} iterator
   * @returns {object}
   */
  static applyCorrectOrientation(group, i) {
    // If this group only has one item, it is displayed as a horizontal card
    const isHorizontal = group.length === 1
    return group.map((card, j) => {
      return {
        card: {
          ...card,
          isHorizontal
        },
        // this is to create a unique identifier by serializing the group and individual iterators
        key: `${i}-${j}`
      }
    })
  }

  /**
   * Apply a ProductOfferCardModel as props for a ProductOfferCard
   * @param group {array}
   * @returns {array}
   */
  static toProductOfferCard(group) {
    const hpl = this.props.hidePriceLine
    return group.map((item, idx) => {
      const poc = { ...item.card, hidePriceLine: hpl }
      return (
        <div key={idx} className={this.e('card-wrapper')}>
          <ProductOfferCard {...poc} />
        </div>
      )
    })
  }

  /**
   * Apply correct wrapping container around rendered components
   * @param group {Array}
   * @param i {number} iterator
   * @returns {JSX}
   */
  static toAppropriateContainer(group) {
    const isTwoCardsContainer = group.length === 2
    const className = isTwoCardsContainer
      ? this.cardsContainerClass
      : this.cardContainerClass
    return (
      <div className={className} key={uuid()}>
        {group}
      </div>
    )
  }
  m() {
    return {
      scotch: true
    }
  }
  render() {
    return (
      <section className={this.b()} aria-label={this.props.hero.title}>
        <GradientHero
          className={this.e('hero')}
          {...this.props.hero}
          theme={this.props.theme}
          title={this.props.title}
          subtitle={this.props.subtitle}
          eyebrow={this.props.eyebrow}
        />
        <div className={this.e('main-cards-container')}>
          {this.props.cards
            .reduce(
              HeroAndCards.applyCorrectGrouping(this.props, {
                groupCardsByTwo: HeroAndCards.groupCardsByTwo,
                groupCardsHorizontalLast: HeroAndCards.groupCardsHorizontalLast,
                groupCardsHorizontalFirst:
                  HeroAndCards.groupCardsHorizontalFirst
              }),
              []
            )
            .map(HeroAndCards.applyCorrectOrientation)
            .map(HeroAndCards.toProductOfferCard, this)
            .map(HeroAndCards.toAppropriateContainer, this)}
        </div>
      </section>
    )
  }
}

HeroAndCards.props(HeroAndCardsModel)
