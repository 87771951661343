import React from 'react'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { icons } from 'app/utils/constants'
import Component from 'app/components/Component'
import Arrow from 'app/components/Carousel/Arrow'
import Carousel from 'app/components/Carousel/Carousel'
import ProductAddOnIconBox, {
  ProductAddOnIconBoxModel
} from 'app/components/ProductAddOnIconBox/ProductAddOnIconBox'

import './IconBoxCarousel.scss'

// Module model definition
export class IconBoxCarouselModel extends Model {
  constructor(d) {
    super(d)
    this.title = d.title
    this.productAddOnIconBoxes = []
    this.customCssName = d.customCssName

    const boxes = Array.isArray(d.productAddOnIconBoxes)
      ? d.productAddOnIconBoxes
      : [d.productAddOnIconBoxes]

    boxes.forEach(card => {
      this.productAddOnIconBoxes.push(
        new ProductAddOnIconBoxModel(card, d.name)
      )
    })
  }

  static props() {
    return {
      title: {
        type: String,
        required: true,
        default: 'Sample title description'
      },
      productAddOnIconBoxes: {
        type: ProductAddOnIconBoxModel.arrayOf,
        required: true,
        default: [
          ProductAddOnIconBoxModel.defaultProps(),
          ProductAddOnIconBoxModel.defaultProps(),
          ProductAddOnIconBoxModel.defaultProps()
        ]
      },
      customCssName: String,
      background: {
        type: String,
        default: 'transparent'
      }
    }
  }
}

// Module definition
export default class IconBoxCarousel extends Component {
  constructor(props) {
    super(props, 'icon-box-carousel')

    this.handleBreakpointChange = this.handleBreakpointChange.bind(this)
    this.handleSlideChange = this.handleSlideChange.bind(this)
    this.renderCarousel = this.renderCarousel.bind(this)
    this.toIconBox = this.toIconBox.bind(this)
    this.toRows = this.toRows.bind(this)
    this.toGroupsIfLarge = this.toGroupsIfLarge.bind(this)
    this.state = {
      breakpoint: null,
      shouldRenderCarousel: false,
      currentIndex: 0
    }

    this.carousel = React.createRef()

    if (global && global.addEventListener) {
      global.addEventListener('resize', this.handleResize)
    }
  }

  componentDidUnmount() {
    if (global && global.removeEventListener) {
      global.removeEventListener('resize', this.handleResize)
    }
  }

  m() {
    let noPrices = true
    this.props.productAddOnIconBoxes.map(iconBox => {
      if (iconBox.productAddOn.price) {
        noPrices = false
      }
    })

    return {
      'single-box': this.props.productAddOnIconBoxes.length === 1,
      noPrices: noPrices,
      [this.props.background]: true
    }
  }

  handleResize() {
    const width = window.innerWidth
    if (width >= 1280 && this.state.breakpoint !== 'large') {
      this.setState({
        breakpoint: 'large',
        currentIndex: 0
      })
    } else if (
      width < 1280 &&
      width >= 860 &&
      this.state.breakpoint !== 'medium'
    ) {
      this.setState({
        breakpoint: 'medium',
        currentIndex: this.state.currentIndex
      })
    } else if (width < 860 && this.state.breakpoint !== 'small') {
      this.setState({
        breakpoint: 'small',
        currentIndex: this.state.currentIndex
      })
    }
  }

  /**
   * The component needs to know what breakpoint the carousel thinks it is in
   * @param newBreakpoint
   */
  handleBreakpointChange(newBreakpoint) {
    if (newBreakpoint === 'large' && window.innerWidth === 1279) {
      return
    }
    this.setState({
      breakpoint: newBreakpoint,
      currentIndex: newBreakpoint === 'large' ? 0 : this.state.currentIndex
    })
  }

  handleSlideChange(currentIndex) {
    this.setState({ currentIndex })
  }

  /**
   * Use with Array.prototype.map.  Creates ProductAddOnIconBox component from model
   * @param iconBox {ProductAddOnIconBoxModel}
   * @param i {Number}
   * @param arr {Array} the array we're mapping
   * @returns {XML}
   */
  toIconBox(iconBox, i, arr) {
    const itemCount = arr.length === 4 ? 2 : arr.length

    return (
      <div
        key={uuid()}
        className={this.e(
          `icon-box-wrapper icon-box-carousel__icon-box-wrapper--count-${itemCount}`
        )}
      >
        <ProductAddOnIconBox
          untabbable={
            this.state.breakpoint !== 'large' && i !== this.state.currentIndex
          }
          className={this.e('icon-box')}
          {...iconBox}
        />
      </div>
    )
  }

  /**
   * Use with Array.prototype.map.  Creates rows for iconboxes
   * @param boxes {Array}
   * @returns {XML}
   */
  toRows(boxes) {
    return (
      <div
        key={uuid()}
        className={this.e(`row icon-box-carousel__row--${boxes.length}-count`)}
      >
        {boxes}
      </div>
    )
  }

  /**
   * Use with Array.prototype.reduce.  If breakpoint is not large, push directly into accumulator clone and return.
   * If large but less than 4 items, push directly ihnto accumulator clone and return.
   * If large and
   * @param acc {Array}
   * @param next {ProductAddOnIconBox}
   * @param i {Number}
   * @returns {Array}
   */
  toGroupsIfLarge(acc, next, i, arr) {
    const newAcc = Array.from(acc)

    // if large or if we don't need rows
    if (this.state.breakpoint !== 'large') {
      newAcc.push(next)
      // if the array length is not even
    } else if (arr.length % 2 !== 0) {
      return [arr]
      // if it's the beginning of a new row
    } else if (i % 2 === 0) {
      newAcc.push([next])
      // if it's the second item in a row
    } else {
      newAcc[newAcc.length - 1].push(next)
    }

    return newAcc
  }

  renderCarousel() {
    const { productAddOnIconBoxes } = this.props
    const carouselOptions = {
      options: {
        nextArrow: (
          <Arrow
            name="Next"
            icons={icons}
            onClick={() => console.log('next')}
          />
        ),
        prevArrow: (
          <Arrow
            name="Previous"
            icons={icons}
            onClick={() => console.log('prev')}
          />
        ),
        accessibility: true,
        autoplay: false,
        adaptiveHeight: true,
        centerMode: false,
        centerPadding: '0px',
        dotsClass: 'carousel__dots',
        draggable: true,
        infinite: false,
        variableWidth: false,
        dots: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        onSwipe: () => console.log,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow:
                productAddOnIconBoxes.length >= 3
                  ? 3
                  : productAddOnIconBoxes.length,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1010,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 740,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true
            }
          }
        ]
      }
    }

    return (
      <Carousel
        ref={this.carousel}
        className={this.e('carousel')}
        onSlideChange={this.handleSlideChange}
        onBreakpointChange={this.handleBreakpointChange}
        {...carouselOptions}
      >
        {productAddOnIconBoxes
          .map(this.toIconBox, this)
          .reduce(this.toGroupsIfLarge, [])}
      </Carousel>
    )
  }

  render() {
    const css = this.props.customCssName
    return (
      <div className={this.b(css)}>
        <div className={this.e('container')}>
          <h2 className={this.e('title')}>{this.props.title}</h2>
          {this.renderCarousel()}
        </div>
      </div>
    )
  }
}

IconBoxCarousel.propTypes = IconBoxCarouselModel.propTypes()
IconBoxCarousel.defaultProps = IconBoxCarouselModel.defaultProps()
