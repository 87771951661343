import React, { useEffect, useState } from 'react'

import { slug } from 'app/utils/paths'
import Bem from 'app/utils/bem-helper'
import Link from 'app/components/Link/Link'
import { useChat } from 'app/modules/ChatContext'

export default function ChatButton({ name, chatLink, showNav }) {
  const b = Bem('product-plan-sticky-nav')
  const [klass, setKlass] = useState('chat-unavailable')
  const { liveagent, chatAvailable } = useChat()

  useEffect(() => {
    if (chatAvailable) {
      setKlass('chat-available')
    } else {
      setKlass('chat-unavailable')
    }
  }, [chatAvailable])

  const getChatButtonId = s => {
    var m = new RegExp(/#chat:([^:]+):([^:]+)(:([^:]+))?$/).exec(s)
    return m != null
      ? {
          buttonId: m[2],
          linkInstanceId: m[1],
          hideOnOnline: m[4] === 'offline',
          hideOnOffline: m[4] === 'online'
        }
      : null
  }

  const handleChat = e => {
    e.preventDefault()
    // console.log('------- chat --------->', liveagent)
    if (chatAvailable && typeof window !== 'undefined') {
      const href = chatLink.href
      try {
        const b = getChatButtonId(href)
        liveagent.startChat(b.buttonId)
      } catch (error) {
        if (window?.LOG_LEVEL?.match(/info|verbose|debug|silly/)) {
          console.log(
            '------- chat start error --------->',
            error,
            '\n',
            getChatButtonId(href)
          )
        }
      }
      // console.log('------- open chat --------->')
    } else {
      // console.log('------- dont chat  --------->')
    }
  }

  return (
    <Link
      className={b
        .e('chat')
        .m({ [klass]: true })
        .classes()}
      tabIndex={!showNav ? '-1' : '0'}
      link={chatLink}
      analyticsEvent="chatAction"
      analyticsValue={`${slug(name)}|${slug(chatLink.ariaLabel)}`}
      onClick={handleChat}
    />
  )
}
