import React, { useEffect, useState } from 'react'

import { useFormikContext } from 'formik'

import Bem from 'app/utils/bem-helper'

import './Section.scss'

export default function Section({
  title,
  sectionTitle,
  step,
  edit,
  children,
  editStep,
  columns = [1, 2, 2],
  completed = false,
  completedValue = null,
  callbackOnSave = null,
  setTouched = null,
  touched = null,
  showBorder = true
}) {
  const b = Bem('form-section')
  const [isCompleted, setIsCompleted] = useState(completed)
  const [showSave, setShowSave] = useState(editStep === step)
  const [showEdit, setShowEdit] = useState(editStep !== step)
  const { values } = useFormikContext()

  useEffect(() => {
    setIsCompleted(completed && editStep !== step)
  }, [completed, editStep])

  useEffect(() => {
    setShowEdit(editStep !== step)
    if (editStep === 0) {
      setShowEdit(true)
      setShowSave(false)
      setIsCompleted(completed)
    } else if (editStep !== step) {
      setShowEdit(true)
      setShowSave(false)
      setIsCompleted(completed)
    }
  }, [editStep])

  useEffect(() => {
    if (!completedValue) {
      setShowSave(false)
    }
  }, [completedValue])

  const handleEdit = () => {
    edit(step)
    setIsCompleted(false)
    setShowSave(true)
  }

  const handleSave = () => {
    setIsCompleted(true)
    edit(null)
    setShowSave(false)
    if (callbackOnSave) {
      callbackOnSave(values, step)
    }
    if (setTouched) {
      setTouched(
        {
          supportType: touched.supportType,
          businessType: touched.businessType
        },
        false
      )
    }
  }

  const analyticsValue =
    `|${String(completedValue).replace(/[, ]+/g, '-')}` || ''

  return (
    <div className={b.m({ ['completed']: isCompleted }).classes()}>
      <div
        className={b
          .e('step')
          .m({
            ['completed']: isCompleted,
            ['border']: showBorder
          })
          .classes()}
      >
        <span>{step}</span>
      </div>
      <h2 className={b.e('title').classes()}>{title}</h2>
      <div
        className={b
          .e('container')
          .m({ ['completed']: isCompleted })
          .classes()}
      >
        <h3 className={b.e('section-title').classes()}>{sectionTitle}</h3>
        <>
          {!isCompleted && (
            <div
              className={b
                .e('content')
                .m({
                  ['completed']: isCompleted,
                  [`mobile-${columns[0]}`]: columns[0],
                  [`tablet-${columns[1]}`]: columns[1],
                  [`desktop-${columns[2]}`]: columns[2]
                })
                .classes()}
            >
              {children}
            </div>
          )}
          {isCompleted && completedValue && (
            <span className={b.e('completed-value').classes()}>
              {completedValue}
            </span>
          )}
        </>
        {isCompleted && completedValue && showEdit && (
          <button
            className={b.e('edit').classes()}
            onClick={handleEdit}
            data-event="navigationAction"
            data-value={`business|contact-form|form-section|${title}${analyticsValue}|edit-button`}
          >
            Edit
          </button>
        )}
        {showSave && (
          <button
            className={b.e('save').classes()}
            onClick={handleSave}
            data-event="navigationAction"
            data-value={`business|contact-form|form-section|${title}${analyticsValue}|continue-button`}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  )
}
