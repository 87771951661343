import React, { useState } from 'react'

import { withCookies } from 'react-cookie'
import { Form, Formik } from 'formik'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import Button from 'app/components/Button/Button'

import Building from './Sections/Building'
import ContactInfo from './Sections/ContactInfo'
import OurSolutions from './Sections/OurSolutions'
import { handleFormSubmit, validationSchema } from './validation'

import './MDUForm.scss'
export class MDUFormModel extends Model {
  static props() {
    return {
      name: String,
      formId: String,
      sections: Array,
      redirectUrl: String,
      submitToEmail: String
    }
  }
}

export function MDUForm({
  cookies,
  sections,
  submitToEmail = 'newdev@sjrb.ca',
  formId = 'mduForm',
  redirectUrl = '/secure/request-information/support/thankyou'
}) {
  const [submissionError, setSubmissionError] = useState(null)

  const b = Bem('mdu-form')

  function setLeadCookie(values, orderId) {
    const leadData = {
      shawAccountNumber: values.shawAccountNumber || 'none',
      productInterest: values.productInterest,
      employeesCount: values.employeesCount,
      orderId,
      leadFormName: 'contact-form'
    }
    const leadDataString = JSON.stringify(leadData)
    const cookieOptions = {
      path: '/',
      expires: new Date(+new Date() + 12096e5)
    }
    cookies.set('leadData', leadDataString, cookieOptions)
  }

  async function handleSubmit(values, actions) {
    setSubmissionError(null)
    actions.setSubmitting(true)
    try {
      if (values.isNewDevelopment === 'Yes') {
        delete values.productInterest
      } else if (values.isNewDevelopment === 'No') {
        delete values.numberOfUnits
        delete values.buildingType
        delete values.estimatedOccupancyDate
      }

      const response = await handleFormSubmit({
        ...values,
        token: 'skip',
        replyToEmail: 'emailAddy',
        formId,
        submitToEmail,
        noFormat: true
      })

      console.log(
        '------- response.data --------->',
        JSON.stringify(response && response.data)
      )

      if (response) {
        const refId =
          response.data.id ||
          (response.data &&
            response.data.data &&
            response.data.data.requestNumber)

        setLeadCookie(values, refId)
        if (typeof window != 'undefined') {
          if (refId) {
            window.localStorage.setItem('refId', refId)
          }
          window.location.href = redirectUrl
        }
      }
    } catch (error) {
      if (window?.LOG_LEVEL?.match(/info|verbose|debug|silly/)) {
        console.log('------- MDU Form Error --------->', error)
      }
      actions.setSubmitting(false)
      setSubmissionError(
        'Form submission was unsuccessful, please try again later.'
      )
    }
  }

  return (
    <section className={b.classes()}>
      <Formik
        initialValues={{
          isNewDevelopment: null,
          emailoptin: '',
          buildingAddress: '',
          city: '',
          province: '',
          postalCode: '',
          buildingType: '',
          numberOfUnits: '',
          estimatedOccupancyDate: '',
          productInterest: [],
          firstName: '',
          lastName: '',
          phoneNum: '',
          emailAddy: '',
          companyName: '',
          position: '',
          comments: ''
        }}
        validationSchema={validationSchema}
        validateOnMount={false}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          isSubmitting
        }) => {
          const showFields = values.isNewDevelopment === 'Yes'
          const showSection = values.isNewDevelopment === 'No'

          return (
            <Form>
              {sections[0] && (
                <Building
                  title={sections[0].title}
                  description={sections[0].description}
                  required={sections[0].required}
                  values={values}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  showFields={showFields}
                />
              )}

              {sections[1] && showSection && (
                <OurSolutions
                  title={sections[1].title}
                  description={sections[1].description}
                />
              )}

              {sections[2] && (
                <ContactInfo
                  title={sections[2].title}
                  description={sections[2].description}
                  values={values}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              )}

              <div className={b.e('center').classes()}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className={b.e('submit').classes()}
                  analyticsValue="business|mdu-form|submit-request"
                  text="Submit request"
                />
              </div>
              <div className={b.e('center').classes()}>
                {submissionError && !isSubmitting && (
                  <div className={b.e('submission-error').classes()}>
                    {submissionError}
                  </div>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </section>
  )
}

export default withCookies(MDUForm)
