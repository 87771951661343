// Application requirements
import Model from 'app/models/Model'

// Model definition
export default class PhoneNumberModel extends Model {
  static props() {
    return {
      label: String,
      number: {
        type: String,
        required: true
      }
    }
  }
}
