import React from 'react'

import { connect } from 'react-redux'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Component from 'app/components/Component'
import { escapeHtml } from 'app/utils/dom-helpers'
import RichText from 'app/components/RichText/RichText'
import { searchSupport } from 'app/actions/supportSearch'
import SearchPagination from 'app/components/SearchPagination/SearchPagination'

import SupportSearch, {
  SupportSearchModel
} from '../SupportSearch/SupportSearch'

import './SupportSearchResults.scss'

// Module model definition
export class SupportSearchResultsModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      title: { type: String, required: true },
      resultsPerPage: Number,
      noResultsText: String,
      searchingText: String,
      searchBar: SupportSearchModel.shape
    }
  }
}

// Module definition
class SupportSearchResults extends Component {
  constructor(props) {
    super(props, 'support-search-results')

    this.state = {
      keyword: '',
      page: 1
    }

    this.search = this.search.bind(this)
    this.onKeywordChanged = this.onKeywordChanged.bind(this)
    this.onPageChanged = this.onPageChanged.bind(this)
    this.onPopState = this.onPopState.bind(this)
  }

  search(keyword, page = 1) {
    const pageSize = this.props.resultsPerPage || 10
    this.setState({ keyword, page })
    this.props.searchSupport(keyword, page, pageSize)
    window.scrollTo(0, 0)
  }

  onKeywordChanged(keyword) {
    if (window.location) {
      const page = 1
      window.history.pushState(
        { page, keyword },
        '',
        `${window.location.origin}${window.location.pathname}?page=${page}&q=${keyword}`
      )
      this.search(keyword, page)
    }
  }

  onPageChanged(keyword, page) {
    if (window.location) {
      window.history.pushState(
        { page, keyword },
        '',
        `${window.location.origin}${window.location.pathname}?page=${page}&q=${keyword}`
      )
      this.search(keyword, page)
    }
  }

  onPopState(event) {
    if (event && event.state) {
      this.search(event.state.keyword, event.state.page)
    }
  }

  componentDidMount() {
    const { query } = this.props.request
    if (query && query.q) {
      this.search(this.props.request.query.q, this.props.request.query.page)
    }

    window.addEventListener('popstate', this.onPopState)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopState)
  }

  prepareHeader(title, keyword) {
    const text = title.replace(
      '${query}', // eslint-disable-line no-template-curly-in-string
      keyword
    )

    return text
  }

  renderHeadline(title, keyword) {
    return (
      <h1 className={this.e('headline')}>
        {this.prepareHeader(title, keyword)}
      </h1>
    )
  }

  renderCounter(supportSearchData) {
    const PAGE_SIZE = this.props.resultsPerPage
    const { currentPage, totalResults } = supportSearchData

    const firstItem = (currentPage - 1) * PAGE_SIZE + 1
    const lastItem =
      totalResults > firstItem + (PAGE_SIZE - 1)
        ? firstItem + (PAGE_SIZE - 1)
        : totalResults

    return (
      <p className={this.e('counter')}>
        {`${firstItem}-${lastItem} of ${totalResults}`}
      </p>
    )
  }

  renderResults(results, keyword) {
    return (
      <div className={this.e('results-container')}>
        <ul>
          {results.map(result => {
            return (
              <li className={this.e('result-item')} key={uuid()}>
                <h2 className={this.e('result-title')}>
                  <a
                    className={this.e('result-link')}
                    href={result.url}
                    data-event="resultAction"
                    data-value={`support-search-results|${slug(keyword)}|${slug(
                      result.title.replace(/<[^>]*[^0-9a-zA-Z\s]/gi, '')
                    )}`}
                    dangerouslySetInnerHTML={{ __html: result.title }}
                  />
                </h2>
                <p
                  className={this.e('result-description')}
                  dangerouslySetInnerHTML={{ __html: result.desc }}
                />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  renderLoading(keyword) {
    const searchBar = this.props.searchBar
      ? new SupportSearchModel(this.props.searchBar)
      : null

    return (
      <div>
        {searchBar && (
          <SupportSearch {...searchBar} onSearch={this.onKeywordChanged} />
        )}
        <div className={this.b()}>
          <div className={this.e('container')}>
            <div className={this.e('message')}>
              <RichText
                markup={this.prepareHeader(
                  `<h3>${this.props.searchingText}</h3>`,
                  keyword
                )}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNothingFound(keyword) {
    const searchBar = this.props.searchBar
      ? new SupportSearchModel(this.props.searchBar)
      : null

    return (
      <div>
        {searchBar && (
          <SupportSearch {...searchBar} onSearch={this.onKeywordChanged} />
        )}
        <div className={this.b()}>
          <div className={this.e('container')}>
            <div className={this.e('message')}>
              <RichText
                markup={this.prepareHeader(this.props.noResultsText, keyword)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { title, supportSearchData } = this.props
    const { loading, data } = supportSearchData || {}
    const { results } = data || {}
    const { keyword } = this.state || {}
    const searchBar = this.props.searchBar
      ? new SupportSearchModel(this.props.searchBar)
      : null
    const escapedKeyword = escapeHtml(keyword)

    if (loading) {
      return this.renderLoading(escapedKeyword)
    }

    if (!results || results.length === 0) {
      return this.renderNothingFound(escapedKeyword)
    }

    return (
      <div>
        {searchBar && (
          <SupportSearch {...searchBar} onSearch={this.onKeywordChanged} />
        )}
        <div className={this.b()}>
          <div className={this.e('container')}>
            {this.renderHeadline(title, escapedKeyword)}
            {this.renderCounter(data)}
            {this.renderResults(results, escapedKeyword)}
          </div>
          <SearchPagination
            searchData={data}
            onPageClick={p => {
              this.onPageChanged(keyword, p)
            }}
          />
        </div>
      </div>
    )
  }
}

SupportSearchResults.props(SupportSearchResultsModel)

export default connect(
  state => {
    return {
      request: state.self,
      supportSearchData: state.supportSearch
    }
  },
  { searchSupport }
)(SupportSearchResults)
