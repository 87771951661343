import * as sharedModels from './shared/models'
import * as landingModels from './landing/models'
import * as pppModels from './ppp/models'
import * as searchModels from './search/models'
import * as homeModels from './home/models'
import * as leadFormModels from './leadForm/models'
import * as supportModels from './support/models'
import * as subscribeModels from './subscribe/models'

export default {
  ...sharedModels,
  ...landingModels,
  ...pppModels,
  ...searchModels,
  ...homeModels,
  ...leadFormModels,
  ...supportModels,
  ...subscribeModels
}
