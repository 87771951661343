import React from 'react'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import RichText from 'app/components/RichText/RichText'
import ResponsiveImage, {
  ResponsiveImageModel
} from 'app/components/ResponsiveImage/ResponsiveImage'

import './StaticDataVisualization.scss'

// Module model definition
export class StaticDataVisualizationModel extends Model {
  static props() {
    return {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      image: {
        type: ResponsiveImageModel.shape,
        required: true
      },
      offset: Number,
      caption: String,
      swapped: Boolean
    }
  }
}

// Module definition
export default class StaticDataVisualization extends Component {
  constructor(props) {
    super(props, 'static-data-visualization')
    this.image = new ResponsiveImageModel(this.props.image)
  }

  get split() {
    return !!this.props.caption
  }

  m() {
    return {
      split: this.split,
      swapped: this.split && this.props.swapped
    }
  }

  get offset() {
    const offset = this.props.offset && `${this.props.offset}px`
    const side = this.props.swapped ? 'right' : 'left'

    return {
      [side]: offset
    }
  }

  render() {
    const truthy = true

    return (
      <div className={this.b()}>
        <div className={this.e('container')}>
          <h2 className={this.e('title')}>{this.props.title}</h2>

          {this.props.description && (
            <RichText
              className={this.e('description')}
              markup={this.props.description}
              isMarkdown={truthy}
            />
          )}

          <div className={this.e('stack')}>
            <span className={this.e('stack-item-image')}>
              <ResponsiveImage
                {...this.image}
                style={this.offset}
                className={this.e('image')}
              />
            </span>

            {this.props.caption && (
              <span className={this.e('stack-item-label')}>
                <RichText
                  className={this.e('caption')}
                  markup={this.props.caption}
                  isMarkdown={truthy}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
}

StaticDataVisualization.props(StaticDataVisualizationModel)
