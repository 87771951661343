import React, { Suspense } from 'react'

import bowser from 'bowser'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ChatProvider } from 'app/modules/ChatContext'
import ErrorBoundary from 'app/components/ErrorBoundary'
import { Disclaimer, DisclaimerProvider } from '@shaw/react-component-library'
import {
  NoComponentFoundError,
  NoContentModelFoundError
} from 'app/utils/errors'
import GlobalAlertMessage from 'app/modules/shared/GlobalAlertMessage/GlobalAlertMessage'

import 'style/App.scss'
import '@shaw/react-component-library/build/index.css'
export class App extends React.PureComponent {
  constructor(props) {
    super(props)

    const ua = typeof window !== 'undefined' && navigator && navigator.userAgent
    const brws = ua && bowser.getParser(ua)
    const browser = brws && brws.getBrowser()
    const browserName = browser
      ? browser.name.toLowerCase().replace(/[\W_]+/g, '-')
      : ''
    const browserVersion = browser && parseInt(browser.version || 0, 10)

    if (browserName && global.document && global.document.body) {
      global.document.body.classList.add(browserName)
      global.document.body.classList.add(`${browserName}-${browserVersion}`)
    }
  }

  /**
   *
   * @param componentData
   * @param i
   * @returns {*}
   */
  renderComponent = (componentData, i = null) => {
    if (!componentData) {
      return null
    }

    const type = componentData.contentType
    const modelName = `${type}Model`

    const ComponentModel = this.props.availableModels[modelName]
    if (!ComponentModel) {
      console.warn('------- no model --------->', modelName)
      throw new NoContentModelFoundError(`No model found: ${modelName}`)
    }

    const props = new ComponentModel(componentData)

    if (i !== null) {
      props.key = componentData.entryId
      props.entryId = componentData.entryId
    }

    const Component = this.props.availableModules[type]
    if (!Component) {
      console.warn('------- no component --------->', type)
      throw new NoComponentFoundError(`No react component found: ${type}`)
    }

    return (
      <ErrorBoundary type={type} key={i}>
        <Component {...props} lazyload={i > 4} />
      </ErrorBoundary>
    )
  }

  setTouchClass() {
    return this.props.isTouch ? 'touch' : 'no-touch'
  }

  render() {
    if (global.document && global.document.body) {
      global.document.body.classList.toggle('noscroll', !this.props.scrolling)
    }
    const { modules, disclaimerTitle } = this.props
    return (
      <ChatProvider>
        <DisclaimerProvider>
          <main className={`app ${this.setTouchClass()}`} id="main">
            <Suspense fallback={null}>
              {modules.map(this.renderComponent)}
            </Suspense>
            <GlobalAlertMessage
              message={`<p>Browser not supported. For a better experience using this site, please update your browser.</p>`}
              showCta={false}
              showIcon={false}
              isSticky
              detectBrowser
              // add classname 'reverse' to change the layout to render the icon on the right
              className={`bottom-alert`}
            />
          </main>
          <Disclaimer title={disclaimerTitle} />
        </DisclaimerProvider>
      </ChatProvider>
    )
  }
}

App.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape()),
  availableModules: PropTypes.shape(),
  availableModels: PropTypes.shape()
}

App.defaultProps = {
  modules: [],
  availableModules: {},
  availableModels: {}
}

const mapStateToProps = ({ content, detectedFeatures, scrolling }) => ({
  modules:
    content.layout && content.layout.modules ? content.layout.modules : [],
  isTouch: detectedFeatures.isTouch,
  scrolling
})

export default connect(mapStateToProps)(App)
