import React from 'react'

import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import { IconModel } from 'app/components/Icon/Icon'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'
import SuperPromo, {
  SuperPromoModel
} from 'app/modules/ppp/SuperPromo/SuperPromo'
import Promo, { PromoModel } from 'app/modules/shared/Promo/Promo'
import GlobalNavPromo, {
  GlobalNavPromoModel
} from 'app/modules/shared/GlobalNav/GlobalNavPromo/GlobalNavPromo'

export class MasterPromoModel extends Model {
  static props() {
    return {
      name: String,
      entryId: String,
      promoType: String,
      contentType: String,
      title: String,
      icon: IconModel.shape,
      eyebrow: String,
      description: String,
      theme: String,
      variant: String,
      cta: LinkModel.shape,
      ctaAlt: LinkModel.shape,
      align: String,
      image: ResponsiveImageModel.shape,
      imageAlignment: String,
      imageFit: String,
      imagePosition: String,
      background: String,
      backgroundImage: ResponsiveImageModel.shape,
      backgroundSize: String,
      backgroundPosition: String,
      height: Number,
      desktopLayout: String,
      mobileLayout: String,
      noMargin: Boolean
    }
  }
}

export default function MasterPromo({ promoType, ...props }) {
  switch (promoType) {
    case 'Promo':
      const promoProps = new PromoModel(props) // eslint-disable-line
      return <Promo {...promoProps} />
    case 'SuperPromo':
      const superPromoProps = new SuperPromoModel(props) // eslint-disable-line
      return <SuperPromo {...superPromoProps} />
    case 'NavigationPromo':
      const navPromoProps = new GlobalNavPromoModel(props) // eslint-disable-line
      return <GlobalNavPromo {...navPromoProps} />
    default:
      return null
  }
}
