import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'

import './BasicCTA.scss'
// Module model definition
export class BasicCTAModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        required: true
      },
      white: Boolean,
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      button: {
        type: LinkModel.shape,
        required: true
      }
    }
  }
}

// Module definition
export default class BasicCTA extends Component {
  constructor(props) {
    super(props, 'basic-cta')
  }

  m() {
    return {
      white: this.props.white
    }
  }

  render() {
    const truthy = true
    const link = new LinkModel(this.props.button)

    return (
      <div className={this.b()}>
        <div className={this.e('container')}>
          <h3 className={this.e('title')}>{this.props.title}</h3>

          <RichText
            className={this.e('description')}
            markup={this.props.description}
            isMarkdown={truthy}
          />

          <span className={this.e('button')}>
            <Button
              {...link.asButton}
              design="scotch-primary"
              variant="light"
              analyticsEvent="navigationAction"
              analyticsValue={`${slug(this.props.name)}|${slug(link.text)}`}
            />
          </span>
        </div>
      </div>
    )
  }
}

BasicCTA.props(BasicCTAModel)
