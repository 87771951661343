import React, { useState, useEffect } from 'react'

import { Link as JumpLink, animateScroll as scroll } from 'react-scroll'
import { throttle } from 'lodash'
import Bem from 'app/utils/bem-helper'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Caret from 'app/components/SVG/Caret'

import RichText from 'app/components/RichText/RichText'
import ErrorBoundary from 'app/components/ErrorBoundary'
import Accordion, {
  AccordionModel
} from 'app/modules/shared/Accordion/Accordion'
import SupportArticleTopicModel from 'app/models/SupportArticleTopic/SupportArticleTopic'
import MasterHero, {
  MasterHeroModel
} from 'app/modules/shared/MasterHero/MasterHero'
import RelatedSupportArticles, {
  RelatedSupportArticlesModel
} from 'app/modules/support/RelatedSupportArticles/RelatedSupportArticles'

import SupportArticleSection from './SupportArticleSection'

import './SupportArticle.scss'

// Module model definition
export class SupportArticleModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      title: { type: String, required: true },
      description: String,
      articleType: String,
      product: String,
      topics: Array,
      relatedArticles: RelatedSupportArticlesModel.shape
    }
  }
}

function SupportHero({ name, title, articleType, renderBreadcrumbs }) {
  const hero = new MasterHeroModel({
    name: name,
    title: title,
    eyebrow: articleType,
    cta: null,
    subtitle: '',
    heroClassName: 'low-profile'
  })

  return (
    <MasterHero
      {...hero}
      name={hero.name || 'Gradient_Hero'}
      heroType="GradientHero"
      breadcrumbs={renderBreadcrumbs()}
      theme="scotch"
    />
  )
}

function Breadcrumbs({ title, product }) {
  const b = Bem('support-article')
  return (
    <React.Fragment>
      <div className={b.e('breadcrumbs').classes()}>
        <div className={b.e('breadcrumbs-container').classes()}>
          <ul>
            <li>
              <a
                href="/support"
                data-event="navigationAction"
                data-value={`support-article-breadcrumbs|support-home`}
              >
                Support
              </a>
              <Caret className="caret" width={8} height={8} color="#1F1F1F" />
            </li>
            <li>
              <a
                href={`/support/products/${slug(product)}`}
                data-event="navigationAction"
                data-value={`support-article-breadcrumbs|products|${slug(
                  product
                )}`}
              >
                {product}
              </a>
              <Caret className="caret" width={8} height={8} color="#1F1F1F" />
            </li>
            <li>{title}</li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}

function Topics({ topics, title }) {
  const b = Bem('support-article')
  return (
    <div className={b.e('topics').classes()}>
      {topics.map(t => {
        if (!t) return null
        const topic =
          t.contentType === 'Accordion'
            ? new AccordionModel(t)
            : new SupportArticleTopicModel(t)

        return t.contentType === 'Accordion' ? (
          <ErrorBoundary key={t.entryId} id={t.entryId} type={topic.name}>
            <Accordion
              {...topic}
              variant="left"
              entryId={t.entryId}
              parentNodeName={title}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary key={t.entryId} id={t.entryId} type={topic.name}>
            <SupportArticleSection
              {...topic}
              entryId={t.entryId}
              parentNodeName={title}
            />
          </ErrorBoundary>
        )
      })}
    </div>
  )
}

export default function SupportArticle({
  name,
  title,
  description,
  articleType,
  product,
  topics = [],
  relatedArticles = {}
}) {
  const b = Bem('support-article')
  const originalTop = 1100
  const [showButton, setShowButton] = useState(false)

  const checkScroll = () => {
    if (window.scrollY > originalTop) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', throttle(checkScroll, 500))
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', checkScroll)
      }
    }
  }, [])

  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 500, smooth: 'easeInOutQuint' })
  }

  const topClass = 'button--scotch-primary button--light'
  return (
    <section className={b.m({ scotch: true }).classes()}>
      <SupportHero
        name={name}
        theme="scotch"
        title={title}
        articleType={articleType}
        renderBreadcrumbs={() => (
          <Breadcrumbs title={title} product={product} />
        )}
      />
      <div className={b.e('content-wrapper').classes()}>
        <button
          id="button-scroll-to-top"
          className={`button ${topClass} scroll-to-top`}
          onClick={scrollToTop}
          style={{
            visibility: showButton ? 'visible' : 'hidden'
          }}
        >
          <span className="button__text">
            <Caret width={12} height={12} color="#ffffff" />
            Top
          </span>
        </button>

        <div className={b.e('content').classes()}>
          <div className={b.e('content-intro').classes()}>
            <div className={b.e('contents').classes()}>
              <span className={b.e('eyebrow').classes()}>Contents</span>
              <ul>
                {topics.map(topic => {
                  if (topic && topic.title) {
                    return (
                      <li key={topic.entryId}>
                        <JumpLink
                          to={`id-${topic.entryId}`}
                          smooth="easeInOutQuint"
                          offset={0}
                          duration={500}
                          data-event="navigationAction"
                          data-value={`support|content-jumplink|${slug(
                            topic.title
                          )}`}
                        >
                          <RichText markup={topic.title} />
                        </JumpLink>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
            <div className={b.e('description').classes()}>
              <span className={b.e('eyebrow').classes()}>Overview</span>
              <RichText markup={description} renderers={{ root: 'p' }} />
            </div>
          </div>
          <Topics topics={topics} title={title} />
          {relatedArticles && <RelatedSupportArticles {...relatedArticles} />}
        </div>
      </div>
    </section>
  )
}

SupportArticle.propTypes = SupportArticleModel.propTypes()
SupportArticle.defaultProps = SupportArticleModel.defaultProps()
