import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import Bem from 'app/utils/bem-helper'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'

import { subscribe, unsubscribe } from 'app/actions/subscription'
import ResponsiveImage, {
  ResponsiveImageModel
} from 'app/components/ResponsiveImage/ResponsiveImage'

import './SubscriptionForm.scss'

const ERRORS = {
  email: 'This value should be a valid email.'
}

const subSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  email: Yup.string().email(ERRORS.email).required('This field is required'),
  consent: Yup.boolean().oneOf([true], 'You must consent to continue')
})
const unsubSchema = Yup.object().shape({
  email: Yup.string().email(ERRORS.email).required('This field is required')
})

export class SubscriptionFormModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      title: { type: String, required: true },
      formType: { type: String, required: true },
      introduction: String,
      emailInputLabel: String,
      emailInputPlaceholder: String,
      submitButtonName: String,
      ctaLink: LinkModel.shape,
      consentText: String,
      footerText: String,
      textOnSuccess: String,
      image: ResponsiveImageModel.shape,
      test: { type: Boolean, default: false }
    }
  }
}

function analytics(subscription, formType) {
  window?.utag?.view({
    page_name: `${window?.utag_data?.page_name}|${subscription?.status}`,
    lead_form_name: `leadform|${formType}`,
    form_name: `leadform|${formType}`,
    form_step: subscription?.status,
    event_name: 'form_step'
  })
}

export function SubscriptionForm(props) {
  const b = Bem('subscription-form')
  const [submissionError, setSubmissionError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [isUsingRecaptchaV2, setIsUsingRecaptchaV2] = useState(false)
  const [v2Token, setV2Token] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    window.setupRecaptchaV2 = () => {
      window.grecaptcha?.render('recaptcha-v2-badge', {
        sitekey: window?.RECAPTCHA_KEY_V2,
        callback: token => {
          setV2Token(token)
          setIsDisabled(false)
          setSubmissionError(null)
        },
        'expired-callback': () => {
          setIsDisabled(true)
          setSubmissionError(null)
        }
      })
    }

    window.setupRecaptchaV3 = () => {
      window.grecaptcha?.render('recaptcha-v3-badge', {
        badge: 'inline',
        sitekey: window?.RECAPTCHA_KEY_V3,
        size: 'invisible'
      })
    }

    injectRecaptchaScript(false)

    return () => {
      delete window.setupRecaptchaV2
      delete window.setupRecaptchaV3
    }
  }, [])

  const injectRecaptchaScript = isRecaptchaV2 => {
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    const version = isRecaptchaV2 ? 'V2' : 'V3'
    script.setAttribute(
      'src',
      `https://www.google.com/recaptcha/api.js?onload=setupRecaptcha${version}&render=explicit`
    )
    head.append(script)
  }

  const verifyRecaptcha = async () => {
    return await window?.grecaptcha?.execute(0, { action: props.formType })
  }

  let schema = props.formType === 'subscribe' ? subSchema : unsubSchema
  if (props.test) {
    schema = props.schema
  }
  const mdl = new LinkModel({
    href: '#',
    label: props.submitButtonName
  })

  const button = {
    ...mdl.asButton,
    type: 'submit',
    analyticsEvent: 'navigationAction',
    analyticsValue: `${slug(props.name)}|${slug(mdl.text)}`
  }

  const handleSubmit = (values, setSubmitting) => {
    setSubmissionError(null)
    setIsDisabled(true)
    const fn =
      props.formType === 'subscribe' ? props.subscribe : props.unsubscribe
    fn(values, {
      success: async rsp => {
        setSubmitting(false)
        if (rsp.statusCode === 'OK') {
          const success =
            props.textOnSuccess?.replace('%email%', values.email) ||
            'You have been unsubscribed'
          setSuccess(success)
          analytics(props.subscription, props.formType)
        } else if (rsp.statusCode === 'FAILED' && values.version === 'v3') {
          setIsUsingRecaptchaV2(true)
          setIsDisabled(true)
          await injectRecaptchaScript(true)
        } else if (values.version === 'v2') {
          setSubmissionError(
            'Form submission was unsuccessful, please try again later.'
          )
        }
      },
      failure: async rsp => {
        setSubmitting(false)
        setIsDisabled(false)
        console.error('Submission Errors', rsp?.errors)
        if (values.version === 'v2') {
          setSubmissionError(
            'Form submission was unsuccessful, please try again later.'
          )
        } else {
          setIsUsingRecaptchaV2(true)
          setIsDisabled(true)
          await injectRecaptchaScript(true)
        }
      }
    })
  }
  const initialFormValues =
    props.formType === 'subscribe'
      ? {
          name: '',
          email: '',
          consent: false
        }
      : {
          email: ''
        }
  return (
    <div className={b.classes()}>
      <div className={b.e('container').classes()}>
        <div className={b.e('form-container').classes()}>
          {success && <RichText markup={success} />}
          {!success && (
            <div>
              <h3>{props.title}</h3>
              <div className={b.e('intro').classes()}>
                <RichText markup={props.introduction} />
              </div>
            </div>
          )}

          {!success && (
            <Formik
              enableReinitialize
              validateOnMount={false}
              validationSchema={schema}
              initialValues={initialFormValues}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true)
                let token
                try {
                  if (!isUsingRecaptchaV2) {
                    token = await verifyRecaptcha()
                    if (!token) {
                      throw new Error('Recaptcha V3 token error')
                    }
                    handleSubmit(
                      { ...data, token, version: 'v3' },
                      setSubmitting
                    )
                  } else {
                    token = v2Token
                    handleSubmit(
                      { ...data, token, version: 'v2' },
                      setSubmitting
                    )
                  }
                } catch (e) {
                  console.error('Recaptcha V3 error, fallback to V2', e)
                  setSubmitting(true)
                  if (!token) {
                    setIsUsingRecaptchaV2(true)
                    await injectRecaptchaScript(true)
                  }
                }
              }}
            >
              {({ touched, isValid, isSubmitting }) => {
                const isTouched = props.test
                  ? true
                  : Object.keys(touched).length > 0

                return (
                  <Form className={b.e('form').classes()}>
                    {props.formType === 'subscribe' && (
                      <label
                        className={b.e('input-label').classes()}
                        htmlFor="name"
                      >
                        <span>Your Name:</span>
                        <Field
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter your legal name"
                          autoComplete="off"
                          className={b.e('input').classes()}
                          data-testid="name"
                        />
                        <ErrorMessage
                          component="div"
                          className={b.e('error').m({ single: true }).classes()}
                          name="name"
                        />
                      </label>
                    )}
                    <label
                      className={b.e('input-label').classes()}
                      htmlFor="email"
                    >
                      <span>{props.emailInputLabel}</span>
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        placeholder={props.emailInputPlaceholder}
                        autoComplete="off"
                        className={b.e('input').classes()}
                        data-testid="email"
                      />
                      <ErrorMessage
                        component="div"
                        className={b.e('error').m({ single: true }).classes()}
                        name="email"
                      />
                    </label>
                    {props.formType === 'subscribe' && (
                      <label
                        className={b
                          .e('input-label')
                          .m({ checkbox: true })
                          .classes()}
                        htmlFor="consent"
                      >
                        <Field
                          type="checkbox"
                          name="consent"
                          id="consent"
                          className={b.e('input').classes()}
                          data-testid="consent"
                        />
                        <RichText
                          className={b.e('consent-label').classes()}
                          markup={props.consentText}
                        />
                        <ErrorMessage
                          component="div"
                          className={b.e('error').m({ single: true }).classes()}
                          name="consent"
                        />
                      </label>
                    )}
                    <div>
                      <Button
                        {...button}
                        disabled={
                          !isTouched || !isValid || isSubmitting || isDisabled
                        }
                      />
                    </div>
                    {submissionError && !isSubmitting && (
                      <div className={b.e('submission-error').classes()}>
                        {submissionError}
                      </div>
                    )}
                  </Form>
                )
              }}
            </Formik>
          )}
          {!success && (
            <div className={b.e('footer').classes()}>
              <RichText markup={props.footerText} />
            </div>
          )}
        </div>
        {props.image && (
          <div className={b.e('image-container').classes()}>
            <ResponsiveImage {...props.image} />
          </div>
        )}
        {isUsingRecaptchaV2 && (
          <div
            className={b
              .e('recaptcha-badge')
              .m({
                success: success !== null
              })
              .classes()}
            id="recaptcha-v2-badge"
          />
        )}
        {!isUsingRecaptchaV2 && (
          <div
            className={b
              .e('recaptcha-badge')
              .m({ success: success !== null })
              .classes()}
            id="recaptcha-v3-badge"
          />
        )}
      </div>
    </div>
  )
}

export default connect(
  state => {
    return {
      subscription: state.subscription
    }
  },
  { subscribe, unsubscribe }
)(SubscriptionForm)
