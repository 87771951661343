import Model from 'app/models/Model'
import { IconModel } from 'app/components/Icon/Icon'
import { LinkModel } from 'app/components/Link/Link'
// Model definition
export default class ProductOfferSpecificationModel extends Model {
  static props() {
    return {
      name: String,
      description: String,
      number: String,
      unit: String,
      icon: IconModel.shape,
      link: LinkModel.shape
    }
  }
}
