import React, { useEffect, useState, useRef } from 'react'

import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import { loadJavaScript, loadStyle } from 'app/utils/dom-helpers'
import GradientBackground from 'app/components/GradientBackground/GradientBackground'
import Bem from 'react-bem-helper'

import './WysdomSearch.scss'

export class WysdomSearchModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        default: 'Wysdom search'
      },
      searchResultsPage: {
        type: LinkModel.shape,
        default: LinkModel.defaultProps()
      },
      mode: {
        type: String,
        default: 'UAT',
        required: true
      },
      language: {
        type: String,
        default: 'en',
        required: true
      },
      lineOfBusiness: {
        type: String,
        default: 'BusinessGlobal',
        required: true
      },
      enableCognitiveSearch: {
        type: Boolean,
        default: true,
        required: true
      },
      enableSolutionModal: {
        type: Boolean,
        default: false,
        required: true
      }
    }
  }
}

export default function WysdomSearch({
  searchResultsPage,
  enableSolutionModal,
  mode,
  language,
  enableCognitiveSearch,
  lineOfBusiness
}) {
  const classes = new Bem({
    name: 'wysdom-search',
    prefix: ''
  })
  const [loaded, setLoaded] = useState(false)
  const wysdomSearchResultsRef = useRef()
  const wysdomSearchBarRef = useRef()

  useEffect(() => {
    const jsFile = '/assets/wysdom.websearch.2.1.32.js'
    const cssFile = '/assets/css/wysdom.websearch.2.1.32.css'
    loadStyle(cssFile, false)

    if (!loaded) {
      loadJavaScript(jsFile, () => {
        window.wysdom.websearch.init({
          mode: mode,
          language: language
        })

        window.wysdom.websearch.searchBar({
          searchBarId: 'wysdom-search-bar',
          searchResultId: 'wysdom-search-results',
          enableCognitiveSearch: enableCognitiveSearch,
          lob: lineOfBusiness,
          enablePagination: true,
          numPerPage: 10
        })

        if (!searchResultsPage.href) {
          // in the case the module is placed on search results page
          // so when use click on search button we need show results on this page
          // also Wysdom SDK will take value of 'q' parameter in URL
          window.wysdom.websearch.searchResult({
            searchResultId: 'wysdom-search-results',
            enableSolutionModal: enableSolutionModal
          })
        }

        setLoaded(true)
      })
    }
  }, [])

  return (
    <div {...classes()}>
      <GradientBackground {...classes({ element: 'hero-bg' })}>
        <div {...classes({ element: 'results-header' })}>
          <h1>Search results</h1>
        </div>
      </GradientBackground>
      <div {...classes({ element: 'container' })}>
        <div {...classes({ element: 'bar' })}>
          {!loaded && <div {...classes({ element: 'init' })}>Loading...</div>}
          <div id="wysdom-search-bar" ref={wysdomSearchBarRef} />
        </div>

        {searchResultsPage && !searchResultsPage.href && (
          <div {...classes({ element: 'results' })}>
            <div id="wysdom-search-results" ref={wysdomSearchResultsRef} />
          </div>
        )}
      </div>
    </div>
  )
}
