import { REGISTER_MODULE_ANCHOR, SET_STORE } from 'app/actions/shared'

import { default as createReducer } from 'app/utils/create-reducer'

export const initialState = {
  anchors: [],
  data: null,
  store: null
}

export default createReducer(
  {
    [REGISTER_MODULE_ANCHOR]: (state, { data }) => {
      if (!state.anchors.includes(data.targetModule.entryId)) {
        const newAnchors = Array.from(state.anchors)
        newAnchors.push(data.targetModule.entryId)

        return {
          ...state,
          anchors: newAnchors
        }
      } else {
        return state
      }
    },
    [SET_STORE]: (state, { data }) => {
      return {
        ...state,
        store: data
      }
    }
  },
  initialState
)
