import React from 'react'

import PropTypes from 'prop-types'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import Component from 'app/components/Component'
import RichText from 'app/components/RichText/RichText'
import Notification from 'app/components/Notification/Notification'
import ItemGroup, { ItemGroupModel } from 'app/components/ItemGroup/ItemGroup'

import './HeroItemTiles.scss'

export class HeroItemTilesModel extends Model {
  static props() {
    return {
      name: String,
      intro: String,
      title: String,
      notification: Object,
      itemGroup: PropTypes.arrayOf(ItemGroupModel.shape).isRequired
    }
  }
}
export default class HeroItemTiles extends Component {
  constructor(props) {
    super(props, 'hero-item-tiles')
  }

  m() {
    return {
      scotch: true
    }
  }

  render() {
    return (
      <div className={this.b()}>
        {this.props.title && (
          <RichText markup={this.props.intro} className={this.e('title')} />
        )}
        {this.props.intro && (
          <div className={this.e('intro')}>
            <RichText markup={this.props.intro} />
          </div>
        )}
        <div className={this.e('notification-container')}>
          {this.props.notification && (
            <Notification {...this.props.notification} />
          )}
        </div>
        {this.renderItemGroups(this.props)}
      </div>
    )
  }
  renderItemGroups(itemsBoxArr) {
    const itemGroupArray = itemsBoxArr.itemGroup
    return itemGroupArray.map(itemBox => {
      return <ItemGroup key={uuid()} {...itemBox} />
    })
  }
}

HeroItemTiles.props(HeroItemTilesModel)
