import React from 'react'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import RichText from 'app/components/RichText/RichText'
import preProcessVideoUrl from 'app/utils/videourl-preprocessor'

import './YouTubePlayer.scss'

// Module model definition
export class YouTubePlayerModel extends Model {
  constructor(d) {
    super(d)
    this.youTubeVideo = preProcessVideoUrl(this.youTubeVideo)
  }
  static props() {
    return {
      name: String,
      title: String,
      description: String,
      youTubeVideo: String
    }
  }
}

const Title = ({ title, inline }) =>
  inline ? <p className="intro">{title}</p> : <h2 className="title">{title}</h2>

// Module definition
export default class YouTubePlayer extends Component {
  constructor(props) {
    super(props, 'youtube-player')

    this.state = {
      finalWidth: null
    }
    this.getSize = this.getSize.bind(this)
  }

  componentDidMount() {
    this.getSize()
    window.addEventListener('resize', this.getSize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getSize)
  }

  getSize() {
    const { width, entryId } = this.props
    if (!width) {
      return
    }
    const elem = document.getElementById(`id-${entryId}`)
    const isResponsive = elem && elem.offsetWidth < width
    const finalWidth = isResponsive ? elem.offsetWidth : width
    this.setState({ finalWidth })
  }

  render() {
    const {
      entryId,
      title,
      description,
      youTubeVideo,
      inline,
      width = null,
      align
    } = this.props
    const { finalWidth } = this.state
    const style = width
      ? { maxWidth: width, paddingBottom: finalWidth * 0.5625 }
      : {}

    return (
      <div className={this.b()}>
        <div
          id={`id-${entryId}`}
          className={this.e('container', !inline && 'mx', align)}
        >
          {title && <Title title={title} inline={inline} />}
          {description && (
            <RichText className="description" markup={description} />
          )}
          <div
            style={style}
            className={this.e('responsive-item', 'responsive-16by9')}
          >
            <iframe
              title={title}
              src={youTubeVideo}
              color="white"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}

YouTubePlayer.props(YouTubePlayerModel)
