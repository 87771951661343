import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import ProductOfferModel from 'app/models/ProductOffer/ProductOffer'
import PriceInformation from 'app/components/PriceInformation/PriceInformation'

import './PromotionalPlanCard.scss'

// Module model definition
export class PromotionalPlanCardModel extends Model {
  constructor(d) {
    super(d)

    this.productOffer = new ProductOfferModel(d.productOffer)
    this.leadGenCta = d.categoryLandingCta
    this.categoryPageCta = d.productPlanPage
  }

  static props() {
    return {
      name: {
        type: String,
        required: true,
        default: 'Product Plan Card'
      },
      productPrefix: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: 'Description'
      },
      productOffer: {
        type: ProductOfferModel.shape,
        default: ProductOfferModel.defaultProps()
      },
      leadGenCta: LinkModel.shape,
      categoryPageCta: LinkModel.shape,
      isFeatured: {
        type: Boolean,
        default: false
      },
      background: {
        type: String,
        default: 'transparent'
      },
      variant: {
        type: String,
        default: 'default'
      }
    }
  }
}

// Module definition
export default class PromotionalPlanCard extends Component {
  constructor(props) {
    super(props, 'promotional-plan-card')
  }

  m() {
    return {
      scotch: true,
      [this.props.variant]: true,
      [this.props.background]: true
    }
  }

  render() {
    const {
      name,
      productOffer,
      productPrefix,
      description,
      leadGenCta,
      categoryPageCta,
      isFeatured,
      background,
      variant
    } = this.props
    const b = Bem('promotional-plan-card-container')
    const { stylizedName, stylizedNameSlug } = productOffer
    const leadGenLink = new LinkModel(leadGenCta)
    const categoryPageLink = new LinkModel(categoryPageCta)

    return (
      // <div className={b.classes()}>
      <div
        className={b
          .m({ scotch: true, [variant]: true, [background]: true })
          .classes()}
      >
        <div className="promotional-plan-card-content">
          <div className={this.b(isFeatured ? 'featured' : 'default')}>
            <div className={this.e('half')}>
              <div className={this.e('header')}>
                {productPrefix && (
                  <RichText isMarkdown markup={productPrefix} />
                )}
                <RichText isMarkdown markup={stylizedName} />
              </div>
              <RichText
                className={this.e('description')}
                isMarkdown
                markup={description || ''}
              />
              {categoryPageCta && (
                <Button
                  className={this.e('product-plan-link-md')}
                  {...categoryPageLink.asButton}
                  design="link-caret"
                  analyticsEvent={categoryPageLink.determineAnalyticsEvent()}
                  analyticsValue={`${slug(name)}|${stylizedNameSlug}|${slug(
                    categoryPageLink.text
                  )}`}
                />
              )}
            </div>
            <div className={this.e('half')}>
              <div className={this.e('prices')}>
                <PriceInformation productOffer={productOffer} />
              </div>
              <div className={this.e('buttons')}>
                {leadGenCta && (
                  <Button
                    {...leadGenLink.asButton}
                    analyticsEvent={leadGenLink.determineAnalyticsEvent()}
                    analyticsValue={`${slug(name)}|${stylizedNameSlug}|${slug(
                      leadGenLink.text
                    )}`}
                  />
                )}
                {categoryPageCta && (
                  <Button
                    className={this.e('product-plan-link-sm')}
                    {...categoryPageLink.asButton}
                    design="link-caret"
                    analyticsEvent={categoryPageLink.determineAnalyticsEvent()}
                    analyticsValue={`${slug(name)}|${stylizedNameSlug}|${slug(
                      categoryPageLink.text
                    )}`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PromotionalPlanCard.propTypes = PromotionalPlanCardModel.propTypes()
PromotionalPlanCard.defaultProps = PromotionalPlanCardModel.defaultProps()
