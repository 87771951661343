/**
 * Compose a set of functions, applying them from right to left.
 * @param {...Function} functions Functions to compose. Each function should accept
 * one parameter (except for the first one at right).
 * @return {Function} A new composed function
 */
export default function compose(...functions) {
  if (functions.length < 1) {
    throw Error('Compose must be called with at least 1 function')
  }

  return functions.reduce(
    (f, g) =>
      (...args) =>
        f(g(...args))
  )
}
