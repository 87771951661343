export const validate = (values, validationRules = []) => {
  const errors = {}

  validationRules.forEach(rule => {
    const name = rule.name

    if (name !== 'shawAccountNumber' && !values[name] && rule.required) {
      errors[name] = rule.requiredError
    }
    if (
      name !== 'shawAccountNumber' &&
      values[name] &&
      rule.validation &&
      !new RegExp(rule.validation).test(values[name])
    ) {
      errors[name] = rule.validationError
    }
  })

  if (
    values.firstName &&
    values.lastName &&
    values.firstName.toLowerCase() === values.lastName.toLowerCase()
  ) {
    errors.firstName = 'First and last name cannot be the same.'
    errors.lastName = 'First and last name cannot be the same.'
  }

  if (
    !values.shawAccountNumber &&
    values.helpWith &&
    values.helpWith.match(/sales/gi) === null
  ) {
    errors.shawAccountNumber = 'Must provide account number'
  } else if (
    values.shawAccountNumber &&
    values.shawAccountNumber.match(
      /^((?!-)(?!.*--)(?!.*-$)[\d-+]{1,10}|(\d{3}(-)?\d{4}(-)?\d{4}))$/
    ) === null
  ) {
    errors.shawAccountNumber =
      'Enter 1 to 11 digits. You can separate them with "-".'
  }

  return errors
}
