import React from 'react'

import { connect } from 'react-redux'

import Model from 'app/models/Model'
import Icon from 'app/components/Icon/Icon'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'

import './SupportSearch.scss'

export class SupportSearchModel extends Model {
  static props() {
    return {
      placeholder: {
        type: String,
        default: 'Search'
      },
      supportSearchPage: {
        type: LinkModel.shape,
        default: LinkModel.defaultProps()
      },
      searchTerm: {
        type: String,
        default: ''
      }
    }
  }
}

// Module definition
class SupportSearch extends Component {
  constructor(props) {
    super(props, 'support-search')

    this.state = {
      opened: true,
      searchTerm: props.searchTerm || '',
      offset: 0,
      position: 'absolute',
      visibility: 'hidden'
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onPopState = this.onPopState.bind(this)
  }

  m() {
    return {
      opened: this.state.opened,
      scotch: true
    }
  }

  onPopState(event) {
    if (event && event.state) {
      this.setState({ searchTerm: event.state.keyword })
    }
  }

  resize = () => {
    this.compute()
  }

  compute = () => {
    const hero =
      document.querySelector('.gradient-hero') ||
      document.querySelector('.gradient-background')
    const alert = document.querySelector('.global-alert-message-group')

    const heroBox = hero ? hero.getBoundingClientRect() : {}
    const alertBox = alert ? alert.getBoundingClientRect() : null

    const alertOffset = alertBox ? alertBox.height - 50 : 0
    const offset = heroBox.bottom - 30 + alertOffset // half of the search bar height
    const position = heroBox.bottom === undefined ? 'relative' : 'absolute'
    this.setState({
      offset,
      position,
      visibility: 'visible'
    })
  }

  componentDidMount() {
    const keyword =
      this.props.request && this.props.request.query
        ? this.props.request.query.q
        : ''

    this.setState({ searchTerm: keyword })
    this.compute()
    window.addEventListener('popstate', this.onPopState)
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopState)
    window.removeEventListener('resize', this.resize)
  }

  onSubmit(event) {
    const safeSearchTerm = this.state.searchTerm?.replace(/\s/g, '%20')
    if (typeof this.props.onSearch === 'function') {
      event.preventDefault()
      this.props.onSearch(safeSearchTerm)
    }
  }

  changeHandler(event) {
    this.setState({ searchTerm: event.target.value })
  }

  hasValue() {
    return this.state.searchTerm && this.state.searchTerm.length > 0
  }

  transitionEndHandler() {
    if (!this.state.opened) {
      this.setState({ searchTerm: '' })
    }
  }

  render() {
    const { supportSearchPage, placeholder } = this.props

    const { searchTerm, offset, position, visibility } = this.state
    const searchLink = new LinkModel(supportSearchPage)

    return (
      <div
        className={this.b()}
        onTransitionEnd={this.transitionEndHandler}
        style={{ top: offset, position, visibility }}
      >
        <form
          action={`${searchLink.href}?q=${searchTerm}`}
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          <div className={this.e('input-wrapper')}>
            <button
              type="submit"
              aria-label="Submit Search"
              className={this.e('submit-button', {
                'has-value': this.hasValue()
              })}
              onClick={this.submitClick}
              data-event="navigationAction"
              data-value={`support-search|submit`}
            >
              <Icon className={this.e('icon')} type="Nav_Search" />
              <span className={this.e('label')}>Search</span>
            </button>
            <input
              className={this.e('input')}
              name="q"
              type="search"
              placeholder={placeholder}
              aria-label={placeholder}
              ref={this.getInputRef}
              value={searchTerm}
              onChange={this.changeHandler}
            />
          </div>
        </form>
      </div>
    )
  }
}

SupportSearch.props(SupportSearchModel)

export default connect(state => {
  return {
    request: state.self
  }
})(SupportSearch)
