import React from 'react'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import Component from 'app/components/Component'
import RichText from 'app/components/RichText/RichText'
import ButtonAndLink, {
  ButtonAndLinkModel
} from 'app/modules/landing/ButtonAndLink/ButtonAndLink'
import ResponsiveImage, {
  ResponsiveImageModel
} from 'app/components/ResponsiveImage/ResponsiveImage'

import './FeatureBlock.scss'

export class FeatureBlockModel extends Model {
  constructor(d) {
    super(d)
  }

  static props() {
    return {
      name: String,
      image: {
        type: ResponsiveImageModel.shape,
        required: true
      },
      copy: String,
      textBox: ButtonAndLinkModel.shape,
      background: { type: String, default: 'white' }
    }
  }
}

export default class FeatureBlock extends Component {
  constructor(props) {
    super(props, 'feature-block')
  }
  render() {
    const { image, copy, textBox, background } = this.props

    return (
      <div className={this.b(background)}>
        <div className={this.e('feature')}>
          <ResponsiveImage key={uuid()} {...image} />
          <div className={this.e('feature-copy')}>
            <RichText key={uuid()} markup={copy} isMarkdown />
          </div>
        </div>
        <div className={this.e('feature')}>
          <ButtonAndLink key={uuid()} {...textBox} />
        </div>
      </div>
    )
  }
}
