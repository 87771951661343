import Model from 'app/models/Model'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'

export default class ImageSelectorItemModel extends Model {
  static props() {
    return {
      entryId: {
        type: String,
        required: true,
        default: '1'
      },
      title: {
        type: String,
        required: true,
        default: 'title'
      },
      subCopy: {
        type: String,
        default: 'description'
      },
      image: {
        type: ResponsiveImageModel.shape,
        required: true,
        default: ResponsiveImageModel.defaultProps()
      }
    }
  }
}
