import React, { useEffect, useState } from 'react'

import omit from 'lodash/omit'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import { SuperPromo } from '@shaw/react-component-library'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'

import './SuperPromo.scss'

// Component model definition
export class SuperPromoModel extends Model {
  constructor(d) {
    super(d)
    this.entryId = d.entryId
    this.responsiveImage = d.image || null // { large: d.image }
  }

  static props() {
    return {
      entryId: String,
      name: String,
      eyebrow: String,
      title: String,
      description: String,
      theme: { type: String, default: 'scotch' },
      variant: String,
      responsiveImage: {
        type: ResponsiveImageModel.shape
      },
      imageAlignment: String,
      imageFit: String,
      imagePosition: String,
      backgroundImage: {
        type: ResponsiveImageModel.shape
      },
      backgroundSize: String,
      backgroundPosition: String,
      cta: LinkModel.shape,
      ctaAlt: LinkModel.shape,
      offset: {
        type: String,
        default: '-100px'
      }
    }
  }
}

export default function SuperPromoComponent({
  entryId,
  theme,
  cta,
  ctaAlt,
  responsiveImage,
  imageAlignment,
  imageFit,
  imagePosition,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  ...props
}) {
  const [page, setPage] = useState('')
  useEffect(() => {
    const trackPage =
      typeof window !== 'undefined' && location.pathname !== '/'
        ? `${slug(location.pathname)}|`
        : 'homepage|'
    setPage(trackPage)
  }, [])

  const defaultCta = cta ? new LinkModel(cta) : null
  const altCta = ctaAlt ? new LinkModel(ctaAlt) : null
  const respImage = responsiveImage
    ? {
        alt:
          responsiveImage?.alt ||
          responsiveImage?.title ||
          responsiveImage?.large?.title ||
          responsiveImage?.medium?.title ||
          responsiveImage?.small?.title,
        large: responsiveImage?.large?.file?.url || null,
        medium: responsiveImage?.medium?.file?.url || null,
        small: responsiveImage?.small?.file?.url || null
      }
    : null

  const bgImage = backgroundImage
    ? {
        large: backgroundImage?.large?.file?.url || null,
        medium: backgroundImage?.medium?.file?.url || null,
        small: backgroundImage?.small?.file?.url || null
      }
    : null
  const firstCta = defaultCta && omit(defaultCta.asButton, ['activeRoute'])
  const secondCta = altCta && omit(altCta.asButton, ['activeRoute'])

  return (
    <SuperPromo
      id={entryId}
      theme={theme}
      {...props}
      background={bgImage}
      backgroundSize={backgroundSize}
      backgroundPosition={backgroundPosition}
      cta={
        cta
          ? {
              ...firstCta,
              variant: defaultCta.style,
              theme: defaultCta.variant,
              'data-event': defaultCta.determineAnalyticsEvent(),
              'data-value': `content|${page}${slug(
                props.title || props.name
              )}|${slug(defaultCta.text)}`
            }
          : null
      }
      ctaAlt={
        ctaAlt
          ? {
              ...secondCta,
              variant: altCta.style,
              theme: altCta.variant,
              'data-event': altCta.determineAnalyticsEvent(),
              'data-value': `content|${page}${slug(
                props.title || props.name
              )}|${slug(altCta.text)}`
            }
          : null
      }
      responsiveImage={respImage}
      imageAlignment={imageAlignment}
      imageFit={imageFit}
      imagePosition={imagePosition}
    />
  )
}
