import url from 'url'

import Model from 'app/models/Model'
import { replaceHostname, assetUrl } from 'app/utils/paths'

const managementKey =
  typeof process !== 'undefined' && process?.env?.CONTENTFUL_MANAGEMENT_API_KEY
const spaceId =
  typeof process !== 'undefined' && process?.env?.CONTENTFUL_CONFIG_SPACE_ID

const contentfulConfig = {
  managementKey,
  spaceId,
  imageHost: 'images.contentful.com',
  previewHost: 'preview.contentful.com'
}

export const imageQuery = {
  png: `?fm=png&fl=png8`,
  jpg: `?fm=jpg&fl=progressive`
}

export function getOptimizedImage(source) {
  const matchExt = source.match(/(jpg|jpeg|tiff|png|gif|svg)$/i)
  const fileExt = matchExt && matchExt[0]
  const fileName = matchExt && source.replace(`.${fileExt}`, '')
  const optimizations = () => {
    if (!fileExt || fileExt.match(/tiff|gif|svg/)) return ''
    if (fileExt.match(/jpg|jpeg/)) {
      return imageQuery.jpg
    } else if (fileExt.match(/png/)) {
      return imageQuery.png
    }
  }
  const finalSrc =
    matchExt && matchExt[0]
      ? `${fileName}.${fileExt}${optimizations()}`
      : source
  return finalSrc
}

// Model definition
export default class ImageModel extends Model {
  constructor(d) {
    super(d)

    this.src = this.fakeMapContentfulImageUrl(this.src)
    this.type =
      this.mime && this.mime.length ? this.mime.split('/')[1] : this.type
  }

  mapContentfulImageUrl(src) {
    // If it already has a protocol, we don't need to add one
    if (src == null) {
      src = ''
    }
    let parsedSrc
    if (src.startsWith('http')) {
      parsedSrc = url.parse(src)
    } else {
      parsedSrc = url.parse(assetUrl(src))
    }

    // Ensure that this is a contentful hosted image that we can map back to
    const isContentful = parsedSrc.hostname === contentfulConfig.imageHost

    // If this isn't a contentful image, we can't map back so we serve as is
    if (!isContentful) {
      return getOptimizedImage(parsedSrc.href)
    }

    // Get the hostname either from an env variable or from the window.location object
    const isServer = process?.env?.REMAP_HOSTNAME
    const hostname = isServer
      ? process?.env?.REMAP_HOSTNAME
      : window.location.host

    // Replace the contentful images host with our hostname/media route
    return replaceHostname(
      getOptimizedImage(src),
      contentfulConfig.imageHost,
      `${hostname}/media`
    )
  }

  // TODO remove this, Dustin wants to see it for now
  fakeMapContentfulImageUrl(src) {
    if (src == null || !src) {
      return ''
    }
    const finalSrc = getOptimizedImage(src)

    if (src.startsWith('http')) {
      return finalSrc
    }

    if (src.startsWith('//')) {
      return `https:${finalSrc}`
    }

    return `https://${finalSrc}`
  }

  static props() {
    // TODO refactor this to PropTypes
    return {
      src: {
        type: String,
        path: 'file.url'
      },
      height: {
        type: Number,
        path: 'file.details.image.height'
      },
      width: {
        type: Number,
        path: 'file.details.image.width'
      },
      mime: {
        type: String,
        path: 'file.contentType'
      },
      name: {
        type: String,

        path: 'file.fileName'
      },
      type: String,
      title: String,
      description: String
    }
  }
}
