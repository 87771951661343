import React, { memo } from 'react'
import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'

export function Rate({
  rateData,
  dialInstructions,
  landlineRateTitle,
  mobileRateTitle
}) {
  if (!rateData) return null

  const b = Bem('long-distance-rates')

  const rate = rateData
    ? rateData
    : {
        landLineRate: '0.00',
        mobileRate: '0.00',
        landlineExclusions: '',
        dialingPrefix: '',
        dialingCode: ''
      }
  let instructions = rate.instructions || dialInstructions

  if (instructions) {
    instructions = rate.dialingPrefix
      ? instructions.replace(/\$dialingPrefix/i, `${rate.dialingPrefix} + `)
      : instructions.replace(/\$dialingPrefix/i, '')
    instructions = rate.dialingCode
      ? instructions.replace(/\$dialingCode/i, `${rate.dialingCode} + `)
      : instructions.replace(/\$dialingCode/i, '')
  }

  function formatRate(number) {
    return new Intl.NumberFormat('en-CA', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number)
  }

  return (
    <div className={b.e('info-block').classes()}>
      <div className={b.e('price-block').classes()}>
        <div className={b.e('half-block', 'right-border').classes()}>
          <span className={b.e('type-title').classes()}>
            <RichText markup={landlineRateTitle} renderers={{ root: 'span' }} />
          </span>
          <div className={b.e('rate-value').classes()}>
            <div className={b.e('rate-crcy').classes()}>$</div>
            <div className={b.e('rate-price').classes()}>
              {formatRate(rate.landLineRate)}
            </div>
            <div className={b.e('rate-per').classes()}>/min</div>
          </div>
        </div>
        <div className={b.e('half-block').classes()}>
          <span className={b.e('type-title').classes()}>
            <RichText markup={mobileRateTitle} renderers={{ root: 'span' }} />
          </span>
          <div className={b.e('rate-value').classes()}>
            <div className={b.e('rate-crcy').classes()}>$</div>
            <div className={b.e('rate-price').classes()}>
              {formatRate(rate.mobileRate)}
            </div>
            <div className={b.e('rate-per').classes()}>/min</div>
          </div>
        </div>
      </div>
      {rate.landlineExclusions && (
        <div className={b.e('notes').classes()}>
          <RichText
            markup={rate.landlineExclusions}
            renderers={{ root: 'span' }}
          />
        </div>
      )}
      {instructions && (
        <div className={b.e('dial-instructions').classes()}>
          <RichText markup={instructions} renderers={{ root: 'span' }} />
        </div>
      )}
    </div>
  )
}

export default memo(Rate)
