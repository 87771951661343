import Bowser from 'bowser'
export const browserMap = {
  chrome: '75',
  firefox: '60',
  'internet explorer': 'Infinity',
  'microsoft edge': '19',
  iphone: '12',
  ipod: '12',
  ipad: '12',
  safari: '12',
  android: '8.0',
  opera: '99'
}
const isGridSupported =
  typeof window !== 'undefined' &&
  typeof window.document.createElement('div').style.grid === 'string'

export const isSupportedBrowser = () => {
  if (typeof window !== 'undefined') {
    const browserLib = Bowser.getParser(window.navigator.userAgent)
    const browser = browserLib.getBrowser()
    let browserVersion = parseFloat(browser.version, 2)
    let browserName = browser.name.toLowerCase()
    const osVersion = parseFloat(browserLib.getOSVersion(), 2)
    const osName = browserLib.getOSName()

    if (osName.match(/android/i)) {
      // console.log('------- android --------->', osVersion)
      browserVersion = osVersion
      browserName = osName.toLowerCase()
    }

    if (browserVersion && browserMap[browserName]) {
      return (
        isGridSupported &&
        browserVersion >= parseFloat(browserMap[browserName], 2)
      )
    }
  }
  return false
}
