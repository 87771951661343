import { breakpoints } from 'app/utils/constants'

export const canUseDOM = () =>
  !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  )

export const isLarge = () => {
  const width =
    typeof window !== 'undefined' &&
    (window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth)

  return width > breakpoints.medium.max
}

export const isMedium = () => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth

  return width < breakpoints.large.min && width > breakpoints.small.max
}

export const isSmall = () => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth

  return width < breakpoints.medium.min
}

export const runOnClient = cb => {
  if (canUseDOM()) {
    cb()
  }
}

export const runOnServer = cb => {
  if (!canUseDOM()) {
    cb()
  }
}
