const formatData = obj => {
  const ignores = ['formType', 'submitToEmail']
  const keys = Object.keys(obj)
  let str = ''
  keys.forEach(function (key) {
    if (ignores.indexOf(key) !== -1) return
    str += `<strong class="field-name">${key}</strong>: <span class="field-value">${obj[key]}</span><br/>`
  })
  return str
}

const formatSubmittedData = data => {
  let fmt = formatData(data)
  return `<div style="font-size: 14px;line-height:19px;font-family:Arial,Verdata,sans-serif;"><p>Hello,<br/>Please find below submitted data:</p><p>${fmt}</p><p>Best regards,<br/>Rogers Business Website</p></div>`
}

const addZeroPadding = val => {
  return val < 10 ? `0${val}` : val
}

const generateRequestNo = () => {
  const dttm = new Date()
  const rnd = Math.floor((1 + Math.random()) * 1000)
  return `${dttm.getFullYear()}${addZeroPadding(
    dttm.getMonth()
  )}${addZeroPadding(dttm.getDate())}${addZeroPadding(
    dttm.getHours()
  )}${addZeroPadding(dttm.getMinutes())}${addZeroPadding(
    dttm.getSeconds()
  )}-${rnd}`
}

const processMarkDown = txt => {
  const re = new RegExp('(.*)__(.+)__(.*)')
  return txt ? txt.replace(re, '$1<b>$2</b>$3') : txt
}

const normalizeName = name => {
  return name ? name.toLowerCase().replace(/\s/gi, '-') : ''
}

export default {
  formatSubmittedData,
  generateRequestNo,
  processMarkDown,
  normalizeName
}
