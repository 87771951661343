export const breakpoints = {
  small: {
    min: 0,
    max: 767
  },
  medium: {
    min: 768,
    max: 1279
  },
  large: {
    min: 1280,
    max: 10000
  }
}

export const defaultCarouselOptions = {
  accessibility: true,
  afterChange: () => {},
  autoplay: false,
  adaptiveHeight: true,
  centerPadding: '0px',
  dotsClass: 'carousel__dots',
  draggable: true,
  variableWidth: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  dots: false,
  arrows: true
}

export const responsiveDefaultCarouselOptions = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      dots: false,
      arrows: true
    }
  },
  {
    breakpoint: breakpoints.large.min,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      dots: true,
      arrows: true
    }
  },
  {
    breakpoint: breakpoints.medium.min,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
      arrows: true
    }
  }
]

export const symbols = {
  None: '',
  Dagger: '†',
  'Double Dagger': '††',
  Asterisk: '3',
  'Double Asterisk': '**'
}

export const icons = {
  startOver: 'Link_StartOver',
  offsite: 'Link_Offsite',
  chevronWhite: 'Link_ChevronWhite',
  chevronBlue: 'Link_ChevronBlue',
  alert: 'Link_Alert',
  checkmark: 'checkmark',
  close: 'close',
  search: 'Nav_Search',
  email: 'email'
}

export const errors = {
  required: 'This field can’t be left blank',
  regex: 'Invalid',
  email: 'Enter a valid email address',
  phone: 'Enter a valid U.S. phone number',
  zip: 'Enter a valid 5-digit Zip Code',
  ssn: 'Enter a valid SSN/ITIN'
}

export const analyticsEvents = Object.freeze({
  CHAT: 'chatAction',
  PHONE: 'callAction',
  SOCIAL: 'socialAction',
  HELPFUL: 'helpfulAction',
  DEFAULT: 'navigationAction',
  EMAIL: 'emailAction'
})

export const chat = Object.freeze({
  WINDOW_ATTRIBUTES:
    'height=640,width=640,toolbar=no,menubar=no,scrollbars=no,location=no,status=no',
  NAME: 'Chat with an agent'
})
