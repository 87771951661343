import React from 'react'

import Bem from 'react-bem-helper'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Caret from 'app/components/SVG/Caret'
import LinkModel from 'app/models/Link/Link'
import GradientHero, {
  GradientHeroModel
} from 'app/components/GradientHero/GradientHero'

import SearchBar from './SearchBar'

import './OutageHero.scss'
export class OutageHeroModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      eyebrow: String,
      title: String,
      category: String,
      breadCrumbTitle: String,
      breadcrumbLevel: { type: String, default: 'two' },
      withSearch: { type: Boolean, default: true },
      supportSearchPage: { type: LinkModel.shape }
    }
  }
}

export default function OutageHero(props) {
  const classes = new Bem({
    name: 'support-outage-hero',
    prefix: ''
  })

  const crumbs = new Bem({
    name: 'support-article',
    prefix: ''
  })

  const renderBreadcrumbs = () => {
    return (
      <React.Fragment>
        <div {...crumbs({ element: 'breadcrumbs' })}>
          <div {...crumbs({ element: 'breadcrumbs-container' })}>
            <ul>
              <li>
                <a
                  href="/support"
                  data-event="navigationAction"
                  data-value={`support-article-breadcrumbs|support-home`}
                >
                  Support
                </a>
                <Caret className="caret" width={8} height={8} color="#1f1f1f" />
              </li>
              {props.breadcrumbLevel !== 'one' && (
                <li>
                  <a
                    href={`/support/${slug(props.category)}`}
                    data-event="navigationAction"
                    data-value={`support-article-breadcrumbs|${slug(
                      props.category
                    )}`}
                  >
                    {props.category}
                  </a>
                  <Caret
                    className="caret"
                    width={8}
                    height={8}
                    color="#1f1f1f"
                  />
                </li>
              )}
              <li>{props.breadCrumbTitle || props.title}</li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  }

  const hero = new GradientHeroModel({
    name: props.name,
    title: props.title,
    eyebrow: props.eyebrow || null,
    cta: null,
    subtitle: '',
    heroClassName: 'low-profile'
  })
  return (
    <section {...classes()}>
      <GradientHero {...hero} breadcrumbs={renderBreadcrumbs()} />
      {props.withSearch && (
        <SearchBar supportSearchPage={props.supportSearchPage} />
      )}
    </section>
  )
}
