import React, { useState } from 'react'

import MaskedInput from 'react-text-mask'
import { withCookies } from 'react-cookie'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'

import { masks } from '../ContactForm/logic'
import { handleFormSubmit, validationSchema } from './validation'

import './RequestForm.scss'

export class RequestFormModel extends Model {
  static props() {
    return {
      name: String,
      formId: String,
      accountType: String,
      sections: Array,
      redirectUrl: String,
      submitToEmail: String
    }
  }
}

const defaultValues = {
  wirelessLines: null,
  newDevices: null,
  currentServices: null,
  employeesCount: '',
  emailoptin: '',
  productInterest: [],
  firstName: '',
  lastName: '',
  phoneNum: '',
  companyName: '',
  emailAddy: '',
  city: '',
  province: '',
  comments: '',
  extra: []
}

export function RequestForm({
  cookies,
  sections,
  formId = 'RequestForm',
  accountType = 'wireless',
  redirectUrl = '/secure/request-information/support/thankyou',
  initialValues = null
}) {
  const [inFocus, setInFocus] = useState('')
  const [filled, setFilled] = useState([])
  const [submissionError, setSubmissionError] = useState(null)
  const [disabled, setDisabled] = useState(false)

  const b = Bem('request-form')

  function setLeadCookie(values, orderId) {
    const leadData = {
      shawAccountNumber: values.shawAccountNumber || 'none',
      productInterest: values.productInterest,
      employeesCount: values.employeesCount,
      orderId,
      leadFormName: 'contact-form'
    }
    const leadDataString = JSON.stringify(leadData)
    const cookieOptions = {
      path: '/',
      expires: new Date(+new Date() + 12096e5)
    }
    cookies.set('leadData', leadDataString, cookieOptions)
  }

  async function handleSubmit(values, actions) {
    setSubmissionError(null)
    actions.setSubmitting(true)
    setDisabled(true)
    try {
      if (
        values.productInterest.includes('Internet') &&
        values.productInterest.includes('Wireless')
      ) {
        delete values.currentServices
      }

      const response = await handleFormSubmit({
        ...values,
        formId,
        accountType,
        token: 'skip',
        contentType: 'RequestForm'
      })
      if (window?.LOG_LEVEL?.match(/info|verbose|debug|silly/)) {
        console.log(
          '------- response.data --------->',
          JSON.stringify(response?.data)
        )
      }

      if (response) {
        const refId = response.data.id
        setLeadCookie(values, refId)
        if (typeof window != 'undefined') {
          if (refId) {
            window.localStorage.setItem('refId', refId)
          }
          window.location.href = redirectUrl
        }
      }
    } catch (error) {
      if (window?.LOG_LEVEL?.match(/info|verbose|debug|silly/)) {
        console.log('------- RequestForm Error --------->', error)
      }
      actions.setSubmitting(false)
      setDisabled(false)
      setSubmissionError(
        'Form submission was unsuccessful, please try again later.'
      )
    }
  }

  return (
    <section className={b.classes()}>
      <Formik
        initialValues={initialValues || defaultValues}
        validationSchema={validationSchema}
        validateOnMount={false}
        onSubmit={handleSubmit}
      >
        {({ values, handleBlur, handleChange, isSubmitting }) => {
          function onFocus({ target }) {
            setInFocus(target.name)
          }
          function onBlur(e) {
            let newFilled

            if (e.target.value) {
              newFilled = [...filled, e.target.name]
            } else {
              newFilled = filled.filter(f => f !== e.target.name)
            }
            setFilled(newFilled)
            handleBlur(e)
          }
          const showSection = !(
            values.productInterest.includes('Internet') &&
            values.productInterest.includes('Wireless')
          )

          const asterix = sections[0].required ? '*' : ''

          return (
            <Form>
              {sections.map((section, i) => {
                if (section.id === 'ourSolutions') {
                  return (
                    <div key={i} className={b.e('section').classes()}>
                      <h2 className={b.e('section-title').classes()}>
                        {section.title}
                      </h2>
                      <div className={b.e('first-section').classes()}>
                        <RichText
                          className={b.e('description').classes()}
                          isMarkdown
                          markup={section.description}
                        />
                        {section.required && (
                          <p className={b.e('required').classes()}>
                            {section.required}
                          </p>
                        )}
                      </div>
                      <div
                        className={b
                          .e('section-container')
                          .m({
                            'desktop-4': true,
                            'tablet-2': true,
                            'mobile-2': true
                          })
                          .classes()}
                      >
                        <label
                          className={b
                            .e('checkbox-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="Internet"
                        >
                          <Field
                            type="checkbox"
                            name="productInterest"
                            value="Internet"
                            id="Internet"
                          />
                          <span>Internet{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('checkbox-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="TV"
                        >
                          <Field
                            type="checkbox"
                            name="productInterest"
                            value="TV"
                            id="TV"
                          />
                          <span>TV{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('checkbox-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="Voice"
                        >
                          <Field
                            type="checkbox"
                            name="productInterest"
                            value="Voice"
                            id="Voice"
                          />
                          <span>Voice{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('checkbox-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="Security"
                        >
                          <Field
                            type="checkbox"
                            name="productInterest"
                            value="Security"
                            id="Security"
                          />
                          <span>Security{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('checkbox-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="Network"
                        >
                          <Field
                            type="checkbox"
                            name="productInterest"
                            value="Network"
                            id="Network"
                          />
                          <span>Network{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('checkbox-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="Cloud"
                        >
                          <Field
                            type="checkbox"
                            name="productInterest"
                            value="Cloud"
                            id="Cloud"
                          />
                          <span>Cloud{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('checkbox-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="Wireless"
                        >
                          <Field
                            type="checkbox"
                            name="productInterest"
                            value="Wireless"
                            id="Wireless"
                          />
                          <span>Wireless{asterix}</span>
                        </label>
                        <ErrorMessage
                          name="productInterest"
                          component="div"
                          className={b.e('error').classes()}
                        />
                      </div>
                    </div>
                  )
                } else if (section.id === 'wireless') {
                  return (
                    <div key={i} className={b.e('section').classes()}>
                      <h2 className={b.e('section-title').classes()}>
                        {section.title}
                      </h2>
                      <RichText
                        className={b.e('description').classes()}
                        isMarkdown
                        markup={section.description}
                      />

                      {showSection && (
                        <>
                          <div
                            className={b
                              .e('section-container')
                              .m({
                                'desktop-4': true,
                                'tablet-4': true,
                                'mobile-1': true
                              })
                              .classes()}
                          >
                            <p
                              className={b
                                .e('section-container-question')
                                .classes()}
                            >
                              What services do you have currently?
                            </p>
                            <label
                              className={b
                                .e('radio-label')
                                .m({ single: true })
                                .classes()}
                              htmlFor="internet-smartwifi"
                            >
                              <Field
                                type="radio"
                                name="currentServices"
                                value="Business Internet / SmartWiFi"
                                id="internet-smartwifi"
                              />
                              <span>
                                Business Internet / SmartWiFi{asterix}
                              </span>
                            </label>
                            <label
                              className={b
                                .e('radio-label')
                                .m({ single: true })
                                .classes()}
                              htmlFor="no-services"
                            >
                              <Field
                                type="radio"
                                name="currentServices"
                                value="I don't have either"
                                id="no-services"
                              />
                              <span>I don&apos;t have either{asterix}</span>
                            </label>
                            <label
                              className={b
                                .e('radio-label')
                                .m({ single: true })
                                .classes()}
                              htmlFor="not-sure-services"
                            >
                              <Field
                                type="radio"
                                name="currentServices"
                                value="Not sure"
                                id="not-sure-services"
                              />
                              <span>Not sure{asterix}</span>
                            </label>
                            <ErrorMessage
                              component="div"
                              className={b
                                .e('error')
                                .m({ wide: true })
                                .classes()}
                              name="currentServices"
                            />
                          </div>
                        </>
                      )}
                      <div
                        className={b
                          .e('section-container')
                          .m({
                            'desktop-4': true,
                            'tablet-4': true,
                            'mobile-1': true
                          })
                          .classes()}
                      >
                        <p
                          className={b
                            .e('section-container-question')
                            .classes()}
                        >
                          How many wireless lines do you need?
                        </p>
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="lines-10"
                        >
                          <Field
                            type="radio"
                            name="wirelessLines"
                            value="10"
                            id="lines-10"
                          />
                          <span>1-10 lines{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="lines-11"
                        >
                          <Field
                            type="radio"
                            name="wirelessLines"
                            value="11"
                            id="lines-11"
                          />
                          <span>11+ lines{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="lines-0"
                        >
                          <Field
                            type="radio"
                            name="wirelessLines"
                            value="Not sure"
                            id="lines-0"
                          />
                          <span>Not sure{asterix}</span>
                        </label>
                        <ErrorMessage
                          component="div"
                          className={b.e('error').m({ wide: true }).classes()}
                          name="wirelessLines"
                        />
                      </div>

                      <div
                        className={b
                          .e('section-container')
                          .m({
                            'desktop-4': true,
                            'tablet-4': true,
                            'mobile-1': true
                          })
                          .classes()}
                      >
                        <p
                          className={b
                            .e('section-container-question')
                            .classes()}
                        >
                          Are you interested in new devices?
                        </p>
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="yes-new-devices"
                        >
                          <Field
                            type="radio"
                            name="newDevices"
                            value="Yes interested"
                            id="yes-new-devices"
                          />
                          <span>
                            <strong>Yes</strong>, tell me about the latest
                            devices & financing options{asterix}
                          </span>
                        </label>
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="no-new-devices"
                        >
                          <Field
                            type="radio"
                            name="newDevices"
                            value="No new devices"
                            id="no-new-devices"
                          />
                          <span>
                            <strong>No</strong>, I want to bring my existing
                            devices only{asterix}
                          </span>
                        </label>
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="add-new-devices"
                        >
                          <Field
                            type="radio"
                            name="newDevices"
                            value="Add new devices"
                            id="add-new-devices"
                          />
                          <span>
                            I&apos;d like to add new devices to my existing ones
                            {asterix}
                          </span>
                        </label>
                        <ErrorMessage
                          component="div"
                          className={b.e('error').m({ wide: true }).classes()}
                          name="newDevices"
                        />
                      </div>
                    </div>
                  )
                } else if (section.id === 'employeesCount') {
                  return (
                    <div key={i} className={b.e('section').classes()}>
                      <h2 className={b.e('section-title').classes()}>
                        {section.title}
                      </h2>
                      <RichText
                        className={b.e('description').classes()}
                        isMarkdown
                        markup={section.description}
                      />

                      <div
                        className={b
                          .e('section-container')
                          .m({
                            'desktop-4': true,
                            'tablet-4': true,
                            'mobile-1': true
                          })
                          .classes()}
                      >
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="employees-1-9"
                        >
                          <Field
                            type="radio"
                            name="employeesCount"
                            value="9"
                            id="employees-1-9"
                          />
                          <span>1 - 9 employees{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="employees-10-99"
                        >
                          <Field
                            type="radio"
                            name="employeesCount"
                            value="99"
                            id="employees-10-99"
                          />
                          <span>10 - 99 employees{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="employees-100"
                        >
                          <Field
                            type="radio"
                            name="employeesCount"
                            value="100"
                            id="employees-100"
                          />
                          <span>100+ employees{asterix}</span>
                        </label>
                        <label
                          className={b
                            .e('radio-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="notsure"
                        >
                          <Field
                            type="radio"
                            name="employeesCount"
                            value="0"
                            id="notsure"
                          />
                          <span>Not sure{asterix}</span>
                        </label>

                        <ErrorMessage
                          name="employeesCount"
                          component="div"
                          className={b.e('error').classes()}
                        />
                      </div>
                    </div>
                  )
                } else if (section.id === 'contactInfo') {
                  return (
                    <div key={i} className={b.e('section').classes()}>
                      <h2 className={b.e('section-title').classes()}>
                        {section.title}
                      </h2>
                      <RichText
                        className={b.e('description').classes()}
                        isMarkdown
                        markup={section.description}
                      />

                      <div
                        className={b
                          .e('section-container')
                          .m({
                            'mobile-1': true
                          })
                          .classes()}
                      >
                        <label
                          className={b
                            .e('input-label')
                            .m({
                              focus: inFocus === 'firstName',
                              filled: !!values.firstName
                            })
                            .classes()}
                          htmlFor="firstName"
                        >
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            className={b.e('input').classes()}
                          />
                          <span>First name{asterix}</span>
                          <ErrorMessage
                            component="div"
                            className={b
                              .e('error')
                              .m({ single: true })
                              .classes()}
                            name="firstName"
                          />
                        </label>
                        <label
                          className={b
                            .e('input-label')
                            .m({
                              focus: inFocus === 'lastName',
                              filled: !!values.lastName
                            })
                            .classes()}
                          htmlFor="lastName"
                        >
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            className={b.e('input').classes()}
                          />
                          <span>Last name{asterix}</span>
                          <ErrorMessage
                            component="div"
                            className={b
                              .e('error')
                              .m({ single: true })
                              .classes()}
                            name="lastName"
                          />
                        </label>
                        <label
                          className={b
                            .e('input-label')
                            .m({
                              focus: inFocus === 'phoneNum',
                              filled: !!values.phoneNum
                            })
                            .classes()}
                          htmlFor="phoneNum"
                        >
                          <MaskedInput
                            type="tel"
                            name="phoneNum"
                            id="phoneNum"
                            onFocus={onFocus}
                            // onBlur={onBlur}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phoneNum}
                            mask={masks.phoneMask}
                            className={b
                              .e('input')
                              .m({ phone: true })
                              .classes()}
                          />
                          <span>Contact phone{asterix}</span>
                          <ErrorMessage
                            component="div"
                            className={b
                              .e('error')
                              .m({ single: true })
                              .classes()}
                            name="phoneNum"
                          />
                        </label>
                        <label
                          className={b
                            .e('input-label')
                            .m({
                              focus: inFocus === 'emailAddy',
                              filled: !!values.emailAddy
                            })
                            .classes()}
                          htmlFor="emailAddy"
                        >
                          <Field
                            type="email"
                            name="emailAddy"
                            id="emailAddy"
                            onFocus={onFocus}
                            className={b.e('input').classes()}
                          />
                          <span>Contact email{asterix}</span>
                          <ErrorMessage
                            component="div"
                            className={b
                              .e('error')
                              .m({ single: true })
                              .classes()}
                            name="emailAddy"
                          />
                        </label>
                        <label
                          className={b
                            .e('input-label')
                            .m({
                              focus: inFocus === 'companyName',
                              filled: !!values.companyName
                            })
                            .classes()}
                          htmlFor="companyName"
                        >
                          <Field
                            type="text"
                            name="companyName"
                            id="companyName"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            className={b.e('input').classes()}
                          />
                          <span>Company name{asterix}</span>
                          <ErrorMessage
                            component="div"
                            className={b
                              .e('error')
                              .m({ single: true })
                              .classes()}
                            name="companyName"
                          />
                        </label>
                        <label
                          className={b
                            .e('input-label')
                            .m({
                              focus: inFocus === 'city',
                              filled: !!values.city
                            })
                            .classes()}
                          htmlFor="city"
                        >
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            className={b.e('input').classes()}
                          />
                          <span>City{asterix}</span>
                          <ErrorMessage
                            component="div"
                            className={b
                              .e('error')
                              .m({ single: true })
                              .classes()}
                            name="city"
                          />
                        </label>
                        <label
                          className={b
                            .e('select-label')
                            .m({
                              focus: inFocus === 'province',
                              filled: !!values.province
                            })
                            .classes()}
                          htmlFor="province"
                        >
                          <Field
                            component="select"
                            name="province"
                            id="province"
                            onFocus={onFocus}
                            onBlur={onBlur}
                          >
                            <option value="">Province{asterix}</option>
                            <option value="AB">Alberta</option>
                            <option value="BC">British Columbia</option>
                            <option value="NL">
                              Newfoundland and Labrador
                            </option>
                            <option value="PE">Prince Edward Island</option>
                            <option value="NS">Nova Scotia</option>
                            <option value="NB">New Brunswick</option>
                            <option value="QC">Quebec</option>
                            <option value="ON">Ontario</option>
                            <option value="MB">Manitoba</option>
                            <option value="SK">Saskatchewan</option>
                            <option value="YT">Yukon</option>
                            <option value="NT">Northwest Territories</option>
                            <option value="NU">Nunavut</option>
                          </Field>
                          <ErrorMessage
                            component="div"
                            className={b
                              .e('error')
                              .m({ single: true })
                              .classes()}
                            name="province"
                          />
                        </label>
                        <div className={b.e('checkbox-container').classes()}>
                          <label
                            htmlFor="onSiteConsult"
                            className={b
                              .e('checkbox-label')
                              .m({ single: true })
                              .classes()}
                          >
                            <Field
                              type="checkbox"
                              id="onSiteConsult"
                              value="On-site consultation"
                              name="extra"
                            />
                            <span>I prefer an on-site sales consultation.</span>
                          </label>
                          <label
                            htmlFor="existingCust"
                            className={b
                              .e('checkbox-label')
                              .m({ single: true })
                              .classes()}
                          >
                            <Field
                              type="checkbox"
                              id="existingCust"
                              value="Existing Rogers Business customer"
                              name="extra"
                            />
                            <span>
                              I&apos;m an existing Rogers Business customer.
                            </span>
                          </label>
                        </div>

                        <label
                          className={b
                            .e('input-label')
                            .m({
                              focus: inFocus === 'comments',
                              filled: !!values.comments,
                              textarea: true
                            })
                            .classes()}
                          htmlFor="comments"
                        >
                          <Field
                            component="textarea"
                            name="comments"
                            id="comments"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            className={b.e('input').classes()}
                            placeholder="Additional comments"
                          />
                          <span>Additional comments{asterix}</span>
                          <ErrorMessage
                            component="div"
                            className={b
                              .e('error')
                              .m({ single: true })
                              .classes()}
                            name="comments"
                          />
                        </label>
                      </div>
                      <div className={b.e('center').classes()}>
                        <label
                          className={b
                            .e('checkbox-label')
                            .m({ single: true })
                            .classes()}
                          htmlFor="emailoptin"
                        >
                          <Field
                            type="checkbox"
                            name="emailoptin"
                            value="Yes"
                            id="emailoptin"
                          />
                          <span>
                            Yes, please email me special offers on the latest
                            technologies and products from Rogers Business (I
                            can opt out any time)
                          </span>
                        </label>
                      </div>
                      <div className={b.e('center').classes()}>
                        <Button
                          disabled={disabled}
                          type="submit"
                          className={b.e('submit').classes()}
                          text={'Submit request'}
                          icon={
                            isSubmitting ? { type: 'Link_StartOver' } : null
                          }
                        />
                      </div>
                      <div className={b.e('center').classes()}>
                        {submissionError && !isSubmitting && (
                          <div className={b.e('submission-error').classes()}>
                            {submissionError}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }
              })}
            </Form>
          )
        }}
      </Formik>
    </section>
  )
}
export default withCookies(RequestForm)
