import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import omit from 'lodash/omit'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import { GrandHero } from '@shaw/react-component-library'
import {
  ResponsiveImageModel,
  formatImage
} from 'app/components/ResponsiveImage/ResponsiveImage'

import './GrandHero.scss'
export class GrandHeroModel extends Model {
  constructor(d) {
    super(d)
    this.responsiveImage = d.responsiveImage || d.image
  }

  static props() {
    return {
      entryId: String,
      name: String,
      theme: String,
      variant: String,
      eyebrow: String,
      logo: { type: ResponsiveImageModel.shape },
      title: String,
      subtitle: String,
      description: String,
      contentAlignment: String,
      videoPoster: String,
      videoLink: String,
      responsiveImage: {
        type: ResponsiveImageModel.shape
      },
      imageAlignment: String,
      imageEllipsis: Boolean,
      background: {
        type: ResponsiveImageModel.shape
      },
      backgroundHorizontal: String,
      backgroundVertical: String,
      cta: LinkModel.shape,
      ctaAlt: LinkModel.shape,
      appStore: PropTypes.shape({
        href: PropTypes.string,
        image: PropTypes.string
      }),
      googlePlay: PropTypes.shape({
        href: PropTypes.string,
        image: PropTypes.string
      })
    }
  }
}

export default function GrandHeroComp({
  name,
  logo,
  responsiveImage,
  imageAlignment,
  imageEllipsis,
  background,
  backgroundHorizontal,
  backgroundVertical,
  cta,
  ctaAlt,
  appStore,
  googlePlay,
  videoLink,
  videoPoster,
  ...props
}) {
  const [page, setPage] = useState('')
  useEffect(() => {
    const trackPage =
      typeof window !== 'undefined' && location.pathname !== '/'
        ? `${slug(location.pathname)}|`
        : 'homepage|'
    setPage(trackPage)
  }, [])
  const logoLink = logo
    ? logo?.large?.file?.url ||
      logo?.medium?.file?.url ||
      logo?.small?.file?.url
    : ''
  const respImage = responsiveImage
    ? {
        large: formatImage(responsiveImage?.large?.file?.url, 'large') || null,
        medium:
          formatImage(responsiveImage?.medium?.file?.url, 'medium') || null,
        small: formatImage(responsiveImage?.small?.file?.url, 'small') || null
      }
    : null

  const bgrImage = background
    ? {
        large: formatImage(background?.large?.file?.url, 'large') || null,
        medium: formatImage(background?.medium?.file?.url, 'medium') || null,
        small: formatImage(background?.small?.file?.url, 'small') || null
      }
    : null

  const defaultCta = cta ? new LinkModel(cta) : null
  const altCta = ctaAlt ? new LinkModel(ctaAlt) : null
  const appStoreLink = appStore
    ? {
        target: appStore.isNewWindow ? '_blank' : '_self',
        href: appStore.href,
        image:
          appStore?.image?.large?.file?.url ||
          appStore?.image?.medium?.file?.url ||
          appStore?.image?.small?.file?.url
      }
    : null
  const googlePlayLink = googlePlay
    ? {
        target: googlePlay.isNewWindow ? '_blank' : '_self',
        href: googlePlay.href,
        image:
          googlePlay?.image?.large?.file?.url ||
          googlePlay?.image?.medium?.file?.url ||
          googlePlay?.image?.small?.file?.url
      }
    : null
  const video = videoLink
    ? {
        src: videoLink,
        poster: videoPoster
      }
    : null

  const firstCta = defaultCta && omit(defaultCta.asButton, ['activeRoute'])
  const secondCta = altCta && omit(altCta.asButton, ['activeRoute'])

  return (
    <GrandHero
      {...props}
      logo={logoLink}
      responsiveImage={respImage}
      imageAlignment={imageAlignment}
      imageEllipsis={imageEllipsis}
      cta={
        cta
          ? {
              ...firstCta,
              variant: defaultCta.style,
              theme: defaultCta.variant,
              'data-event': defaultCta.determineAnalyticsEvent(),
              'data-value': `content|${page}${slug(props.title || name)}|${slug(
                defaultCta.text
              )}`
            }
          : null
      }
      ctaAlt={
        ctaAlt
          ? {
              ...secondCta,
              variant: altCta.style,
              theme: defaultCta.variant,
              'data-event': altCta.determineAnalyticsEvent(),
              'data-value': `content|${page}${slug(props.title || name)}|${slug(
                altCta.text
              )}`
            }
          : null
      }
      appStore={appStoreLink}
      googlePlay={googlePlayLink}
      background={bgrImage}
      backgroundHorizontal={backgroundHorizontal}
      backgroundVertical={backgroundVertical}
      video={video}
    />
  )
}
