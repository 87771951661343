import { default as createReducer } from 'app/utils/create-reducer'
import {
  SUPPORT_SEARCHING,
  SUPPORT_SEARCH_RESULTS
} from 'app/actions/supportSearch'

export const initialState = {
  loading: false,
  data: null
}

export default createReducer(
  {
    [SUPPORT_SEARCHING]: state => {
      return {
        ...state,
        loading: true
      }
    },
    [SUPPORT_SEARCH_RESULTS]: (state, { data }) => {
      return {
        ...state,
        loading: false,
        data
      }
    }
  },
  initialState
)
