import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import Testimonial from 'app/components/Testimonial/Testimonial'

import './CaseStudyTestimonial.scss'

// Module model definition
export class CaseStudyTestimonialModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      testimonial: String,
      author: String,
      company: String,
      background: { type: String, default: 'transparent' }
    }
  }
}

// Module definition
export default function CaseStudyTestimonial(
  props = CaseStudyTestimonialModel.defaultProps()
) {
  const b = Bem('case-study-testimonial')
  const { author, company, testimonial, background } = props
  return (
    <Testimonial
      className={b.m({ [background]: true }).classes()}
      author={`${author}, ${company}`}
      quote={testimonial}
    />
  )
}

CaseStudyTestimonial.propTypes = CaseStudyTestimonialModel.propTypes()
