import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Card from 'app/components/Card/Card'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import Icon, { IconModel } from 'app/components/Icon/Icon'
import MasterHero, {
  MasterHeroModel
} from 'app/modules/shared/MasterHero/MasterHero'

import './FormSuccess.scss'
// Module model definition
export class FormSuccessModel extends Model {
  constructor(d) {
    super(d)
    this.cta = new LinkModel(d.cta)
    this.icon = new IconModel(d.icon)
    this.hero = new MasterHeroModel(d.hero)
  }

  static props() {
    return {
      name: String,
      title: String,
      description: String,
      refNumber: { type: String, default: 'Your reference number is: ' },
      noRefNumber: {
        type: String,
        default:
          'Your request has been received. You will receive an email reply shortly.'
      },
      icon: IconModel.shape,
      cta: LinkModel.shape,
      hero: MasterHeroModel.shape
    }
  }
}

class FormSuccess extends Component {
  constructor(props) {
    super(props, 'form-success')
    this.state = { refId: null }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      try {
        const id = window.localStorage.getItem('refId')
        const refId = !!id && id !== '[object Object]' ? id : null
        // console.log('------- refId --------->', refId)
        this.setState({ refId })

        setTimeout(() => {
          window.localStorage.removeItem('refId')
        }, 3000)
      } catch (error) {
        console.log("------- can't get ref id --------->")
      }
    }
  }

  render() {
    return (
      <div className={this.e('inner')}>
        <Card className={this.e('card')} isHorizontal>
          <Icon className={this.e('icon')} {...this.props.icon} />
          <h3 className={this.e('title')}>{this.props.title}</h3>
          <p className={this.e('description')}>{this.props.description}</p>
          {this.state.refId && (
            <p className={this.e('description')}>
              {this.state.refId === true || this.state.refId === 'true' ? (
                <strong>{this.props.noRefNumber}</strong>
              ) : (
                <strong>{`${this.props.refNumber} ${this.state.refId}`}</strong>
              )}
            </p>
          )}
        </Card>
        {this.props.cta && this.props.cta.label && (
          <Button
            design="scotch-primary"
            variant="light"
            className={this.e('cta')}
            analyticsEvent={this.props.cta.determineAnalyticsEvent()}
            analyticsValue={`${slug(this.props.name)}|${
              this.props.cta.ariaLabel
            }`}
            {...this.props.cta.asButton}
          />
        )}
      </div>
    )
  }
}

FormSuccess.props(FormSuccessModel)

export default class FormSuccessComponent extends Component {
  constructor(props) {
    super(props, 'form-success')
  }
  render() {
    return (
      <div className={this.b()}>
        <MasterHero className={this.e('hero')} {...this.props.hero} />
        <FormSuccess {...this.props} />
      </div>
    )
  }
}
