import React from 'react'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import RichText from 'app/components/RichText/RichText'

import './CaseStudyArticleChapter.scss'

// Module model definition
export class CaseStudyArticleChapterModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      chapterContent: String,
      fullWidthContent: Boolean
    }
  }
}

// Module definition
export default class CaseStudyArticleChapter extends Component {
  constructor(props) {
    super(props, 'case-study-article-chapter')
  }

  render() {
    const wideClass = this.props.fullWidthContent
      ? this.e('content--full-width')
      : ''
    return (
      <section className={this.b()} aria-label={this.props.name}>
        <div className={this.e('content-wrapper')}>
          <div className={`${this.e('content')} ${wideClass}`}>
            <RichText markup={this.props.chapterContent} />
          </div>
        </div>
      </section>
    )
  }
}

CaseStudyArticleChapter.propTypes = CaseStudyArticleChapterModel.propTypes()
CaseStudyArticleChapter.defaultProps =
  CaseStudyArticleChapterModel.defaultProps()
