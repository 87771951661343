import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Component from 'app/components/Component'
import Carousel from 'app/components/Carousel/Carousel'
import IconBadge from 'app/components/IconBadge/IconBadge'
import { BasicIconBoxModel } from 'app/components/BasicIconBox/BasicIconBox'

import './SimpleIconCarousel.scss'

// Module model definition
export class SimpleIconCarouselModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      items: { type: BasicIconBoxModel.arrayOf, required: true },
      isCarousel: { type: Boolean }
    }
  }
}

// Module definition
export default class SimpleIconCarousel extends Component {
  constructor(props) {
    super(props, 'simple-icon-carousel')

    this.state = {
      breakpoint: null,
      shouldRenderCarousel: false,
      currentIndex: 0
    }

    if (global && global.addEventListener) {
      global.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    if (global && global.removeEventListener) {
      global.removeEventListener('resize', this.handleResize)
    }
  }

  handleResize = () => {
    const width = window.innerWidth
    if (width >= 1024 && this.state.breakpoint !== 'large') {
      this.setState({
        breakpoint: 'large',
        currentIndex: 0
      })
    } else if (
      width < 1024 &&
      width >= 768 &&
      this.state.breakpoint !== 'medium'
    ) {
      this.setState({
        breakpoint: 'medium',
        currentIndex: this.state.currentIndex
      })
    } else if (width < 768 && this.state.breakpoint !== 'small') {
      this.setState({
        breakpoint: 'small',
        currentIndex: this.state.currentIndex
      })
    }
  }

  /**
   * The component needs to know what breakpoint the carousel thinks it is in
   * @param newBreakpoint
   */
  handleBreakpointChange = newBreakpoint => {
    if (newBreakpoint === 'large' && window.innerWidth === 1023) {
      return
    }
    this.setState({
      breakpoint: newBreakpoint,
      currentIndex: newBreakpoint === 'large' ? 0 : this.state.currentIndex
    })
  }

  handleSlideChange = currentIndex => {
    this.setState({ currentIndex })
  }

  renderIcons(items) {
    return items.map(item => (
      <div key={slug(item.title)} className={this.e('item')}>
        <IconBadge
          icon={{ type: item.icon.type, svg: item.icon.svg }}
          className={'no-icon-border'}
        />
        <h3 className={this.e('item-description')}>{item.title}</h3>
      </div>
    ))
  }

  render() {
    const { items, isCarousel } = this.props

    const unslickIfSingleItem = {}

    if (items.length === 1) {
      unslickIfSingleItem.small = false
      unslickIfSingleItem.medium = false
    }

    return (
      <section className={this.b()} aria-label={this.props.title}>
        {/* <div className={this.e('title')}>
          <h2>{this.props.title}</h2>
        </div> */}
        <div className={this.e('wrapper')}>
          <div className={this.e('container')}>
            <div className={this.e('items')}>
              {isCarousel && (
                <Carousel
                  ref={carousel => {
                    this.carousel = carousel
                  }}
                  className={this.e('carousel')}
                  onSlideChange={this.handleSlideChange}
                  onBreakpointChange={this.handleBreakpointChange}
                  {...unslickIfSingleItem}
                >
                  {this.renderIcons(items)}
                </Carousel>
              )}
              {!isCarousel && this.renderIcons(items)}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

SimpleIconCarousel.props(SimpleIconCarouselModel)
