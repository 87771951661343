import React from 'react'

import { connect } from 'react-redux'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RegionSelector from 'app/components/RegionSelector/RegionSelector'
import RegionSelectorButton from 'app/components/RegionSelector/RegionSelectorButton'

import './GlobalHeader.scss'

function showForMobile() {
  if (global.innerWidth < 1280) {
    return true
  }
  if (global.orientation && typeof global.orientation === 'number') {
    if (Math.abs(global.orientation) === 90 && global.screen.width < 1280) {
      return true
    } else if (global.screen.width > 1280) {
      return false
    }
  } else {
    return false
  }
}

// Module model definition
export class GlobalHeaderModel extends Model {
  constructor(d) {
    super(d)
    const castLinks = item => new LinkModel(item)
    this.tabs = d.tabs.map(castLinks)
    this.linksCenter = d.linksCenter.map(castLinks)
    this.linksRight = d.linksRight.map(castLinks)
  }

  static props() {
    return {
      name: String,
      tabs: LinkModel.arrayOf,
      linksCenter: LinkModel.arrayOf,
      linksRight: LinkModel.arrayOf
    }
  }
}

// Module component definition
class GlobalHeader extends Component {
  constructor(props) {
    super(props, 'global-header')

    this.state = {
      isOpen: false,
      isLoading: false,
      showForMobileView: showForMobile()
    }

    this.toggleRegionSelector = this.toggleRegionSelector.bind(this)
    this.isRegionSaving = this.isRegionSaving.bind(this)
    this.setShowForMobileView = this.setShowForMobileView.bind(this)
  }

  componentDidMount() {
    global.addEventListener('resize', this.setShowForMobileView)
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.setShowForMobileView)
  }

  setShowForMobileView() {
    this.setState({ showForMobileView: showForMobile() })
  }
  // Temporary
  isSecondTab(link, index) {
    return index === 1
  }

  canRender(links) {
    return links && links.length
  }

  toggleRegionSelector() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  isRegionSaving() {
    this.setState({
      isLoading: true
    })
  }

  m() {
    return {
      scotch: true
    }
  }

  render() {
    const { isOpen } = this.state
    return (
      <div>
        <header className={this.b()}>
          <div role="navigation" aria-label="Header Navigation">
            <nav className={this.e('container', 'paddingless')}>
              {this.renderLinks(this.props.tabs, 'tab', this.isSecondTab)}
              {this.renderLinks(this.props.linksCenter)}
              {this.renderLinks(this.props.linksRight, 'rightLinks')}
            </nav>
          </div>
          <RegionSelector
            showCta={false}
            isLoadingProp={false}
            isOpen={isOpen}
            provinceProp=""
            callback={this.isRegionSaving}
          />
        </header>
      </div>
    )
  }

  renderLinks(links, type = 'link', filter) {
    if (!this.canRender(links)) {
      return
    }

    // dynamic function attributes
    if (typeof type === 'function') {
      filter = type
      type = 'link'
    }
    const cleanType = type === 'rightLinks' ? 'link' : type

    return (
      <ul className={this.e(`${cleanType}s`)}>
        {type === 'rightLinks' && (
          <li>
            <RegionSelectorButton
              isLoading={this.state.isLoading}
              handleClick={this.toggleRegionSelector}
            />
          </li>
        )}
        {links.map((link, index) => this.renderLink(link, index, type, filter))}
      </ul>
    )
  }

  renderLink(link, index, type, filter) {
    const modifier = {
      active:
        (link.type === 'page' && link.url === this.props.path) ||
        (filter && filter(link, index))
    }
    const linkModel = new LinkModel(link)
    const cleanType = type === 'rightLinks' ? 'link' : type

    return (
      <li className={this.e(cleanType, modifier)} key={index}>
        <Button
          {...linkModel.asButton}
          design={'link'}
          analyticsValue={`${slug(this.props.name)}|${slug(link.ariaLabel)}`}
        />
      </li>
    )
  }
}

const mapStateToProps = state => ({
  path: state.self.path
})

GlobalHeader.props(GlobalHeaderModel)

export default connect(mapStateToProps)(GlobalHeader)
