import React from 'react'

import Bem from 'react-bem-helper'
import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Link from 'app/components/Link/Link'
import Icon from 'app/components/Icon/Icon'
import LinkModel from 'app/models/Link/Link'

import './SingleLink.scss'

export class SingleLinkModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        required: true
      },

      theme: {
        type: String,
        required: false,
        default: 'white'
      },
      link: {
        type: LinkModel.shape,
        required: false,
        default: null
      }
    }
  }
}

export default function SingleLink({ name, theme, link }) {
  const classes = new Bem({
    name: 'single-link',
    prefix: ''
  })

  const icon = link && link.icon
  const label = link && (link.ariaLabel || link.label)
  const button = new LinkModel(link)
  return (
    <div {...classes({ modifier: theme })}>
      {link && (
        <Link
          link={button}
          useChildren
          analyticsValue={`${slug(name)}|${slug(label)}`}
        >
          {icon && <Icon {...icon} />}
          <span {...classes('button-text')}>{label}</span>
        </Link>
      )}
    </div>
  )
}

SingleLink.propTypes = {
  name: PropTypes.string.isRequired,
  theme: PropTypes.string,
  link: PropTypes.shape(LinkModel.props())
}
