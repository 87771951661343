import axios from 'axios'

export const SUBSCRIPTION_IDLDE = 'SUBSCRIPTION_IDLDE'
export const SUBSCRIBE = 'SUBSCRIBE'
export const SUBSCRIBING = 'SUBSCRIBING'
export const SUBSCRIBED = 'SUBSCRIBED'

export const UNSUBSCRIBE = 'UNSUBSCRIBE'
export const UNSUBSCRIBING = 'UNSUBSCRIBING'
export const UNSUBSCRIBED = 'UNSUBSCRIBED'

export const setSubscription = type => ({ type })
export const setSubscriptionResults = (type, data) => ({ type, data })

export const subscribe = (
  data,
  cbs = { success: console.log, failure: console.error }
) => {
  return dispatch => {
    dispatch(setSubscription(SUBSCRIBING))
    axios
      .post('/subscription/subscribe', data)
      .then(res => {
        dispatch(setSubscriptionResults(SUBSCRIBED, res.data))
        if (cbs.success) {
          cbs.success(res.data)
        }
      })
      .catch(err => {
        dispatch(setSubscriptionResults(SUBSCRIBED, err?.response?.data))
        if (cbs.failure) {
          cbs.failure(err?.response?.data)
        }
      })
  }
}

export const unsubscribe = (
  data,
  cbs = { success: console.log, failure: console.error }
) => {
  return dispatch => {
    dispatch(setSubscription(UNSUBSCRIBING))
    axios
      .post('/subscription/unsubscribe', data)
      .then(res => {
        dispatch(setSubscriptionResults(UNSUBSCRIBED, res.data))
        if (cbs.success) {
          cbs.success(res.data)
        }
      })
      .catch(err => {
        dispatch(setSubscriptionResults(UNSUBSCRIBED, err.response.data))
        if (cbs.failure) {
          cbs.failure(err.response.data)
        }
      })
  }
}
