import React from 'react'

import PropTypes from 'prop-types'

import { v1 as uuid } from 'uuid'
import chunk from 'lodash/chunk'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import maxChunk from 'app/utils/max-chunk'
import Link from 'app/components/Link/Link'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import { IconModel } from 'app/components/Icon/Icon'
import RichText from 'app/components/RichText/RichText'
import preProcessVideoUrl from 'app/utils/videourl-preprocessor'
import BasicIconBox, {
  BasicIconBoxModel
} from 'app/components/BasicIconBox/BasicIconBox'

import './IconBoxStatic.scss'
// Module model definition
export class IconBoxStaticModel {
  constructor(d) {
    // CMS
    this.name = d.name
    this.title = d.title
    this.titleHtml = d.titleHtml
    this.description = d.description
    this.basicIconBoxes = d.basicIconBoxes
    this.white = d.white
    this.buttonStyle = d.buttonStyle || 'secondary'
    this.showDividers = d.showDividers === undefined ? true : d.showDividers
    this.datasheet = d.datasheet
    this.showVideoOnPage = d.showVideoOnPage
    this.footerSmall = d.footerSmall
    this.footerBig = d.footerBig
    this.background = d.background
    this.iconSize = d.iconSize

    if (d.cta) {
      this.cta = new LinkModel(d.cta)
      this.videoUrl = new LinkModel(d.videoUrl)
      this.analyticsEvent = this.cta.determineAnalyticsEvent()
      this.analyticsValue = `${slug(d.name)}|${slug(this.cta.text)}`
    }
  }

  static propTypes() {
    return {
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      titleHtml: PropTypes.string.isRequired,
      description: PropTypes.string,
      cta: LinkModel.shape,
      basicIconBoxes: PropTypes.arrayOf(
        PropTypes.shape(BasicIconBoxModel.propTypes())
      ),
      analyticsEvent: PropTypes.string,
      analyticsValue: PropTypes.string,
      white: PropTypes.bool,
      buttonStyle: PropTypes.string,
      showDividers: PropTypes.bool,
      datasheet: PropTypes.object,
      videoURL: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(LinkModel.propTypes())
      ]),
      footerSmall: PropTypes.string,
      footerBig: PropTypes.string,
      background: PropTypes.string,
      iconSize: PropTypes.string
    }
  }

  static defaultProps() {
    return {
      name: 'IconBoxStatic',
      title: '',
      titleHtml: '',
      description: '',
      cta: null,
      basicIconBoxes: [],
      white: false,
      buttonStyle: 'link-caret',
      showDividers: true,
      datasheet: null,
      videoURL: '',
      footerSmall: '',
      footerBig: '',
      background: 'transparent',
      iconSize: 'large'
    }
  }
}

// Module definition
export default class IconBoxStatic extends React.Component {
  constructor(props) {
    super(props)
    this.b = Bem('icon-box-static')
  }

  renderVideo() {
    if (!this.props.videoUrl || !this.props.videoUrl.href) {
      return null
    }

    const videoUrl = preProcessVideoUrl(this.props.videoUrl.href)

    if (!videoUrl || !this.props.showVideoOnPage) {
      if (!this.props.videoUrl || !this.props.videoUrl.href) {
        return null
      }

      const videoLink = new LinkModel({
        href: this.props.videoUrl.href,
        label: this.props.videoUrl.label,
        analyticsEvent: 'navigationAction',
        analyticsValue: `${slug(this.props.name)}|${slug(
          this.props.videoUrl.label
        )}`
      })

      return (
        <div className={this.b.e('video-container').classes()}>
          <Button
            {...videoLink.asButton}
            design="link"
            analyticsEvent={videoLink.analyticsEvent}
            analyticsValue={`${slug(this.props.name)}|${slug(
              this.props.videoUrl.label
            )}`}
          />
        </div>
      )
    }

    return (
      <div className={this.b.e('video-container').classes()}>
        <div className={this.b.e('video').classes()}>
          <iframe
            title={this.props.videoUrl.label}
            src={videoUrl}
            allow="autoplay; encrypted-media"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      analyticsEvent,
      analyticsValue,
      cta,
      white,
      buttonStyle,
      showDividers,
      footerSmall,
      footerBig,
      bottomPadding,
      background,
      iconSize
    } = this.props

    const ds = this.props.datasheet
      ? new LinkModel({
          href: this.props.datasheet.file.url,
          label: this.props.datasheet.title,
          icon: new IconModel({
            type: 'ProductFeatures_DownloadPDF'
          }),
          analyticsEvent: 'downloadAction',
          analyticsValue: `${slug(this.props.name)}|${slug(
            this.props.datasheet.title
          )}`,
          isNewWindow: true
        })
      : null

    const noBottomPadding = bottomPadding === false ? 'no-bottom-padding' : ''

    return (
      <div
        className={this.b
          .m({
            white: white,
            showDividers: showDividers,
            noBottomPadding,
            [background]: background,
            scotch: true,
            [`icon-${iconSize}`]: true
          })
          .classes()}
      >
        <div className={this.b.e('container').classes()}>
          {this.props.title && (
            <h2 className={this.b.e('title').classes()}>{this.props.title}</h2>
          )}
          {this.props.titleHtml && (
            <h2 className={this.b.e('title').classes()}>
              <RichText markup={this.props.titleHtml} />
            </h2>
          )}
          {this.props.description && (
            <div className={this.b.e('description').classes()}>
              <RichText markup={this.props.description} />
            </div>
          )}
          <div className={this.b.e('basic-icon-box-groups').classes()}>
            {this.renderBasicIconBoxGroups()}
          </div>
          {ds && (
            <div className={this.b.e('datasheet').classes()}>
              <Link link={ds} useChildren>
                <div className={this.b.e('datasheet-link').classes()}>
                  {this.props.datasheet.title}
                </div>
              </Link>
            </div>
          )}
          {this.renderVideo()}

          {(footerSmall || footerBig) && (
            <footer className={this.b.e('footer').classes()}>
              {footerSmall && <h4>{this.props.footerSmall}</h4>}
              {footerBig && <RichText markup={this.props.footerBig} />}
            </footer>
          )}

          {this.props.cta && (
            <div className={this.b.e('cta').classes()}>
              <Button
                {...cta.asButton}
                analyticsEvent={cta.analyticsEvent || analyticsEvent}
                analyticsValue={analyticsValue}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderBasicIconBoxGroups() {
    const basicIconBoxChunk = maxChunk(this.props.basicIconBoxes.length, 4)
    const basicIconBoxGroups = chunk(
      this.props.basicIconBoxes,
      basicIconBoxChunk
    )

    return basicIconBoxGroups.map(basicIconBoxGroup => (
      <div
        key={uuid()}
        className={this.b
          .e('basic-icon-box-group')
          .m({
            'one-item': basicIconBoxGroup.length === 1,
            'two-items': basicIconBoxGroup.length === 2,
            'three-items': basicIconBoxGroup.length === 3,
            'four-items': basicIconBoxGroup.length === 4
          })
          .classes()}
      >
        {this.renderBasicIconBoxes(basicIconBoxGroup)}
      </div>
    ))
  }

  renderBasicIconBoxes(basicIconBoxes) {
    basicIconBoxes = basicIconBoxes || this.props.basicIconBoxes
    return basicIconBoxes.map(basicIconBox => (
      <BasicIconBox
        key={uuid()}
        className={this.b.e('basic-icon-box').classes()}
        {...basicIconBox}
      />
    ))
  }
}

IconBoxStatic.propTypes = IconBoxStaticModel.propTypes()
IconBoxStatic.defaultProps = IconBoxStaticModel.defaultProps()
