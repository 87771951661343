import content from './content'
import detectedFeatures from './detectedFeatures'
import scrolling from './scrolling'
import self from './self'
import page from './page'
import leadForm from './lead-form'
import forms from './form'
import longDistanceRates from './longDistanceRates'
import search from './search'
import supportSearch from './supportSearch'
import subscription from './subscription'

export default {
  content,
  detectedFeatures,
  scrolling,
  self,
  page,
  leadForm,
  forms,
  longDistanceRates,
  search,
  supportSearch,
  subscription
}
