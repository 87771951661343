// Vendor packages
import deburr from 'lodash/deburr'
import kebabCase from 'lodash/kebabCase'

/**
 * Generates an application url for all endpoints
 * that need sitewide settings and formatting.
 *
 * @param  {string|object} source - A url or object with endpoint
 *
 * @return {string} A relative or absolute url
 */
export function url(source) {
  if (source.href) {
    return url(`${source.href}`)
  }

  if (source.src) {
    return assetUrl(`${source.src}`)
  }

  return normalize(`${source}`)
}

export function replaceHostname(source, oldHost, newHost) {
  return assetUrl(`${newHost}${removeHostname(source, oldHost)}`)
}

/**
 * Removes protocol and hostname, leaving only the path (after the /)
 *
 * @example
 * // returns /image/logo.png
 * removeHostname('https://images.contentful.com/image/logo.png', 'images.contentful.com')
 *
 * @param source
 * @param host
 * @returns {*}
 */
export function removeHostname(source, host) {
  return removeProtocol(source).split(host)[1]
}

/**
 * Generates an application url for an asset's
 * endpoint relative to the served context.
 *
 * @param  {string|object} source - A url or image object
 *
 * @return {string} An absolute url
 */
export function assetUrl(source) {
  return `https://${removeProtocol(url(source))}`
}

/**
 * Removes the protocol from the beginning of a url.
 *
 * @example
 * // returns 'foo.com'
 * removeProtocol('http://foo.com')
 *
 * @example
 * // returns 'foo.com'
 * removeProtocol('//foo.com')
 *
 * @param  {string} url
 *
 * @return {string} A url with no protocol
 */
export function removeProtocol(url) {
  return url ? url.replace(/(^\w+:|^)\/\//, '') : ''
}

/**
 * Apply sitewide formatting to a url.
 *
 * @param  {string} url
 *
 * @return {string} A relative or absolute url
 */
export function normalize(url) {
  return deburr(url)
}

/**
 * Slugify a string.
 *
 * @example
 * // result foo-bar-baz-biz-zap
 * slug('---__-  -foo%&bar bazBizZAP///')
 *
 * @param  {string} url
 *
 * @return {string} A relative or absolute url
 */
export function slug(str) {
  const splitByPipe = str ? str.split('|') : []
  const kebabSplit = splitByPipe.map(item => kebabCase(item))
  return kebabSplit.join('|')
}
