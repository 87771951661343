import React, { useEffect, useLayoutEffect, useState } from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import { HeroModel } from 'app/components/Hero/Hero'
import { SuperHero } from '@shaw/react-component-library'
import {
  ResponsiveImageModel,
  formatImage
} from 'app/components/ResponsiveImage/ResponsiveImage'

import './SuperHero.scss'

// Component model definition
export class SuperHeroModel extends Model {
  constructor(d) {
    super(d)
    this.entryId = d.entryId
    this.image = d.image || null // { large: d.image }
    this.video = d.videoAsset || null // { large: d.video }
  }

  static props() {
    return {
      ...HeroModel.props(),
      topOffset: Number,
      entryId: String,
      video: String,
      videoPoster: String,
      image: {
        type: ResponsiveImageModel.shape
      },
      background: {
        type: ResponsiveImageModel.shape
      },
      imageAlignment: String
    }
  }
}

export default function SuperHeroComponent({
  entryId,
  topOffset = 126,
  theme,
  cta,
  ctaAlt,
  image,
  imageAlignment,
  video,
  videoPoster,
  background,
  ...props
}) {
  // TODO: do it for desktop only!
  const [offset, setOffset] = useState(topOffset)
  const [page, setPage] = useState('')
  useEffect(() => {
    const trackPage =
      typeof window !== 'undefined' && location.pathname !== '/'
        ? `${slug(location.pathname)}|`
        : 'homepage|'
    setPage(trackPage)
  }, [])
  useLayoutEffect(() => {
    const alert = document.querySelector('.global-alert-message')
    const header = document.querySelector('.global-header')
    const nav = document.querySelector('.global-nav')
    const alertHeight = alert ? alert.offsetHeight : 0
    const headerHeight = header ? header.offsetHeight : 0
    const navHeight = nav ? nav.offsetHeight : 0
    const offsetHeight = alertHeight + headerHeight + navHeight

    setOffset(offsetHeight)
  }, [])
  const heroProps = new HeroModel(props)
  const defaultCta = cta ? new LinkModel(cta) : null
  const altCta = ctaAlt ? new LinkModel(ctaAlt) : null
  const responsiveImage = image
    ? {
        alt:
          image?.alt ||
          image?.title ||
          image?.large?.title ||
          image?.medium?.title ||
          image?.small?.title,
        large: formatImage(image?.large?.file?.url, 'large') || null,
        medium: formatImage(image?.medium?.file?.url, 'medium') || null,
        small: formatImage(image?.small?.file?.url, 'large') || null
      }
    : null

  const bgImage = background
    ? {
        large: formatImage(background?.large?.file?.url, 'large') || null,
        medium: formatImage(background?.medium?.file?.url, 'medium') || null,
        small: formatImage(background?.small?.file?.url, 'small') || null
      }
    : null
  const poster = videoPoster ? videoPoster?.file?.url : null
  const src = video ? video?.file?.url : null

  return (
    <SuperHero
      id={entryId}
      offset={offset}
      theme={theme}
      {...heroProps}
      video={{
        src,
        poster
      }}
      background={bgImage}
      cta={
        cta
          ? {
              ...defaultCta.asButton,
              variant: defaultCta.style,
              'data-event': defaultCta.determineAnalyticsEvent(),
              'data-value': `content|${page}${slug(
                heroProps.title || heroProps.name
              )}|${slug(defaultCta.text)}`
            }
          : null
      }
      ctaAlt={
        ctaAlt
          ? {
              ...altCta.asButton,
              variant: altCta.style,
              'data-event': altCta.determineAnalyticsEvent(),
              'data-value': `content|${page}${slug(
                heroProps.title || heroProps.name
              )}|${slug(altCta.text)}`
            }
          : null
      }
      responsiveImage={responsiveImage}
      imageAlignment={imageAlignment}
    />
  )
}
