import { default as createReducer } from 'app/utils/create-reducer'
import { SEARCHING, SEARCH_RESULTS } from 'app/actions/search'

export const initialState = {
  loading: false,
  data: null
}

export default createReducer(
  {
    [SEARCHING]: state => {
      return {
        ...state,
        loading: true
      }
    },
    [SEARCH_RESULTS]: (state, { data }) => {
      return {
        ...state,
        loading: false,
        data
      }
    }
  },
  initialState
)
