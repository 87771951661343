import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'

import './Demo.scss'

export class DemoModel extends Model {
  static props() {
    return {
      title: String,
      description: String,
      items: Array
    }
  }
}

function DemoItem({ title, items, background }) {
  const classes = Bem('demo-item')
  return (
    <div className={classes.m({ [background]: true }).c()}>
      {title && <RichText markup={title} className={classes.e('title').c()} />}
      {items.map(item => {
        if (item.contentType === 'link') {
          const cta = new LinkModel(item)

          return <Button key={item.entryId} {...cta.asButton} />
        }
        return null
      })}
    </div>
  )
}

export default function Demo({ title, description, items }) {
  const classes = Bem('demo')
  return (
    <section className={classes.c()}>
      {title && <RichText markup={title} />}
      {description && <RichText markup={description} />}
      <div className={classes.e('container').c()}>
        {items.map(item => (
          <DemoItem key={item.entryId} {...item} />
        ))}
      </div>
    </section>
  )
}
