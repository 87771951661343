import axios from 'axios'

export const SUPPORT_SEARCHING = 'SUPPORT_SEARCHING'
export const SUPPORT_SEARCH_RESULTS = 'SUPPORT_SEARCH_RESULTS'

export const setSupportSearching = () => ({ type: SUPPORT_SEARCHING })
export const setSupportSearchResults = data => ({
  type: SUPPORT_SEARCH_RESULTS,
  data
})

export const searchSupport = (
  keyword,
  page,
  pagesize,
  cbs = { success: () => {}, failure: () => {} }
) => {
  return dispatch => {
    dispatch(setSupportSearching())
    axios
      .get('/support-search', {
        params: {
          keyword,
          page,
          pagesize
        }
      })
      .then(res => {
        dispatch(setSupportSearchResults(res.data))
        if (cbs.success) {
          cbs.success(res.data)
        }
      })
      .catch(err => {
        dispatch(setSupportSearchResults(err.response.data))
        if (cbs.failure) {
          cbs.failure(err.response.data)
        }
      })
  }
}
