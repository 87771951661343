import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import ImageModel from 'app/models/Image/Image'
import { IconModel } from 'app/components/Icon/Icon'
class NavPromoModel extends Model {
  static props() {
    return {
      name: String,
      align: String,
      background: String,
      title: String,
      description: String,
      icon: IconModel.shape,
      cta: LinkModel.shape,
      image: ImageModel.shape
    }
  }
}
class GroupLinks extends Model {
  constructor(d) {
    super(d)

    this.links = (d.collection || []).map(link => new LinkModel(link))
    this.groupTitle = d.label
  }

  static props() {
    return {
      groupTitle: String,
      links: LinkModel.arrayOf
    }
  }
}

export default class LinkListModel extends Model {
  constructor(d) {
    super(d)
    this.links = (d.links || []).map(link => new LinkModel(link))
    this.groupLinks = (d.groupLinks || []).map(link => new GroupLinks(link))
    this.groupLinks2 = (d.groupLinks2 || []).map(link => new GroupLinks(link))
    this.link = d.link ? new LinkModel(d.link) : null
    this.rightPromo = d.rightPromo ? new NavPromoModel(d.rightPromo) : null
    this.bottomPromo = d.bottomPromo ? new NavPromoModel(d.bottomPromo) : null
  }

  static props() {
    return {
      href: String,
      label: [String, Number],
      link: LinkModel.shape,
      links: LinkModel.arrayOf,
      groupTitle: String,
      groupLinks: GroupLinks.arrayOf,
      groupLinks2: GroupLinks.arrayOf,
      rightPromo: NavPromoModel.shape,
      bottomPromo: NavPromoModel.shape
    }
  }
}
