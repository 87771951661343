import * as sharedMods from './shared'
import * as landingMods from './landing'
import * as pppMods from './ppp'
import * as searchMods from './search'
import * as homeMods from './home'
import * as leadFormMods from './leadForm'
import * as supportMods from './support'
import * as subscribeMods from './subscribe'

export default {
  ...sharedMods,
  ...landingMods,
  ...pppMods,
  ...searchMods,
  ...homeMods,
  ...leadFormMods,
  ...supportMods,
  ...subscribeMods
}
