import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import Bem from 'react-bem-helper'

import Model from 'app/models/Model'
import RichText from 'app/components/RichText/RichText'

import OutageArticle, {
  OutageArticleModel
} from '../OutageArticle/OutageArticle'

import './OutageList.scss'

const dot = () => ({
  alignItems: 'center',
  display: 'flex'
})

const colourStyles = {
  dropdownIndicator: (styles, { isFocused, hasValue }) => {
    return {
      ...styles,
      border: 'none',
      color: '#1f1f1f',
      transform: isFocused && !hasValue ? 'rotate(-180deg)' : 'rotate(0deg)',
      ':hover': {
        ...styles[':hover'],
        color: '#1f1f1f'
      }
    }
  },
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none'
  }),
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    padding: '5px 7px',
    border: '1px solid #1f1f1f',
    ':hover': {
      ...styles[':hover'],
      border: '1px solid #1f1f1f',
      cursor: 'pointer'
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderBottom: '1px solid #ededed',
      padding: '12px 15px',
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? '#dddddd'
          : isFocused
            ? '#ededed'
            : null,
      color: isDisabled ? '#ccc' : isSelected ? data.color : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : '#aaa')
      }
    }
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
}
const options = [
  // { value: 'National', label: 'National' },
  { value: null, label: 'All Outages' },
  { value: 'Vancouver Island', label: 'Vancouver Island' },
  { value: 'Lower Mainland', label: 'Lower Mainland' },
  { value: 'BC Interior', label: 'BC Interior' },
  { value: 'Northern Alberta', label: 'Northern Alberta' },
  { value: 'Southern Alberta', label: 'Southern Alberta' },
  { value: 'Saskatchewan', label: 'Saskatchewan' },
  { value: 'Manitoba', label: 'Manitoba' },
  { value: 'Ontario', label: 'Ontario' }
]

export class OutageListModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      introText: String,
      filterTitle: String,
      emptyList: String,
      hasFilter: Boolean,
      outages: { type: OutageArticleModel.shape, required: true }
    }
  }
}

export default function OutageList({
  outages,
  introText,
  filterTitle,
  hasFilter,
  emptyList = 'Great news! There are no service outages in your area.'
}) {
  const initOutages = outages
    ? outages.filter(outage => outage && outage.status !== 'archived')
    : null
  const [filter, setFilter] = useState(null)
  const [list, setList] = useState(initOutages)

  useEffect(() => {
    if (filter !== null) {
      const newlist = initOutages.filter(
        item => item.region.includes(filter) || item.region.includes('National')
      )
      setList(newlist)
    } else if (filter === null) {
      setList(initOutages)
    }
  }, [filter])

  const handleChange = input => {
    setFilter(input.value)
  }
  const classes = new Bem({
    name: 'support-outage-list',
    prefix: ''
  })

  if (!outages || outages.length === 0) {
    return (
      <div {...classes({ element: 'outages-container' })}>
        <div {...classes({ element: 'outages' })}>
          <RichText
            {...classes({ element: 'no-outages' })}
            markup={emptyList}
            isMarkdown
            renderers={{
              root: 'p',
              paragraph: 'span'
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <section {...classes()}>
      {introText && (
        <div {...classes({ element: 'intro' })}>
          <RichText
            markup={introText}
            isMarkdown
            renderers={{
              root: 'p',
              paragraph: 'span'
            }}
          />
        </div>
      )}
      {hasFilter && (
        <div {...classes({ element: 'filter-container' })}>
          <div {...classes({ element: 'filter' })}>
            {filterTitle && (
              <h4 {...classes({ element: 'filter-title' })}>{filterTitle}</h4>
            )}
            <div {...classes({ element: 'filter-box' })}>
              Filter:{' '}
              <Select
                placeholder="Select an option"
                ariaLabel="Select an option"
                className="select-box"
                options={options}
                onChange={handleChange}
                styles={colourStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: '#1f1f1f',
                    primary: '#1f1f1f'
                  }
                })}
              />
            </div>
          </div>
        </div>
      )}
      <div {...classes({ element: 'outages-container' })}>
        <div {...classes({ element: 'outages' })}>
          {(!list || list.length === 0) && (
            <RichText
              {...classes({ element: 'no-outages' })}
              markup={emptyList}
              isMarkdown
              renderers={{
                root: 'p',
                paragraph: 'span'
              }}
            />
          )}
          {list.map((outage, i) =>
            outage ? (
              <OutageArticle key={outage.entryId || i} {...outage} />
            ) : null
          )}
        </div>
      </div>
    </section>
  )
}
