import React, { useEffect, useState } from 'react'

import Bem from 'react-bem-helper'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Icon from 'app/components/Icon/Icon'
import LinkModel from 'app/models/Link/Link'

import './SearchBar.scss'

export class SearchBarModel extends Model {
  static props() {
    return {
      placeholder: { type: String, default: 'Search Rogers Business Support' }
    }
  }
}

export default function SearchBar(props) {
  const classes = new Bem({
    name: 'support-search-bar',
    prefix: ''
  })

  const [searchTerm, setSearchTerm] = useState(props.searchTerm || '')
  useEffect(() => {
    window.addEventListener('popstate', onPopState)
    return () => {
      window.removeEventListener('popstate', onPopState)
    }
  }, [])
  const isActiveCss = searchTerm ? 'active' : ''
  const searchLink = new LinkModel(props.supportSearchPage)
  const safeSearchTerm = searchTerm.replace(/\s/g, '%20')
  const buildSearchUrl = () => {
    return `${searchLink.href}?q=${safeSearchTerm}`
  }

  const onPopState = event => {
    if (event && event.state) {
      setSearchTerm(event.state.keyword)
    }
  }
  const onSubmit = event => {
    if (typeof props.onSearch === 'function') {
      const safeSearchTerm = searchTerm.replace(/\s/g, '%20')
      event.preventDefault()
      props.onSearch(safeSearchTerm)
      return
    }

    // for some reason default form behaviour when action is used
    // the ?q is not passed to search result page
    // that's why I had to do the code below until I find the reason
    event.preventDefault()
    window.location.href = buildSearchUrl()
  }

  const changeHandler = event => {
    setSearchTerm(event.target.value)
  }
  return (
    <div {...classes()}>
      <form
        {...classes({ element: 'container' })}
        method="get"
        action={buildSearchUrl()}
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <input
          type="text"
          {...classes({ element: 'input' })}
          placeholder={props.placeholder || searchLink.label}
          value={searchTerm}
          onChange={changeHandler}
        />
        <button
          type="submit"
          {...classes({ element: 'btn-search', modifier: isActiveCss })}
          onClick={onSubmit}
          data-event="navigationAction"
          data-value={`${slug(props.name)}|search-bar|search`}
        >
          <Icon type="search" />
        </button>
      </form>
    </div>
  )
}
