import * as Yup from 'yup'
import axios from 'axios'

const phoneRegExp =
  /^([(]{0,1}?[\d]{3}[)\s]{0,2}?[\d]{3}[-]{0,1}[\d]{4})([\s]{0,1}[a-z]{1}[\s]{0,1}[\d]{2,4})?$/

const nameRegExp = /^[a-zA-Z0-9]+([-_\s]{1}[a-zA-Z0-9]+)*$/
// const accountRegExp =
//   /^$|^((?!-)(?!.*--)(?!.*-$)[\d-+]{1,10}|(\d{3}(-)?\d{4}(-)?\d{4}))$/

// const postalCodeRegExp =
//   /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Must provide first name.')
    .matches(nameRegExp, 'Only alphanumeric characters, please.')
    .notOneOf(
      [Yup.ref('lastName'), null],
      'First and last name cannot be the same.'
    ),
  lastName: Yup.string()
    .required('Must provide last name.')
    .matches(nameRegExp, 'Only alphanumeric characters, please.')
    .notOneOf(
      [Yup.ref('firstName'), null],
      'First and last name cannot be the same.'
    ),
  phoneNum: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Must provide a 10-digit phone number.'),
  emailAddy: Yup.string()
    .email('Email seems invalid.')
    .required('Must provide a valid email address.'),
  companyName: Yup.string().required('Must provide company name.'),
  city: Yup.string().required('Must provide city.'),
  province: Yup.string().required('Must select a province.'),
  comments: Yup.string().required('Must add some comments.'),
  productInterest: Yup.array().test({
    name: 'productInterest-test',
    exclusive: true,
    message: 'Select at least one option',
    test: value => value.length > 0
  }),
  wirelessLines: Yup.string().required('Must select an option.').nullable(),
  newDevices: Yup.string().required('Must select an option.').nullable(),
  employeesCount: Yup.string().required('Must select an option.').nullable(),
  currentServices: Yup.string().when('productInterest', {
    is: productInterest => {
      return (
        productInterest.length === 0 ||
        (productInterest.length > 0 &&
          !(
            productInterest.includes('Internet') &&
            productInterest.includes('Wireless')
          ))
      )
    },
    then: Yup.string().required('Must select an option.').nullable(),
    otherwise: Yup.string().nullable()
  })
})

async function handleFormSubmit(values) {
  let formUrl = ''
  if (typeof window !== undefined) {
    formUrl = `${location.href}`
  }
  return await axios.post('/lead-form-submit', {
    ...values,
    formSubmitTo: 'SALESFORCE',
    contentType: 'RequestForm',
    formUrl
  })
}

export { validationSchema, handleFormSubmit }
