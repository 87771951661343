import { DISABLE_SCROLLING, ENABLE_SCROLLING } from 'app/actions/shared'
import { default as createReducer } from 'app/utils/create-reducer'

export const initialState = true
export default createReducer(
  {
    [DISABLE_SCROLLING]: () => false,
    [ENABLE_SCROLLING]: () => true
  },
  initialState
)
