import { default as createReducer } from 'app/utils/create-reducer'
import {
  SUBSCRIPTION_IDLDE,
  SUBSCRIBE,
  SUBSCRIBING,
  SUBSCRIBED,
  UNSUBSCRIBE,
  UNSUBSCRIBING,
  UNSUBSCRIBED
} from 'app/actions/subscription'

export const initialState = {
  status: SUBSCRIPTION_IDLDE,
  data: null
}

export default createReducer(
  {
    [SUBSCRIBE]: state => {
      return {
        ...state,
        status: SUBSCRIBE,
        data: null
      }
    },
    [SUBSCRIBING]: state => {
      return {
        ...state,
        status: SUBSCRIBING
      }
    },
    [SUBSCRIBED]: (state, { data }) => {
      return {
        ...state,
        status: SUBSCRIBED,
        data: data
      }
    },
    [UNSUBSCRIBE]: state => {
      return {
        ...state,
        status: UNSUBSCRIBE,
        data: null
      }
    },
    [UNSUBSCRIBING]: state => {
      return {
        ...state,
        status: UNSUBSCRIBING
      }
    },
    [UNSUBSCRIBED]: (state, { data }) => {
      return {
        ...state,
        status: UNSUBSCRIBED,
        data: data
      }
    }
  },
  initialState
)
