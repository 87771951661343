import React from 'react'

import { connect } from 'react-redux'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Icon from 'app/components/Icon/Icon'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Eyebrow from 'app/components/Eyebrow/Eyebrow'
import RichText from 'app/components/RichText/RichText'
import GradientBackground from 'app/components/GradientBackground/GradientBackground'

import './HeroSupportSearch.scss'
export class HeroSupportSearchModel extends Model {
  static props() {
    return {
      name: String,
      searchTerm: {
        type: String,
        default: ''
      },
      eyebrow: String,
      title: String,
      description: String,
      theme: String,
      placeholder: {
        type: String,
        default: 'Search'
      },
      alternativeLayout: Boolean,
      supportSearchPage: {
        type: LinkModel.shape,
        default: LinkModel.defaultProps()
      }
    }
  }
}

// Module definition
class HeroSupportSearch extends Component {
  constructor(props) {
    super(props, 'hero-support-search')

    this.state = {
      opened: true,
      searchTerm: ''
    }
  }
  componentDidMount() {
    const keyword =
      this.props.request && this.props.request.query
        ? this.props.request.query.q
        : ''
    this.setState({ searchTerm: keyword })

    window.addEventListener('popstate', this.onPopState)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopState)
  }

  m = () => {
    return {
      opened: this.state.opened,
      scotch: true
    }
  }

  buildSearchUrl = () => {
    const { searchTerm } = this.state
    const safeSearchTerm = searchTerm?.replace(/\s/g, '%20')
    const { supportSearchPage } = this.props
    const searchLink = new LinkModel(supportSearchPage)

    return `${searchLink.href}?q=${safeSearchTerm}`
  }

  onPopState = event => {
    if (event && event.state) {
      this.setState({ searchTerm: event.state.keyword })
    }
  }

  onSubmit = event => {
    if (typeof this.props.onSearch === 'function') {
      event.preventDefault()
      const safeSearchTerm = this.state.searchTerm?.replace(/\s/g, '%20')
      this.props.onSearch(safeSearchTerm)
      return
    }

    // for some reason default form behaviour when action is used
    // the ?q is not passed to search result page
    // that's why I had to do the code below until I find the reason
    event.preventDefault()
    window.location.href = this.buildSearchUrl()
  }

  changeHandler = event => {
    this.setState({ searchTerm: event.target.value })
  }

  hasValue = () => {
    return this.state.searchTerm && this.state.searchTerm.length > 0
  }

  renderSearchBar = () => {
    const isActiveCss = this.state.searchTerm ? 'active' : ''

    return (
      <div className={this.e('search-bar-container')}>
        <input
          type="text"
          className={this.e('input')}
          placeholder={this.props.placeholder}
          value={this.state.searchTerm}
          onChange={this.changeHandler}
        />
        <button
          type="submit"
          className={this.e('btn-search', isActiveCss)}
          onClick={this.onSubmit}
          data-event="navigationAction"
          data-value={`${slug(this.props.name)}|search-bar|search`}
        >
          <Icon type="search" />
        </button>
      </div>
    )
  }

  render() {
    const isAlternativeLayout = this.props.alternativeLayout
      ? 'alt-layout'
      : 'original'
    return (
      <div className={this.b()}>
        <form
          method="get"
          action={this.buildSearchUrl()}
          autoComplete="off"
          onSubmit={this.onSubmit}
          className={this.e(isAlternativeLayout)}
        >
          <GradientBackground
            className={this.e('hero-bg')}
            theme={this.props.theme}
          >
            <div className={this.e('hero-body')}>
              <div className={this.e('aligned')}>
                {this.props.eyebrow && <Eyebrow text={this.props.eyebrow} />}
                {this.props.title && (
                  <RichText
                    renderers={{ root: 'h1' }}
                    className={this.e('heading')}
                    markup={this.props.title}
                  />
                )}
                {this.props.description && (
                  <RichText
                    className={this.e('description')}
                    markup={this.props.description}
                  />
                )}
              </div>
              {this.renderSearchBar()}
            </div>
          </GradientBackground>
        </form>
      </div>
    )
  }
}

HeroSupportSearch.props(HeroSupportSearchModel)

export default connect(state => {
  return {
    request: state.self
  }
})(HeroSupportSearch)
