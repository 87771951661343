import React from 'react'

import axios from 'axios'
import ReactDOM from 'react-dom'
import queryParse from 'query-parse'
import { Provider } from 'react-redux'

import ConnectedApp from 'app/modules/App'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { createPatchedStore, initializeStoreServer } from 'app/utils/store'
import {
  createBrowserDetectionAction,
  // initLiveAgent,
  setRequestQuery,
  setStore
} from 'app/actions/shared'

import { formatContentfulEntry } from './format-contentful'

export default function bootstrapClient(mods, models) {
  let thisRoute = window.__INITIAL_STATE__.rawData
  let dataRoute = `/content-provider?path=${thisRoute}`
  const previewKey = getQueryParam('preview_key')

  if (previewKey) {
    dataRoute += `&preview_key=${previewKey}`
  }

  axios
    .get(dataRoute)
    .then(res => {
      const axeConfig = {}
      if (process.env.NODE_ENV !== 'production') {
        const axe = require('@axe-core/react')
        axe(React, ReactDOM, 1000, axeConfig)
      }

      const initialState = {
        content: formatContentfulEntry(res.data),
        ...window.__INITIAL_STATE__
      }
      delete initialState.rawData

      const appRoot = window.document.querySelector('div[data-component="app"]')
      const store = window.__INITIAL_STATE__
        ? createPatchedStore(
            initializeStoreServer(initialState, false).getState()
          )
        : createPatchedStore()

      if (window && window.location) {
        const query = window.location.search
          ? queryParse.toObject(window.location.search.substr(1))
          : {}
        store.dispatch(setRequestQuery(query))
      }

      if (!module.hot || (appRoot && appRoot.innerHTML !== '')) {
        hydrateRoot(
          appRoot,
          <Provider store={store}>
            <ConnectedApp
              availableModules={mods}
              availableModels={models}
              disclaimerTitle="Terms & Conditions"
            />
          </Provider>
        )
      } else {
        const root = createRoot(appRoot)
        root.render(
          <Provider store={store}>
            <ConnectedApp
              availableModules={mods}
              availableModels={models}
              disclaimerTitle="Terms & Conditions"
            />
          </Provider>
        )
      }

      store.dispatch(setStore(store))
      store.dispatch(createBrowserDetectionAction())
      // store.dispatch(initLiveAgent())
    })
    .catch(err => {
      console.error(err)
    })
}

export function getQueryParam(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]') // eslint-disable-line
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(window.location.search)
  return results === null
    ? false
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}
