// Application dependencies
import Model from 'app/models/Model'

// Model definition
export default class PricingTextModel extends Model {
  static props() {
    return {
      name: { type: String, default: 'Pricing Text Model' },
      startingAtText: { type: String, default: '' },
      regularPriceText: { type: String, default: 'regular default' },
      standardPriceText: { type: String, default: 'standard default' },
      firstYearPriceText: { type: String, default: 'first year default' },
      otherYearPriceText: { type: String, default: 'third year default' },
      promoBannerText: { type: String, default: '' },
      promoBannerTextHtml: { type: String, default: '' },
      newCustomerText: { type: String, default: 'for default customers only' },
      recommendedText: { type: String, default: 'RECOMMENDED DEFAULT' },
      customDisclaimer: { type: String, default: '*' }
    }
  }
}
