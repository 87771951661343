import IconCmsModel from 'app/components/Icon/Icon.cms.json'

/**
 * Returns a new function with a chainable isRequired method.  This allows us to specify required or optional
 * custom props
 * @param validator {function} custom propType function
 * @returns {function(this:null)} chainable propType function
 */
export function makeChainable(validator) {
  function chainableValidator(isRequired, props, propName, componentName) {
    const _prop = props[propName]

    if (isRequired) {
      if (typeof _prop === 'undefined') {
        return new Error(
          `Error in ${componentName}, ${propName} prop.  Required props cannot be undefined.`
        )
      }

      if (_prop === null) {
        return new Error(
          `Error in ${componentName}, ${propName} prop.  Required props cannot be null.`
        )
      }
    }

    return validator(props, propName, componentName)
  }

  const _validator = chainableValidator.bind(null, false)
  _validator.isRequired = chainableValidator.bind(null, true)
  return _validator
}

/**
 * Validate an icon prop.  Checks if the prop is a string and if it is contained in our icon set
 * @param props {object} All props for component
 * @param propName {string} Name of prop to be checked
 * @param componentName {string} Name of component
 * @returns {null || Error} Returns null if the prop check passes
 */
export function isIcon(props, propName, componentName) {
  const _prop = props[propName]

  if (typeof _prop !== 'string') {
    return new Error(
      `Error in ${componentName}, ${propName} prop.  Icon prop must be a string.`
    )
  }

  if (!getValidIconSet().includes(_prop)) {
    return new Error(
      `Error in ${componentName}, ${propName} prop.  Icon prop not a part of valid set (${_prop}).` +
        `  Check Icon.cms.json type field validator for allowed iconSet.`
    )
  }

  return null
}

/**
 * Import this as CustomPropTypes into a component to use shaw custom prop types.
 * For more info on custom prop types see the docs: https://facebook.github.io/react/docs/typechecking-with-proptypes.html
 * example: CustomPropTypes.icon.isRequired
 */
export default {
  icon: makeChainable(isIcon)
}

// Helpers

export function getValidIconSet() {
  return IconCmsModel.contentType.fields
    .find(field => field.id === 'type')
    .validations.find(validator => Array.isArray(validator.in)).in
}
