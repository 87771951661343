import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import { BasicTextBoxModel } from 'app/components/BasicTextBox/BasicTextBox'
import CollapsableCard from 'app/components/CollapsableCard/CollapsableCard'
import ResponsiveImage, {
  ResponsiveImageModel
} from 'app/components/ResponsiveImage/ResponsiveImage'
import BasicTextBoxStack, {
  BasicTextBoxStackModel
} from 'app/components/BasicTextBoxStack/BasicTextBoxStack'
import HardwareFeatureListItem, {
  HardwareFeatureListItemModel
} from 'app/components/HardwareFeatureListItem/HardwareFeatureListItem'

import './ExpandingProductCard.scss'

// Module model definition
export class ExpandingProductCardModel extends Model {
  static props() {
    return {
      name: String,
      title: {
        type: String,
        required: true
      },
      image: {
        type: ResponsiveImageModel.shape,
        required: true
      },
      textBoxes: {
        type: BasicTextBoxModel.arrayOf,
        required: true
      },
      hardwareFeatureListItems: {
        type: HardwareFeatureListItemModel.arrayOf,
        required: true,
        default: []
      },
      cta: LinkModel.shape,
      background: {
        type: String,
        default: 'white'
      }
    }
  }
}

export default class ExpandingProductCard extends Component {
  constructor(props) {
    super(props, 'expanding-product-card')

    this.state = {
      isShowingOverflow: false
    }
  }

  m() {
    return {
      [this.props.background]: true,
      scotch: true
    }
  }

  toggleCard() {
    this.setState({ isShowingOverflow: !this.state.isShowingOverflow })
  }

  renderMainContent() {
    const stackModel = new BasicTextBoxStackModel({
      showDividers: true,
      textBoxes: this.props.textBoxes,
      orientation: 'row'
    })

    return (
      <div className={this.e('text-box-container')}>
        <BasicTextBoxStack {...stackModel} />
      </div>
    )
  }

  renderCollapsableContent() {
    const { hardwareFeatureListItems } = this.props
    return hardwareFeatureListItems && hardwareFeatureListItems.length > 0 ? (
      <ul className={this.e('hardware-feature-list')}>
        {hardwareFeatureListItems &&
          hardwareFeatureListItems.map((listItem, idx) => (
            <HardwareFeatureListItem key={idx} {...listItem} />
          ))}
      </ul>
    ) : (
      ''
    )
  }

  renderCTA() {
    if (!this.props.cta) {
      return null
    }

    const link = new LinkModel(this.props.cta)

    return (
      <div className={this.e('cta-block')}>
        <Button
          {...link.asButton}
          analyticsEvent={link.analyticsEvent || 'navigarionAction'}
          analyticsValue={`${slug(this.props.name)}|${slug(link.label)}`}
        />
      </div>
    )
  }

  render() {
    return (
      <div
        className={this.b(
          this.props.hardwareFeatureListItems || this.props.cta
            ? ''
            : 'nothing-to-expand'
        )}
      >
        <div className={this.e('full-bleed-background')}>
          <ResponsiveImage
            {...this.props.image}
            className={this.e('full-bleed-background-image')}
          />
        </div>
        <div className={this.e('content-container')}>
          <h2 className={this.e('title')}>{this.props.title}</h2>
          <CollapsableCard
            className={this.e('collapsable-card')}
            toggleCard={this.toggleCard}
            isOpen={this.state.isShowingOverflow}
            renderMain={this.renderMainContent}
            renderCollapsable={this.renderCollapsableContent}
            analyticsEvent={'helpfulAction'}
            parentModuleName={`${slug(this.props.name)}`}
          />
        </div>
        {this.renderCTA()}
      </div>
    )
  }
}

ExpandingProductCard.props(ExpandingProductCardModel)
