import React from 'react'

import Bem from 'react-bem-helper'

import RichText from 'app/components/RichText/RichText'
import VideoPlayer from 'app/modules/landing/YouTubePlayer/YouTubePlayer'

import './SupportArticleSection.scss'

export default function SupportArticleSection({
  title,
  topicIntro,
  introVideos,
  expandableText,
  expandableVideos,
  entryId
}) {
  const classes = new Bem({
    name: 'support-artice-section',
    prefix: ''
  })

  return (
    <div {...classes()} id={`id-${entryId}`} name={`id-${entryId}`}>
      {introVideos &&
        introVideos.map(video => (
          <VideoPlayer key={video.entryId} inline {...video} />
        ))}
      {title && <h3>{title}</h3>}
      {topicIntro && (
        <RichText
          withContainer
          markup={topicIntro}
          renderers={{ root: 'div' }}
        />
      )}
      {expandableVideos &&
        expandableVideos.map(video => (
          <VideoPlayer key={video.entryId} inline {...video} />
        ))}
      {expandableText && (
        <RichText
          withContainer
          markup={expandableText}
          renderers={{ root: 'div' }}
        />
      )}
    </div>
  )
}
