export const findNodePosX = node => {
  let curleft = 0
  if (node.offsetParent) {
    do {
      curleft += node.offsetLeft
      node = node.offsetParent
    } while (node !== null)
  }
  return curleft
}

export const findNodePosY = node => {
  let curtop = 0
  if (node.offsetParent) {
    do {
      curtop += node.offsetTop
      node = node.offsetParent
    } while (node !== null)
  }
  return curtop
}

export const escapeHtml = text => {
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  }

  return text.replace(/[&<>"']/g, function (m) {
    return map[m]
  })
}
export const loadJavaScript = (src, onload) => {
  let b = document
  let e = (b.scripts && b.scripts.length - 1) || -1
  while (e >= 0) {
    b.scripts[e].getAttribute('src') === src ? (e = -1) : e--
  }
  if (e < 0) {
    const c = 'script'
    const d = b.createElement(c)
    d.src = src
    d.type = 'text/java' + c
    d.async = false
    d.onload = onload
    const a = b.getElementsByTagName(c)[0]
    a.parentNode.insertBefore(d, a)
  } else if (typeof onload === 'function') {
    onload()
  }
}
export const loadStyle = (url, async, onload) => {
  const e = document.querySelectorAll(
    `link[rel="stylesheet"][href="${url}"]`
  ).length
  if (e === 0) {
    const wa = document.createElement('link')
    wa.rel = 'stylesheet'
    wa.type = 'text/css'
    wa.async = async !== undefined ? async : false
    wa.href = url
    wa.onload = onload
    var s = document.getElementsByTagName('head')[0]
    s.appendChild(wa)
  } else if (typeof onload === 'function') {
    onload()
  }
}
