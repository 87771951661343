import React from 'react'
import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'

import './FinalMessage.scss'

export default function FinalMessage({
  message = {},
  tracking,
  resetForm = null
}) {
  const b = Bem('final-message')
  const supportMessage = `In order to serve you best, please call <a
  href="tel:${message && message.value}" data-event="navigationAction"
  data-value="secure-request-information-support|contact-form|technical-support|call-now-for-technical-support">${
    message && message.value
  }</a> for assistance.`
  const cancelMessage = `In order to cancel your Rogers Business services, you are required to
  contact us by telephone. Please call <a href="tel:${
    message && message.value
  }" data-event="navigationAction" data-value="secure-request-information-support|contact-form|cancel|call-now-to-cancel-a-service">${
    message && message.value
  }</a> for assistance.`

  return (
    <div className={b.classes()}>
      <div className={b.e('icon').classes()}>
        <svg
          height="83"
          viewBox="0 0 83 83"
          width="83"
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-CUSTOM_SVG"
        >
          <g fill="none" stroke="#da291c">
            <path
              d="m69.2064119 69.2051881c-7.1245731 7.1242976-16.9670107 11.5308119-27.8384119 11.5308119-10.870757 0-20.7127974-4.4065973-27.8371303-11.5309303-7.12425111-7.1242511-11.5308697-16.9661906-11.5308697-27.8370697s4.40661859-20.7128186 11.5308697-27.8370697c7.1243329-7.12433295 16.9663733-11.5309303 27.8371303-11.5309303 10.8714012 0 20.7138388 4.40651434 27.8384119 11.5308119 7.1244942 7.1242186 11.5311881 16.9661705 11.5311881 27.8371881s-4.4066939 20.7129695-11.5311881 27.8371881z"
              strokeWidth="4"
            ></path>
            <path
              d="m42 20v26.0751857c0 .2761424-.1119288.5261424-.2928932.7071068s-.4309644.2928932-.7071068.2928932-.5261424-.1119288-.7071068-.2928932-.2928932-.4309644-.2928932-.7071068v-26.0751857c0-.2761424.1119288-.5261424.2928932-.7071068s.4309644-.2928932.7071068-.2928932.5261424.1119288.7071068.2928932.2928932.4309644.2928932.7071068z"
              strokeWidth="2"
            ></path>
            <circle cx="41" cy="60" r="1.5" strokeWidth="3"></circle>
          </g>
        </svg>
      </div>
      {message && message.title && (
        <h2 className={b.e('title').classes()}>{message.title}</h2>
      )}

      <RichText
        className={b.e('message').classes()}
        isMarkdown
        renderers={{ root: 'div' }}
        markup={message.status === 'cancel' ? cancelMessage : supportMessage}
      />

      <a
        href={`tel:${message && message.value}`}
        tabIndex="0"
        className="button button--scotch-primary button--light basic-icon-box__cta-button"
        target="_self"
        data-event="navigationAction"
        data-value={`contact-form|${tracking}|${
          message.status === 'cancel'
            ? 'call-now-to-cancel-a-service'
            : 'call-now-for-support'
        }`}
        aria-label={
          message.status === 'cancel'
            ? 'Call now to cancel a service'
            : 'Call now for support'
        }
      >
        <span className="button__text">Call now</span>
      </a>

      {resetForm !== null && (
        <button
          className={b.e('go-back').classes()}
          onClick={() => {
            resetForm()
          }}
        >
          <span>Start over</span>
        </button>
      )}
    </div>
  )
}
