import React from 'react'

import PropTypes from 'prop-types'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import BasicIconBox, {
  BasicIconBoxModel
} from 'app/components/BasicIconBox/BasicIconBox'

import 'app/components/BasicIconBox/BasicIconBox.scss'

import './FeaturedSolutions.scss'

// Module model definition
export class FeaturedSolutionsModel extends Model {
  static props() {
    return {
      name: String,
      title: String,
      alternativeBackground: Boolean,
      isCarousel: Boolean,
      iconBoxes: PropTypes.arrayOf(
        PropTypes.shape(BasicIconBoxModel.propTypes())
      ),
      background: { type: String, default: 'transparent' }
    }
  }
}

// Module definition
export default class FeaturedSolutions extends Component {
  constructor(props) {
    super(props, 'featured-solutions')
    this.handleBreakpointChange = this.handleBreakpointChange.bind(this)
    this.handleSlideChange = this.handleSlideChange.bind(this)
    this.renderItem = this.renderItem.bind(this)

    this.state = {
      breakpoint: null,
      shouldRenderCarousel: false,
      currentIndex: 0
    }

    if (global && global.addEventListener && props.isCarousel !== null) {
      global.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    if (
      global &&
      global.removeEventListener &&
      this.props.isCarousel !== null
    ) {
      global.removeEventListener('resize', this.handleResize)
    }
  }

  handleResize() {
    const width = window.innerWidth
    if (width >= 1024 && this.state.breakpoint !== 'large') {
      this.setState({
        breakpoint: 'large',
        currentIndex: 0
      })
    } else if (
      width < 1024 &&
      width >= 768 &&
      this.state.breakpoint !== 'medium'
    ) {
      this.setState({
        breakpoint: 'medium',
        currentIndex: this.state.currentIndex
      })
    } else if (width < 768 && this.state.breakpoint !== 'small') {
      this.setState({
        breakpoint: 'small',
        currentIndex: this.state.currentIndex
      })
    }
  }

  handleBreakpointChange(newBreakpoint) {
    if (newBreakpoint === 'large' && window.innerWidth === 1023) {
      return
    }
    this.setState({
      breakpoint: newBreakpoint,
      currentIndex: newBreakpoint === 'large' ? 0 : this.state.currentIndex
    })
  }

  handleSlideChange(currentIndex) {
    this.setState({ currentIndex })
  }

  renderItem(iconBox) {
    const buttonStyle = ''
    const ctaLink = new LinkModel(iconBox.cta)
    const settings = {
      ...iconBox,
      analyticsEvent: ctaLink.determineAnalyticsEvent(),
      analyticsValue: `${slug(this.props.name)}|${slug(iconBox.name)}|${slug(
        iconBox.cta.name
      )}`
    }

    return (
      <div key={uuid()} className={this.e('icon-box-container')}>
        <div className={this.e('icon-box-body')}>
          <BasicIconBox
            className={this.e('box')}
            buttonStyle={buttonStyle || 'primary'}
            {...settings}
          />
        </div>
      </div>
    )
  }

  m() {
    return {
      [this.props.background]: true
    }
  }

  render() {
    const slides = this.props.iconBoxes.map(iconBox => {
      return this.renderItem(iconBox)
    })
    return (
      <section className={this.b()} aria-label={this.props.name}>
        <div className={this.e('content-wrapper')}>
          <div className={this.e('content')}>
            <h2>{this.props.title}</h2>
            <div className={this.e('items')}>
              <div className={this.e('stacked')}>{slides}</div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

FeaturedSolutions.propTypes = FeaturedSolutionsModel.propTypes()
FeaturedSolutions.defaultProps = FeaturedSolutionsModel.defaultProps()
