import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Card from 'app/components/Card/Card'
import Link from 'app/components/Link/Link'
import { icons } from 'app/utils/constants'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import { getLongDistanceRates } from 'app/actions/shared'
import Icon, { IconModel } from 'app/components/Icon/Icon'
import LongDistanceRatesCalculator from 'app/utils/ldr-helper'
import Collapsable from 'app/components/Collapsable/Collapsable'
import Rate from './Rate'
import PdfIcon from '../ProductPlanCardCarousel/PdfDownload.svg'

import './LongDistanceRates.scss'

export class LongDistanceRatesModel extends Model {
  constructor(d) {
    super(d)
    this.ratesPdf = d.ratesPdf ? new LinkModel(d.ratesPdf) : undefined
  }

  static props() {
    return {
      name: { type: String, required: true },
      icon: { type: IconModel.shape, required: true },
      title: { type: String, required: false },
      description: { type: String, required: false },
      placeholder: { type: String, required: false },
      ctaButtonName: { type: String, required: true },
      ratesFile: Object,
      errorNoCountryFound: { type: String, required: false },
      landlineRateTitle: { type: String, required: false },
      mobileRateTitle: { type: String, required: false },
      dialInstructions: { type: String, required: false },
      ratesPdf: LinkModel.shape,
      background: { type: String, default: 'transparent' }
    }
  }
}

export function LongDistanceRates({
  background,
  className,
  ratesFile,
  getLongDistanceRates,
  name,
  title,
  description,
  placeholder,
  icon,
  ctaButtonName,
  ratesPdf,
  dialInstructions,
  landlineRateTitle,
  mobileRateTitle,
  errorNoCountryFound
}) {
  const b = Bem('long-distance-rates')
  const [ldrCalc, setLdrCalc] = useState(null)
  const [country, setCountry] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [rate, setRate] = useState(null)
  const [notFound, setNotFound] = useState(false)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    const url = ratesFile ? ratesFile.file.url : ''
    getLongDistanceRates(url, {
      success: data => {
        const ldrCalc = new LongDistanceRatesCalculator(data)
        setLdrCalc(ldrCalc)
      },
      failure: data => {
        console.log('Failed to load data from : ', url, data)
      }
    })
  }, [])

  useEffect(() => {
    if (!focused && country.length > 2) {
      setTimeout(() => {
        onGetRatesClick()
      }, 100)
    }
  }, [country, focused])

  const onInputChange = e => {
    setCountry(e.target.value)
    setFocused(true)
  }
  const onInputFocus = () => {
    setFocused(true)
  }

  const onInputBlur = () => {
    setFocused(false)
  }

  const getSuggestions = value => {
    const lv = (value && value.toLowerCase()) || ''
    const list = ldrCalc.getCountries().filter(c => {
      return value.length > 2 && c.value.toLowerCase().includes(lv)
    })
    return list
  }

  const onGetRatesClick = e => {
    if (e) {
      e.preventDefault()
    }
    if (country) {
      const rate = ldrCalc.findRateByCountry(country)
      setRate(rate)
      setNotFound(rate === null)
    } else {
      setRate(null)
      setNotFound(false)
    }
  }

  const onSuggestionsFetchRequested = value => {
    setSuggestions(getSuggestions(value.value))
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const onSuggestionSelected = (event, { suggestionValue }) => {
    setCountry(suggestionValue)
    setFocused(false)
  }

  const getSuggestionValue = suggestion => {
    return suggestion.value
  }

  const renderSuggestion = suggestion => {
    return <div key={suggestion.value}>{suggestion.value}</div>
  }

  const link = new LinkModel({ href: '#', label: ctaButtonName })

  const button = {
    ...link.asButton,
    design: 'scotch-primary',
    theme: 'light',
    analyticsEvent: link.determineAnalyticsEvent(),
    analyticsValue: `${slug(name)}|${slug(link.text)}`
  }

  const iconModel = new IconModel(icon)
  const searchIcon = new IconModel({ type: icons.search })

  const inputProps = {
    placeholder,
    value: country,
    onChange: onInputChange,
    onFocus: onInputFocus,
    onBlur: onInputBlur,
    className: b.e('search-input').classes()
  }

  return (
    <div
      className={b
        .m({ [background]: true })
        .and(className)
        .classes()}
    >
      <Card className={b.e('rates').classes()} icon={iconModel}>
        <h3 className={b.e('title').classes()}>
          <RichText markup={title} />
        </h3>
        {description && (
          <div className={b.e('description').classes()}>
            <RichText markup={description} />
          </div>
        )}

        <div className={b.e('search-container').classes()}>
          <div className={b.e('search-input-container').classes()}>
            <span
              className={b
                .e('search-input-icon', focused ? 'focused' : '')
                .classes()}
            >
              <Icon {...searchIcon} />
            </span>
            <Autosuggest
              highlightFirstSuggestion
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionSelected={onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
            />
          </div>
          <div className={b.e('search-button').classes()}>
            <Button
              className={b.e('button').classes()}
              {...button}
              onClick={onGetRatesClick}
            />
          </div>
        </div>
        <Collapsable
          className={b.e('result-block').classes}
          isCollapsed={rate !== null}
        >
          {notFound === false && rate && (
            <Rate
              rateData={rate}
              dialInstructions={dialInstructions}
              landlineRateTitle={landlineRateTitle}
              mobileRateTitle={mobileRateTitle}
            />
          )}
        </Collapsable>

        <Collapsable
          className={b.e('result-block').classes()}
          isCollapsed={notFound === true}
        >
          {!rate && notFound && (
            <div className={b.e('rate-error').classes()}>
              <RichText markup={errorNoCountryFound} />
            </div>
          )}
        </Collapsable>
        {ratesPdf && (
          <Link
            className={b.e('rates-pdf').classes()}
            link={ratesPdf}
            useChildren
            analyticsValue={`${slug(name)}|${slug(ratesPdf.text)}`}
          >
            <PdfIcon width={34} height={34} />
            <span className={b.e('rates-pdf-text').classes()}>
              {ratesPdf.text}
            </span>
          </Link>
        )}
      </Card>
    </div>
  )
}

const mapStateToProps = ({ ldr }) => ({
  ldr
})

export default connect(mapStateToProps, { getLongDistanceRates })(
  LongDistanceRates
)
