import React from 'react'

import Bem from 'react-bem-helper'
import { format } from 'date-fns'

import Model from 'app/models/Model'
import RichText from 'app/components/RichText/RichText'

import './LiveUpdate.scss'

export class LiveUpdateModel extends Model {
  static props() {
    return {}
  }
}

export default function LiveUpdate({ date, tz, description }) {
  const classes = new Bem({
    name: 'outage-live-update',
    prefix: ''
  })
  const time = format(new Date(date), 'hh:mm a')
  const dt = format(new Date(date), 'yyyy/MM/dd')

  return (
    <div {...classes()}>
      <div {...classes({ element: 'date' })}>{dt}</div>
      <div {...classes({ element: 'time' })}>
        {time} {tz}
      </div>
      <div {...classes({ element: 'description' })}>
        <RichText
          markup={description}
          isMarkdown
          renderers={{
            root: 'p',
            paragraph: 'span'
          }}
        />
      </div>
    </div>
  )
}
