import React from 'react'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import Eyebrow from 'app/components/Eyebrow/Eyebrow'
import RichText from 'app/components/RichText/RichText'
import Icon, { IconModel } from 'app/components/Icon/Icon'

import './ButtonAndLink.scss'

export class ButtonAndLinkModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      icon: { type: IconModel.shape, default: null, required: false },
      eyebrow: { type: String, required: false },
      title: { type: String, required: false },
      titleVariant: { type: String, required: false },
      description: { type: String, required: false },
      cta: { type: LinkModel.shape, required: false },
      link: { type: LinkModel.shape, required: false },
      noPadding: { type: Boolean, required: false, default: false },
      background: { type: String, required: false, default: 'white' },
      layout: { type: String, required: false, default: 'default' },
      theme: { type: String },
      bottomMargin: { type: Boolean, default: false }
    }
  }
}

// Module definition
export default class ButtonAndLink extends Component {
  constructor(props) {
    super(props, 'button-and-link')
    this.state = {
      currentPage: ''
    }
  }

  componentDidMount() {
    const page =
      typeof window !== 'undefined' && location.pathname !== '/'
        ? `${slug(location.pathname)}|`
        : 'homepage|'
    setTimeout(() => {
      this.setState({
        currentPage: page
      })
    }, 100)
  }

  m() {
    return {
      [this.props.layout]: true,
      [this.props.background]: true,
      'no-padding': this.props.noPadding,
      'bottom-margin': this.props.bottomMargin
    }
  }

  render() {
    const { currentPage } = this.state
    const { name, eyebrow, title, titleVariant, description, cta, link, icon } =
      this.props
    let ctaDef

    if (cta) {
      const ctaLink1 = new LinkModel(cta)
      ctaDef = {
        ...ctaLink1.asButton,
        type: 'link',
        analyticsEvent: 'navigationAction',
        analyticsValue: `${currentPage}${slug(eyebrow || title || name)}|${slug(
          cta.label
        )}`
      }
    }
    let linkDef = {}
    if (link) {
      const ctaLink2 = new LinkModel(link)
      linkDef = {
        ...ctaLink2.asButton,
        icon: ctaLink2.icon,
        type: 'link',
        design: 'link',
        analyticsEvent: 'navigationAction',
        analyticsValue: `${currentPage}${slug(eyebrow || title || name)}|${slug(
          link.label
        )}`
      }
    }

    const titleVar = titleVariant
      ? `${this.e('title')} ${this.e('title')}--${titleVariant}`
      : this.e('title')
    return (
      <section className={this.b()} aria-label={title}>
        {icon && (
          <div className={this.e('icon')}>
            <Icon {...icon} />
          </div>
        )}
        {eyebrow && <Eyebrow text={eyebrow} />}
        {title && (
          <div className={titleVar}>
            <RichText markup={title} renderers={{ root: 'h2' }} />
          </div>
        )}
        <div className={this.e('wrapper')}>
          <div className={this.e('container')}>
            {description && (
              <RichText
                className={this.e('description')}
                markup={description}
              />
            )}
            <div className={this.e('links')}>
              {cta && <Button key={uuid()} {...ctaDef} />}
              {link && <Button key={uuid()} {...linkDef} />}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

ButtonAndLink.props(ButtonAndLinkModel)
