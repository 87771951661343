const splitLine = (line, delim) => {
  if (line == null || line.length === 0) return []
  var arr = []
  var s = 0
  var b = false
  for (var i = 0, l = line.length; i < l; ++i) {
    if (line[i] === '"') {
      b = !b
      continue
    }

    if (line[i] === delim && b === false) {
      var nl = line.substr(s, i - s)
      if (nl[0] === '"' && nl[nl.length - 1] === '"') {
        nl = nl.substr(1, nl.length - 2)
      }
      arr.push(nl)
      s = i + 1
    }
  }

  return arr
}

const getCountries = data => {
  let set = new Set()

  data.forEach(country => {
    const data = {
      value: country.value,
      countryCode: country.countryCode,
      regions: country.regions
    }
    return !set.has(JSON.stringify(data)) && set.add(JSON.stringify(data))
  })

  set = new Set([...set].map(o => JSON.parse(o)))

  return Array.from(set)
}

const processCSV = csvdata => {
  const eol = '\r\n'
  const lines = csvdata.split(eol)
  const result = []

  for (let l = 1, ll = lines.length; l < ll; l++) {
    let isNew = false
    const cols = splitLine(lines[l], ',')
    if (cols.length < 12) continue
    const isMobile = cols[5] === 'Y'
    let obj = result.find(i => i.countryCode === cols[1])

    if (!obj) {
      isNew = true
      obj = {
        countryCode: cols[1],
        dialingCode: cols[9],
        dialingPrefix: cols[8],
        landlineExclusions: '',
        mobileExclusions: '',
        landLineRates: [],
        mobileRates: [],
        regions: [],
        value: cols[4] ? `${cols[0]} (${cols[4]})` : cols[0],
        name: cols[4] ? cols[4] : '',
        regionCode: cols[3] || '',
        instructions: cols[10]
      }
    }

    if (isMobile) {
      obj.mobileExclusions = cols[11]
    } else {
      obj.landlineExclusions = cols[11]
    }

    const price = cols[6]

    if (isMobile) {
      obj.mobileRates.push(price)
    } else {
      obj.landLineRates.push(price)
    }

    if (price && price !== '' && isNew === true) {
      result.push(obj)
    }
  }
  return result
}

class LongDistanceRatesCalculator {
  constructor(data) {
    this.rates = processCSV(data)
    this.countries = getCountries(this.rates)
  }

  findCountryCode(country) {
    const search = country ? country.toLowerCase() : ''
    if (!search) {
      return ''
    }

    const codes = this.countries.filter(c => {
      return c.value.toLowerCase() === search
    })

    return codes.length > 0 ? codes[0].countryCode : ''
  }

  findRateByCountry(country) {
    const code = this.findCountryCode(country)
    return this.findRateByCode(code)
  }

  findRateByCode(code) {
    if (!code) {
      return null
    }
    const rate = this.rates.filter(c => {
      return c.countryCode === code
    })[0]

    return rate
      ? {
          countryCode: rate.countryCode,
          country: rate.value,
          landLineRate: rate.landLineRates[0],
          mobileRate: rate.mobileRates[0],
          dialingPrefix: rate.dialingPrefix,
          dialingCode: rate.dialingCode,
          landlineExclusions: rate.landlineExclusions,
          mobileExclusions: rate.mobileExclusions,
          instructions: rate.instructions
        }
      : null
  }

  getCountries() {
    return this.countries
  }
}

export default LongDistanceRatesCalculator
