import { SET_TOUCH_FLAG } from 'app/actions/shared'
import { default as createReducer } from 'app/utils/create-reducer'

export const initialState = {
  isTouch: false
}

export default createReducer(
  {
    [SET_TOUCH_FLAG]: state => ({
      ...state,
      isTouch: true
    })
  },
  initialState
)
