const convertStrTimeToMinutes = str => {
  const arr = str.split(':')
  if (arr.length !== 2) {
    return 0
  }
  return +arr[0] * 60 + +arr[1]
}

const convertDateTimeToMinutes = dt => {
  return dt.getHours() * 60 + dt.getMinutes()
}

const dateTimeInUTC = offset => {
  // create Date object for current location
  const d = new Date()

  // convert to msec
  // add local time zone offset
  // get UTC time in msec
  const utc = d.getTime() + d.getTimezoneOffset() * 60000

  // create new Date object for different city
  // using supplied offset
  const nd = new Date(utc + 3600000 * offset)

  // return time as a string
  return nd
}

const isItBusinessTime = (strStartTime, strEndTime, utcOffset) => {
  if (
    strStartTime === null ||
    strStartTime === '' ||
    strEndTime === null ||
    strEndTime === ''
  ) {
    return true
  }

  const ct = dateTimeInUTC(utcOffset)
  const day = ct.getDay()
  const ctMinutes = convertDateTimeToMinutes(ct)
  const startMinutes = convertStrTimeToMinutes(strStartTime)
  const endMinutes = convertStrTimeToMinutes(strEndTime)

  if (day < 1 || day > 5) {
    return false
  }

  if (ctMinutes > startMinutes && ctMinutes < endMinutes) {
    return true
  }

  return false
}

export default {
  convertStrTimeToMinutes,
  convertDateTimeToMinutes,
  dateTimeInUTC,
  isItBusinessTime
}
