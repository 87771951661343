import axios from 'axios'

export const SEARCHING = 'SEARCHING'
export const SEARCH_RESULTS = 'SEARCH_RESULTS'

export const setSearching = () => ({ type: SEARCHING })
export const setSearchResults = data => ({ type: SEARCH_RESULTS, data })

export const doSearch = (
  keyword,
  page,
  pagesize,
  cbs = { success: () => {}, failure: () => {} }
) => {
  return dispatch => {
    dispatch(setSearching())
    axios
      .get('/do-search', {
        params: {
          keyword,
          page,
          pagesize
        }
      })
      .then(res => {
        dispatch(setSearchResults(res.data))
        if (cbs.success) {
          cbs.success(res.data)
        }
      })
      .catch(err => {
        dispatch(setSearchResults(err.response.data))
        if (cbs.failure) {
          cbs.failure(err.response.data)
        }
      })
  }
}
