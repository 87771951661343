import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'

// Model definition
export default class ContactsBlockModel extends Model {
  static props() {
    return {
      name: { type: String, default: 'Contacts Block' },
      title: { type: String, default: 'Small And Medium Business' },
      contacts: { type: LinkModel.arrayOf, default: [] },
      image: { type: ResponsiveImageModel.shape, default: null }
    }
  }
}
