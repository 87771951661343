// Vendor requirements
import get from 'lodash/get'
import isString from 'lodash/isString'

// Application requirements
import { errors } from './constants'

// Class definition
export default class FormMessages {
  constructor(messages) {
    this.messages = messages || {}
    this.fields = {}
  }

  raw(rule, field) {
    return (
      get(this, `fields.${field}.${rule}`) ||
      this.messages[rule] ||
      errors[rule] ||
      'error'
    )
  }

  field(field) {
    const messages = (this.fields[field] = this.fields[field] || {})

    return messages
  }

  get(rule, field, input) {
    if (!isString(field)) {
      input = field
      field = undefined
    }

    const message = this.raw(rule, field)

    return this.replace(message, input)
  }

  add(field, rule, message) {
    let messages = this.messages

    if (message) {
      messages = this.field(field)
    } else {
      message = rule
      rule = field
    }

    messages[rule] = message
  }

  replace(message, input = {}) {
    for (const key in input) {
      message = message.replace(new RegExp(':' + key), input[key])
    }

    return message
  }
}
