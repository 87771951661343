import React from 'react'

import PropTypes from 'prop-types'

import Bem from 'app/utils/bem-helper'
import {
  ResponsiveImageModel,
  formatImage
} from 'app/components/ResponsiveImage/ResponsiveImage'
import FullBleedBackground from 'app/components/FullBleedBackground/FullBleedBackground'
import ProductAddOnCard, {
  ProductAddOnCardModel
} from 'app/components/ProductAddOnCard/ProductAddOnCard'

import './FullBleedImageCard.scss'

// Module model definition
export class FullBleedImageCardModel {
  constructor(d) {
    // TODO: This converts a regular image into a responsive image for now until all content is fixed
    this.image = d.responsiveImage || { large: d.image }
    this.productAddOnCard = new ProductAddOnCardModel(
      d.productAddOnCard,
      d.name
    )
    this.background = d.background
  }

  static propTypes() {
    return {
      image: ResponsiveImageModel.shape.isRequired,
      productAddOnCard: PropTypes.shape(ProductAddOnCardModel.propTypes())
        .isRequired,
      background: PropTypes.string
    }
  }

  static defaultProps() {
    return {
      image: ResponsiveImageModel.defaultProps(),
      productAddOnCard: ProductAddOnCardModel.defaultProps(),
      background: 'transparent'
    }
  }
}

export default function FullBleedImageCard(
  props = FullBleedImageCardModel.defaultProps()
) {
  const b = Bem('full-bleed-image-card')
  const classes = props.className
    ? `${props.className} full-bleed-image-card--${props.background}`
    : `full-bleed-image-card--${props.background}`
  const respImage = new ResponsiveImageModel(props.image)
  return (
    <div className={b.and(classes).classes()}>
      <FullBleedBackground
        className={b.e('full-bleed-background').classes()}
        image={{
          ...respImage,
          large: {
            ...respImage?.large,
            src: formatImage(respImage?.large?.src, 'large')
          },
          medium: {
            ...respImage?.medium,
            src: formatImage(respImage?.medium?.src, 'medium')
          },
          small: {
            ...respImage?.small,
            src: formatImage(respImage?.small?.src, 'small')
          }
        }}
      />
      <ProductAddOnCard
        className={b.e('product-add-on-card').classes()}
        {...props.productAddOnCard}
      />
    </div>
  )
}

FullBleedImageCard.propTypes = FullBleedImageCardModel.propTypes()
