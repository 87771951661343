import { ErrorMessage, Field, useFormikContext } from 'formik'

import Bem from 'app/utils/bem-helper'
import React from 'react'

export default function BusinessType({
  fields,
  onChange,
  setFieldValue,
  ...props
}) {
  const b = Bem('contact-form')
  const context = useFormikContext()
  const values = props.values || context.values

  return (
    <>
      {fields.map(field => {
        return field.excludeFrom.includes(values.supportType) ? null : (
          <label
            key={field.id}
            className={b.e('radio-label').classes()}
            htmlFor={field.id}
          >
            <Field
              type="radio"
              name="businessType"
              value={field.value}
              id={field.id}
              onChange={e => {
                onChange(e)
                setFieldValue('requestType', '')
              }}
            />
            <span>{field.label}</span>
            <small>{field.description}</small>
          </label>
        )
      })}

      <ErrorMessage
        name="businessType"
        component="div"
        className={b.e('error').classes()}
      />
    </>
  )
}
