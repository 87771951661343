import React from 'react'

import { v1 as uuid } from 'uuid'
import { connect } from 'react-redux'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Link from 'app/components/Link/Link'
import { icons } from 'app/utils/constants'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Arrow from 'app/components/Carousel/Arrow'
import Carousel from 'app/components/Carousel/Carousel'
import RichText from 'app/components/RichText/RichText'
import withAnchor from 'app/containers/withAnchor/withAnchor'
import Notification, {
  NotificationModel
} from 'app/components/Notification/Notification'
import ProductPlanCard, {
  ProductPlanCardModel
} from 'app/components/ProductPlanCard/NewProductPlanCard'

import PdfIcon from '../ProductPlanCardCarousel/PdfDownload.svg'

import './ProductPlanCardCarousel.scss'
export class ProductPlanCardCarouselModel extends Model {
  constructor(d) {
    super(d)
    this.cardGroups = []
    const mapPlanCards = card => new ProductPlanCardModel(card, d.name)
    this.cta = d.cta ? new LinkModel(d.cta) : undefined
    this.productPlanCards =
      Array.isArray(d.productPlanCards) && d.productPlanCards.length
        ? d.productPlanCards.map(mapPlanCards)
        : []
  }

  static props() {
    return {
      name: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      description: String,
      productPlanCards: ProductPlanCardModel.arrayOf.isRequired,
      cta: LinkModel.shape,
      moduleCode: String,
      cardsHeight: { type: String, required: true, default: 815 },
      background: { type: String, default: 'white' },
      notification: NotificationModel.shape
    }
  }
}

const sizeMap = {
  small: 430,
  medium: 768,
  large: 1024
}

export class ProductPlanCardCarousel extends Component {
  constructor(props) {
    super(props, 'product-plan-card-carousel')
    this.groupSizes = {
      small: 1,
      medium: 2,
      large: 3
    }

    this.state = {
      currentIndex: 0,
      groups: 1,
      brakepoint: null,
      slidesToShow: 3
    }

    this.carousel = React.createRef()
  }

  componentDidMount() {
    if (global && global.removeEventListener) {
      this.setState({
        groups: this.cardGroups.length
      })
      window.addEventListener('resize', this.handleBreakpointChange)
      window.dispatchEvent(new Event('resize'))
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleBreakpointChange)
  }

  static getDerivedStateFromProps(nextProps) {
    return { groups: nextProps.productPlanCards.length }
  }

  handleBreakpointChange = ({ target }) => {
    const width = target.innerWidth
    if (width > sizeMap.medium) {
      this.setState({ breakpoint: 'large', slidesToShow: 3 })
    } else if (width > sizeMap.small) {
      this.setState({ breakpoint: 'medium', slidesToShow: 2 })
    } else {
      this.setState({ breakpoint: 'small', slidesToShow: 1 })
    }
  }

  sortProductPlansToGroups = (acc, next, i) => {
    const newAcc = [...acc]
    const arraySize = this.groupSizes[this.state.breakpoint || 'large']

    // Start a new array within our array if the last is equal to groupSize
    if (i === 0 || newAcc[newAcc.length - 1].length >= arraySize) {
      newAcc.push([next])
      // Otherwise, push this item into the current array
    } else {
      newAcc[newAcc.length - 1].push(next)
    }

    return newAcc
  }

  createRenderProductPlanCard = (card, i) => {
    const untabbable = i !== this.state.currentIndex

    return (
      <ProductPlanCard
        key={uuid()}
        {...card}
        height={parseInt(this.props.cardsHeight, 10)}
        parentName={this.props.name}
        untabbable={untabbable}
      />
    )
  }

  renderCarousel = () => {
    this.cardGroups = this.props.productPlanCards.reduce(
      this.sortProductPlansToGroups,
      []
    )
    const collection = this.cardGroups.reduce((acc, arr) => {
      arr.forEach(item => {
        acc.push(item)
      })
      return acc
    }, [])

    const carouselOptions = {
      options: {
        nextArrow: (
          <Arrow
            name="Next"
            icons={icons}
            onClick={() => console.log('next')}
          />
        ),
        prevArrow: (
          <Arrow
            name="Previous"
            icons={icons}
            onClick={() => console.log('prev')}
          />
        ),
        accessibility: true,
        autoplay: false,
        adaptiveHeight: true,
        centerMode: false,
        centerPadding: '0px',
        dotsClass: 'carousel__dots',
        draggable: true,
        infinite: false,
        variableWidth: false,
        dots: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        beforeChange: (current, next) => {
          this.setState({ currentIndex: next })
        },
        onSwipe: direction => {
          const button = direction === 'left' ? 'next' : 'previous'
          if (typeof window !== 'undefined' && window.utag) {
            if (direction === 'right' && this.state.currentIndex > 0) {
              window.utag.link({
                ...utag_data,
                event_name: 'navigationAction',
                navigationAction: `content|${slug(
                  window.location.pathname
                )}|product-plan-card-carousel|${button}`
              })
            } else if (
              direction === 'left' &&
              this.state.currentIndex * this.state.slidesToShow <
                collection.length - 1
            ) {
              window.utag.link({
                ...utag_data,
                event_name: 'navigationAction',
                navigationAction: `content|${slug(
                  window.location.pathname
                )}|product-plan-card-carousel|${button}`
              })
            }
          }
        },
        customPaging: i => {
          return (
            <button
              data-event="navigationAction"
              data-value={`content|${slug(
                window.location.pathname
              )}|product-plan-card-carousel|slide|${i + 1}`}
            >
              {i + 1}
            </button>
          )
        },
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: collection.length >= 3 ? 3 : collection.length,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1010,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true
            }
          },
          {
            breakpoint: 740,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true
            }
          }
        ]
      }
    }
    const productPlans = collection.map((plan, i) =>
      this.createRenderProductPlanCard(plan, i)
    )
    return (
      <Carousel
        ref={this.carousel}
        className={this.e('carousel')}
        {...carouselOptions}
      >
        {productPlans}
      </Carousel>
    )
  }

  m() {
    return {
      scotch: true,
      [this.props.background]: true,
      'with-cta': this.props.cta
    }
  }

  render() {
    const { entryId, name, title, description, notification, cta } = this.props

    const pdfButtonStyle = cta
      ? `${this.e('cta')} button--${cta.style || 'secondary'} button--${
          cta.variant || 'default'
        }`
      : ''

    return (
      // name attr is needed here for scroll link gto function
      <div name={entryId}>
        <div name="target" className={this.b()} aria-label={title}>
          <div className={this.e('inner')}>
            <RichText
              className={this.e('title')}
              markup={title}
              // renderers={{ root: 'h3' }}
            />

            {description && (
              <RichText
                className={this.e('description')}
                markup={description}
                // renderers={{ root: 'p' }}
              />
            )}

            {this.renderCarousel()}

            <Notification {...notification} />

            {cta && typeof cta === 'object' && (
              <Link
                className={pdfButtonStyle}
                inherit={false}
                link={cta}
                useChildren
                analyticsValue={`${slug(name)}|${slug(cta.text)}`}
              >
                <span className={this.e('cta-text')}>{cta.text}</span>
                <PdfIcon width={26} height={26} />
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withAnchor(
  connect(state => ({
    browser: state.self.browser
  }))(ProductPlanCardCarousel)
)

ProductPlanCardCarousel.defaultProps = ProductPlanCardCarouselModel.props()
