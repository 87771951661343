import {
  SET_REQUEST_DATA,
  SET_BROWSER_TYPE,
  SET_REQUEST_QUERY
} from 'app/actions/shared'
import { default as createReducer } from 'app/utils/create-reducer'

export default createReducer(
  {
    [SET_REQUEST_DATA]: (state, { data }) => ({
      ...state,
      req: {
        ...state.req,
        ...data
      }
    }),
    [SET_BROWSER_TYPE]: (state, { data }) => {
      return {
        ...state,
        browser: data
      }
    },
    [SET_REQUEST_QUERY]: (state, { data }) => {
      return {
        ...state,
        query: data
      }
    }
  },
  {}
)
