import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'

import Logo from './Logo.svg.js'
import ShawLogo from './ShawLogo.svg.js'

import './ShawPromise.scss'

// Module model definition
export class ShawPromiseModel extends Model {
  static props() {
    return {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      }
    }
  }
}

// Model definition
export default function ShawPromise(props) {
  const { title, description } = props
  const b = Bem('shaw-promise')
  const isRogers = title.includes('${RogersLogo}')
  const logo = isRogers ? '${RogersLogo}' : '${logo}'
  const label = isRogers ? 'Rogers' : 'Shaw'
  const ariaLabel = title.replace(logo, label) //eslint-disable-line

  return (
    <section className={b.c()} aria-label={ariaLabel}>
      <div className={b.e('container').c()}>
        {titleFormatter(title, b)}
        <p className={b.e('description').c()}>{description}</p>
      </div>
    </section>
  )
}

export function titleFormatter(title, b) {
  const isRogers = title.includes('${RogersLogo}')
  const titleArr = isRogers
    ? title.split('${RogersLogo}')
    : title.split('${logo}') //eslint-disable-line
  return (
    <h2 className={b.e('title').c()}>
      <span className={b.e('pre-svg').c()}>{titleArr[0]}</span>
      <div className={b.e('svg-container').c()}>
        {isRogers ? <Logo /> : <ShawLogo />}
      </div>
    </h2>
  )
}

ShawPromise.propTypes = ShawPromiseModel.propTypes()
ShawPromise.defaultProps = ShawPromiseModel.defaultProps()
