import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import '@ungap/url-search-params' // TODO: uninstall for node 12 later

if (typeof window !== 'undefined') {
  if (!window.Element.prototype.matches) {
    window.Element.prototype.matches =
      window.Element.prototype.msMatchesSelector ||
      window.Element.prototype.webkitMatchesSelector
  }

  if (!window.Element.prototype.closest) {
    window.Element.prototype.closest = function (s) {
      var el = this

      do {
        if (el.matches(s)) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el.nodeType === 1)
      return null
    }
  }
}
