import get from 'lodash/get'
import head from 'lodash/head'
import isArray from 'lodash/isArray'
import mapValues from 'lodash/mapValues'

export default class FormErrors {
  constructor() {
    this.clear()
  }

  add(field, message) {
    this.get(field).push(message)
  }

  remove(field) {
    if (field) {
      delete this.errors[field]
    } else {
      this.clear()
    }
  }

  get(field) {
    const errors = (this.errors[field] = this.errors[field] || [])

    return errors
  }

  has(field) {
    return isArray(this.errors[field])
  }

  first(field) {
    return field
      ? head(get(this.errors, field, []))
      : mapValues(this.errors, errors => head(errors))
  }

  all(field) {
    return field ? this.errors[field] : this.errors
  }

  clear() {
    this.errors = {}
  }

  isEmpty() {
    for (const key in this.errors) {
      return false
    }

    return true
  }
}
