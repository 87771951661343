import thunk from 'redux-thunk'

import { applyMiddleware, combineReducers, createStore } from 'redux'

import reducers from 'app/reducers/index'
import { analyticsMiddleware } from 'app/analytics/middleware'

import compose from './compose'

let store

/**
 * Build an array of middleware to be used in the Redux store.
 * @returns {Array} middlewares to use
 */
export function getMiddleware() {
  const middlewares = [thunk, analyticsMiddleware]

  return middlewares
}

/**
 * Initializes the store.
 * @returns {Store} Redux store.
 */
export function initializeStore(initialState = {}) {
  const isServer = typeof window === 'undefined'

  if (isServer) {
    store = initializeStoreServer(initialState, true)
  } else {
    store = createPatchedStore(initialState)
  }

  return store
}

export function initializeStoreServer(initialState = {}, isServer) {
  const rootReducer = combineReducers(reducers)
  const middleware = getMiddleware(false, isServer)

  return createStore(rootReducer, initialState, applyMiddleware(...middleware))
}

export function createPatchedStore(initialState = {}) {
  const rootReducer = combineReducers(reducers)
  const middleware = getMiddleware(false, false)

  const create = compose(
    applyMiddleware(...middleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )(createStore)

  return create(rootReducer, initialState)
}

/**
 * An exportable dispatch function.
 * @param action
 */
export const dispatch = action => store.dispatch(action)
export default store
