import React, { useLayoutEffect } from 'react'

import Bem from 'react-bem-helper'
import { connect } from 'react-redux'

import NoSSR from 'app/utils/NoSsr'

import './ShareButton.scss'

function ShareButton({ route, title }) {
  const classes = new Bem({
    name: 'share-button',
    prefix: ''
  })

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && window.addthis) {
      window.addthis.init()
      if (window.addthis.layers && window.addthis.layers.refresh) {
        window.addthis.layers.refresh()
      }
    }
  })

  return (
    <div {...classes()}>
      <NoSSR>
        <div
          className="addthis_inline_share_toolbox_qylh"
          data-addthis-url={route}
          data-addthis-title={title}
        ></div>
      </NoSSR>
    </div>
  )
}
const mapStateToProps = ({ content, self }) => ({
  route: self.url,
  title: content.title
})

export default connect(mapStateToProps)(ShareButton)
