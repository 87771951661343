import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'

import './RelatedSupportArticles.scss'

// Module model definition
export class RelatedSupportArticlesModel extends Model {
  constructor(d) {
    super(d)
    this.links = (d && d.links ? d.links : []).map(l => {
      return new LinkModel(l)
    })
  }
  static props() {
    return {
      name: String,
      title: String,
      links: LinkModel.arrayOf
    }
  }
}

// Module definition
export default class RelatedSupportArticles extends Component {
  constructor(props) {
    super(props, 'related-articles')
  }

  renderArticles() {
    if (!this.props.links) {
      return null
    }

    const items = this.props.links.map((a, idx) => {
      // console.log('Article: ', a)
      const link = new LinkModel(a)
      const button = {
        ...link.asButton,
        text: a.label,
        type: 'link',
        design: 'link',
        analyticsEvent: 'navigationAction',
        analyticsValue: `${slug(this.props.name)}|${slug(a.label)}`
      }
      return (
        <li key={idx}>
          <Button {...button} />
        </li>
      )
    })
    return (
      <div className={this.e('items')}>
        <ul>{items}</ul>
      </div>
    )
  }

  render() {
    return (
      <section className={this.b()} aria-label={this.props.name}>
        <div className={this.e('container')}>
          <div className={this.e('title')}>
            <h3>
              <RichText markup={this.props.title} />
            </h3>
          </div>
          {this.renderArticles()}
        </div>
      </section>
    )
  }
}

RelatedSupportArticles.propTypes = RelatedSupportArticlesModel.propTypes()
RelatedSupportArticles.defaultProps = RelatedSupportArticlesModel.defaultProps()
