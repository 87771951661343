import React from 'react'

import Model from 'app/models/Model'
import Card from 'app/components/Card/Card'
import Component from 'app/components/Component'
import Checkbox from 'app/components/Checkbox/Checkbox'
import RichText from 'app/components/RichText/RichText'
import FormField from 'app/components/FormField/FormField'
import RadioGroup from 'app/components/RadioGroup/RadioGroup'
import ProductGroup from 'app/components/ProductGroup/ProductGroup'
import CheckboxGroup from 'app/components/CheckboxGroup/CheckboxGroup'
import FormFieldGroup from 'app/components/FormFieldGroup/FormFieldGroup'

import './FormGroup.scss'

const FormGroupMap = {
  Checkbox,
  CheckboxGroup,
  FormField,
  FormFieldGroup,
  RadioGroup,
  ProductGroup
}

// Module model definition
export class FormGroupModel extends Model {
  static props() {
    return {
      form: Object,
      title: {
        type: String,
        required: false
      },
      description: String,
      layout: { type: String, default: 'default' },
      groups: {
        type: Array,
        required: true
      },
      onChange: {
        type: Function,
        default: () => {}
      },
      required: { type: Boolean, default: false },
      requiredError: { type: String, default: '' }
    }
  }
}

// Module definition
export default class FormGroup extends Component {
  constructor(props) {
    super(props, 'form-group')
    this.state = {
      isVisible: true
    }
  }

  setVisibility(flag) {
    this.setState({ isVisible: flag })
  }

  m() {
    return {
      [this.props.layout]: true
    }
  }

  render() {
    const groupClass = this.state.isVisible ? this.b() : `${this.b()} hidden`
    return (
      <div className={groupClass}>
        {this.props.title && (
          <div className={this.e('title')}>{this.props.title}</div>
        )}

        {this.props.description && (
          <RichText
            className={this.e('description')}
            markup={this.props.description}
          />
        )}

        <Card className={this.e('card')}>
          <div className={this.e('content')}>
            {this.props.groups.map(this.renderGroup)}
          </div>
        </Card>
      </div>
    )
  }

  renderGroup(group, index) {
    let thisGroup = { ...group }

    if (group.contentType === 'Checkbox') {
      thisGroup.name = undefined
    }

    const props = {
      className: this.e('group'),
      form: this.props.form,
      key: index,
      onFormChange: this.props.onChange,
      ...thisGroup,
      setVisibility: this.setVisibility
    }
    const Component = FormGroupMap[group.contentType]
    return <Component {...props} />
  }
}

FormGroup.props(FormGroupModel)
