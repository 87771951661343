import React from 'react'

import { connect } from 'react-redux'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Caret from 'app/components/SVG/Caret'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import LinkListModel from 'app/models/LinkList/LinkList'
import Notification from 'app/components/Notification/Notification'
import MasterHero, {
  MasterHeroModel
} from 'app/modules/shared/MasterHero/MasterHero'

import './SupportProductArticles.scss'

// Module model definition
export class SupportProductArticlesModel extends Model {
  constructor(d) {
    super(d)
    this.articlesLists = d.articlesLists.map(al => new LinkListModel(al))
  }
  static props() {
    return {
      name: { type: String, required: true },
      productName: { type: String, required: true },
      title: { type: String, required: true },
      articlesLists: LinkListModel.arrayOf,
      notification: Object
    }
  }
}

// Module definition
class SupportProductArticles extends Component {
  constructor(props) {
    super(props, 'support-product-articles')
  }

  m() {
    return {
      scotch: true
    }
  }

  renderHero() {
    const hero = new MasterHeroModel({
      title: this.props.productName,
      heroType: 'GradientHero',
      name: this.props.name,
      eyebrow: '',
      cta: null,
      subtitle: '',
      heroClassName: 'low-profile'
    })
    return (
      <MasterHero
        {...hero}
        name={hero.name || 'Gradient_Hero'}
        breadcrumbs={this.renderBreadcrumbs()}
        theme="scotch"
      />
    )
  }

  renderBreadcrumbs() {
    return (
      <div className={this.e('breadcrumbs')}>
        <div className={this.e('breadcrumbs-container')}>
          <ul>
            <li>
              <a
                href="/support"
                data-event="navigationAction"
                data-value={`support-products-breadcrumbs|home`}
              >
                Support
              </a>
              <Caret className="caret" width={8} height={8} color="#1F1F1F" />
            </li>
            <li>{this.props.productName}</li>
          </ul>
        </div>
      </div>
    )
  }

  renderTypes() {
    if (!this.props.articlesLists) {
      return null
    }

    return (
      <div className={this.e('articles-types')}>
        {this.props.articlesLists.map((t, idx) => {
          return this.renderType(t, idx)
        })}
      </div>
    )
  }

  renderType(t, idx) {
    const { path } = this.props
    if (!t.links) {
      return null
    }
    const parentNode =
      path && path.startsWith('/')
        ? path.substr(1).toLowerCase().replace(/\//gim, '|')
        : ''
    const items = t.links.map((a, j) => {
      const button = {
        ...a.asButton,
        type: 'link',
        design: 'link',
        analyticsEvent: a.determineAnalyticsEvent(),
        analyticsValue: `${parentNode}|${slug(t.label)}|${slug(a.label)}`
      }
      return (
        <li key={`${idx}-${j}`}>
          <Button {...button} />
        </li>
      )
    })

    return (
      <div key={idx} className={this.e('article-type-container')}>
        <div className={this.e('article-type')}>
          {t.label && <h4>{t.label}</h4>}
          <ul>{items}</ul>
        </div>
      </div>
    )
  }

  render() {
    return (
      <section className={this.b()} aria-label={this.props.name}>
        {this.renderHero()}
        <div className={this.e('content-wrapper')}>
          <div className={this.e('content')}>
            <div className={this.e('title')}>
              <h3>
                <RichText markup={this.props.title} />
              </h3>
            </div>
            {this.props.notification && (
              <Notification {...this.props.notification} />
            )}
            {this.renderTypes()}
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  path: state.self.path
})

SupportProductArticles.props(SupportProductArticlesModel)

export default connect(mapStateToProps)(SupportProductArticles)
