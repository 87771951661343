import React, { useState } from 'react'
import { ErrorMessage, Field } from 'formik'
import MaskedInput from 'react-text-mask'
import DatePicker from 'react-date-picker/dist/entry.nostyle'
import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'
import { masks } from '../../ContactForm/logic'

export default function Building({
  title,
  description,
  required,
  onBlur,
  onChange,
  values,
  setFieldValue,
  showFields
}) {
  const [inFocus, setInFocus] = useState('')
  const [filled, setFilled] = useState([])

  function handleFocus({ target }) {
    setInFocus(target.name)
  }
  function handleBlur(e) {
    let newFilled

    if (e.target.value) {
      newFilled = [...filled, e.target.name]
    } else {
      newFilled = filled.filter(f => f !== e.target.name)
    }
    setFilled(newFilled)
    setInFocus('')
    onBlur(e)
  }

  const b = Bem('mdu-form')
  return (
    <div className={b.e('section').classes()}>
      <h2 className={b.e('section-title').classes()}>{title}</h2>
      <div className={b.e('first-section').classes()}>
        <RichText
          className={b.e('description').classes()}
          isMarkdown
          markup={description}
        />
        <p className={b.e('required').classes()}>{required}</p>
      </div>
      <div
        className={b
          .e('section-container')
          .m({
            'desktop-1': true,
            'mobile-1': true
          })
          .classes()}
      >
        <div className={b.e('flexbox').classes()}>
          <p className={b.e('section-container-question').classes()}>
            Is this a new development?
          </p>
          <label
            className={b.e('radio-label').m({ single: true }).classes()}
            htmlFor="no"
          >
            <Field type="radio" name="isNewDevelopment" value="No" id="no" />
            <span>No</span>
          </label>
          <label
            className={b.e('radio-label').m({ single: true }).classes()}
            htmlFor="yes"
          >
            <Field type="radio" name="isNewDevelopment" value="Yes" id="yes" />
            <span>Yes</span>
          </label>
        </div>
        <ErrorMessage
          component="div"
          className={b.e('error').m({ wide: true }).classes()}
          name="isNewDevelopment"
        />
      </div>
      <div
        className={b
          .e('section-container')
          .m({
            'mobile-1': true
          })
          .classes()}
      >
        <h4 className={b.e('section-container-title').classes()}>
          Building details
        </h4>

        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'buildingAddress',
              filled: !!values.buildingAddress
            })
            .classes()}
          htmlFor="buildingAddress"
        >
          <Field
            type="text"
            name="buildingAddress"
            id="buildingAddress"
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={b.e('input').classes()}
          />
          <span>Building address*</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="buildingAddress"
          />
        </label>
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'city',
              filled: !!values.city
            })
            .classes()}
          htmlFor="city"
        >
          <Field
            type="text"
            name="city"
            id="city"
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={b.e('input').classes()}
          />
          <span>City*</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="city"
          />
        </label>
        <label
          className={b
            .e('select-label')
            .m({
              focus: inFocus === 'province',
              filled: !!values.province
            })
            .classes()}
          htmlFor="province"
        >
          <Field
            component="select"
            name="province"
            id="province"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <option value="">Select province*</option>
            <option value="AB">Alberta</option>
            <option value="BC">British Columbia</option>
            <option value="NL">Newfoundland and Labrador</option>
            <option value="PE">Prince Edward Island</option>
            <option value="NS">Nova Scotia</option>
            <option value="NB">New Brunswick</option>
            <option value="QC">Quebec</option>
            <option value="ON">Ontario</option>
            <option value="MB">Manitoba</option>
            <option value="SK">Saskatchewan</option>
            <option value="YT">Yukon</option>
            <option value="NT">Northwest Territories</option>
            <option value="NU">Nunavut</option>
          </Field>
          <span>Province*</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="province"
          />
        </label>
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'postalCode',
              filled: !!values.postalCode
            })
            .classes()}
          htmlFor="postalCode"
        >
          <MaskedInput
            type="text"
            name="postalCode"
            id="postalCode"
            onFocus={handleFocus}
            onChange={onChange}
            onBlur={handleBlur}
            value={values.postalCode}
            mask={masks.postalCodeMask}
            className={b.e('input').m({ single: true }).classes()}
          />

          <span>Postal code</span>

          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="postalCode"
          />
        </label>
        {showFields && (
          <>
            <label
              className={b
                .e('input-label')
                .m({
                  focus: inFocus === 'numberOfUnits',
                  filled: !!values.numberOfUnits
                })
                .classes()}
              htmlFor="numberOfUnits"
            >
              <Field
                type="text"
                name="numberOfUnits"
                id="numberOfUnits"
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={b.e('input').classes()}
              />
              <span>Number of units</span>
              <ErrorMessage
                component="div"
                className={b.e('error').m({ single: true }).classes()}
                name="numberOfUnits"
              />
            </label>
            <label
              className={b
                .e('select-label')
                .m({
                  focus: inFocus === 'buildingType',
                  filled: !!values.buildingType
                })
                .classes()}
              htmlFor="buildingType"
            >
              <Field
                component="select"
                name="buildingType"
                id="buildingType"
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <option value="">Building type</option>
                <option value="Infill">Infill</option>
                <option value="Townhome">Townhome (duplex/4plex/6plex)</option>
                <option value="MDU Residential">MDU Residential</option>
                <option value="MDU Mixed use">MDU Mixed use</option>
                <option value="Other">Other</option>
              </Field>
              <span>Building type</span>
              <ErrorMessage
                component="div"
                className={b.e('error').m({ single: true }).classes()}
                name="buildingType"
              />
            </label>
            <label
              className={b
                .e('input-label')
                .m({
                  focus: inFocus === 'estimatedOccupancyDate',
                  filled: !!values.estimatedOccupancyDate,
                  date: true
                })
                .classes()}
              htmlFor="estimatedOccupancyDate"
            >
              <Field
                component={() => (
                  <DatePicker
                    format="yyyy-MM-dd"
                    value={
                      values.estimatedOccupancyDate
                        ? new Date(values.estimatedOccupancyDate)
                        : null
                    }
                    onChange={value => {
                      const date = value && value.toDateString()
                      setFieldValue('estimatedOccupancyDate', date)
                    }}
                    minDate={new Date('2022-01-01')}
                  />
                )}
                name="estimatedOccupancyDate"
                id="estimatedOccupancyDate"
                onChange={onChange}
                className={b.e('input').classes()}
              />
              <span>Estimated occupancy date</span>
              <ErrorMessage
                component="div"
                className={b.e('error').m({ single: true }).classes()}
                name="estimatedOccupancyDate"
              />
            </label>
          </>
        )}
      </div>
    </div>
  )
}
