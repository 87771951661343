export default class ExtendableError extends Error {
  constructor(msg) {
    super()
    this.message = msg
    this.stack = new Error().stack
    this.name = this.constructor.name
  }
}

export class NoContentModelFoundError extends ExtendableError {
  constructor(msg) {
    super(msg)
    this.name = 'NoContentModelFoundError'
  }
}

export class NoComponentFoundError extends ExtendableError {
  constructor(msg) {
    super(msg)
    this.name = 'NoComponentFoundError'
  }
}
