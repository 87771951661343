import React from 'react'

import { connect } from 'react-redux'
import { scroller } from 'react-scroll'

import Model from 'app/models/Model'
import throttle from 'lodash/throttle'
import { slug } from 'app/utils/paths'
import Link from 'app/components/Link/Link'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import ChatButton from './ChatButton'
import RichText from 'app/components/RichText/RichText'
import { createRegisterModuleAnchor } from 'app/actions/shared'
import { FullBleedHeroModel } from 'app/modules/ppp/FullBleedHero/FullBleedHero'

import './ProductPlanStickyNav.scss'

// Module model definition
export class ProductPlanStickyNavModel extends Model {
  constructor(d) {
    super(d)
    this.cta = new LinkModel(d.cta)
    this.logoLink = new LinkModel(d.logoLink)
    this.phoneLink = new LinkModel(d.phoneLink)
    this.chatLink = new LinkModel(d.chatLink)
    this.customLink = new LinkModel(d.customLink)
    this.triggerModule = new FullBleedHeroModel(d.triggerModule)
  }

  static props() {
    return {
      productLineName: String,
      ctaLabel: String,
      logoLink: LinkModel.shape,
      phoneLink: LinkModel.shape,
      chatLink: LinkModel.shape,
      customLink: LinkModel.shape,
      triggerModule: FullBleedHeroModel.shape,
      showNav: Boolean,
      scrollTo: String,
      duration: Number,
      name: String
    }
  }
}

export class ProductPlanStickyNav extends Component {
  constructor(props) {
    super(props, 'product-plan-sticky-nav')
    this.scrollTrigger = null
    this.state = {
      show: false
    }
  }

  componentDidMount() {
    this.registerModuleAnchor()

    setTimeout(() => {
      this.scrollTrigger = document.querySelector(
        `[data-entry-id='${this.props.triggerModule.entryId}']`
      )
      this.handleScroll()
      window.addEventListener('scroll', throttle(this.handleScroll, 100))
    }, 0)
  }

  handleScroll() {
    if (this.scrollTrigger) {
      const show = this.scrollTrigger.getBoundingClientRect().bottom < 0
      if (this.state.show !== show) {
        this.setState({ show })
      }
    }
  }

  handleClick(id) {
    const options = {
      duration: 369,
      smooth: true,
      offset: -40
    }
    scroller.scrollTo(id, options)
  }

  registerModuleAnchor() {
    if (this.props.anchor) {
      this.props.registerModuleAnchor(this.props.anchor)
    }
  }

  m() {
    return {
      visible: this.state.show
    }
  }

  render() {
    const {
      productLineName,
      cta,
      logoLink,
      phoneLink,
      chatLink,
      customLink,
      triggerModule,
      name
    } = this.props

    const showNav = this.state.show
    const button = cta.asButton

    if (!triggerModule || !button) {
      return null
    }

    return (
      <nav className={this.b()}>
        <div className={this.e('inner')}>
          <div className={this.e('left')}>
            {logoLink && (
              <Link
                className={this.e('logo')}
                tabIndex={!showNav ? '-1' : '0'}
                link={logoLink}
                analyticsValue={`${slug(name)}|${slug(logoLink.ariaLabel)}`}
              />
            )}
            {productLineName && (
              <RichText
                className={this.e('product-line')}
                markup={productLineName}
                isMarkdown
              />
            )}
          </div>
          <div className={this.e('right')}>
            {customLink && (
              <Link
                className={this.e('custom')}
                tabIndex={!showNav ? '-1' : '0'}
                link={customLink}
                analyticsValue={`${slug(name)}|${slug(customLink.ariaLabel)}`}
              />
            )}
            {phoneLink && (
              <Link
                className={this.e('phone')}
                tabIndex={!showNav ? '-1' : '0'}
                link={phoneLink}
                analyticsValue={`${slug(name)}|${slug(phoneLink.ariaLabel)}`}
              />
            )}
            {chatLink && (
              <ChatButton name={name} showNav={showNav} chatLink={chatLink} />
            )}
            {button && button.anchor && (
              <div className={this.e('cta-container')}>
                <button
                  className={this.e('cta')}
                  tabIndex={!showNav ? '-1' : '0'}
                  data-event="helpfulAction"
                  data-value={`${slug(name)}|${slug(button.text)}`}
                  onClick={() =>
                    this.handleClick(button.anchor.targetModule.entryId)
                  }
                >
                  {button.text}
                </button>
              </div>
            )}
            {button && !button.anchor && button.link && (
              <div className={this.e('cta-container')}>
                <Link
                  className={this.e('cta')}
                  tabIndex={!showNav ? '-1' : '0'}
                  link={cta}
                  analyticsValue={`${slug(name)}|${slug(cta.ariaLabel)}`}
                />
              </div>
            )}
          </div>
        </div>
      </nav>
    )
  }
}

ProductPlanStickyNav.props(ProductPlanStickyNavModel)

const mapDispatchToProps = dispatch => ({
  registerModuleAnchor: anchor => {
    dispatch(createRegisterModuleAnchor(anchor))
  }
})

export default connect(null, mapDispatchToProps)(ProductPlanStickyNav)
