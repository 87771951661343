import React from 'react'

import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Component from 'app/components/Component'
import RichText from 'app/components/RichText/RichText'
import BasicIconBox, {
  BasicIconBoxModel
} from 'app/components/BasicIconBox/BasicIconBox'

import './BasicIconBoxes.scss'

// Module model definition
export class BasicIconBoxesModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      title: String,
      description: String,
      basicIconBoxes: PropTypes.arrayOf(
        PropTypes.shape(BasicIconBoxModel.propTypes())
      )
    }
  }
}

// Module definition
export default class BasicIconBoxes extends Component {
  constructor(props) {
    super(props, 'basic-icon-boxes')
  }

  render() {
    return (
      <div className={this.b()} id={`${slug(this.props.name)}`}>
        <div className={this.e('intro')}>
          <div className={this.e('container')}>
            <div className={this.e('title')}>{this.props.title}</div>
            {this.props.description && (
              <div className={this.e('description')}>
                <RichText markup={this.props.description} />
              </div>
            )}
          </div>
        </div>
        {this.renderBasicIconBoxes()}
      </div>
    )
  }

  renderBasicIconBoxes() {
    const basicIconBoxes = this.props.basicIconBoxes

    if (!basicIconBoxes) {
      return null
    }

    const boxes = basicIconBoxes.map((basicIconBox, i) => (
      <BasicIconBox
        key={`bib-${i}`}
        className={this.e('box')}
        {...basicIconBox}
        buttonStyle="secondary"
      />
    ))

    return (
      <div className={this.e('boxes')}>
        <div className={this.e('container')}>{boxes}</div>
      </div>
    )
  }
}

BasicIconBoxes.props(BasicIconBoxesModel)
