import React from 'react'

import { throttle } from 'lodash'
import { connect } from 'react-redux'

import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Sticky from 'app/components/Sticky/Sticky'
import RichText from 'app/components/RichText/RichText'

import './StickyNav.scss'

// Module model definition
export class StickyNavModel extends Model {
  static props() {
    return {
      name: String,
      items: LinkModel.arrayOf,
      stylingClassName: String
    }
  }
}

class StickyNav extends Component {
  constructor(props) {
    super(props, 'sticky-nav')
    this.state = {
      itemsPos: [],
      activeIdx: -1
    }

    this.onScroll = this.onScroll.bind(this)
    this.onResize = this.onResize.bind(this)

    this.throttledResize = throttle(this.onResize, 200)
    this.throttledScroll = throttle(this.onScroll, 200)
  }

  findPosY(node) {
    if (!node) {
      return 0
    }
    let curtop = 0
    if (node.offsetParent) {
      do {
        curtop += node.offsetTop
        node = node.offsetParent
      } while (node !== null)
    }
    return curtop
  }

  setItemsPositions() {
    const arr = []
    this.props.items &&
      this.props.items.map((l, idx) => {
        if (!l.href.startsWith('#')) {
          return
        }

        arr.push({
          link: l,
          idx,
          posY: this.findPosY(document.getElementById(l.href.substr(1)))
        })
      })

    const idx = this.props.items.findIndex(p => {
      if (p.href.startsWith('#')) {
        return
      }
      return p.href === this.props.request.path
    })

    this.setState({
      itemsPos: arr,
      activeIdx: idx
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.throttledScroll)
    window.addEventListener('resize', this.throttledResize)
    this.setItemsPositions()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledScroll)
    window.removeEventListener('resize', this.throttledResize)
  }

  onResize() {
    this.setItemsPositions()
  }

  onScroll() {
    const l = this.state.itemsPos.length
    if (l === 0) {
      return
    }

    const wy = window.pageYOffset + 200

    for (var i = l - 1; i >= 0; --i) {
      const link = this.state.itemsPos[i]
      if (link.posY > 0 && wy >= link.posY) {
        if (this.state.activeIdx !== link.idx) {
          this.setState({ activeIdx: link.idx })
        }
        return
      }
    }

    this.setState({ activeIdx: -1 })
  }

  onLinkClicked(e, link) {
    if (link.href.startsWith('#')) {
      e.preventDefault()
      Scroller.scrollTo(link.href.substr(1), {
        duration: 300,
        offset: -90,
        smooth: true
      })
    }
  }

  renderStickyNavItems() {
    return this.props.items.map((l, idx) => {
      return (
        <li
          key={idx}
          className={this.e(
            'item',
            idx === this.state.activeIdx ? 'active' : ''
          )}
        >
          <a href={l.href} onClick={e => this.onLinkClicked(e, l, idx)}>
            <RichText markup={l.label} />
          </a>
        </li>
      )
    })
  }

  renderStickyNav() {
    if (!this.props.items) {
      return null
    }

    return (
      <div className={this.b()}>
        <div className={this.e('sticky-nav', this.props.stylingClassName)}>
          <div className={this.e('items')}>
            <ul>{this.renderStickyNavItems()}</ul>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return <Sticky style={{ zIndex: 100 }}>{this.renderStickyNav()}</Sticky>
  }
}

StickyNav.props(StickyNavModel)

export default connect(state => {
  return {
    request: state.self
  }
})(StickyNav)
