import React from 'react'

import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'
import { ItemGroupModel } from 'app/components/ItemGroup/ItemGroup'
import { ProductOfferCardModel } from 'app/components/ProductOfferCard/ProductOfferCard'
import DefaultHero, {
  DefaultHeroModel
} from 'app/components/DefaultHero/DefaultHero'
import GrandHero, { GrandHeroModel } from 'app/modules/ppp/GrandHero/GrandHero'
import SuperHero, { SuperHeroModel } from 'app/modules/ppp/SuperHero/SuperHero'
import OutageHero, {
  OutageHeroModel
} from 'app/modules/support/OutageHero/OutageHero'
import FullBleedHero, {
  FullBleedHeroModel
} from 'app/modules/ppp/FullBleedHero/FullBleedHero'
import GradientHero, {
  GradientHeroModel
} from 'app/components/GradientHero/GradientHero'
import HeroAndCards, {
  HeroAndCardsModel
} from 'app/modules/landing/HeroAndCards/HeroAndCards'
import HeroItemTiles, {
  HeroItemTilesModel
} from 'app/modules/support/HeroItemTiles/HeroItemTiles'
import HeroSupportSearch, {
  HeroSupportSearchModel
} from 'app/modules/support/HeroSupportSearch/HeroSupportSearch'

export class MasterHeroModel extends Model {
  static props() {
    return {
      name: String,
      entryId: String,
      heroType: String,
      contentType: String,
      title: String,
      eyebrow: String,
      subtitle: String,
      description: String,
      layout: String,
      theme: String,
      variant: String,
      alignContent: String,
      cta: LinkModel.shape,
      ctaAlt: LinkModel.shape,
      appStore: LinkModel.shape,
      googlePlay: LinkModel.shape,
      image: ResponsiveImageModel.shape,
      imageAlignment: String,
      imageEllipsis: Boolean,
      background: ResponsiveImageModel.shape,
      backgroundHorizontal: String,
      backgroundVertical: String,
      logo: ResponsiveImageModel.shape,
      videoLink: String,
      videoPoster: String,
      videoAsset: String,
      category: String,
      breadCrumbTitle: String,
      breadcrumbLevel: String,
      withSearch: Boolean,
      supportSearchPage: LinkModel.shape,
      placeholder: String,
      cards: ProductOfferCardModel.arrayOf,
      hidePriceLine: Boolean,
      alternativeLayout: Boolean,
      intro: String,
      width: String,
      notification: Object,
      itemGroup: ItemGroupModel.arrayOf
    }
  }
}

export default function MasterHero({ heroType, ...props }) {
  switch (heroType) {
    case 'DefaultHero':
      const defaultHeroProps = new DefaultHeroModel(props) // eslint-disable-line
      return <DefaultHero {...defaultHeroProps} />
    case 'GrandHero':
      const grandHeroProps = new GrandHeroModel(props) // eslint-disable-line
      return <GrandHero {...grandHeroProps} />
    case 'SuperHero':
      const superHeroProps = new SuperHeroModel(props) // eslint-disable-line
      return <SuperHero {...superHeroProps} />
    case 'FullBleedHero':
      const fullBleedHeroProps = new FullBleedHeroModel(props) // eslint-disable-line
      return <FullBleedHero {...fullBleedHeroProps} />
    case 'GradientHero':
      const gradientHeroProps = new GradientHeroModel(props) // eslint-disable-line
      return <GradientHero {...gradientHeroProps} />
    case 'OutageHero':
      const outageHeroProps = new OutageHeroModel(props) // eslint-disable-line
      return <OutageHero {...outageHeroProps} />
    case 'HeroAndCards':
      const heroAndCardsProps = new HeroAndCardsModel(props) // eslint-disable-line
      return <HeroAndCards {...heroAndCardsProps} />
    case 'HeroWithTiles':
      const heroItemTilesProps = new HeroItemTilesModel(props) // eslint-disable-line
      return <HeroItemTiles {...heroItemTilesProps} />
    case 'HeroSupportSearch':
      const heroSupportSearchProps = new HeroSupportSearchModel(props) // eslint-disable-line
      return <HeroSupportSearch {...heroSupportSearchProps} />
    default:
      return null
  }
}
