// Application requirements
import FormRules from './form-rules'
import FormErrors from './form-errors'
import FormMessages from './form-messages'

// Class definition
export default class Form {
  constructor(fields, messages) {
    this.rules = new FormRules()
    this.errors = new FormErrors()
    this.messages = new FormMessages(messages)
    this.values = {}

    if (fields) {
      this.add(fields)
    }
  }

  // Require a field
  require(field, message) {
    if (message) {
      this.messages.add(field, 'required', message)
    }

    return this.add(field, 'required')
  }

  // Add rules to a field
  add(field, rules) {
    if (typeof field === 'object') {
      const fields = field

      for (field in fields) {
        this.add(field, fields[field])
      }

      return this
    }

    this.rules.add(field, rules)
    this.set(field)

    return this
  }

  // Get a field
  get(field) {
    return this.values[field]
  }

  // Get all values
  getAll() {
    return this.values
  }

  // Set a field value
  set(field, value) {
    this.invalidate(field)

    // If there is a value
    if (typeof value !== 'undefined') {
      this[field] = value
      this.values[field] = value
    }

    return value
  }

  fill(fields = {}) {
    for (const field in fields) {
      this.set(field, fields[field])
    }
  }

  filled(field) {
    if (field) {
      return this.rules.required(field, this.get(field))
    }

    for (field in this.rules.all()) {
      if (!this.filled(field)) {
        return false
      }
    }

    return true
  }

  empty(field) {
    return this.rules.empty(field, this.get(field))
  }

  complete() {
    return this.filled()
  }

  invalidate(field) {
    return this.errors.remove(field)
  }

  validate(field) {
    if (!field) {
      this.rules.each(this.validate.bind(this))

      return this.errors.isEmpty()
    }

    this.invalidate(field)

    const invalid = this.rules.validate(field, this.get(field))

    invalid.map(rule => {
      const message = this.messages.get(rule, field, { field })
      this.errors.add(field, message)
    })

    return !this.errors.has(field)
  }

  silentlyValidate(field) {
    const invalid = this.rules.validate(field, this.get(field))
    return invalid.length <= 0
  }

  silentlyValidateForm() {
    let valid = true

    this.rules.each(field => {
      if (!this.silentlyValidate(field)) {
        valid = false
      }
    })

    return valid
  }

  reset(field, defaultValue) {
    this.set(field, defaultValue)
    return defaultValue
  }
}
