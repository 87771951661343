import { ErrorMessage, Field } from 'formik'

import Bem from 'app/utils/bem-helper'
import React from 'react'

export default function BusinessSize() {
  const b = Bem('contact-form')
  return (
    <>
      <label
        className={b.e('radio-label').m({ single: true }).classes()}
        htmlFor="employees-100"
      >
        <Field
          type="radio"
          name="employeesCount"
          value="100"
          id="employees-100"
        />
        <span>1 - 100</span>
      </label>
      <label
        className={b.e('radio-label').m({ single: true }).classes()}
        htmlFor="employees-101-500"
      >
        <Field
          type="radio"
          name="employeesCount"
          value="500"
          id="employees-101-500"
        />
        <span>101 - 500</span>
      </label>
      <label
        className={b.e('radio-label').m({ single: true }).classes()}
        htmlFor="employees-501-1000"
      >
        <Field
          type="radio"
          name="employeesCount"
          value="1000"
          id="employees-501-1000"
        />
        <span>501 – 1,000</span>
      </label>
      <label
        className={b.e('radio-label').m({ single: true }).classes()}
        htmlFor="employees-1001"
      >
        <Field
          type="radio"
          name="employeesCount"
          value="1001"
          id="employees-1001"
        />
        <span>1,000+</span>
      </label>
      <label
        className={b.e('radio-label').m({ single: true }).classes()}
        htmlFor="notsure"
      >
        <Field type="radio" name="employeesCount" value="0" id="notsure" />
        <span>Not sure</span>
      </label>

      <ErrorMessage
        name="employeesCount"
        component="div"
        className={b.e('error').classes()}
      />
    </>
  )
}
