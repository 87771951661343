import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import ContactCard, {
  ContactCardModel
} from 'app/components/ContactCard/ContactCard'

import './BasicContact.scss'

// Module model definition
export class BasicContactModel extends Model {
  static props() {
    return {
      contactCard: { type: ContactCardModel.shape, required: true },
      background: { type: String, default: 'transparent' }
    }
  }
}

// Module definition
export default function BasicContact(props) {
  const b = Bem('basic-contact')
  return (
    <div
      className={b
        .m({ [props.background]: true })
        .and(props.className)
        .classes()}
    >
      <ContactCard
        className={b.e('contact-card').classes()}
        {...props.contactCard}
      />
    </div>
  )
}

BasicContact.propTypes = BasicContactModel.propTypes()
BasicContact.defaultProps = BasicContactModel.defaultProps()
