import React from 'react'

import Component from 'app/components/Component'

import './GlobalNavToggle.scss'

// Component definition
export default class GlobalNavToggle extends Component {
  constructor(props) {
    super(props, 'global-nav-toggle')
  }

  toggle() {
    this.props.onToggle()
  }

  m() {
    return {
      open: this.props.isActive
    }
  }

  render() {
    return (
      <button
        className={this.b()}
        aria-expanded={this.props.isActive}
        aria-controls={this.props.menuId}
        onClick={this.toggle}
      >
        <span />
        <span />
        <span className={this.e('text')}>Menu</span>
      </button>
    )
  }
}

GlobalNavToggle.props({
  isActive: Boolean,
  menuId: String,
  onToggle: Function
})
