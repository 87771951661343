import Cryptr from 'cryptr'

const cryptr = new Cryptr(process.env.CRYPT_KEY || 'secret')

/*
 * List of fields that should be encrypted on front-end side.
 */
const fieldToEncrypt = ['submitToUrl']

/**
 * Entries beyond a depth will not be included in page data.
 * The object keys define the content type being formatted.
 * The object value is the depth to be included in data.
 */
const formatDepths = {
  Layout: 1,
  supportPage: 1,
  metaTag: 1
}

const maxDepth = 20
export let history = []

const noMatchError = 'Items array is empty, no matching entry found'

const handleErrors = e => {
  if (e.message === noMatchError) {
    console.debug('format-contentful error', e.message)
  } else {
    console.debug('STACK', e)
    console.debug('HISTORY', JSON.stringify(history, null, 2))
  }
}

/**
 * This is the function which kicks off the formatter.  It breaks out Entries and Assets and then walks the fields
 * object
 * @param obj {object} Contentful API return
 * @returns {object} Formatted Contentful API return
 */
export default function formatContentfulEntries(obj, organizeBy) {
  history = []

  try {
    const config = Object.assign({}, obj)
    const items = config.items
    let entries = null
    let assets = null
    if (config.includes) {
      entries = config.includes.Entry.concat(items)
      assets = config.includes.Asset
    }

    if (!items.length) {
      throw new Error(noMatchError)
    }

    return items.reduce((o, c) => {
      const p = Object.assign({}, o)
      if (c.fields && c.fields[organizeBy]) {
        const f = formatToFields(c)
        p[f[organizeBy]] = walkObject(f, entries, assets)
        return p
      }

      return o
    }, {})
  } catch (e) {
    handleErrors(e)
  }
}

export function formatContentfulEntry(obj) {
  history = []
  const config = Object.assign({}, obj)
  const items = config.items
  let entries = null
  let assets = null
  if (config.includes) {
    entries = config.includes.Entry.concat(items)
    assets = config.includes.Asset
  }

  try {
    const item = items[0]

    if (!item) {
      throw new Error(noMatchError)
    }

    if (item.fields) {
      return walkObject(formatToFields(item), entries, assets)
    }

    return {}
  } catch (e) {
    handleErrors(e)
    return {}
  }
}

/**
 * Takes an object of a page's associated fields and returns a formatted object with all fields resolved.
 * This function is used recursively.
 *
 * @param f (object) - A given page's fields.
 * @param entries - config.items.Entries - These are entries used or referenced by the requested pages
 * @param assets - config.items.Assets - These are assets (media) used or referenced by requested pages
 * @param depth (Number)
 * @returns {formatted fields of page}
 */
export function walkObject(f, entries, assets, depth = 0) {
  return Object.keys(f).reduce((o, k) => {
    const p = Object.assign({}, o)
    p[k] = formatReference(f[k], entries, assets, depth)

    if (fieldToEncrypt.indexOf(k) !== -1) {
      p[k] = cryptr.encrypt(p[k])
    }

    return p
  }, {})
}

/**
 * Decides what type of reference we have.  We iterate arrays, walk objects, and match references to the correct
 * Entry or Asset
 * @param r {*} Possible reference
 * @param entries {object} Linked entries
 * @param assets {object} Linked assets
 * @returns {*}
 */
export function formatReference(r, entries, assets, depth) {
  // If you need to debug a circular reference, use uncomment the line below.
  if (!r) {
    return null
  }
  if (r.entryId) {
    // history.push({ depth, type: `WALK FIELDS: ${r.contentType}` })
    return walkObject(r, entries, assets, depth)
  } else if (isAsset(r)) {
    // history.push({ depth, type: `ASSET: ${r.sys.type}` })
    return matchReferenceToAsset(r, assets, true)
  } else if (isReferenceToAsset(r)) {
    // history.push({ depth, type: `LINK ASSET: ${r.sys.linkType}` })
    return matchReferenceToAsset(r, assets)
  } else if (isReferenceToEntry(r)) {
    // history.push({ depth, type: `LINK ENTRY: ${r.sys.id}` })
    return matchReferenceToEntry(r, entries, assets, depth)
  } else if (Array.isArray(r)) {
    // history.push({ depth, type: `WALK ARRAY` })
    return walkReferenceArray(r, entries, assets, depth)
  }

  // history.push({ depth, type: `END TREE: ${r}}` })
  return r
}

/**
 * Maps an array of references to a new array with Assets / Entries in place
 * @param ref {array}
 * @param entries {object}
 * @param assets {object}
 * @returns {*}
 */
export function walkReferenceArray(ref, entries, assets, depth) {
  return ref.map(r => formatReference(r, entries, assets, depth))
}

export function isAsset(r) {
  return (
    typeof r === 'object' &&
    Object.prototype.hasOwnProperty.call(r, 'sys') &&
    r.sys.type === 'Asset'
  )
}

/**
 * When a field does not have its own fields, but is itself still an object, we know that it is a reference.  This
 * function checks a reference to decide if the reference is an Entry.
 * @param r - A potential reference.  Could be any data type.
 * @returns {boolean} - Whether the object is a reference to an Entry.
 */
export function isReferenceToEntry(r) {
  return (
    typeof r === 'object' &&
    Object.prototype.hasOwnProperty.call(r, 'sys') &&
    Object.prototype.hasOwnProperty.call(r.sys, 'id')
  )
}

/**
 * When a field does not have its own fields, but is itself still an object, we know that it is a reference.  This
 * function checks a reference to decide if the reference is an Asset.
 * @param r - A potential reference.  Could be any data type.
 * @returns {boolean} - Whether the object is a reference to an Asset.
 */
export function isReferenceToAsset(r) {
  return (
    typeof r === 'object' &&
    Object.prototype.hasOwnProperty.call(r, 'sys') &&
    r.sys.linkType === 'Asset'
  )
}

/**
 * When a field  has its own fields property, we know that it is a distinct content type.  This function adds a
 * contentType property to the formatted fields object as well as an entryId to be used as an identifier.
 *
 * @param r (content entry)
 * @returns {*} fields object with appended contentType property and entryId property
 */
export function formatToFields(r) {
  try {
    const contentType = r.sys.contentType
    const entryId = r.sys.id
    const revision = r.sys.revision

    return Object.assign(
      {
        contentType: contentType ? contentType.sys.id : r.sys.type,
        entryId,
        revision
      },
      r.fields
    )
  } catch (e) {
    console.log(r, e)
  }
}

/**
 * If a reference is to an entry, we match it to the correct entry
 * @param r {reference}
 * @param entries
 * @param assets
 * @returns {*}
 */
function matchReferenceToEntry(r, entries, assets, depth) {
  if (!entries) {
    entries = []
  }
  const matchingReference = entries.find(include => include.sys.id === r.sys.id)
  if (matchingReference) {
    const contentType = matchingReference.sys.contentType.sys.id
    const formatDepth = formatDepths[contentType]

    if (formatDepth && depth > formatDepth) {
      return null
    }

    if (depth > maxDepth) {
      console.warn(
        'Max depth exceeded in Contentful formatter!  Check /app/utils/format-contentful.js'
      )
      return null
    }

    const newObj = Object.assign({}, matchingReference)

    return formatReference(formatToFields(newObj), entries, assets, depth + 1)
  }

  return null
}

/**
 * If a reference is to an asset, we match it to the correct asset
 * @param r {reference}
 * @param assets
 * @returns {*}
 */
function matchReferenceToAsset(r, assets, isAsset = false) {
  let matchingAsset = assets.find(include => include.sys.id === r.sys.id)

  if (isAsset) {
    matchingAsset = r
  }

  const newObj = Object.assign(
    {},
    matchingAsset != null ? matchingAsset.fields : []
  )
  newObj.contentType = 'Asset'

  return newObj
}
