import React from 'react'

import Bem from 'react-bem-helper'
import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Link from 'app/components/Link/Link'
import LinkModel from 'app/models/Link/Link'
import ImageModel from 'app/models/Image/Image'
import ResponsiveImage from 'app/components/ResponsiveImage/ResponsiveImage'

import './SimpleImage.scss'

export class SimpleImageModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        required: true
      },
      image: {
        type: ImageModel.shape,
        required: true
      },
      background: {
        type: String,
        required: false,
        default: 'transparent'
      },
      link: {
        type: LinkModel.shape,
        required: false,
        default: null
      }
    }
  }
}

export default function SimpleImage({ background, link, image }) {
  const classes = new Bem({
    name: 'simple-image',
    prefix: ''
  })

  if (link) {
    return (
      <div {...classes({ modifier: background })}>
        <Link
          link={link}
          useChildren
          analyticsValue={`simple-image|${slug(link.label)}`}
        >
          <ResponsiveImage
            useRetina
            useImageSizes
            {...image}
            alt={image.name}
          />
        </Link>
      </div>
    )
  }
  return (
    <div {...classes({ modifier: background })}>
      <ResponsiveImage useRetina useImageSizes {...image} alt={image.name} />
    </div>
  )
}

SimpleImage.propTypes = {
  link: PropTypes.shape(),
  image: PropTypes.shape(),
  background: PropTypes.string
}
