import { default as createReducer } from 'app/utils/create-reducer'
import { SET_FORM_FIELD_VALUE } from 'app/actions/form'

export const initialState = {}

export default createReducer(
  {
    [SET_FORM_FIELD_VALUE]: (state, { data }) => {
      const form = state[data.formName] ? state[data.formName] : {}
      const newState = {
        ...state,
        [data.formName]: {
          ...form,
          [data.fieldId]: data.fieldVal
        }
      }
      return newState
    }
  },
  initialState
)
