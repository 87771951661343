/* eslint no-console: 0 */
import { default as aer } from './registry'
import { ANALYTICS_EVENT } from 'app/actions/shared'

/**
 * Logs information about analytics dispatch in DEVELOP ENV
 * @param action
 * @param event
 * @param data
 */
export const analyticsLogger = (action, event) => {
  const d = new Date()
  const formattedTime = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`
  console.groupCollapsed(
    `analytics ${action.name} ---- @F@F ---- ${formattedTime}`
  )
  console.log('%caction: ', 'color: teal; font-weight: bold;', action)
  console.log('%cevent: ', 'color: orange; font-weight: bold;', `${event}`)
  console.log(
    '%cpayload: ',
    'color: blue; font-weight: bold;',
    window.SHAW.dataLayer
  )
  console.groupEnd()
}

/**
 * creates a custom event, sets a data-layer object as a payload
 * and on the window, and dispatches the event to trigger the analytics
 * suite.
 * @param {string} eventName found in analytics requirements
 * @param {object} payload containing a data layer object
 */
export const dispatchAnalyticsEvent = (eventName, payload = {}) => {
  const analyticsEvent = new CustomEvent(eventName, { detail: payload })
  window.SHAW.dataLayer = payload

  document.body.dispatchEvent(analyticsEvent)
}

/**
 * @see http://redux.js.org/docs/advanced/Middleware.html
 */
export const analyticsMiddleware = store => next => action => {
  if (typeof action === 'undefined') {
    return next({})
  } else if (
    typeof action.name === 'undefined' ||
    action.type !== ANALYTICS_EVENT
  ) {
    return next(action)
  }
  const { eventName, dataLayer } = aer.get(action.name)(
    store.getState(),
    action
  )
  dispatchAnalyticsEvent(eventName, dataLayer)
  analyticsLogger(action, eventName)
  return next(action)
}
