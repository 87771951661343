import React, { useEffect, useRef, useState } from 'react'

import Bem from 'react-bem-helper'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { slug } from 'app/utils/paths'
import Link from 'app/components/Link/Link'
import LinkModel from 'app/models/Link/Link'
import { breakpoints } from 'app/utils/constants'
import LinkGroupModel from 'app/models/LinkList/LinkList'
import Transition from 'app/components/Transition/Transition'
import RegionSelector from 'app/components/RegionSelector/RegionSelector'
import SearchBar from 'app/components/SearchBar/SearchBar'
import {
  createDisableScrolling,
  createEnableScrolling
} from 'app/actions/shared'
import RegionSelectorButton from 'app/components/RegionSelector/RegionSelectorButton'

import GlobalNavDropdown from '../GlobalNavDropdown/GlobalNavDropdown'

import './GlobalNavMenu.scss'

// Component definition

function GlobalNavMenu({
  searchBar,
  hideCategoriesInDesktopView,
  menuSelected,
  enableScrolling,
  disableScrolling,
  isOpen,
  ...props
}) {
  const [isLocationOpen, setIsLocationOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selection, setSelection] = useState(null)
  const [displayDropdowns, setDisplayDropdowns] = useState(
    !hideCategoriesInDesktopView
  )
  const searchBarRef = useRef()
  const transitionRef = useRef()

  const classes = new Bem({
    name: 'global-nav-menu',
    prefix: ''
  })

  useEffect(() => {
    if (global && global.addEventListener) {
      global.addEventListener('resize', onResize)
      onResize()
    }
  }, [])

  useEffect(() => {
    const transition = isOpen
      ? transitionRef.current.enter
      : transitionRef.current.leave
    transition()
  }, [isOpen])

  const onResize = () => {
    const width =
      global.innerWidth ||
      global.document.documentElement.clientWidth ||
      global.document.body.clientWidth

    let breakpoint
    for (const size in breakpoints) {
      if (width >= breakpoints[size].min && width <= breakpoints[size].max) {
        breakpoint = size
        break
      }
    }

    if (breakpoint === 'large') {
      setDisplayDropdowns(!hideCategoriesInDesktopView)
    } else if (displayDropdowns === false) {
      setDisplayDropdowns(true)
    }
  }

  const select = index => {
    const selection = !selectionIs(index) && index

    setSelection(selection)
    menuSelected(selection)
  }

  const selectionIs = index => {
    return selection === index
  }

  const onOpen = () => {
    setTimeout(() => {
      disableScrolling()
    }, 500)
    window.scrollTo(0, 0)
  }

  const onClose = () => {
    setTimeout(() => {
      enableScrolling()
    }, 500)
  }

  const onClosed = () => {
    setSelection(null)
  }
  const onOpened = () => {}

  // const largeScreen = () => {
  //   return canUseDOM() && isLarge()
  // }

  const isRegionSaving = () => {
    setIsLoading(true)
  }

  const toggleRegionSelector = () => {
    setIsLocationOpen(!isLocationOpen)
  }

  const renderItems = (dropdowns, links, personalSiteLink) => {
    return (
      <ul {...classes({ element: 'items', modifier: ['scotch'] })}>
        <li {...classes({ element: 'input' })}>
          <RegionSelectorButton
            isLoading={isLoading}
            handleClick={toggleRegionSelector}
          />
          <RegionSelector
            showCta={false}
            isOpen={isLocationOpen}
            callback={isRegionSaving}
            id="global-nav-region-selector"
          />
        </li>
        {dropdowns?.length > 0 && (
          <li {...classes({ element: 'input' })}>{renderSearch()}</li>
        )}

        {renderDropdowns(dropdowns)}

        <li {...classes({ element: 'item', modifier: ['scotch'] })}>
          {renderLinks(links)}
        </li>
        <li {...classes({ element: 'item', modifier: ['scotch'] })}>
          <Link
            {...classes({ element: 'personal-site-link' })}
            analyticsValue={`${slug(props.parentName)}|${
              personalSiteLink && slug(personalSiteLink.ariaLabel)
            }`}
            link={personalSiteLink}
          />
        </li>
      </ul>
    )
  }

  const renderSearch = () => {
    if (!searchBar) {
      return null
    }

    return (
      <SearchBar
        cssModification="dropdown-menu"
        {...searchBar}
        innerRef={searchBarRef}
      />
    )
  }

  const renderDropdowns = dropdowns => {
    const categories = displayDropdowns ? dropdowns : []

    if (!categories) {
      return
    }

    return categories.map((group, index) => {
      if (!group) return null
      return (
        <li
          {...classes({ element: 'item', modifier: ['scotch'] })}
          key={`dropdown-${index}`}
        >
          <GlobalNavDropdown
            index={index}
            parentName={props.parentName}
            linkGroup={group}
            onSelect={select}
            isSelected={props.hideMenu !== false && selectionIs(index)}
          />
        </li>
      )
    })
  }

  const renderLinks = links => {
    if (links && links.length > 0) {
      return (
        <div {...classes({ element: 'additional-links' })}>
          {links.map((link, index) => (
            <Link
              {...classes({ element: 'link' })}
              analyticsValue={`${slug(props.parentName)}|${slug(
                link.ariaLabel
              )}`}
              key={`links-${index}`}
              link={link}
            />
          ))}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <Transition
      id={props.id}
      className={props.className}
      name={'global-nav-menu'}
      renderers={{ root: 'div' }}
      tag="div"
      ref={transitionRef}
      enter={50}
      leave={50}
      onEnterBegin={onOpen}
      onEnterComplete={onOpened}
      onLeaveBegin={onClose}
      onLeaveComplete={onClosed}
    >
      {renderItems(props.dropdowns, props.links, props.personalSiteLink)}
    </Transition>
  )
}

GlobalNavMenu.propTypes = {
  hideCategoriesInDesktopView: PropTypes.bool,
  dropdowns: PropTypes.arrayOf(LinkGroupModel.shape),
  isOpen: PropTypes.bool,
  links: PropTypes.arrayOf(LinkModel.shape).isRequired,
  // searchBar: PropTypes.shape(SearchBarModel.shape),
  parentName: PropTypes.string
}

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
  disableScrolling: data => dispatch(createDisableScrolling(data)),
  enableScrolling: data => dispatch(createEnableScrolling(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNavMenu)
