import React from 'react'

import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Link from 'app/components/Link/Link'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Chevron from 'app/components/Chevron/Chevron'
import LinkGroupModel from 'app/models/LinkList/LinkList'
import Collapsable from 'app/components/Collapsable/Collapsable'
import { canUseDOM, isLarge, runOnClient } from 'app/utils/env-helpers'
import GlobalNavPromo from 'app/modules/shared/GlobalNav/GlobalNavPromo/GlobalNavPromo'

import './GlobalNavDropdown.scss'
// Component definition
export default class GlobalNavDropdown extends Component {
  constructor(props) {
    super(props, 'global-nav-dropdown')
    this.subMenu = React.createRef()
    this.linkTabIndex = '-1'
    this.state = {
      ssrDone: false,
      url: '',
      isCollapsed: true,
      animated: true,
      hover: false,
      focus: false,
      align: 'left'
    }
  }

  componentDidMount() {
    this.linkTabIndex = isLarge() || this.props.isSelected ? '0' : '-1'
    this.setState({ ssrDone: true, url: location.pathname })
  }

  componentDidUpdate(nextProps) {
    this.linkTabIndex = isLarge() || nextProps.isSelected ? '0' : '-1'
  }

  getSubSize = ref => {
    this.subMenu = ref
    const submenu = ref.current.getBoundingClientRect()
    const page = global.document.body.getBoundingClientRect()

    this.setState({
      align:
        submenu.width + submenu.x > page.width ||
        submenu.width + submenu.x > 1500
          ? 'right'
          : 'left'
    })
  }

  handleClick = e => {
    e.preventDefault()

    this.setState({ isCollapsed: !this.state.isCollapsed })
    this.props.onSelect(this.props.index)
  }

  onFocus() {
    if (isLarge()) {
      this.setState({ focus: true })
    }
  }

  onBlur() {}

  m() {
    return {
      scotch: true,
      selected: this.props.isSelected,
      [`align-${this.state.align}`]: this.state.align
    }
  }

  render() {
    return (
      <div className={this.b()}>
        {this.renderLabel(this.props.linkGroup)}

        <Collapsable
          className={this.e('drawer')}
          isCollapsed={this.props.isSelected}
          refCallback={this.getSubSize}
        >
          <div className={this.e('flex-columns')}>
            <div className={this.e('column')}>
              <ul className={this.e('links')} aria-label="submenu">
                {this.props.linkGroup.groupTitle && (
                  <h4 className={this.e('group-title')}>
                    {this.props.linkGroup.groupTitle}
                  </h4>
                )}
                {this.props.linkGroup.links &&
                  this.props.linkGroup.links.map(this.renderLink)}
              </ul>
            </div>
            {this.props.linkGroup.groupLinks &&
              this.props.linkGroup.groupLinks.length > 0 && (
                <div className={this.e('column')}>
                  {this.props.linkGroup.groupLinks.map(gLink => {
                    return (
                      <ul
                        key={gLink.groupTitle}
                        className={this.e('links')}
                        aria-label="submenu"
                      >
                        {gLink.groupTitle && (
                          <h4 className={this.e('group-title')}>
                            {gLink.groupTitle}
                          </h4>
                        )}

                        {gLink.links && gLink.links.map(this.renderLink)}
                      </ul>
                    )
                  })}
                </div>
              )}

            {this.props.linkGroup.groupLinks2 &&
              this.props.linkGroup.groupLinks2.length > 0 && (
                <div className={this.e('column')}>
                  {this.props.linkGroup.groupLinks2.map(gLink => {
                    return (
                      <ul
                        key={gLink.groupTitle}
                        className={this.e('links')}
                        aria-label="submenu"
                      >
                        {gLink.groupTitle && (
                          <h4 className={this.e('group-title')}>
                            {gLink.groupTitle}
                          </h4>
                        )}

                        {gLink.links && gLink.links.map(this.renderLink)}
                      </ul>
                    )
                  })}
                </div>
              )}

            {this.props.linkGroup.rightPromo && (
              <div className={this.e('column')}>
                <GlobalNavPromo {...this.props.linkGroup.rightPromo} />
              </div>
            )}
          </div>
          {this.props.linkGroup.bottomPromo && (
            <div className={this.e('row')}>
              <GlobalNavPromo {...this.props.linkGroup.bottomPromo} bottom />
            </div>
          )}
        </Collapsable>
      </div>
    )
  }

  renderLabel(linkGroup) {
    const { url, ssrDone } = this.state
    const b = Bem('global-nav-dropdown')
    let analyticsValue =
      linkGroup && (linkGroup.label || (linkGroup.link && linkGroup.link.label))
    let analyticsEvent

    runOnClient(() => {
      if (!isLarge()) {
        analyticsValue += ` drawer|${
          this.props.isSelected ? 'collapse' : 'expand'
        }`
        analyticsEvent = 'helpfulAction'
      }
    })

    let largeVp = true
    if (global && global.innerWidth) {
      largeVp = global.innerWidth > 1023
    }
    const link = new LinkModel(linkGroup.link)
    const activeRegexp = new RegExp(link.activeRoute)
    const route = ssrDone ? url.split('/') : [null, null]
    const isActiveRoute =
      ssrDone && link.activeRoute && activeRegexp.test(`/${route[1]}`) === true

    return (
      <button
        className={b
          .e('label')
          .m({
            [`${this.props.isSelected ? 'expanded' : 'collapsed'}`]: true,
            'is-active': isActiveRoute
          })
          .classes()}
        data-value={`${slug(this.props.parentName)}|${slug(analyticsValue)}`}
        data-event={analyticsEvent}
        onClick={this.handleClick}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      >
        <span
          className="text"
          data-text={linkGroup?.label || linkGroup?.link?.label}
        >
          {linkGroup &&
            (linkGroup.label || (linkGroup.link && linkGroup.link.label))}
        </span>
        <Chevron
          className={this.e('chevron')}
          open={this.props.isSelected && !largeVp}
          thin
          fast
        />
      </button>
    )
  }

  renderLink(link, key, modifiers) {
    let tabIndex

    if (canUseDOM()) {
      tabIndex = isLarge() && modifiers === 'extra' ? '-1' : this.linkTabIndex
    } else {
      tabIndex = this.linkTabIndex
    }

    const className =
      typeof modifiers === 'string' ? this.e('link', modifiers) : this.e('link')
    const { activeRoute, ...restLink } = link
    return (
      link && (
        <li className={className} key={key}>
          <Link
            tabIndex={tabIndex}
            analyticsValue={`${slug(this.props.parentName)}|${slug(
              link.ariaLabel
            )}`}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            link={restLink}
          />
        </li>
      )
    )
  }
}

GlobalNavDropdown.props({
  linkGroup: LinkGroupModel.shape,
  index: Number,
  isSelected: Boolean,
  onSelect: Function,
  parentName: String
})
