// Vendor packages
import PropTypes from 'prop-types'
import mapValues from 'lodash/mapValues'
import isFunction from 'lodash/isFunction'

/**
 * Map basic javscript types to React prop-types.
 *
 * @type {Object}
 */
export const propTypeMap = {
  [String]: PropTypes.string,
  [Number]: PropTypes.number,
  [Boolean]: PropTypes.bool,
  [Function]: PropTypes.func,
  [Object]: PropTypes.object,
  [Array]: PropTypes.array,
  [Symbol]: PropTypes.symbol
}

/**
 * Convert property definitions to React prop-types.
 *
 * @example
 * // returns { foo: PropTypes.string, bar: PropTypes.number, baz: PropTypes.bool.isRequired }
 *
 * propTypes({
 *   foo: PropTypes.string
 *   bar: Number,
 *   baz: {
 *     type: Boolean,
 *     required: true
 *   }
 * })
 *
 * @param  {object} props - Object of property definitions
 *
 * @return {object} Object of React prop-types
 *
 * @see propType()
 */
export function propTypes(props) {
  try {
    return mapValues(props, propType)
  } catch (e) {
    console.warn('Error in propTypes in utils/prop-types:  ', props)
  }
}

/**
 * Converts a property definition to a React prop-type.
 * Providing a React prop-type will skip conversion.
 *
 * @example
 * // returns PropTypes.string
 * propType(String)
 *
 * @example
 * // returns PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
 * propTypes([Number, Boolean])
 *
 * @example
 * // returns PropsTypes.func
 * propTypes({
 *   type: Function
 * })
 *
 * @example
 * // return PropTypes.object.isRequired
 * propTypes({
 *   type: Object,
 *   required: true
 * })
 *
 * @param  {function|array} prop - A javascript type, React prop-type, property definition or array of those
 *
 * @return {function} A React prop-type
 */
export function propType(prop) {
  if (Array.isArray(prop)) {
    return PropTypes.oneOfType(prop.map(propType))
  }

  const type =
    propTypeMap[prop] ||
    customPropType(prop) ||
    propTypeMap[prop.type] ||
    prop.type ||
    prop

  return prop.required ? type.isRequired : type
}

/**
 * Generates a React prop-type from a callback.
 * Use for simple property value validation.
 *
 * @example
 * const prop = {
 *   validator(value) {
 *     return value > 10
 *   }
 * }
 *
 * @param  {object} prop - A property definition
 *
 * @return {void|function} A callback that validates a property's value
 */
export function customPropType(prop) {
  return (
    prop.validator &&
    function (props, propName, componentName) {
      const value = props[propName]
      const valid = prop.validator(value)

      if (!valid) {
        return new Error(
          `Invalid prop '${propName}' supplied to '${componentName}'. Validation failed.`
        )
      }
    }
  )
}

/**
 * Get the default values from property definitions.
 *
 * @example
 * // returns { foo: undefined, bar: 'baz' }
 * defaultProps({
 *   foo: Number,
 *   bar: {
 *     type: String,
 *     default: 'baz'
 *   }
 * })
 *
 * @param  {object} props - Property definitions
 *
 * @return {object} Property default values
 *
 * @see propDefault()
 */
export function defaultProps(props) {
  try {
    return mapValues(props, propDefault)
  } catch (e) {
    console.warn('Error in defaultProps in utils/prop-types:  ', props)
  }
}

/**
 * Gets the default value of a property definition.
 * A callback is required for objects or arrays,
 * and ensures a callback for function props.
 *
 * @example
 * // returns 'foo'
 * propDefault({
 *   foo: {
 *     type: String,
 *     default: 'foo'
 *   }
 * })
 *
 * @example
 * // returns { bar: 'baz' }
 * propDefault({
 *   foo: {
 *     type: Object,
 *     default() {
 *       return { bar: 'baz' }
 *     }
 *   }
 * })
 *
 * @example
 * // returns function () {}
 * propDefault({
 *   foo: Function
 * })
 *
 * @param  {object} prop - Property definition
 *
 * @return {mixed} The default value for a property
 */
export function propDefault(prop) {
  const value = prop.default

  // You really don't want to delete this.  If you do, you'll have a very odd bug that only manifests
  // when using React minified prod bundle - TOM
  if (typeof value === 'undefined') {
    const pt = prop.type || prop

    if (pt.name === 'Function') {
      return function fnDefault() {}
    }

    return undefined
  }

  // Or this
  if (!value) {
    return value
  }

  if (propType(prop) === PropTypes.func) {
    return value || function () {}
  }

  if (isFunction(value)) {
    return value()
  }

  return value
}
