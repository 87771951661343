import React, { useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import { withCookies } from 'react-cookie'

import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Button from 'app/components/Button/Button'
import { ClientDataLayer } from 'app/utils/DataLayer'
import Section from './Section'
import ContactInfo from './Steps/ContactInfo'
import SupportType from './Steps/SupportType'
import BusinessSize from './Steps/BusinessSize'
import BusinessType from './Steps/BusinessType'
import OurSolutions from './Steps/OurSolutions'
import FinalMessage from './Steps/FinalMessage'
import {
  businessTypeFieldsDefaults,
  handleFormSubmit,
  salesValidationSchema,
  validationSchema,
  submissionMap
} from './logic'

import './ContactForm.scss'

const steps = [
  'Support Type',
  'Business/Industry',
  'Our Solutions',
  'Business Size',
  'Contact Info'
]

const analyticsSteps = {
  0: 'supportType',
  1: 'businessType',
  2: 'productInterest',
  3: 'employeesCount'
}

const employeesCountOptions = {
  100: '1 - 100',
  500: '101 - 500',
  1000: '501 - 1000',
  1001: '1001+',
  0: 'Not sure'
}

function ContactForm({
  cookies,
  businessTypeFields = businessTypeFieldsDefaults,
  redirectUrl = '/secure/request-information/support/thankyou'
}) {
  const b = Bem('contact-form')
  const [disabled, setDisabled] = useState(false)
  const [showContinue, setShowContinue] = useState(true)
  const [completedSteps, setCompletedSteps] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [editStep, setEditStep] = useState(0)
  const [supportType, setSupportType] = useState('')
  const [submissionError, setSubmissionError] = useState(null)
  const [finalMessage, setFinalMessage] = useState(null)
  const isLastStep = completedSteps.includes(steps[3])
  const [currentValidationSchema, setCurrentValidationSchema] = useState(
    salesValidationSchema[activeStep]
  )
  useEffect(() => {
    if (supportType === 'Sales') {
      setCurrentValidationSchema(salesValidationSchema[activeStep])
    } else {
      setCurrentValidationSchema(validationSchema[activeStep])
    }

    if (activeStep === 0) {
      analytics(activeStep, null)
    }
  }, [activeStep, supportType])

  useEffect(() => {
    if (editStep === 0) {
      setShowContinue(true)
    } else {
      setShowContinue(false)
    }
  }, [editStep])

  function analytics(cStep, message) {
    const step = message ? `${message.status}-message` : slug(steps[cStep])
    if (typeof window !== 'undefined' && window.utag) {
      window.utag.view({
        page_name: `${utag_data.page_name}|${step}`,
        page_section: 'contact-form',
        lead_form_name: 'leadform|contact-form',
        form_name: 'leadform|contact-form',
        form_step: step,
        event_name: 'form_step'
      })
    }
  }

  function setLeadCookie(values, orderId) {
    try {
      let id = ''
      if (orderId) {
        id = orderId
      }
      const leadData = {
        leadFormName: 'leadform|contact-form',
        shawAccountNumber: values.shawAccountNumber || '',
        productInterest: values.productInterest || [],
        employeesCount: values.employeesCount || '',
        orderId: id
      }
      const leadDataString = JSON.stringify(leadData)
      const cookieOptions = {
        path: '/',
        expires: new Date(+new Date() + 12096e5)
      }
      cookies.set('leadData', leadDataString, cookieOptions)
    } catch (error) {
      if (windiw.LOG_LEVEL?.match(/info|verbose|debug|silly/)) {
        console.log('------- analytics cookie error --------->', error)
      }
    }
  }

  async function submitForm(values, actions) {
    setDisabled(true)
    try {
      if (
        values.supportType.match(
          /technical support|account management|cancel services/i
        ) !== null
      ) {
        if (
          values.requestType &&
          values.requestType.match(/move request/i) === null
        ) {
          delete values.currentAddress
          delete values.moveDate
          delete values.newAddress
          delete values.newServices
        }
        if (values.businessType.match(/Small and Medium Business/i) !== null) {
          delete values.moveDate
        }
        if (values.employeesCount) {
          delete values.employeesCount
        }
        if (values.productInterest && values.productInterest.length) {
          values.productInterest = []
        }

        values.extra =
          values.extra?.length &&
          values.extra.filter(e => e !== 'On site consultation')
      }
      const response = await handleFormSubmit({ ...values, token: 'skip' })
      actions.setSubmitting(false)
      if (process?.env?.LOG_LEVEL?.match(/info|verbose|debug|silly/)) {
        console.log(
          '------- response.data --------->',
          JSON.stringify(response && response.data)
        )
      }

      if (response) {
        const refId = response?.data?.id || response?.data?.data?.requestNumber

        setLeadCookie(values, refId)

        if (typeof window != 'undefined') {
          if (refId) {
            window.localStorage.setItem('refId', refId)
          }
          window.location.href = redirectUrl
        }
      }
    } catch (error) {
      if (process?.env?.LOG_LEVEL?.match(/info|verbose|debug|silly/)) {
        console.log('------- Contact Form Error --------->', error)
      }

      actions.setSubmitting(false)
      setDisabled(false)
      const cdl = new ClientDataLayer()
      cdl.sendError('Form Submission error')
      setSubmissionError(
        'Form submission was unsuccessful, please try again later.'
      )
    }
  }

  function handleSubmit(values, actions) {
    const shortFlow =
      completedSteps.length > 1 &&
      values.supportType?.match(
        /technical support|account management|cancel services/i
      ) !== null &&
      values.businessType

    setFinalMessage(null)
    actions.setSubmitting(false)
    let analyticsStep = activeStep + 1

    if (
      values.businessType &&
      values.supportType?.match(/technical support|cancel services/i) &&
      submissionMap[values.supportType].phone[values.businessType]
    ) {
      setCompletedSteps(
        Array.from(new Set([...completedSteps, 'Final Message']))
      )
      setFinalMessage(
        submissionMap[values.supportType].phone[values.businessType]
      )
    } else if (
      values.supportType?.match(
        /technical support|account management|cancel services/i
      ) &&
      completedSteps.includes(steps[1])
    ) {
      analyticsStep = steps[steps.length - 1]
      submitForm(values, actions)
    } else if (isLastStep) {
      submitForm(values, actions)
    } else {
      setActiveStep(activeStep + 1)
      setCompletedSteps(
        Array.from(
          new Set([
            ...completedSteps.filter(s => s !== 'Final Message'),
            steps[activeStep]
          ])
        )
      )
      actions.setTouched({})
    }

    if (
      values.supportType?.match(
        /technical support|account management|cancel services/i
      ) &&
      values.businessType
    ) {
      analyticsStep = steps.length - 1
    }

    if (!shortFlow && analyticsStep < steps.length) {
      analytics(
        analyticsStep,
        submissionMap[values.supportType].phone[values.businessType]
      )
    }
  }

  function resetUi() {
    setCompletedSteps([])
    setActiveStep(0)
    setEditStep(0)
    setSupportType('')

    if (typeof window !== 'undefined') {
      const form = document.getElementById('contact-form')
      const y = form.offsetTop
      window.scrollTo(0, y)
    }
  }

  function alternativeUiFlow(values, step) {
    setEditStep(step)
    let newActiveStep = completedSteps.length
    let analyticsStep = completedSteps.length

    if (values.supportType?.match(/sales/i)) {
      if (
        !values.businessType ||
        values.businessType?.match(
          /small and medium business|enterprise\/franchise|hotels/i
        ) === null
      ) {
        newActiveStep = 1
        setCompletedSteps([steps[0]])
      }
    } else if (values.supportType?.match(/account management/i)) {
      if (values.businessType?.match(/Community Living - Residents/i)) {
        newActiveStep = 1
        setCompletedSteps([steps[0], steps[1]])
      } else if (values.businessType) {
        const cSteps = Array.from(new Set([...completedSteps, steps[1]]))
        newActiveStep = 2
        analyticsStep = steps.length - 1
        setCompletedSteps(cSteps)
      }
    } else if (values.supportType?.match(/technical support/i)) {
      if (
        values.businessType?.match(
          /enterprise\/franchise|hotels|business satellite|partner channel/i
        )
      ) {
        newActiveStep = 2
        setCompletedSteps([steps[0], steps[1]])
      } else {
        const cSteps = Array.from(new Set([...completedSteps, steps[1]]))
        newActiveStep = values.businessType ? 2 : 1
        analyticsStep = steps.length - 1
        setCompletedSteps(cSteps)
      }
    } else if (values.supportType?.match(/cancel services/i)) {
      if (
        values.businessType?.match(
          /small and medium business|community living - residents/i
        )
      ) {
        newActiveStep = completedSteps.length
        setCompletedSteps([steps[0], steps[1]])
      } else if (values.businessType) {
        const cSteps = Array.from(new Set([...completedSteps, steps[1]]))
        newActiveStep = 2
        analyticsStep = steps.length - 1
        setCompletedSteps(cSteps)
      }
    }

    setActiveStep(newActiveStep)
    setEditStep(0)
    analytics(
      analyticsStep,
      submissionMap[values.supportType].phone[values.businessType]
    )
  }

  function handleSave() {
    setEditStep(0)
  }

  function handleEdit(step) {
    setEditStep(step)
  }

  function buttonAnalytics(values, isSubmit, shortFlow) {
    const step = shortFlow ? 3 : activeStep

    const analytics =
      analyticsSteps[step] === 'productInterest' &&
      values[analyticsSteps[step]].length
        ? values[analyticsSteps[step]].join(';')
        : values[analyticsSteps[step]]
    return values.businessType && isSubmit
      ? `${values.supportType}|${values.businessType}|`
      : `${analytics}|`
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={currentValidationSchema}
      validateOnMount={false}
      initialValues={{
        // required fields:
        supportType: '',
        businessType: '',
        productInterest: [],
        employeesCount: '',
        firstName: '',
        lastName: '',
        phoneNum: '',
        emailAddy: '',
        companyName: '',
        city: '',
        province: '',
        details: '',
        // conditionally required fields:
        shawAccountNumber: '',
        cancellationOption: '',
        requestType: '',
        moveDate: '',
        currentAddress: '',
        newAddress: '',
        newServices: ''
      }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        setTouched,
        resetForm,
        isSubmitting
      }) => {
        const message =
          (completedSteps.length > 1 &&
            values.supportType &&
            values.businessType &&
            submissionMap[values.supportType].phone[values.businessType]) ||
          (completedSteps.length > 1 &&
            values.supportType &&
            values.businessType &&
            submissionMap[values.supportType].phone[values.requestType])
        const shortFlow =
          completedSteps.length > 1 &&
          values.supportType?.match(
            /technical support|account management|cancel services/i
          ) !== null &&
          values.businessType
        const isSubmit = shortFlow || isLastStep

        return (
          <Form>
            <div className={b.e('all').classes()}>All fields required</div>
            <Section
              step={1}
              title={steps[0]}
              sectionTitle="What can we help you with today?"
              edit={handleEdit}
              editStep={editStep}
              completedValue={values.supportType}
              completed={
                values.supportType && completedSteps.includes(steps[0])
              }
              columns={[1, 2, 4]}
              callbackOnSave={alternativeUiFlow}
              setTouched={setTouched}
              touched={touched}
            >
              <SupportType
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setSupportType={setSupportType}
                values={values}
              />
            </Section>
            {values.supportType && completedSteps.includes(steps[0]) && (
              <Section
                step={2}
                title={steps[1]}
                sectionTitle="What type of business are you in?"
                edit={handleEdit}
                editStep={editStep}
                completedValue={values.businessType}
                completed={
                  values.businessType &&
                  editStep !== 1 &&
                  (completedSteps.includes(steps[1]) ||
                    completedSteps.includes('Final Message'))
                }
                callbackOnSave={alternativeUiFlow}
                setTouched={setTouched}
                touched={touched}
                showBorder={!message}
              >
                <BusinessType
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  fields={businessTypeFields}
                />
              </Section>
            )}
            {message && (
              <FinalMessage
                message={message}
                tracking={`${values.supportType}|${values.businessType}`}
                resetForm={() => {
                  resetForm()
                  resetUi()
                }}
              />
            )}
            {!message && (
              <>
                {!shortFlow &&
                  values.businessType &&
                  completedSteps.includes(steps[1]) && (
                    <Section
                      step={3}
                      title={steps[2]}
                      sectionTitle="Which business solutions are you interested in?"
                      edit={handleEdit}
                      editStep={editStep}
                      completedValue={values.productInterest.join(', ')}
                      completed={
                        values.productInterest.length > 0 &&
                        completedSteps.includes(steps[2])
                      }
                      columns={[1, 4, 4]}
                      callbackOnSave={handleSave}
                    >
                      <OurSolutions />
                    </Section>
                  )}
                {!shortFlow &&
                  values.businessType &&
                  values.productInterest.length > 0 &&
                  completedSteps.includes(steps[2]) && (
                    <Section
                      step={4}
                      title={steps[3]}
                      sectionTitle="How many employees in your organization?"
                      edit={handleEdit}
                      editStep={editStep}
                      completedValue={
                        employeesCountOptions[values.employeesCount]
                      }
                      completed={
                        values.employeesCount &&
                        completedSteps.includes(steps[3])
                      }
                      columns={[1, 2, 5]}
                      callbackOnSave={handleSave}
                    >
                      <BusinessSize />
                    </Section>
                  )}
                {values.businessType &&
                  (shortFlow
                    ? completedSteps.includes(steps[1])
                    : values.employeesCount &&
                      completedSteps.includes(steps[3])) && (
                    <>
                      {values.supportType.match(/account management/i) &&
                      values.requestType.match(/disconnects/i) ? (
                        <FinalMessage
                          message={message}
                          resetForm={() => {
                            resetForm()
                            resetUi()
                          }}
                        />
                      ) : (
                        <Section
                          step={shortFlow ? 3 : 5}
                          title={steps[4]}
                          sectionTitle="How can we reach you?"
                          edit={handleEdit}
                          editStep={editStep}
                          completed={false}
                          completedValue={true}
                        >
                          <ContactInfo
                            values={values}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            setFieldValue={setFieldValue}
                          />
                        </Section>
                      )}
                    </>
                  )}
              </>
            )}

            {!message && showContinue && (
              <Button
                disabled={disabled}
                type="submit"
                className={b.e('continue').classes()}
                text={isSubmit ? 'Submit request' : 'Continue'}
                design="scotch-primary"
                variant="light"
                analyticsEvent={isSubmit ? 'submitAction' : 'navigationAction'}
                analyticsValue={`business|contact-form|step-${
                  shortFlow ? 3 : activeStep + 1
                }-${
                  shortFlow ? `${steps[4]}` : `${steps[activeStep]}`
                }|${buttonAnalytics(values, isSubmit, shortFlow)}${
                  isSubmit ? 'Submit request' : 'Continue'
                }`}
              />
            )}
            {submissionError && !isSubmitting && (
              <div className={b.e('submission-error').classes()}>
                {submissionError}
              </div>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default withCookies(ContactForm)
