import React, { useEffect, useState } from 'react'

import Bem from 'react-bem-helper'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import { Promo } from '@shaw/react-component-library'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'

import './Promo.scss'

export class PromoModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      theme: String,
      variant: String,
      height: Number,
      title: String,
      description: String,
      image: ResponsiveImageModel.shape,
      cta: LinkModel.shape,
      desktopLayout: String,
      mobileLayout: String,
      noMargin: Boolean,
      backgroundSize: String
    }
  }
}

export default function PromoComponent(props) {
  const classes = new Bem({
    name: 'promo-container',
    prefix: 'c-'
  })

  const [page, setPage] = useState('')
  useEffect(() => {
    const trackPage =
      typeof window !== 'undefined' && location.pathname !== '/'
        ? `${slug(location.pathname)}|`
        : 'homepage|'
    setPage(trackPage)
  }, [])

  const button = {
    ...props.cta,
    variant: props?.cta?.style,
    theme: props?.cta?.variant,
    analyticsValue: `content|${page}${slug(props.title || props.name)}|${
      props.cta.label
    }`
  }

  return (
    <div
      {...classes({
        modifier: {
          'no-margin': props.noMargin,
          [`background-${props.backgroundSize}`]: true
        }
      })}
    >
      <Promo
        {...props}
        button={button}
        renderers={{
          description: {
            root: 'div',
            p: 'p'
          }
        }}
      />
    </div>
  )
}
