import { default as createReducer } from 'app/utils/create-reducer'
import {
  SET_SUBMITTING_LEAD_FORM_DATA,
  SET_LEAD_FORM_DATA
} from 'app/actions/lead-form'

export const initialState = {
  errors: null,
  statusCode: null,
  statusDescription: null,
  data: {
    requestNumber: null
  },
  submitting: false
}

export default createReducer(
  {
    [SET_SUBMITTING_LEAD_FORM_DATA]: (state, { data }) => ({
      ...state,
      submitting: data
    }),
    [SET_LEAD_FORM_DATA]: (state, { data }) => ({
      ...state,
      ...data,
      data: {
        ...state.data,
        ...data.data
      }
    })
  },
  initialState
)
