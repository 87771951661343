import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import { IconModel } from 'app/components/Icon/Icon'
import PricingTextModel from 'app/models/PricingText/PricingText'
import RegionalPriceModel from 'app/models/RegionalPrice/RegionalPrice'
import { ProductFeatureListItemModel } from 'app/components/ProductFeatureListItem/ProductFeatureListItem'
import ProductOfferSpecificationModel from 'app/models/ProductOfferSpecification/ProductOfferSpecification'

const NO_PRICE = 'XX.XX'
const _entryId = new WeakMap()

// Model definition
export default class ProductOfferModel extends Model {
  constructor(d) {
    super(d)
    _entryId.set(this, d.entryId || false)
    this.term = d.term || d.frequency
    this.termDisclaimer = d.priceDisclaimer
  }

  static props() {
    return {
      name: String,
      entryId: String,
      shortDescription: String,
      banner: String,
      headerVariation: String,
      productName: String,
      stylizedName: String,
      productCode: String,
      productPlanPage: LinkModel.shape,
      icon: IconModel.shape,
      regularPrice: String,
      standardPrice: String,
      firstYearPrice: String,
      otherYearPrice: String,
      offerFeatures: ProductFeatureListItemModel.arrayOf,
      offerSpecs: ProductOfferSpecificationModel.arrayOf,
      pricingText: PricingTextModel.shape,
      isActivePromo: Boolean,
      regionalPricing: [RegionalPriceModel.shape, Object],
      // Deprecated
      price: String,
      priceSuffix: String,
      pricePrefix: String,
      priceDisclaimer: String,
      term: String,
      termUnits: String
    }
  }

  static defaultProps() {
    return {
      ...super.defaultProps(),
      isActivePromo: false,
      icon: {
        type: 'email'
      },
      offerFeatures: [
        ProductFeatureListItemModel.defaultProps(),
        ProductFeatureListItemModel.defaultProps(),
        ProductFeatureListItemModel.defaultProps(),
        ProductFeatureListItemModel.defaultProps()
      ],
      offerSpecs: [
        ProductOfferSpecificationModel.defaultProps(),
        ProductOfferSpecificationModel.defaultProps(),
        ProductOfferSpecificationModel.defaultProps(),
        ProductOfferSpecificationModel.defaultProps()
      ],
      pricingText: PricingTextModel.defaultProps(),
      regionalPricing: RegionalPriceModel.defaultProps(),
      stylizedName: 'Product Offer Name',
      pricing: [{ price: NO_PRICE, text: 'Price text error' }]
    }
  }

  getRegionalPrices(type) {
    if (!this.regionalPricing || !this.regionalPricing.length) return {}
    return this.regionalPricing.reduce((accumulator, item) => {
      const province = item.province.toLowerCase()
      accumulator[province] = {
        price: item[type],
        text: item[`${type}Text`]
      }
      return accumulator
    }, {})
  }

  get pricing() {
    let prices = []

    if (!this.pricingText) {
      prices.push({ price: NO_PRICE, text: 'Price text error' })
      console.warn(
        `Product offer ${_entryId.get(
          this
        )} does not reference a PriceText model.  Will render XX.XX`
      )
      return prices
    }

    // Promo price is always displayed in conjunction with regular price
    if (this.firstYearPrice && this.otherYearPrice && this.regularPrice) {
      prices.push({
        price: this.firstYearPrice,
        text: this.pricingText.firstYearPriceText,
        ...this.getRegionalPrices('firstYearPrice')
      })
      prices.push({
        price: this.firstYearPrice,
        text: this.pricingText.otherYearPriceText,
        ...this.getRegionalPrices('firstYearPrice')
      })
    } else if (this.standardPrice) {
      prices.push({
        price: this.standardPrice,
        text: this.pricingText.standardPriceText,
        ...this.getRegionalPrices('standardPrice')
      })
    }

    // If we have regular price, show it
    if (this.regularPrice) {
      prices.push({
        price: this.regularPrice,
        text: this.pricingText.regularPriceText,
        ...this.getRegionalPrices('regularPrice')
      })
    }

    // If there are no prices, return [NO_PRICE], a clear indication that this offer needs a price
    if (!prices.length) {
      prices.push({ price: NO_PRICE, text: 'Price config error' })
      console.warn(
        `Product offer ${this.entryId} does not have a valid price configuration.  Will render XX.XX`
      )
    }

    return prices
  }

  get stylizedNameSlug() {
    return this.stylizedName.replace(/[^a-z0-9+]+/gi, '')
  }
}
