// Vendor packages
import Model from 'app/models/Model'

// Model definition
export default class ProductAddOnModel extends Model {
  get productName() {
    return this.stylizedName.replace(/[^0-9a-zA-Z\s]/gi, '')
  }

  static props() {
    return {
      name: String,
      stylizedName: String,
      shortDescription: String,
      price: String,
      pricePrefix: String,
      priceSuffix: String
    }
  }

  static defaultProps() {
    return {
      name: 'Product Name',
      stylizedName: '**Product** Name',
      shortDescription: 'This should be a description of the product offer',
      price: ''
    }
  }
}
