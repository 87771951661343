import { default as createReducer } from 'app/utils/create-reducer'
import {
  LONG_DISTANCE_RATES,
  LONG_DISTANCE_RATES_LOADING
} from 'app/actions/shared'

export const initialState = {
  loading: false,
  ldr: null
}

export default createReducer(
  {
    [LONG_DISTANCE_RATES]: (state, { data }) => {
      return {
        ...state,
        ldr: data,
        loading: false
      }
    },
    [LONG_DISTANCE_RATES_LOADING]: state => {
      return {
        ...state,
        loading: true
      }
    }
  },
  initialState
)
