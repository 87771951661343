import React from 'react'

import { withCookies } from 'react-cookie'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'
import ProductPlanCard, {
  ProductPlanCardModel
} from 'app/components/ProductPlanCard/NewProductPlanCard'

import HorizontalPlanCard from './HorizontalPlanCard'

import './ProductPlanCardsGrid.scss'
export class ProductPlanCardsGridModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        required: true
      },
      title: String,
      description: String,
      background: { type: String, default: 'grey' },
      layout: { type: String, default: 'Horizontal' },
      planCards: ProductPlanCardModel.arrayOf.isRequired,
      cardsHeight: { type: Number, required: true, default: 815 },
      resetMargin: { type: String, default: '' }
    }
  }
}
export function ProductPlanCardsGrid(props) {
  const {
    entryId,
    name,
    layout,
    background,
    title,
    description,
    planCards,
    cardsHeight,
    cookies,
    resetMargin
  } = props
  const b = Bem('product-plan-cards-grid')
  const isVertical = layout === 'Vertical'
  const quizResults = cookies && cookies.get('quizResults')

  return (
    <div
      name={entryId}
      className={b
        .m({
          [background]: background,
          [`reset-${resetMargin}-spacing`]: resetMargin
        })
        .classes()}
    >
      <div className={b.e('container').classes()}>
        {title && (
          <div className={b.e('title').classes()}>
            <RichText markup={title} isMarkdown />
          </div>
        )}
        {description && (
          <div className={b.e('description').classes()}>
            <RichText markup={description} isMarkdown />
          </div>
        )}
        <div
          className={b
            .e('plan-cards')
            .m({
              vertical: isVertical
            })
            .classes()}
        >
          {planCards &&
            planCards.map(card => {
              let isRecommended = false

              if (quizResults && quizResults.canonicalProductOfferIds) {
                isRecommended = quizResults.canonicalProductOfferIds.includes(
                  card.productOffer.entryId
                )
              }
              return isVertical || card.contentType === 'ProductPlanCard' ? (
                <ProductPlanCard
                  key={card.entryId}
                  parentName={name}
                  height={parseInt(cardsHeight, 10)}
                  {...card}
                  isRecommended={isRecommended}
                  isPromo
                />
              ) : (
                <HorizontalPlanCard
                  key={card.entryId}
                  parentName={name}
                  {...card}
                  isRecommended={isRecommended}
                  isPromo
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}
export default withCookies(ProductPlanCardsGrid)
