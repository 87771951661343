import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'

import ContactFormComponent from './ContactFormComponent'
export class ContactFormModel extends Model {
  static props() {
    return {
      name: String,
      formId: String,
      intro: String,
      businessTypeFields: Array,
      redirectUrl: String
    }
  }
}

export default function ContactForm({ background, intro, ...props }) {
  const b = Bem('contact-form')

  return (
    <section
      id="contact-form"
      className={b.m({ [background]: background }).classes()}
    >
      <div className={b.e('container').classes()}>
        <RichText
          className={b.e('intro').classes()}
          isMarkdown
          markup={intro}
        />
        <ContactFormComponent {...props} />
      </div>
    </section>
  )
}
