import React, { useEffect, useState } from 'react'

import Model from 'app/models/Model'
import { Accordion } from '@shaw/react-component-library'

import './Accordion.scss'

export class AccordionModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      eyebrow: String,
      title: String,
      description: String,
      background: String,
      variant: String,
      items: Array,
      showCollapsed: { type: Boolean, default: false }
    }
  }
}

export default function AccordionComponent({ showCollapsed, ...props }) {
  const [page, setPage] = useState('')
  useEffect(() => {
    const page = typeof window !== 'undefined' ? window.location.pathname : ''
    setPage(page)
  }, [])

  const initialIndex = showCollapsed ? -1 : 0

  return (
    <Accordion
      {...props}
      initialIndex={initialIndex}
      analyticsValue={`content|${page.replace('/', '')}`}
    />
  )
}
