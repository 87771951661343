import React from 'react'

import { withCookies } from 'react-cookie'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import { FullWidthImageBanner } from '@shaw/react-component-library'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'

import './FullBleedImageBanner.scss'
export class FullBleedImageBannerModel extends Model {
  constructor(d) {
    super(d)

    this.link = d.link ? new LinkModel(d.link) : null
  }

  static props() {
    return {
      theme: String,
      name: String,
      responsiveImage: ResponsiveImageModel.shape,
      link: LinkModel.shape,
      visibleIn: Object
    }
  }
}

function FullBleedImageBanner({
  responsiveImage,
  entryId,
  link,
  theme,
  name,
  visibleIn = ['ab', 'bc', 'sk', 'mb', 'on'],
  cookies
}) {
  const province = cookies ? cookies.get('queryLocation') : 'ab'
  const target = (link && link.asButton && link.asButton.target) || '_self'
  const respImage = responsiveImage
    ? {
        large:
          responsiveImage.large && responsiveImage.large.file
            ? responsiveImage.large.file.url
            : null,
        medium:
          responsiveImage.medium && responsiveImage.medium.file
            ? responsiveImage.medium.file.url
            : null,
        small:
          responsiveImage.small && responsiveImage.small.file
            ? responsiveImage.small.file.url
            : null
      }
    : null
  if (respImage === null) return null
  return (
    <FullWidthImageBanner
      id={entryId}
      theme={theme}
      responsiveImage={respImage}
      link={
        link
          ? {
              ...link,
              target,
              analyticsEvent: link.determineAnalyticsEvent(),
              analyticsValue: `${slug(name)}|${slug(link.text)}`
            }
          : null
      }
      visibleIn={visibleIn}
      location={province || 'ab'}
    />
  )
}
export default withCookies(FullBleedImageBanner)
