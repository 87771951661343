import React from 'react'

import Bem from 'react-bem-helper'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import ShareButton from './ShareButton'
import LiveUpdate, { LiveUpdateModel } from './LiveUpdate/LiveUpdate'

import './OutageArticle.scss'

export class OutageArticleModel extends Model {
  static props() {
    return {
      area: String,
      details: String,
      liveUpdates: LiveUpdateModel.shape,
      name: String,
      refNumber: String,
      region: String,
      services: String,
      share: { type: Boolean, default: false },
      status: String,
      summmary: String,
      title: String
    }
  }
}

export default function OutageArticle({
  area,
  details,
  liveUpdates,
  refNumber,
  services,
  share,
  status,
  summary,
  title,
  link,
  isDetail = false
}) {
  const classes = new Bem({
    name: 'support-outage-article',
    prefix: ''
  })
  const location = typeof window !== 'undefined' ? window.location : null
  const currentPage = location && location.pathname
  const moreDetails = new LinkModel(link)
  const button = {
    ...moreDetails.asButton,
    text: link?.label,
    type: 'link',
    design: 'link',
    analyticsEvent: 'navigationAction',
    analyticsValue: `content|${slug(currentPage)}|outage-article|${slug(
      link?.label
    )}`
  }
  const updates = liveUpdates.filter(item => item !== null)
  const firstLiveUpdate = updates && updates.length > 0 ? [updates[0]] : []
  const liveUpdatesList = isDetail ? updates : firstLiveUpdate
  const modifier = status.replace(/\s/g, '-')
  return (
    <div {...classes({ modifier: isDetail ? 'details' : '' })}>
      {title && (
        <div {...classes({ element: 'title' })}>
          {!isDetail && (
            <RichText
              markup={`${title} (${status})`}
              isMarkdown
              renderers={{
                root: 'h2',
                paragraph: 'span'
              }}
            />
          )}
          <span {...classes({ element: 'status', modifier })}>{status}</span>
        </div>
      )}
      {isDetail && area && (
        <div {...classes({ element: 'summary' })}>
          <RichText
            markup={isDetail ? `<strong>Affected Area:</strong> ${area}` : area}
            isMarkdown
            renderers={{
              root: 'p',
              paragraph: 'span'
            }}
          />
        </div>
      )}
      {isDetail && services && (
        <div {...classes({ element: 'summary' })}>
          <RichText
            markup={
              isDetail
                ? `<strong>Affected Services:</strong> ${services}`
                : services
            }
            isMarkdown
            renderers={{
              root: 'p',
              paragraph: 'span'
            }}
          />
        </div>
      )}

      {isDetail && refNumber && (
        <div {...classes({ element: 'summary' })}>
          <RichText
            markup={
              isDetail
                ? `<strong>Reference Number:</strong> ${refNumber}`
                : refNumber
            }
            isMarkdown
            renderers={{
              root: 'p',
              paragraph: 'span'
            }}
          />
        </div>
      )}

      {summary && (
        <div {...classes({ element: 'summary' })}>
          <RichText
            markup={isDetail ? `<strong>Summary:</strong> ${summary}` : summary}
            isMarkdown
            renderers={{
              root: 'p',
              paragraph: 'span'
            }}
          />
        </div>
      )}

      {isDetail && details && (
        <div {...classes({ element: 'summary' })}>
          <RichText
            markup={
              isDetail ? `<strong>Current Status:</strong> ${details}` : details
            }
            isMarkdown
            renderers={{
              root: 'p',
              paragraph: 'span'
            }}
          />
        </div>
      )}

      {liveUpdates && (
        <div {...classes({ element: 'live-updates' })}>
          <h4 {...classes({ element: 'live-updates-title' })}>Live Updates:</h4>
          {liveUpdatesList.map(update => (
            <LiveUpdate key={update.entryId} {...update} />
          ))}
        </div>
      )}
      {link && !isDetail && (
        <Button {...button} {...classes({ element: 'more-details' })} />
      )}

      {isDetail && share && (
        <div {...classes({ element: 'share' })}>
          <ShareButton />
        </div>
      )}
    </div>
  )
}
