import React from 'react'

function SvgComponent(props) {
  return (
    <svg width={18.804} height={22.265} viewBox="0 0 18.804 22.265" {...props}>
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        data-name="Module/Desktop/PlanCards/2P-Bundles/2YR"
      >
        <path d="M8.287 20.131H2.365A1.615 1.615 0 01.75 18.516V2.365A1.615 1.615 0 012.365.75h8.075l4.31 4.307v5.458" />
        <path d="M7.287 16.131a5.384 5.384 0 115.383 5.384 5.383 5.383 0 01-5.383-5.384z" />
        <path d="M14.824 16.208l-2.154 2.154-2.153-2.154M12.671 17.361v-3.347M9.05 1.015v3.85a1.65 1.65 0 001.65 1.65h3.85" />
      </g>
    </svg>
  )
}

export default SvgComponent
