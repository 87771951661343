import Model from 'app/models/Model'
// TODO: need to build it so it matches the collection in Contentful

export default class PricingTextModel extends Model {
  static props() {
    return [
      {
        name: { type: String, default: 'Pricing Text Model' },
        regularPrice: { type: String, default: 'N/A' },
        regularPriceText: { type: String, default: 'regular default' },
        standardPrice: { type: String, default: 'N/A' },
        standardPriceText: { type: String, default: 'standard default' },
        firstYearPriceText: { type: String, default: 'first year default' },
        firstYearPrice: { type: String, default: 'N/A' },
        otherYearPriceText: { type: String, default: 'third year default' },
        otherYearPrice: { type: String, default: 'N/A' }
        // promoBannerText: { type: String, default: 'promo banner default' },
        // newCustomerText: { type: String, default: 'for default customers only' },
        // recommendedText: { type: String, default: 'RECOMMENDED DEFAULT' }
      }
    ]
  }
}
