import React from 'react'
import { ErrorMessage, Field } from 'formik'
import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'

export default function OurSolutions({ title, description }) {
  const b = Bem('mdu-form')

  return (
    <div className={b.e('section').classes()}>
      <h2 className={b.e('section-title').classes()}>{title}</h2>
      <RichText
        className={b.e('description').classes()}
        isMarkdown
        markup={description}
      />

      <div
        className={b
          .e('section-container')
          .m({
            'desktop-4': true,
            'tablet-2': true,
            'mobile-2': true
          })
          .classes()}
      >
        <label
          className={b.e('checkbox-label').m({ single: true }).classes()}
          htmlFor="Internet"
        >
          <Field
            type="checkbox"
            name="productInterest"
            value="Internet"
            id="Internet"
          />
          <span>Internet</span>
        </label>
        <label
          className={b.e('checkbox-label').m({ single: true }).classes()}
          htmlFor="TV"
        >
          <Field type="checkbox" name="productInterest" value="TV" id="TV" />
          <span>TV</span>
        </label>
        <label
          className={b.e('checkbox-label').m({ single: true }).classes()}
          htmlFor="Voice"
        >
          <Field
            type="checkbox"
            name="productInterest"
            value="Voice"
            id="Voice"
          />
          <span>Voice</span>
        </label>
        <label
          className={b.e('checkbox-label').m({ single: true }).classes()}
          htmlFor="Security"
        >
          <Field
            type="checkbox"
            name="productInterest"
            value="Security"
            id="Security"
          />
          <span>Security</span>
        </label>

        <label
          className={b.e('checkbox-label').m({ single: true }).classes()}
          htmlFor="Network"
        >
          <Field
            type="checkbox"
            name="productInterest"
            value="Network"
            id="Network"
          />
          <span>Network</span>
        </label>
        <label
          className={b.e('checkbox-label').m({ single: true }).classes()}
          htmlFor="Cloud"
        >
          <Field
            type="checkbox"
            name="productInterest"
            value="Cloud"
            id="Cloud"
          />
          <span>Cloud</span>
        </label>
        <ErrorMessage
          name="productInterest"
          component="div"
          className={b.e('error').classes()}
        />
      </div>
    </div>
  )
}
