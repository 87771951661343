/* global utag */
import formatters from './form-data-format'
const SITENAME = 'business'
const HOME = 'home'
const SEARCH_LAYOUT = 'searchPage'
const PPP_LAYOUT = 'pppPage'
// const LEAD_LAYOUT = 'leadFormPage'
const SEARCH_TYPE = 'global'

export class ClientDataLayer {
  constructor() {
    this.pageData = global.utag_data // eslint-disable-line
  }

  get(name) {
    return this.pageData[name]
  }

  set(name, val) {
    this.pageData[name] = val
    return val
  }

  navigationCall(event, value) {
    try {
      if (utag && utag.link) {
        utag.link({
          ...this.pageData,
          event_name: event,
          navigationAction: value
        })
      } else {
        throw new Error(
          'DataLayer error: utag.link method could not be called.' +
            'This is either because the object or method does not exist.'
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  directCall(eventName, updates = false) {
    try {
      if (typeof this.pageData === 'object') {
        this.pageData.event_name = eventName

        if (updates && typeof updates === 'object') {
          Object.keys(updates).forEach(key => {
            this.set(key, updates[key])
          })
        }
      } else {
        throw new Error(
          'DataLayer error: utag_data does not exist or is not an object'
        )
      }

      if (utag && utag.link) {
        utag.link(this.pageData)
        return this.pageData
      } else {
        throw new Error(
          'DataLayer error: utag.link method could not be called.' +
            'This is either because the object or method does not exist.'
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  purchase(leadData) {
    try {
      if (utag && utag.link) {
        const ut = { ...this.pageData }
        const onesArr = leadData.productInterest.map(() => {
          return 1
        })
        const shawAccountNumber = leadData.shawAccountNumber
        const urlParams = window.location.search
          ? window.location.search.substr(1).split('&').join('|')
          : ''
        const leadFormName =
          urlParams.length > 0 ? `lead form|${urlParams}` : 'lead form'
        ut['event_name:lead submit'] = 'trigger' // This is correct, with the space;
        ut.lead_form_name = leadFormName
        ut.form_step = 'thankyou'
        ut.product_order_type = shawAccountNumber ? 'cross-sell' : 'acquisition'
        ut.order_id = leadData.orderId
        ut.product_type = leadData.productInterest // array of product names
        ut.product_name = leadData.productInterest // same as product_type
        ut.product_id = leadData.productInterest // same as product_type
        ut.product_quantity = onesArr // array of 1s same length as product_type/id
        ut.product_rgu = onesArr.map(() => {
          return '1'
        }) // array of 1s same length as product_type/id
        ut.company_size = leadData.employeesCount
        utag.link(ut)
      } else {
        throw new Error(
          'DataLayer error: utag.link method could not be called.' +
            'This is either because the object or method does not exist.'
        )
      }
    } catch (e) {
      console.error(
        'Exception when sending lead form subimission analytics: ',
        e
      )
    }
  }

  sendError(desc) {
    try {
      if (utag && utag.link) {
        const ut = { ...this.pageData }
        ut.link_name = 'Form Validation Error'
        ut.site_error = desc
        ut['event_name:siteError'] = true
        utag.link(ut)
      } else {
        throw new Error(
          'DataLayer error: utag.link method could not be called.' +
            'This is either because the object or method does not exist.'
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default class DataLayer {
  constructor(req, layout, browser, isLeadFormPage, formName) {
    this.fnDeps = [
      'calcHour',
      'getTimestamp',
      'getUaString',
      'getPlatform',
      'getRef',
      'getLeadFormName'
    ]
    this.req = req
    this.layout = layout
    this.browser = browser
    this.isLeadFormPage = isLeadFormPage != null ? isLeadFormPage : false
    this.formName = formName
    this.pageData = {
      // Calculated
      hour_of_day: '!execute:calcHour',
      timestamp: '!execute:getTimestamp',
      province: '',

      // Route based
      page_name: null,
      page_section: null,
      page_section_l2: null,
      page_section_l3: null,
      page_section_l4: null,
      page_section_l5: null,
      taxonomy_level: null,

      // Layout based
      prod_view: layout === PPP_LAYOUT,

      // CMS based
      content_template_name: layout,

      // ENV based
      site_version: process ? process.env.BUILD_NUMBER : null,

      form_name: this.isLeadFormPage ? this.formName || 'lead form' : '',
      lead_form_name: this.isLeadFormPage ? this.formName || 'lead form' : '',

      // Cookie based
      home_postal_code: null, // investigate cookie-ing this from lead gen

      // Platform based
      platform: '!execute:getPlatform',

      // Req based
      referring_url: '!execute:getRef',
      site_name: 'business', // req.hostname
      user_agent_string: '!execute:getUaString',

      // Hard coded
      site_language: 'english',
      user_login_state: 'logged-out',
      user_type: 'unknown',

      // Not applicable
      account_id: null,
      ab_testing_page_type: null
    }
  }

  get(name) {
    return this.pageData[name]
  }

  set(name, val) {
    this.pageData[name] = val
    return val
  }

  get device() {
    let d = 'desktop'
    const platform =
      this.browser && this.browser.platform && this.browser.platform.type

    if (platform === 'mobile') {
      d = 'mobile'
    }

    if (platform === 'tablet') {
      d = 'tablet'
    }

    return d
  }

  get urlQueryParams() {
    let params = []
    for (let f in this.req.query) {
      params.push(`${f}=${formatters.normalizeName(this.req.query[f])}`)
    }
    return params.length > 0 ? '|' + params.join('|') : ''
  }

  get routeBasedFields() {
    const segments = this.req.path.split('/').filter(seg => seg !== '')
    const isHome = !segments.length

    const data = {
      page_name: `${SITENAME}|${isHome ? HOME : segments.join('|')}`,
      page_section: isHome ? HOME : segments[0],
      taxonomy_level: 0
    }

    segments.forEach((seg, i) => {
      data.taxonomy_level++

      if (i !== 0) {
        const propString = `page_section_l${i + 1}`
        data[propString] = seg
      }
    })

    return data
  }

  get searchBasedFields() {
    if (this.layout !== SEARCH_LAYOUT) {
      return {}
    }

    let keyword = null
    let results = null

    if (this.req.search) {
      keyword = this.req.search.keyword
      results = this.req.search.totalResults
    }

    const searchData = {
      ai_search_keyword: keyword,
      number_search_results: results,
      internal_search_type: SEARCH_TYPE
    }

    if (searchData.number_search_results === 0) {
      // eslint-disable-line
      searchData['event_name:internalSearchResultsNotFound'] = 'trigger'
    }

    return searchData
  }

  get functionDependencies() {
    let fnsString = ''

    this.fnDeps.forEach((fn, i) => {
      if (i) {
        fnsString += '\n\n'
      }

      fnsString += String(fn)
    })

    return fnsString
  }

  get asObject() {
    return {
      ...this.pageData,
      ...this.routeBasedFields,
      ...this.searchBasedFields
    }
  }

  get asJSON() {
    let json = JSON.stringify(this.asObject).replace(/</g, '\\u003c')
    this.fnDeps.forEach(fn => {
      json = json.replace(`"!execute:${fn}"`, `${fn}()`)
    })

    return `addLeadFormData(${json})`
  }

  static createClientDataLayer(dl) {
    return new ClientDataLayer(dl)
  }
}
