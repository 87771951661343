import { ErrorMessage, Field } from 'formik'

import Bem from 'app/utils/bem-helper'
import React from 'react'

export default function OurSolutions() {
  const b = Bem('contact-form')
  return (
    <>
      <label
        className={b.e('checkbox-label').m({ single: true }).classes()}
        htmlFor="Internet"
      >
        <Field
          type="checkbox"
          name="productInterest"
          value="Internet"
          id="Internet"
        />
        <span>Internet</span>
      </label>
      <label
        className={b.e('checkbox-label').m({ single: true }).classes()}
        htmlFor="Voice"
      >
        <Field
          type="checkbox"
          name="productInterest"
          value="Voice"
          id="Voice"
        />
        <span>Voice</span>
      </label>
      <label
        className={b.e('checkbox-label').m({ single: true }).classes()}
        htmlFor="TV"
      >
        <Field type="checkbox" name="productInterest" value="TV" id="TV" />
        <span>TV</span>
      </label>
      <label
        className={b.e('checkbox-label').m({ single: true }).classes()}
        htmlFor="Network"
      >
        <Field
          type="checkbox"
          name="productInterest"
          value="Network"
          id="Network"
        />
        <span>Network</span>
      </label>
      <label
        className={b.e('checkbox-label').m({ single: true }).classes()}
        htmlFor="Cloud"
      >
        <Field
          type="checkbox"
          name="productInterest"
          value="Cloud"
          id="Cloud"
        />
        <span>Cloud</span>
      </label>
      <label
        className={b.e('checkbox-label').m({ single: true }).classes()}
        htmlFor="Security"
      >
        <Field
          type="checkbox"
          name="productInterest"
          value="Security"
          id="Security"
        />
        <span>Security</span>
      </label>
      <label
        className={b.e('checkbox-label').m({ single: true }).classes()}
        htmlFor="fullSolution"
      >
        <Field
          type="checkbox"
          name="productInterest"
          value="Full solution review"
          id="fullSolution"
        />
        <span>Full solution review</span>
      </label>
      <label
        className={b.e('checkbox-label').m({ single: true }).classes()}
        htmlFor="other"
      >
        <Field
          type="checkbox"
          name="productInterest"
          value="Other"
          id="other"
        />
        <span>Other</span>
      </label>
      <ErrorMessage
        name="productInterest"
        component="div"
        className={b.e('error').classes()}
      />
    </>
  )
}
