import Model from 'app/models/Model'

import { YouTubePlayerModel } from '../../modules/landing/YouTubePlayer/YouTubePlayer'
// Model definition
export default class SupportArticleTopicModel extends Model {
  static props() {
    return {
      name: String,
      title: String,
      topicIntro: String,
      expandableText: String,
      topicComplexity: String,
      parentNodeName: String,
      introVideos: YouTubePlayerModel.arrayOf,
      expandableVideos: YouTubePlayerModel.arrayOf
    }
  }
}
