/**
 * Calculate the maximum number of items while chunking,
 * where each chunk has the same number of items, and
 * is more than 1. Otherwise, returns the maximum.
 *
 * @param  {int} total - total number of items
 * @param  {int} max - ideal items in each chunk
 *
 * @return {int}
 */
export default function maxChunk(total, max) {
  let chunk = max

  do {
    if (total % chunk === 0) {
      return chunk
    }
  } while (--chunk > 1)

  return max
}
