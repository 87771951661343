import PropTypes from 'prop-types'

import get from 'lodash/get'
import { defaultProps, propTypes } from 'app/utils/prop-types'

// Model definition
export default class Model {
  constructor(data) {
    this.fill(data)
  }

  fill(data) {
    if (data == null) {
      return
    }

    const fillableProps = this.constructor.fillableProps()
    const defaultProps = this.constructor.defaultProps()

    try {
      fillableProps.forEach(prop => {
        var value = get(data, this.constructor.propPath(prop))

        if (value === undefined) {
          value = data[prop]
        }

        if (value === undefined) {
          value = defaultProps[prop]
        }

        this[prop] = value
      })
    } catch (e) {
      console.error('---- error ----', e)
    }
  }

  static props() {
    return {}
  }

  static propTypes() {
    return propTypes(this.props())
  }

  static defaultProps() {
    return defaultProps(this.props())
  }

  static propPath(prop) {
    return get(this.props(), `${prop}.path`, prop)
  }

  static fillableProps() {
    return Object.keys(this.propTypes()).filter(prop => {
      return !get(this.props(), `${prop}.exclude`, false)
    })
  }

  static get shape() {
    return PropTypes.shape(this.propTypes())
  }

  static get arrayOf() {
    return PropTypes.arrayOf(this.shape)
  }
}
