import React from 'react'

import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'
import { IconModel } from 'app/components/Icon/Icon'
import RichTextBox, {
  RichTextBoxModel
} from 'app/components/RichTextBox/RichTextBox'
import BasicIconBox, {
  BasicIconBoxModel
} from 'app/components/BasicIconBox/BasicIconBox'
import ImageTextBox, {
  ImageTextBoxModel
} from 'app/components/ImageTextBox/ImageTextBox'
import BasicTextBox, {
  BasicTextBoxModel
} from 'app/components/BasicTextBox/BasicTextBox'
import VideoBox, { VideoBoxModel } from 'app/components/VideoBox/VideoBox'

export class MasterBoxModel extends Model {
  static props() {
    return {
      name: String,
      entryId: String,
      boxType: String,
      logo: ResponsiveImageModel.shape,
      isFeatured: Boolean,
      featuredCopy: String,
      eyebrow: String,
      title: String,
      titleWeight: String,
      description: String,
      icon: IconModel.shape,
      image: ResponsiveImageModel.shape,
      imageAsset: Object,
      video: Object,
      cta: LinkModel.shape,
      ctaAlt: LinkModel.shape,
      category: String,
      alignMobile: String,
      background: String,
      layout: String
    }
  }
}

export default function MasterBox({ boxType, ...props }) {
  switch (boxType) {
    case 'IconBox':
      const iconBoxProps = new BasicIconBoxModel(props) // eslint-disable-line
      return <BasicIconBox {...iconBoxProps} />
    case 'ImageBox':
      const imageBoxProps = new ImageTextBoxModel(props) // eslint-disable-line
      return <ImageTextBox {...imageBoxProps} />
    case 'TextCard':
      const textBoxProps = new BasicTextBoxModel(props) // eslint-disable-line
      return <BasicTextBox {...textBoxProps} />
    case 'TextBox':
      if (props.layout === 'page') {
        const richtextBoxProps = new RichTextBoxModel(props) // eslint-disable-line
        return <RichTextBox {...richtextBoxProps} />
      } else {
        const textBoxProps = new BasicTextBoxModel(props) // eslint-disable-line
        return <BasicTextBox {...textBoxProps} />
      }
    case 'VideoBox':
      const videoBoxProps = new VideoBoxModel(props) // eslint-disable-line
      return <VideoBox {...videoBoxProps} />
    default:
      return null
  }
}
