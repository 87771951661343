import Model from 'app/models/Model'
import { IconModel } from 'app/components/Icon/Icon'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'

export default class LinkModel extends Model {
  static props() {
    return {
      name: String,
      href: String,
      target: [String, Object],
      file: Object,
      label: [String, Number],
      icon: IconModel.shape,
      image: ResponsiveImageModel.shape,
      isNewWindow: Boolean,
      analyticsEvent: String,
      accessibilityLabel: String,
      additionalLabel: String,
      tooltip: String,
      style: String,
      variant: String,
      activeRoute: String
    }
  }

  determineAnalyticsEvent() {
    if (this.analyticsEvent) {
      return this.analyticsEvent
    }

    switch (this.type) {
      case 'chat':
        return 'chatAction'

      case 'email':
        return 'emailAction'

      case 'phone':
        return 'callAction'

      default:
        return 'navigationAction'
    }
  }

  get offsite() {
    return this.type === 'link' && this.href.startsWith('http')
  }

  get type() {
    if (this.href) {
      if (this.href.search('#chat:') !== -1) {
        return 'chat'
      }
      return 'link'
    }

    if (!this.target && !this.file) {
      return 'blank'
    }

    if (this.file) {
      return 'file'
    }

    if (this.target.contentType === 'ModuleAnchor') {
      return 'anchor'
    }

    if (this.target.canonicalUrl) {
      return 'page'
    }

    if (this.target.email) {
      return 'email'
    }

    if (this.target.number) {
      return 'phone'
    }
  }

  get isPhone() {
    return (
      (this.href && this.href.startsWith('tel')) ||
      (this.target && this.target.number)
    )
  }

  get url() {
    if (this.href) {
      return this.href
    }

    if (!this.target && !this.file) {
      return
    }

    if (this.file) {
      return `https:${this.file.file.url}`
    }
    if (this.target.route) {
      return this.target.route
    }

    if (this.target.email) {
      return `mailto:${this.target.email}`
    }

    if (this.target.number) {
      return `tel:${this.target.number}`
    }
  }

  get targetWindow() {
    // TODO: sometimes this.isNewWindow is not set properly in the cms: eg: ItemListBox line 86
    return this.isNewWindow || this.href?.match('https://') ? '_blank' : '_self'
  }

  get text() {
    if (this.label) {
      return this.label
    }

    if (!this.target && !this.file) {
      return
    }

    if (this.file) {
      return this.file.title
    }

    return this.target.email || this.target.number
  }

  get ariaLabel() {
    if (this.accessibilityLabel && this.accessibilityLabel.length > 0) {
      return this.accessibilityLabel
    }

    if (typeof this.text === 'undefined') {
      if (this.icon) {
        return this.icon.type
      }

      return
    }

    return this.text
  }

  get analytics() {
    return {
      'data-event': this.determineAnalyticsEvent()
    }
  }

  get asButton() {
    return {
      type: 'link',
      design: this.style,
      variant: this.variant,
      icon: this.icon,
      text: this.text,
      link: this.url,
      target: this.targetWindow,
      rel: this.targetWindow === '_blank' ? 'noreferrer' : null,
      anchor: this.type === 'anchor' ? this.target : undefined,
      ariaLabel: this.ariaLabel,
      activeRoute: this.activeRoute
    }
  }
}
