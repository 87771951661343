import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import ImageModel from 'app/models/Image/Image'
import Component from 'app/components/Component'

import './Copyright.scss'

export class CopyrightModel extends Model {
  static props() {
    return {
      name: String,
      image: ImageModel.shape,
      copyrightText: String
    }
  }
}

class Copyright extends Component {
  constructor(props) {
    super(props)
    this.b = Bem('footer')
  }

  render() {
    const { image, copyrightText } = this.props
    const img = new ImageModel(image)
    return (
      <div>
        <section
          className={this.b.e('copyright-bar').c()}
          aria-label="Footer Copyright"
        >
          <div className={this.b.e('container').c()}>
            <div className={this.b.e('container-row').c()}>
              <div className={this.b.e('container-full-width-column').c()}>
                {img && img.title && (
                  <img
                    className={this.b.e('image').c()}
                    src={img.src}
                    alt={img.title}
                  />
                )}
                <p className={this.b.e('copyright-text').c()}>
                  {`©${new Date().getFullYear()} ${copyrightText}`}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
Copyright.props(CopyrightModel)
export default Copyright
