import * as Yup from 'yup'
import axios from 'axios'
import { isValid } from 'date-fns'

const phoneRegExp =
  /^([(]{0,1}?[\d]{3}[)\s]{0,2}?[\d]{3}[-]{0,1}[\d]{4})([\s]{0,1}[a-z]{1}[\s]{0,1}[\d]{2,4})?$/

const postalCodeRegExp =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
const nameRegExp = /^[a-zA-Z0-9]+([-_\s]{1}[a-zA-Z0-9]+)*$/

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Must provide first name.')
    .matches(nameRegExp, 'Only alphanumeric characters, please.')
    .notOneOf(
      [Yup.ref('lastName'), null],
      'First and last name cannot be the same.'
    ),
  lastName: Yup.string()
    .required('Must provide last name.')
    .matches(nameRegExp, 'Only alphanumeric characters, please.')
    .notOneOf(
      [Yup.ref('firstName'), null],
      'First and last name cannot be the same.'
    ),
  phoneNum: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Must provide a 10-digit phone number.'),
  emailAddy: Yup.string()
    .email('Email seems invalid.')
    .required('Must provide a valid email address.'),
  postalCode: Yup.string()
    // .required('Must provide postal code.')
    .matches(postalCodeRegExp, 'Postal code seems invalid.'),
  companyName: Yup.string().required('Must provide company name.'),
  position: Yup.string().required('Must provide position.'),
  buildingAddress: Yup.string().required('Must provide a building address.'),
  city: Yup.string().required('Must provide city.'),
  province: Yup.string().required('Must select a province.'),
  productInterest: Yup.array().when('isNewDevelopment', {
    is: value => value && value.match(/no/i),
    then: Yup.array().test({
      name: 'productInterest-test',
      exclusive: true,
      message: 'Select at least one option',
      test: value => value.length > 0
    })
  }),
  numberOfUnits: Yup.number().typeError('Must enter a valid number'),
  isNewDevelopment: Yup.string().required('Must select an option.').nullable(),
  estimatedOccupancyDate: Yup.date()
    .when('isNewDevelopment', {
      is: value => value && value.match(/yes/i),
      then: Yup.date()
        .test({
          name: 'date-test',
          exclusive: true,
          message: 'Date is not valid',
          test: value => {
            return !value || isValid(value)
          }
        })
        .nullable()
    })
    .nullable()
})

async function handleFormSubmit(values) {
  let formUrl = ''
  if (typeof window !== undefined) {
    formUrl = `${location.href}`
  }
  return await axios.post('/lead-form-submit', {
    ...values,
    formSubmitTo: 'EMAIL',
    contentType: 'MDUForm',
    formUrl
  })
}

export { validationSchema, handleFormSubmit }
