import axios from 'axios'
// CONSTANTS
export const ANALYTICS_EVENT = 'ANALYTICS_EVENT'
export const DISABLE_SCROLLING = 'DISABLE_SCROLLING'
export const ENABLE_SCROLLING = 'ENABLE_SCROLLING'
export const SET_TOUCH_FLAG = 'SET_TOUCH_FLAG'
export const SET_REQUEST_DATA = 'SET_REQUEST_DATA'
export const SET_REQUEST_QUERY = 'SET_REQUEST_QUERY'
export const SET_BROWSER_TYPE = 'SET_BROWSER_TYPE'
export const REGISTER_MODULE_ANCHOR = 'REGISTER_MODULE_ANCHOR'
export const INIT_LIVEAGENT = 'INIT_LIVEAGENT'
export const LONG_DISTANCE_RATES = 'LONG_DISTANCE_RATES'
export const LONG_DISTANCE_RATES_LOADING = 'LONG_DISTANCE_RATES_LOADING'
export const SET_STORE = 'SET_STORE'

// STORE CREATORS
export const setStore = store => ({
  type: SET_STORE,
  data: store
})

// ACTION CREATORS
// Analytics
export const createAnalyticEvent = (name, data) => ({
  type: ANALYTICS_EVENT,
  name,
  data
})

// Scrolling
export const createDisableScrolling = () => ({ type: DISABLE_SCROLLING })
export const createEnableScrolling = () => ({ type: ENABLE_SCROLLING })

// Detected Features
export const createSetTouchFlag = () => ({
  type: SET_TOUCH_FLAG
})

export const createBrowserDetectionAction = () => {
  return dispatch => {
    if ('ontouchstart' in window) {
      dispatch(createSetTouchFlag())
    }
  }
}

// Self
/**
 * An additive PUT-style update to state.self.req
 * @param data {request} A subset of the express request object.  hostname, path, route, params, protocol, cookies
 */
export const createSetRequestData = data => ({ type: SET_REQUEST_DATA, data })

export const setRequestQuery = data => ({ type: SET_REQUEST_QUERY, data })

/**
 * Used by Button and Link to self-register anchor links to the page reducer, allowing Anchorable components to
 * act as targets
 */
export const createRegisterModuleAnchor = data => ({
  type: REGISTER_MODULE_ANCHOR,
  data
})

export const createSetBrowserType = data => ({ type: SET_BROWSER_TYPE, data })

export const setLongDistanceRates = data => ({
  type: LONG_DISTANCE_RATES,
  data
})
export const setLongDistanceRatesLoading = () => ({
  type: LONG_DISTANCE_RATES_LOADING
})

export const getLongDistanceRates = (
  ldrUrl,
  cbs = { success: () => {}, failure: () => {} }
) => {
  return dispatch => {
    dispatch(setLongDistanceRatesLoading())
    axios
      .get(ldrUrl)
      .then(res => {
        dispatch(setLongDistanceRates(res.data))
        if (cbs.success) {
          cbs.success(res.data)
        }
      })
      .catch(err => {
        const error =
          err.response && err.response.data ? err.response.data : err
        dispatch(setLongDistanceRates(error))
        if (cbs.failure) {
          cbs.failure(err.response.data)
        }
      })
  }
}
