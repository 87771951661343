import React, { Fragment } from 'react'
import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'

import './LegalDisclaimer.scss'

export default function LegalDisclaimer({ symbol, body }) {
  const b = Bem('legal-disclaimer')

  return (
    <p data-disclaimer={symbol} className={b.classes()}>
      <sup>{symbol}</sup>{' '}
      <RichText
        markup={body}
        isMarkdown
        noClass
        renderers={{ root: Fragment, p: Fragment }}
      />
    </p>
  )
}
