import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import MaskedInput from 'react-text-mask'
import { withCookies } from 'react-cookie'
import { Field, Form, Formik } from 'formik'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Card from 'app/components/Card/Card'
import Button from 'app/components/Button/Button'
import { HeroModel } from 'app/components/Hero/Hero'
import { ClientDataLayer } from 'app/utils/DataLayer'
import RichText from 'app/components/RichText/RichText'
import FullBleedHero from 'app/modules/ppp/FullBleedHero/FullBleedHero'
import { FormGroupModel } from 'app/modules/leadForm/FormGroup/FormGroup'
import BasicIconBox, {
  BasicIconBoxModel
} from 'app/components/BasicIconBox/BasicIconBox'

import { masks } from '../ContactForm/logic'
import { validate } from './validation'

import './RequestSupportForm.scss'
export class RequestSupportFormModel extends Model {
  static props() {
    return {
      name: String,
      formId: String,
      submitTo: { type: String, default: '' },
      submitToUrl: { type: String, default: '' },
      submitToEmail: { type: String, default: '' },
      hero: { type: HeroModel.shape },
      description: String,
      infoBox: String,
      redirectUrl: {
        type: String,
        default: '/secure/request-information/support/thankyou'
      },
      cancellationMessage: { type: BasicIconBoxModel.shape, required: false },
      groups: {
        type: FormGroupModel.arrayOf,
        required: true
      },
      background: {
        type: String,
        default: 'soft-white'
      }
    }
  }
}

const initial = {
  submitError: ''
}

const sendFormData = data => axios.post('/lead-form-submit', data)

export const RequestSupportForm = ({
  hero,
  description,
  background,
  infoBox,
  redirectUrl,
  formId,
  submitTo,
  submitToUrl,
  submitToEmail,
  name,
  submitForm = sendFormData,
  initialProps = initial,
  groups,
  cookies
}) => {
  const [submitError, setSubmitError] = useState(null)
  const [alternative, setAlternative] = useState(false)
  const [pageUrl, setPageUrl] = useState('')
  const [inFocus, setInFocus] = useState('')
  const [filled, setFilled] = useState([])
  const [disabled, setDisabled] = useState(false)
  const b = Bem('request-support-form')
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPageUrl(window.location.pathname)
    }
  }, [])

  const handleAlternative = value => {
    setAlternative(value || null)
  }

  function onFocus({ target }) {
    setInFocus(target.name)
  }
  function onBlur(e) {
    let newFilled

    if (e.target.value) {
      newFilled = [...filled, e.target.name]
    } else {
      newFilled = filled.filter(f => f !== e.target.name)
    }
    setFilled(newFilled)
  }
  function setLeadCookie(values, orderId) {
    const leadData = {
      shawAccountNumber: values.shawAccountNumber || 'none',
      productInterest: values.productInterest,
      employeesCount: values.employeesCount,
      orderId,
      leadFormName: 'contact-form'
    }
    const leadDataString = JSON.stringify(leadData)
    const cookieOptions = {
      path: '/',
      expires: new Date(+new Date() + 12096e5)
    }
    cookies.set('leadData', leadDataString, cookieOptions)
  }

  const validationRules = groups
    ? groups.reduce((acc, group) => {
        group.groups.forEach(grp => {
          if (grp.id) initial[grp.id] = ''
          if (grp.required) {
            acc.push({
              name: grp.id,
              required: true,
              requiredError: grp.requiredError || 'Required'
            })
          }
          if (grp.fields) {
            grp.fields.forEach(field => {
              if (field.fieldType === 'hidden') {
                initial[field.id] = field.defaultValue
              } else if (field.id) {
                initial[field.id] = ''
              }
              if (field.required) {
                acc.push({
                  name: field.id,
                  required: true,
                  requiredError: field.requiredError || 'Required',
                  validation: field?.validation || null,
                  validationError: field.validationError
                })
              }
            })
          }
        })
        return acc
      }, [])
    : []
  const radioDefault = groups?.reduce((acc, group) => {
    const name = group.groups[0].id
    if (
      group.groups?.[0]?.contentType === 'RadioGroup' &&
      group.groups?.[0]?.default
    ) {
      acc = { [name]: group.groups[0].default.value }
    }
    return acc
  }, {})

  const initialValues = {
    formId,
    submitTo,
    submitToEmail,
    submitToUrl,
    formType: 'support',
    employeesCount: '0',
    ...initial,
    ...initialProps,
    ...radioDefault
  }
  const renderInput = (field, values, handleChange, handleBlur, label = '') => {
    if (field.mask) {
      return (
        <MaskedInput
          type="text"
          name={field.id}
          id={field.id}
          onChange={handleChange}
          onBlur={e => {
            onBlur(e)
            handleBlur(e)
          }}
          onFocus={e => {
            onFocus(e)
          }}
          value={values[field.id]}
          mask={masks[field.mask]}
        />
      )
    }
    if (field.contentType === 'FormDropdown') {
      return (
        <select
          name={field.id}
          id={field.id}
          onChange={handleChange}
          onBlur={e => {
            onBlur(e)
            handleBlur(e)
          }}
          onFocus={e => {
            onFocus(e)
          }}
          value={values[field.id]}
        >
          {label && <option value="">{label}</option>}
          {field.list.map(li => (
            <option key={li} value={li}>
              {li}
            </option>
          ))}
        </select>
      )
    }
    return (
      <input
        type={field.fieldType || 'text'}
        name={field.id}
        id={field.id}
        onChange={handleChange}
        onBlur={e => {
          onBlur(e)
          handleBlur(e)
        }}
        onFocus={e => {
          onFocus(e)
        }}
        value={values[field.id]}
      />
    )
  }

  return (
    <div className={b.m({ [background]: background }).classes()}>
      <FullBleedHero className={b.e('hero').classes()} {...hero} />
      <section className={b.e('container').classes()}>
        <RichText
          className={b.e('description').classes()}
          isMarkdown
          markup={description}
        />
        <div className={b.e('form').classes()}>
          <Formik
            validateOnMount
            initialValues={initialValues}
            validate={values => validate(values, validationRules)}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldTouched }
            ) => {
              setSubmitting(true)
              setDisabled(true)
              setSubmitError(null)
              const data = values
              delete data.submitError

              try {
                const response = await submitForm({ ...data, token: 'skip' })

                if (window) {
                  window.location.href = redirectUrl
                  if (
                    response?.data &&
                    (response.data.success || response.data.statusCode === 'OK')
                  ) {
                    const refId = response?.data?.id
                      ? response.data.id
                      : response?.data?.data?.requestNumber
                    window.localStorage.setItem('refId', refId)
                    setLeadCookie(data, refId)
                  }
                }
              } catch (error) {
                setSubmitError('Something went wrong, please try again later!')
                setDisabled(false)
                const cdl = new ClientDataLayer()
                cdl.sendError('Form Submission error')
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue
              /* and other goodies */
            }) => {
              const trackPage =
                typeof window !== 'undefined' && location.pathname !== '/'
                  ? `${slug(location.pathname)}|`
                  : 'homepage|'
              const trackModuleName = `${slug(name.replace(/.*-/, ''))}|`
              const trackRadio = values.helpWith
                ? `${slug(values.helpWith)}|`
                : ''
              return (
                <Form>
                  <p className="required">* = required</p>

                  {groups &&
                    groups.map((group, index) => {
                      return (
                        <Fragment key={index}>
                          {(!alternative ||
                            group.groups[0].contentType === 'RadioGroup') && (
                            <fieldset>
                              <legend
                                id={group.entryId}
                                className={b.e('form-group-title').classes()}
                              >
                                {group.title}
                              </legend>
                              {group.groups &&
                                group.groups.map((grp, index) => (
                                  <Card
                                    key={grp.entryId || index}
                                    className={`w-auto ${
                                      grp.list ? 'list-' + grp.list.length : ''
                                    } ${
                                      grp.fields ? 'columns-' + grp.columns : ''
                                    }`}
                                  >
                                    {!grp.list && !grp.fields && grp.area && (
                                      <div
                                        key={grp.entryId}
                                        className={b
                                          .e(`form-element`)
                                          .classes()}
                                      >
                                        <label
                                          htmlFor={grp.id}
                                          className={b
                                            .e(`input-label`)
                                            .m({
                                              focus: inFocus === grp.id,
                                              filled: !!values[grp.id],
                                              textarea: true
                                            })
                                            .classes()}
                                        >
                                          <textarea
                                            aria-label={grp.placeholder}
                                            type="details"
                                            name={grp.id}
                                            id={grp.id}
                                            onBlur={e => {
                                              onBlur(e)
                                              handleBlur(e)
                                            }}
                                            onFocus={e => {
                                              onFocus(e)
                                            }}
                                            onChange={handleChange}
                                            value={values.details}
                                            placeholder={grp.placeholder}
                                          />

                                          {errors[grp.id] && touched[grp.id] ? (
                                            <div
                                              className={b.e('error').classes()}
                                            >
                                              {errors[grp.id]}
                                            </div>
                                          ) : null}
                                        </label>
                                      </div>
                                    )}

                                    {grp.list &&
                                      grp.list.map((item, ind) => {
                                        const type =
                                          item.contentType === 'RadioButton'
                                            ? 'radio'
                                            : 'checkbox'
                                        return (
                                          <div
                                            key={item.entryId || ind}
                                            className={b
                                              .e(`form-element`)
                                              .classes()}
                                          >
                                            <label
                                              htmlFor={item.value}
                                              className={b
                                                .e(`${type}-label`)
                                                .classes()}
                                            >
                                              <input
                                                data-testid={item.value}
                                                aria-labelledby={`radio-section ${item.value}`}
                                                type={type}
                                                name={grp.id}
                                                id={item.value}
                                                value={item.value}
                                                data-event="navigationAction"
                                                data-value={`business|${slug(
                                                  pageUrl
                                                )}|${slug(
                                                  'Support-Request-Form'
                                                )}|${item.label}`}
                                                onChange={e => {
                                                  handleChange(e)

                                                  setFieldValue(
                                                    'submitToEmail',
                                                    item.emailTarget ||
                                                      submitToEmail
                                                  )

                                                  setFieldValue(
                                                    'submitTo',
                                                    item.submitTo || submitTo
                                                  )

                                                  handleAlternative(
                                                    item.alternativeContent
                                                  )
                                                }}
                                                onBlur={handleBlur}
                                                checked={
                                                  values[grp.id] === item.value
                                                }
                                              />

                                              <span> {item.label}</span>
                                            </label>
                                          </div>
                                        )
                                      })}
                                    {grp.fields &&
                                      grp.fields.map((field, ii) => {
                                        const type =
                                          field.contentType === 'FormDropdown'
                                            ? 'select'
                                            : 'input'
                                        const label =
                                          field.placeholder || field.label

                                        if (field.fieldType == 'hidden') {
                                          return (
                                            <Field
                                              key={field.entryId || ii}
                                              type={field.fieldType}
                                              name={field.id}
                                              id={field.id}
                                              onBlur={handleBlur}
                                              value={field.defaultValue}
                                            />
                                          )
                                        }

                                        return (
                                          <div
                                            key={field.entryId || ii}
                                            className={b
                                              .e(
                                                `form-element  ${
                                                  values[field.id]
                                                    ? 'has-value'
                                                    : ''
                                                }`
                                              )
                                              .classes()}
                                          >
                                            <label
                                              htmlFor={field.id}
                                              className={b
                                                .e(`${type}-label`)
                                                .m({
                                                  focus: inFocus === field.id,
                                                  filled: !!values?.[field.id]
                                                })
                                                .classes()}
                                            >
                                              {renderInput(
                                                field,
                                                values,
                                                handleChange,
                                                handleBlur,
                                                label
                                              )}
                                              <span>{label}</span>

                                              {errors[field.id] &&
                                              touched[field.id] ? (
                                                <div
                                                  className={b
                                                    .e('error')
                                                    .classes()}
                                                >
                                                  {errors[field.id]}
                                                </div>
                                              ) : null}
                                            </label>
                                          </div>
                                        )
                                      })}

                                    {!grp.area &&
                                    errors[grp.id] &&
                                    touched[grp.id] ? (
                                      <div className={b.e('error').classes()}>
                                        {errors[grp.id]}
                                      </div>
                                    ) : null}
                                  </Card>
                                ))}
                            </fieldset>
                          )}
                        </Fragment>
                      )
                    })}

                  {alternative && (
                    <div className={b.e('center-block').classes()}>
                      <BasicIconBox
                        className={b.e('cancel-info').classes()}
                        {...alternative}
                        trackPage={trackPage}
                        trackModuleName={trackModuleName}
                        trackRadio={trackRadio}
                      />
                    </div>
                  )}
                  {!alternative && (
                    <div className={b.e('center-block').classes()}>
                      <Button
                        data-testId="submit"
                        type="submit"
                        disabled={disabled}
                        design="scotch-primary"
                        variant="light"
                        text="Submit request"
                        analyticsEvent="submitAction"
                        analyticsValue={`${trackPage}${trackModuleName}${trackRadio}submit-request`}
                        ariaLabel="Submit Support Request"
                        className={b.e('cta-button').classes()}
                        icon={isSubmitting ? { type: 'Link_StartOver' } : null}
                      />

                      {submitError ? (
                        <div className={b.e('submit-error').classes()}>
                          {submitError}
                        </div>
                      ) : null}
                    </div>
                  )}
                </Form>
              )
            }}
          </Formik>
        </div>
        {infoBox && !alternative && (
          <RichText
            className={b.e('info-box').classes()}
            isMarkdown
            markup={infoBox}
          />
        )}
      </section>
    </div>
  )
}

export default withCookies(RequestSupportForm)
