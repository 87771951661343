import React from 'react'

import Bem from 'react-bem-helper'

import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'

import SearchBar from '../OutageHero/SearchBar'
import OutageHero from '../OutageHero/OutageHero'
import OutageArticle, {
  OutageArticleModel
} from '../OutageArticle/OutageArticle'
import RichText from 'app/components/RichText/RichText'

import './OutageDetails.scss'

export class OutageDetailsModel extends Model {
  static props() {
    return {
      name: String,
      heroEyebrow: String,
      heroTitle: String,
      introText: String,
      outage: { type: OutageArticleModel.shape, required: true },
      supportSearchPage: { type: LinkModel.shape }
    }
  }
}

export default function OutageDetails({
  name,
  outage,
  heroEyebrow,
  heroTitle,
  introText,
  supportSearchPage
}) {
  const classes = new Bem({
    name: 'support-outage-details',
    prefix: ''
  })

  return (
    <section {...classes()}>
      <OutageHero
        eyebrow={heroEyebrow}
        title={`${heroTitle} (${outage.status})`}
        breadCrumbTitle={heroEyebrow}
        category="Service Outages"
        name={name}
      />
      <SearchBar supportSearchPage={supportSearchPage} />
      <div {...classes({ element: 'container' })}>
        {introText && (
          <div {...classes({ element: 'intro' })}>
            <RichText
              markup={introText}
              isMarkdown
              renderers={{
                root: 'p',
                paragraph: 'span'
              }}
            />
          </div>
        )}
        <OutageArticle isDetail {...outage} />
      </div>
    </section>
  )
}
