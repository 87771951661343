import React from 'react'

import Slick from 'react-slick'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { icons } from 'app/utils/constants'
import Arrow from 'app/components/Carousel/Arrow'
import GlobalAlertMessage, {
  GlobalAlertMessageModel
} from 'app/modules/shared/GlobalAlertMessage/GlobalAlertMessage'

import './GlobalAlertMessageGroup.scss'

export class GlobalAlertMessageGroupModel extends Model {
  static props() {
    return {
      name: String,
      alertMessages: GlobalAlertMessageModel.arrayOf,
      background: { type: String, default: 'red' }
    }
  }
}

const options = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  nextArrow: (
    <Arrow name="Next" icons={icons} onClick={() => console.log('next')} />
  ),
  prevArrow: (
    <Arrow name="Previous" icons={icons} onClick={() => console.log('prev')} />
  )
}

const GlobalAlertMessageGroup = props => {
  const b = Bem('global-alert-message-group')
  return (
    <div className={b.m({ [props.background]: true }).classes()}>
      <Slick className="global-alert-message-group-carousel" {...options}>
        {props.alertMessages.map(message => (
          <GlobalAlertMessage
            key={message.entryId}
            {...message}
            className="center"
          />
        ))}
      </Slick>
    </div>
  )
}
export default GlobalAlertMessageGroup
