import React from 'react'
import Model from 'app/models/Model'
import BasicIconBox from 'app/components/BasicIconBox/BasicIconBox'
import BasicTextBox from 'app/components/BasicTextBox/BasicTextBox'
import ImageTextBox from 'app/components/ImageTextBox/ImageTextBox'
import { IconModel } from 'app/components/Icon/Icon'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'
import ImageModel from 'app/models/Image/Image'
import LinkModel from 'app/models/Link/Link'

export class ContentBoxModel extends Model {
  static props() {
    return {
      boxType: String,
      eyebrow: String,
      title: String,
      titleWeight: String,
      description: String,
      featuredCopy: String,
      background: String,
      alignMobile: String,
      isFeatured: Boolean,
      icon: IconModel.shape,
      cta: LinkModel.shape,
      link: LinkModel.shape,
      image: ResponsiveImageModel.shape,
      logo: ImageModel.shape
    }
  }
}

export default function ContentBox({
  boxType,
  logo,
  image,
  isFeatured,
  featuredCopy,
  ...props
}) {
  if (boxType.match(/icon box/i)) {
    return <BasicIconBox {...props} />
  }
  if (boxType.match(/image text box/i)) {
    return (
      <ImageTextBox
        {...props}
        logo={logo}
        image={image}
        isFeatured={isFeatured}
        featuredCopy={featuredCopy}
      />
    )
  }
  if (boxType.match(/text box/i)) {
    return <BasicTextBox {...props} />
  }
  return null
}
