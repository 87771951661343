import React from 'react'

import Bem from 'app/utils/bem-helper'
import Icon from 'app/components/Icon/Icon'
import Link from 'app/components/Link/Link'
import LinkModel from 'app/models/Link/Link'
import { IconModel } from 'app/components/Icon/Icon'
import RichText from 'app/components/RichText/RichText'
import Model from 'app/models/Model'

import './GlobalNavPromo.scss'

export class GlobalNavPromoModel extends Model {
  static props() {
    return {
      background: String,
      align: String,
      bottom: Boolean,
      title: String,
      icon: IconModel.shape,
      description: String,
      cta: LinkModel.shape,
      image: Object
    }
  }
}

export default function GlobalNavPromo({
  background,
  align,
  bottom,
  title,
  icon = null,
  description,
  cta,
  image
}) {
  const b = Bem('global-nav-promo')
  return bottom ? (
    <div
      className={b.m({ bottom: bottom, [background]: background }).classes()}
    >
      <Link className={b.e('link').classes()} link={cta} useChildren>
        <h4 className={b.e('title').classes()}>
          {icon && (
            <span className={b.e('icon').classes()}>
              <Icon {...icon} replaceStrokeColor={false} />
            </span>
          )}
          <RichText markup={title} renderers={{ root: 'span' }} />
        </h4>
        {description && (
          <p className={b.e('description').classes()}>
            <RichText markup={description} renderers={{ root: 'span' }} />
          </p>
        )}
      </Link>
    </div>
  ) : (
    <div
      className={b
        .m({ bottom: bottom, [background]: background, [align]: align })
        .classes()}
    >
      {image && (
        <div className={b.e('image').classes()}>
          <img src={image.src} alt={image.description} />
        </div>
      )}
      <div className={b.e('content').classes()}>
        <h4
          className={b
            .e('title')
            .m({
              'no-icon': icon === null
            })
            .classes()}
        >
          {icon && (
            <span className={b.e('icon').classes()}>
              <Icon {...icon} replaceStrokeColor={false} />
            </span>
          )}

          <RichText markup={title} renderers={{ root: 'span' }} />
        </h4>
        {description && (
          <p className={b.e('description').classes()}>
            <RichText markup={description} renderers={{ root: 'span' }} />
          </p>
        )}
        {cta && <Link className={b.e('link').classes()} link={cta} />}
      </div>
    </div>
  )
}
