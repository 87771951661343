import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import ImageModel from 'app/models/Image/Image'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import { ImageTextBoxModel } from 'app/components/ImageTextBox/ImageTextBox'

import './CaseStudyArticlesList.scss'

// Module model definition
export class CaseStudyArticlesListModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      title: String,
      caseStudyArticles: { type: ImageTextBoxModel.arrayOf, required: true }
    }
  }
}

// Module definition
export default class CaseStudyArticlesList extends Component {
  constructor(props) {
    super(props, 'case-study-articles-list')
  }

  renderList() {
    const articles = this.props.caseStudyArticles || []

    if (articles.length === 0) {
      return <p>No articles have been added to the list.</p>
    }

    const titleSlug = this.props.title ? `${slug(this.props.title)}|` : ''
    const items = articles.map((item, idx) => {
      const btnModel = new LinkModel(item.cta)

      const button = {
        ...btnModel.asButton,
        analyticsEvent: 'navigationAction',
        analyticsValue: `${titleSlug}|${slug(item.title)}|${slug(
          btnModel.text
        )}`
      }
      const articleImage = new ImageModel(item.imageAsset)
      const logo = new ImageModel(item.logo)

      return (
        <div
          key={`article-${idx}`}
          className={`${this.e('study-article')} ${
            item.isFeatured && 'featured'
          }`}
        >
          <div
            className={`${this.e('study-body')} ${
              item.isFeatured && 'featured'
            }`}
          >
            <div className={`${this.e('study-img')}`}>
              <a
                href={btnModel.url}
                aria-label={btnModel.ariaLabel}
                data-event={button.analyticsEvent}
                data-value={button.analyticsValue}
              >
                {!item.isFeatured && (
                  <img className="logo" src={logo.src} alt={item.title} />
                )}
                <img className="img" src={articleImage.src} alt={item.title} />
              </a>
            </div>

            {item.isFeatured && (
              <div className={this.e('study-logo')}>
                <img src={logo.src} alt={item.title} />
              </div>
            )}
            <div className={this.e('study-title')}>
              <h3>{item.title}</h3>
            </div>
            <div className={this.e('study-category')}>
              <h5>{item.category}</h5>
            </div>

            {item.isFeatured && (
              <div className={this.e('study-desc')}>
                <RichText markup={item.description} />
              </div>
            )}
            <div className={this.e('study-cta')}>
              <Button {...button} />
            </div>
          </div>
        </div>
      )
    })

    return <div className={this.e('list')}>{items}</div>
  }

  render() {
    return (
      <section className={this.b()} aria-label={this.props.title}>
        {this.props.title && (
          <div className={this.e('title')}>
            <h2>{this.props.title}</h2>
          </div>
        )}
        <div className={this.e('wrapper')}>
          <div className={this.e('container')}>
            <div className={this.e('articles')}>{this.renderList()}</div>
          </div>
        </div>
      </section>
    )
  }
}

CaseStudyArticlesList.props(CaseStudyArticlesListModel)
