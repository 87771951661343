import React from 'react'

import { connect } from 'react-redux'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import { doSearch } from 'app/actions/search'
import Component from 'app/components/Component'
import { escapeHtml } from 'app/utils/dom-helpers'
import RichText from 'app/components/RichText/RichText'
import SearchBar, { SearchBarModel } from 'app/components/SearchBar/SearchBar'
import SearchPagination from 'app/components/SearchPagination/SearchPagination'
import SearchNoResults, {
  SearchNoResultsModel
} from 'app/components/SearchNoResults/SearchNoResults'

import './SearchResults.scss'

// Module model definition
export class SearchResultsModel extends Model {
  static props() {
    return {
      headline: String,
      noResults: SearchNoResultsModel.shape,
      searchBar: SearchBarModel.shape
    }
  }
}

// Module definition
class SearchResults extends Component {
  constructor(props) {
    super(props, 'search-results')

    this.state = {
      keyword: '',
      page: 1
    }

    this.search = this.search.bind(this)
    this.onKeywordChanged = this.onKeywordChanged.bind(this)
    this.onPageChanged = this.onPageChanged.bind(this)
    this.onPopState = this.onPopState.bind(this)
  }

  search(keyword, page = 1) {
    const pageSize = this.props.resultsPerPage || 10
    this.setState({ keyword, page })
    this.props.doSearch(keyword, page, pageSize)
    window.scrollTo(0, 0)
  }

  onKeywordChanged(keyword) {
    if (window.location) {
      const page = 1
      window.history.pushState(
        { page, keyword },
        '',
        `${window.location.origin}${window.location.pathname}?page=${page}&q=${keyword}`
      )
      this.search(keyword, page)
    }
  }

  onPageChanged(keyword, page) {
    if (window.location) {
      window.history.pushState(
        { page, keyword },
        '',
        `${window.location.origin}${window.location.pathname}?page=${page}&q=${keyword}`
      )
      this.search(keyword, page)
    }
  }

  onPopState(event) {
    if (event && event.state) {
      this.search(event.state.keyword, event.state.page)
    }
  }

  componentDidMount() {
    const { query } = this.props.request
    if (query && query.q) {
      this.search(this.props.request.query.q, this.props.request.query.page)
    }

    window.addEventListener('popstate', this.onPopState)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopState)
  }

  renderHeadline(headline, keyword) {
    const text = headline.replace(
      '${query}', // eslint-disable-line no-template-curly-in-string
      keyword
    )
    return <h1 className={this.e('headline')}>{text}</h1>
  }

  renderCounter(data) {
    const PAGE_SIZE = 10
    const { currentPage, totalResults } = data

    const firstItem = (currentPage - 1) * PAGE_SIZE + 1
    const lastItem =
      totalResults > firstItem + (PAGE_SIZE - 1)
        ? firstItem + (PAGE_SIZE - 1)
        : totalResults

    return (
      <p className={this.e('counter')}>
        {`${firstItem}-${lastItem} of ${totalResults}`}
      </p>
    )
  }

  renderResults(results) {
    return (
      <div className={this.e('results-container')}>
        <ul>
          {results.map(result => {
            return (
              <li className={this.e('result-item')} key={uuid()}>
                <h2 className={this.e('result-title')}>
                  <a
                    className={this.e('result-link')}
                    href={result.url}
                    data-event="resultAction"
                    data-value={`search-results|${slug(
                      this.props.searchData.keyword
                    )}|${slug(
                      result.title.replace(/<[^>]*[^0-9a-zA-Z\s]/gi, '')
                    )}`}
                    dangerouslySetInnerHTML={{ __html: result.title }}
                  />
                </h2>
                <p
                  className={this.e('result-description')}
                  dangerouslySetInnerHTML={{ __html: result.desc }}
                />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  prepareHeader(title, keyword) {
    const text = title.replace(
      '${query}', // eslint-disable-line no-template-curly-in-string
      keyword
    )

    return text
  }

  renderLoading(keyword) {
    const searchBar = this.props.searchBar
      ? new SearchBarModel(this.props.searchBar)
      : null
    return (
      <div className={this.b()}>
        {searchBar && (
          <SearchBar
            {...searchBar}
            searchTerm={keyword}
            onSearch={this.onKeywordChanged}
          />
        )}
        <div className={this.e('container')}>
          <div className={this.e('message')}>
            <RichText
              markup={this.prepareHeader(
                "<h3>Searching for '${query}' </h3>", // eslint-disable-line no-template-curly-in-string
                keyword
              )}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { headline, noResults, searchData, searchBar } = this.props
    const { loading, data } = searchData || {}
    const { results } = data !== null ? data : { results: [] }
    const { keyword } = this.state || {}
    const escapedKeyword = escapeHtml(keyword)

    if (loading) {
      return this.renderLoading(escapedKeyword)
    }

    if (results.length) {
      return (
        <div className={this.b()}>
          <SearchBar
            {...searchBar}
            searchTerm={keyword}
            onSearch={this.onKeywordChanged}
          />
          <div className={this.e('container')}>
            {this.renderHeadline(headline, escapedKeyword)}
            {this.renderCounter(data)}
            {this.renderResults(escapedKeyword)}
          </div>
          <SearchPagination
            searchData={searchData.data}
            onPageClick={p => {
              this.onPageChanged(keyword, p)
            }}
          />
        </div>
      )
    } else if (keyword) {
      return (
        <div>
          <div className={this.b()}>
            <SearchBar
              {...searchBar}
              searchTerm={keyword}
              onSearch={this.onKeywordChanged}
            />
          </div>
          <SearchNoResults searchTerm={escapedKeyword} {...noResults} />
        </div>
      )
    } else {
      return (
        <div className={this.b()}>
          <SearchBar
            {...searchBar}
            searchTerm={keyword}
            onSearch={this.onKeywordChanged}
          />
        </div>
      )
    }
  }
}

SearchResults.props(SearchResultsModel)

export default connect(
  state => {
    return {
      request: state.self,
      searchData: state.search
    }
  },
  { doSearch }
)(SearchResults)
