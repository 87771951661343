import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Link from 'app/components/Link/Link'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import { DisclaimerCta } from '@shaw/react-component-library'
import CollapsableCard from 'app/components/CollapsableCard/CollapsableCard'

import './ThemePack.scss'

// Module model definition
export class ThemePackModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        required: true
      },
      jumpLinkId: {
        type: String,
        required: false,
        default: ''
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: false
      },
      packagePrice: {
        type: String,
        required: false
      },
      priceDisclaimer: {
        type: String,
        required: false,
        default: '*'
      },
      layout: {
        type: String,
        required: false
      },
      collapsable: {
        type: Boolean,
        required: false
      },
      channels: {
        type: Object.shape,
        required: true
      },
      cta: {
        type: LinkModel.shape,
        requried: false
      },
      link: {
        type: LinkModel.shape,
        requried: false
      },
      theme: {
        type: String,
        default: 'scotch'
      }
    }
  }
}

const Wrap = ({ children }) => <div>{children}</div>

// Module definition
export default class ThemePacks extends Component {
  constructor(props) {
    super(props, 'theme-pack')

    this.state = {
      isOpen: props.inCollection ? true : false
    }

    this.renderMain = this.renderMain.bind(this)
    this.renderCollapsable = this.renderCollapsable.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  renderMain() {
    const {
      title,
      description,
      cta,
      link,
      packagePrice,
      priceDisclaimer,
      showIndividualButton,
      inContainer,
      layout
    } = this.props

    const tCta = new LinkModel(cta)
    const tLink = new LinkModel(link)
    const showCTA = cta && showIndividualButton && layout === 'Premium'
    return (
      <div className={this.e('info')}>
        <h3 className={this.e('theme-name')}>
          <RichText isMarkdown markup={title} renderers={{ root: 'span' }} />
          {packagePrice && !inContainer && (
            <span className={this.e('theme-price')}>
              {packagePrice}
              <DisclaimerCta className={this.e('theme-price-sup')}>
                {priceDisclaimer}
              </DisclaimerCta>
            </span>
          )}
        </h3>

        {description && (
          <RichText
            isMarkdown
            className={this.e('theme-description')}
            markup={description}
          />
        )}
        {link && <Link className={this.e('theme-link')} link={tLink} />}
        {showCTA && (
          <Button className={this.e('theme-cta')} {...tCta.asButton} />
        )}
      </div>
    )
  }

  renderCollapsable() {
    const { channels, jumpLinkId, cta, showIndividualButton, layout } =
      this.props
    const tCta = new LinkModel(cta)
    const showCTA = cta && showIndividualButton && layout !== 'Premium'
    return (
      <div id={jumpLinkId} className={this.e('pack')}>
        {channels?.map(channel => {
          return (
            <div key={slug(channel.value.name)} className={this.e('channel')}>
              <img src={channel.value.imageUrl} alt={channel.value.name} />
              <div className={this.e('description')}>
                <RichText
                  isMarkdown
                  className={this.e('channel-name')}
                  markup={channel.value.name}
                  renderers={{ root: 'h4' }}
                />

                {channel.value.description && (
                  <RichText
                    isMarkdown
                    className={this.e('channel-description')}
                    markup={channel.value.description}
                    renderers={{ root: 'p' }}
                  />
                )}
                {channel.value.price && (
                  <RichText
                    isMarkdown
                    className={this.e('channel-price')}
                    markup={channel.value.price}
                    renderers={{ root: 'p' }}
                  />
                )}
              </div>
            </div>
          )
        })}
        {showCTA && <Button design="secondary" {...tCta.asButton} />}
      </div>
    )
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { layout, collapsable, inContainer, theme = 'scotch' } = this.props
    const { isOpen } = this.state
    const Container = collapsable ? CollapsableCard : Wrap
    const containerProps = collapsable
      ? {
          theme,
          isOpen: isOpen,
          toggleCard: this.toggle,
          className: this.e('collapsable-box', isOpen ? 'open' : ''),
          renderMain: () => this.renderMain(),
          renderCollapsable: () => this.renderCollapsable()
        }
      : {}

    return (
      <div className={this.e(`wrapper ${inContainer ? 'child' : ''} ${theme}`)}>
        <Container {...containerProps}>
          <div className={this.b(layout && layout.toLowerCase())}>
            {this.renderMain()}
            {this.renderCollapsable()}
          </div>
        </Container>
      </div>
    )
  }
}
