import React from 'react'

import Bem from 'react-bem-helper'

import Model from 'app/models/Model'
import { FourUpPromo } from '@shaw/react-component-library'

import './FourUpPromo.scss'
export class FourUpPromoModel extends Model {
  static props() {
    return {
      name: { type: String, required: true },
      noMargin: Boolean,
      items: Array
    }
  }
}

export default function FourUpPromoComponent(props) {
  const classes = new Bem({
    name: 'four-up-promo-container',
    prefix: 'c-'
  })
  return (
    <div
      {...classes({
        modifier: { 'no-margin': props.noMargin, scotch: true }
      })}
    >
      <FourUpPromo {...props} theme="scotch" />
    </div>
  )
}
