import { ErrorMessage, Field } from 'formik'
import React, { useState, useEffect } from 'react'
import MaskedInput from 'react-text-mask'
import DatePicker from 'react-date-picker/dist/entry.nostyle'
import Bem from 'app/utils/bem-helper'
import { masks } from '../logic'

export default function ContactInfo({
  onBlur,
  onChange,
  values,
  setFieldValue
}) {
  const [inFocus, setInFocus] = useState('')
  const [filled, setFilled] = useState([])
  useEffect(() => {
    const body = document.querySelector('body')
    body.addEventListener('click', setInFocus(''))
    return () => {
      body.removeEventListener('click', setInFocus(''))
    }
  }, [])

  function handleFocus({ target }) {
    setInFocus(target.name)
  }
  function handleBlur(e) {
    let newFilled

    if (e.target.value) {
      newFilled = [...filled, e.target.name]
    } else {
      newFilled = filled.filter(f => f !== e.target.name)
    }
    setInFocus('')
    setFilled(newFilled)
    onBlur(e)
  }
  const b = Bem('contact-form')
  const showAccount = values.supportType.match(
    /Account Management|Technical Support|Cancel Services/i
  )
  const butHideAccount =
    values.supportType.match(/Technical Support/i) &&
    values.businessType.match(
      /Hotels|Business Satellite|Partner Channel|Enterprise/i
    )
  const showCancelOptions = values.supportType.match(/Cancel Services/i)
  const showRequestType =
    showAccount && values.supportType.match(/Technical Support/i) === null

  return (
    <>
      <label
        className={b
          .e('input-label')
          .m({
            focus: inFocus === 'firstName',
            filled: !!values.firstName
          })
          .classes()}
        htmlFor="firstName"
      >
        <Field
          type="text"
          name="firstName"
          id="firstName"
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={b.e('input').classes()}
        />
        <span>First Name</span>
        <ErrorMessage
          component="div"
          className={b.e('error').m({ single: true }).classes()}
          name="firstName"
        />
      </label>
      <label
        className={b
          .e('input-label')
          .m({
            focus: inFocus === 'lastName',
            filled: !!values.lastName
          })
          .classes()}
        htmlFor="lastName"
      >
        <Field
          type="text"
          name="lastName"
          id="lastName"
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={b.e('input').classes()}
        />
        <span>Last Name</span>
        <ErrorMessage
          component="div"
          className={b.e('error').m({ single: true }).classes()}
          name="lastName"
        />
      </label>
      <label
        className={b
          .e('input-label')
          .m({
            focus: inFocus === 'phoneNum',
            filled: !!values.phoneNum
          })
          .classes()}
        htmlFor="phoneNum"
      >
        <MaskedInput
          type="tel"
          name="phoneNum"
          id="phoneNum"
          onFocus={handleFocus}
          onChange={onChange}
          onBlur={handleBlur}
          value={values.phoneNum}
          mask={masks.phoneMask}
          className={b.e('input').m({ phone: true }).classes()}
        />
        <span>Contact Phone</span>
        <ErrorMessage
          component="div"
          className={b.e('error').m({ single: true }).classes()}
          name="phoneNum"
        />
      </label>
      <label
        className={b
          .e('input-label')
          .m({
            focus: inFocus === 'emailAddy',
            filled: !!values.emailAddy
          })
          .classes()}
        htmlFor="emailAddy"
      >
        <Field
          type="email"
          name="emailAddy"
          id="emailAddy"
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={b.e('input').classes()}
        />
        <span>Contact email</span>
        <ErrorMessage
          component="div"
          className={b.e('error').m({ single: true }).classes()}
          name="emailAddy"
        />
      </label>
      <label
        className={b
          .e('input-label')
          .m({
            focus: inFocus === 'companyName',
            filled: !!values.companyName
          })
          .classes()}
        htmlFor="companyName"
      >
        <Field
          type="text"
          name="companyName"
          id="companyName"
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={b.e('input').classes()}
        />
        <span>Company</span>
        <ErrorMessage
          component="div"
          className={b.e('error').m({ single: true }).classes()}
          name="companyName"
        />
      </label>
      {showAccount && !butHideAccount ? (
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'shawAccountNumber',
              filled: !!values.shawAccountNumber
            })
            .classes()}
          htmlFor="shawAccountNumber"
        >
          <Field
            type="text"
            name="shawAccountNumber"
            id="shawAccountNumber"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={b.e('input').classes()}
          />
          <span>Account Number</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="shawAccountNumber"
          />
        </label>
      ) : (
        <div />
      )}
      <label
        className={b
          .e('input-label')
          .m({ focus: inFocus === 'city', filled: !!values.city })
          .classes()}
        htmlFor="city"
      >
        <Field
          type="text"
          name="city"
          id="city"
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={b.e('input').classes()}
        />
        <span>City</span>
        <ErrorMessage
          component="div"
          className={b.e('error').m({ single: true }).classes()}
          name="city"
        />
      </label>
      <label
        className={b
          .e('select-label')
          .m({
            focus: inFocus === 'province',
            filled: !!values.province
          })
          .classes()}
        htmlFor="province"
      >
        <Field
          component="select"
          name="province"
          id="province"
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <option value="">Province</option>
          <option value="AB">Alberta</option>
          <option value="BC">British Columbia</option>
          <option value="NL">Newfoundland and Labrador</option>
          <option value="PE">Prince Edward Island</option>
          <option value="NS">Nova Scotia</option>
          <option value="NB">New Brunswick</option>
          <option value="QC">Quebec</option>
          <option value="ON">Ontario</option>
          <option value="MB">Manitoba</option>
          <option value="SK">Saskatchewan</option>
          <option value="YT">Yukon</option>
          <option value="NT">Northwest Territories</option>
          <option value="NU">Nunavut</option>
        </Field>
        <ErrorMessage
          component="div"
          className={b.e('error').m({ single: true }).classes()}
          name="province"
        />
      </label>
      {showRequestType && !showCancelOptions && (
        <label
          className={b
            .e('select-label')
            .m({
              focus: inFocus === 'requestType',
              filled: !!values.requestType
            })
            .classes()}
          htmlFor="requestType"
        >
          <Field
            component="select"
            name="requestType"
            id="requestType"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <option value="">Request Type</option>
            <option value="Billing Inquiries">Billing Inquiries</option>
            <option value="Account Management">Account Management</option>
            <option value="Move Request">Move Request</option>
            <option value="Add or Change Requests">
              Add or Change Requests
            </option>
            {values.businessType.match(/Small and Medium Business/i) ===
              null && <option value="Disconnects">Disconnects</option>}
          </Field>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="requestType"
          />
        </label>
      )}
      {showRequestType && !showCancelOptions && <div />}
      {values.requestType && values.requestType.match(/move request/i) && (
        <div className={b.e('move-box').classes()}>
          {values.businessType.match(/Small and Medium Business/i) === null && (
            <label
              className={b
                .e('input-label')
                .m({
                  focus: inFocus === 'moveDate',
                  filled: !!values.moveDate,
                  date: true
                })
                .classes()}
              htmlFor="moveDate"
            >
              <Field
                component={() => (
                  <DatePicker
                    format="yyyy-MM-dd"
                    value={values.moveDate ? new Date(values.moveDate) : null}
                    onChange={value => {
                      const date =
                        value === null ? '' : value && value.toDateString()
                      setFieldValue('moveDate', date)
                    }}
                    minDate={new Date('2022-01-01')}
                  />
                )}
                name="moveDate"
                id="moveDate"
                onFocus={handleFocus}
                onChange={onChange}
                onBlur={handleBlur}
                className={b.e('input').classes()}
              />
              <span>Requested move date (yyyy-mm-dd)</span>
              <ErrorMessage
                component="div"
                className={b.e('error').m({ single: true }).classes()}
                name="moveDate"
              />
            </label>
          )}
          <label
            className={b
              .e('input-label')
              .m({
                focus: inFocus === 'currentAddress',
                filled: !!values.currentAddress
              })
              .classes()}
            htmlFor="currentAddress"
          >
            <Field
              type="text"
              name="currentAddress"
              id="currentAddress"
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={b.e('input').classes()}
            />
            <span>Current Address</span>

            <ErrorMessage
              component="div"
              className={b.e('error').m({ single: true }).classes()}
              name="currentAddress"
            />
          </label>
          <label
            className={b
              .e('input-label')
              .m({
                focus: inFocus === 'newAddress',
                filled: !!values.newAddress
              })
              .classes()}
            htmlFor="newAddress"
          >
            <Field
              type="text"
              name="newAddress"
              id="newAddress"
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={b.e('input').classes()}
            />
            <span>New address with postal code</span>

            <ErrorMessage
              component="div"
              className={b.e('error').m({ single: true }).classes()}
              name="newAddress"
            />
          </label>
          <label
            className={b
              .e('input-label')
              .m({
                focus: inFocus === 'newServices',
                filled: !!values.newServices
              })
              .classes()}
            htmlFor="newServices"
          >
            <Field
              type="text"
              name="newServices"
              id="newServices"
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={b.e('input').classes()}
            />
            <span>Which service and/or account # are you moving?</span>
            <ErrorMessage
              component="div"
              className={b.e('error').m({ single: true }).classes()}
              name="newServices"
            />
          </label>
        </div>
      )}
      {showCancelOptions && (
        <label
          className={b
            .e('select-label')
            .m({
              focus: inFocus === 'cancellationOption',
              filled: !!values.cancellationOption
            })
            .classes()}
          htmlFor="cancellationOption"
        >
          <Field
            component="select"
            name="cancellationOption"
            id="cancellationOption"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <option value="">Cancellation Options</option>
            <option value="Business Closing">Business Closing</option>
            <option value="Pricing">Pricing</option>
            <option value="Capabilities">Capabilities</option>
            <option value="Customer Experience">Customer Experience</option>
            <option value="Does Not Use">Does Not Use</option>
            <option value="Moving">Moving</option>
          </Field>

          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="cancellationOption"
          />
        </label>
      )}

      <label
        className={b
          .e('input-label')
          .m({
            focus: inFocus === 'details',
            filled: !!values.details,
            textarea: true
          })
          .classes()}
        htmlFor="details"
      >
        <Field
          component="textarea"
          name="details"
          id="details"
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={b.e('input').classes()}
          placeholder="Please describe the details of your request"
        />
        <span>Please describe the details of your request</span>
        <ErrorMessage
          component="div"
          className={b.e('error').m({ single: true }).classes()}
          name="details"
        />
      </label>
      <div className={b.e('checkboxes').classes()}>
        {values.supportType.match(/sales/i) && (
          <label
            className={b.e('checkbox-label').m({ single: true }).classes()}
            htmlFor="onSiteConsult"
          >
            <Field
              type="checkbox"
              name="extra"
              value="On site consultation"
              id="onSiteConsult"
            />
            <span>I prefer an on-site sales consultation</span>
          </label>
        )}
        {values.supportType.match(/account management|cancel services/i) ===
          null && (
          <label
            className={b.e('checkbox-label').m({ single: true }).classes()}
            htmlFor="existingCust"
          >
            <Field
              type="checkbox"
              name="extra"
              value="Existing customer"
              id="existingCust"
            />
            <span>I&lsquo;m an existing Rogers Business customer</span>
          </label>
        )}
      </div>
    </>
  )
}
