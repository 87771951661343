import React, { useState } from 'react'
import { ErrorMessage, Field } from 'formik'
import Bem from 'app/utils/bem-helper'
import MaskedInput from 'react-text-mask'
import RichText from 'app/components/RichText/RichText'
import { masks } from '../../ContactForm/logic'

export default function ContactInfo({
  title,
  description,
  onBlur,
  onChange,
  values
}) {
  const [inFocus, setInFocus] = useState('')
  const [filled, setFilled] = useState([])

  function handleFocus(e) {
    setInFocus(e.target.name)
  }
  function handleBlur(e) {
    let newFilled

    if (e.target.value) {
      newFilled = [...filled, e.target.name]
    } else {
      newFilled = filled.filter(f => f !== e.target.name)
    }
    setFilled(newFilled)
    setInFocus('')
    onBlur(e)
  }
  const b = Bem('mdu-form')
  return (
    <div className={b.e('section').classes()}>
      <h2 className={b.e('section-title').classes()}>{title}</h2>
      <RichText
        className={b.e('description').classes()}
        isMarkdown
        markup={description}
      />
      <div
        className={b
          .e('section-container')
          .m({
            'mobile-1': true
          })
          .classes()}
      >
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'firstName',
              filled: !!values.firstName
            })
            .classes()}
          htmlFor="firstName"
        >
          <Field
            type="text"
            name="firstName"
            id="firstName"
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={b.e('input').classes()}
          />
          <span>First name*</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="firstName"
          />
        </label>
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'lastName',
              filled: !!values.lastName
            })
            .classes()}
          htmlFor="lastName"
        >
          <Field
            type="text"
            name="lastName"
            id="lastName"
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={b.e('input').classes()}
          />
          <span>Last name*</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="lastName"
          />
        </label>
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'phoneNum',
              filled: !!values.phoneNum
            })
            .classes()}
          htmlFor="phoneNum"
        >
          <MaskedInput
            type="tel"
            name="phoneNum"
            id="phoneNum"
            onFocus={handleFocus}
            onChange={onChange}
            onBlur={handleBlur}
            value={values.phoneNum}
            mask={masks.phoneMask}
            className={b.e('input').m({ phone: true }).classes()}
          />
          <span>Contact phone*</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="phoneNum"
          />
        </label>
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'emailAddy',
              filled: !!values.emailAddy
            })
            .classes()}
          htmlFor="emailAddy"
        >
          <Field
            type="email"
            name="emailAddy"
            id="emailAddy"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={b.e('input').classes()}
          />
          <span>Contact email*</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="emailAddy"
          />
        </label>
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'companyName',
              filled: !!values.companyName
            })
            .classes()}
          htmlFor="companyName"
        >
          <Field
            type="text"
            name="companyName"
            id="companyName"
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            className={b.e('input').classes()}
          />
          <span>Company name*</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="companyName"
          />
        </label>
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'position',
              filled: !!values.position
            })
            .classes()}
          htmlFor="position"
        >
          <Field
            type="text"
            name="position"
            id="position"
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            className={b.e('input').classes()}
          />
          <span>Position*</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="position"
          />
        </label>
        <label
          className={b
            .e('input-label')
            .m({
              focus: inFocus === 'comments',
              filled: !!values.comments,
              textarea: true
            })
            .classes()}
          htmlFor="comments"
        >
          <Field
            component="textarea"
            name="comments"
            id="comments"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={b.e('input').classes()}
            placeholder="Additional comments"
          />
          <span>Additional comments</span>
          <ErrorMessage
            component="div"
            className={b.e('error').m({ single: true }).classes()}
            name="comments"
          />
        </label>
      </div>
      <div className={b.e('center').classes()}>
        <label
          className={b.e('checkbox-label').m({ single: true }).classes()}
          htmlFor="emailoptin"
        >
          <Field
            type="checkbox"
            name="emailoptin"
            value="Yes"
            id="emailoptin"
          />
          <span>
            Yes, please email me special offers on the latest technologies and
            products from Rogers Business (I can opt out any time)
          </span>
        </label>
      </div>
    </div>
  )
}
