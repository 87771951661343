import * as Yup from 'yup'
import axios from 'axios'

const businessTypeFieldsDefaults = [
  {
    value: 'Small and Medium Business',
    id: 'smb',
    label: 'Small and Medium Business',
    description:
      'Small to medium businesses with few locations, up to 100 employees, with simple connectivity needs.',
    excludeFrom: []
  },
  {
    value: 'Enterprise/Franchise',
    id: 'enterprise',
    label: 'Enterprise/Franchise',
    description:
      'Medium to large businesses, or businesses with multiple locations, and more complex connectivity needs.',
    excludeFrom: []
  },
  {
    value: 'Hotels',
    id: 'hotels',
    label: 'Hotels',
    description: 'Hotel and motel customers.',
    excludeFrom: []
  },
  {
    value: 'Workforce Housing',
    id: 'workforce',
    label: 'Workforce Housing',
    description: 'Remote work locations.',
    excludeFrom: ['Sales']
  },
  {
    value: 'Community Living',
    id: 'community1',
    label: 'Community Living',
    description: 'For EMPLOYEES of Seniors Living Communities.',
    excludeFrom: ['Sales']
  },
  {
    value: 'Community Living',
    id: 'community2',
    label: 'Community Living',
    description: 'For EMPLOYEES of Seniors Living Communities.',
    excludeFrom: ['Sales']
  },
  {
    value: 'Business Satellite',
    id: 'businessSatellite',
    label: 'Business Satellite',
    description: 'Any customer using Shaw Direct satellite services.',
    excludeFrom: ['Sales']
  },
  {
    value: 'Wholesale',
    id: 'wholesale',
    label: 'Wholesale',
    description: 'Third party wholesalers of Shaw services.',
    excludeFrom: ['Sales']
  },
  {
    value: 'Partner Channel',
    id: 'parnerChannel',
    label: 'Partner Channel',
    description: 'Shaw branded services provided by a third party.',
    excludeFrom: ['Sales']
  },
  {
    value: 'Public Sector',
    id: 'publicSector',
    label: 'Public Sector',
    description:
      'Public sector institutions that are controlled / funded / administered by government or a branch of government.',
    excludeFrom: ['Sales']
  }
]

const phoneRegExp =
  /^([(]{0,1}?[\d]{3}[)\s]{0,2}?[\d]{3}[-]{0,1}[\d]{4})([\s]{0,1}[a-z]{1}[\s]{0,1}[\d]{2,4})?$/

/*
  /^((?!-)(?!.*--)(?!.*-$)[\d-+]{1,10}|(\d{3}(-)?\d{4}(-)?\d{4}))$/
  */
const startEndRegexp = /^(?!\D.*)(?!.*\D$).*/
const doubleDashRegexp = /^[0-9]+(-[0-9]+)*$/

const shortAccountRegExp = /^([\d]{4,5})$/
const accountRegExp =
  /^((\d{10})|(\d{2}(-)?\d{4}(-)?\d{4})|(\d{3}(-)?\d{4}(-)?\d{4}))$/

Yup.addMethod(Yup.string, 'startend', function startEnd(message) {
  return this.matches(startEndRegexp, {
    message,
    name: 'startend',
    excludeEmptyString: false
  })
})
Yup.addMethod(Yup.string, 'no2dash', function no2Dash(message) {
  return this.matches(doubleDashRegexp, {
    message,
    name: 'no2dash',
    excludeEmptyString: false
  })
})
Yup.addMethod(Yup.string, 'account', function validateAccount(message) {
  return this.test({
    name: 'account',
    message: props => {
      const value = props && props.value
      const cleanValue = value && value.replace(/\D/g, '')
      if (!cleanValue) {
        return null
      }
      if (
        (cleanValue.length >= 4 || cleanValue.length <= 5) &&
        cleanValue.length < 10
      ) {
        if (value.includes('-')) {
          return 'Enter only digits please.'
        }
      }
      if (
        cleanValue.length < 10 &&
        (cleanValue.length < 4 || cleanValue.length > 5)
      ) {
        return 'Account has either 4, 5, 10 or 11 digits'
      } else if (cleanValue.length === 11) {
        const first = Number(cleanValue.charAt(0))
        if (first !== 0) {
          return 'Account is 11 digits long and starts with a 0'
        }
      } else if (cleanValue.length > 11) {
        return message
      }
    },
    test: value => {
      const cleanValue = value && value.replace(/\D/g, '')
      if (!cleanValue) {
        return false
      } else if (cleanValue.length >= 4 || cleanValue.length <= 5) {
        return value.match(shortAccountRegExp)
      } else {
        return value.match(accountRegExp)
      }
    }
  })
})

const nameRegExp = /^[a-zA-Z0-9]+([-_\s]{1}[a-zA-Z0-9]+)*$/

const supportTypeValidation = Yup.object().shape({
  supportType: Yup.string().required('Please choose an option.')
})
const businessTypeValidation = Yup.object().shape({
  businessType: Yup.string().required('Please choose an option.')
})
const solutionsValidation = Yup.object().shape({
  productInterest: Yup.array().when('supportType', {
    is: value =>
      value && value.match(/technical support|account management/i) === null,
    then: Yup.array().test({
      name: 'productInterest-test',
      exclusive: true,
      message: 'Select at least one option',
      test: value => value.length > 0
    })
  }),
  firstName: Yup.string().when('supportType', {
    is: value =>
      value && value.match(/technical support|account management/i) !== null,
    then: Yup.string()
      .required('Must provide a first name.')
      .matches(nameRegExp, 'Only alphanumeric characters, please.')
      .notOneOf(
        [Yup.ref('lastName'), null],
        'First and last name cannot be the same.'
      ),
    otherwise: Yup.string()
  }),
  lastName: Yup.string().when('supportType', {
    is: value =>
      value && value.match(/technical support|account management/i) !== null,
    then: Yup.string()
      .required('Must provide a last name.')
      .matches(nameRegExp, 'Only alphanumeric characters, please.')
      .notOneOf(
        [Yup.ref('firstName'), null],
        'First and last name cannot be the same.'
      ),
    otherwise: Yup.string()
  }),
  phoneNum: Yup.string().when('supportType', {
    is: value =>
      value && value.match(/technical support|account management/i) !== null,
    then: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Must provide a 10-digit phone number.'),
    otherwise: Yup.string()
  }),
  emailAddy: Yup.string().when('supportType', {
    is: value =>
      value && value.match(/technical support|account management/i) !== null,
    then: Yup.string()
      .email('Email seems invalid.')
      .required('Must provide a valid email address.'),
    otherwise: Yup.string()
  }),
  city: Yup.string().when('supportType', {
    is: value =>
      value && value.match(/technical support|account management/i) !== null,
    then: Yup.string().required('Must provide a city name.'),
    otherwise: Yup.string()
  }),
  province: Yup.string().when('supportType', {
    is: value =>
      value && value.match(/technical support|account management/i) !== null,
    then: Yup.string().required('Must select a province.'),
    otherwise: Yup.string()
  }),
  companyName: Yup.string().when('supportType', {
    is: value =>
      value && value.match(/technical support|account management/i) !== null,
    then: Yup.string()
      .required('Must provide a company name.')
      .matches(nameRegExp, 'Only alphanumeric characters, please.'),
    otherwise: Yup.string()
  }),
  shawAccountNumber: Yup.string()
    .when('supportType', {
      is: value => value && value.match(/account management/i) !== null,
      then: Yup.string().required('Must provide an account number.'),
      otherwise: Yup.string()
    })
    .startend('Must start and end in number.')
    .no2dash('Use single dash to format number.')
    .account('Your account number have either 4, 5, 10 or 11 digits.'),

  details: Yup.string().when('supportType', {
    is: value =>
      value && value.match(/technical support|account management/i) !== null,
    then: Yup.string().required('Must provide some comments.'),
    otherwise: Yup.string()
  }),
  requestType: Yup.string().when('supportType', {
    is: value => value && value.match(/account management/i) !== null,
    then: Yup.string().required('Must select an option.'),
    otherwise: Yup.string()
  }),
  moveDate: Yup.string().when(['supportType', 'businessType', 'requestType'], {
    is: (supportType, businessType, requestType) =>
      supportType.match(/technical support|account management/i) !== null &&
      businessType.match(/Small and Medium Business/i) === null &&
      requestType &&
      requestType.match(/move request/i) !== null,
    then: Yup.string().required('Must enter date.'),
    otherwise: Yup.string().typeError('Must enter date.')
  }),
  currentAddress: Yup.string().when(['supportType', 'requestType'], {
    is: (supportType, requestType) =>
      supportType.match(/technical support|account management/i) !== null &&
      requestType &&
      requestType.match(/move request/i) !== null,
    then: Yup.string().required('Must enter address.'),
    otherwise: Yup.string()
  }),
  newAddress: Yup.string().when(['supportType', 'requestType'], {
    is: (supportType, requestType) =>
      supportType.match(/technical support|account management/i) !== null &&
      requestType &&
      requestType.match(/move request/i) !== null,
    then: Yup.string().required('Must enter new address.'),
    otherwise: Yup.string()
  }),
  newServices: Yup.string().when(['supportType', 'requestType'], {
    is: (supportType, requestType) =>
      supportType.match(/technical support|account management/i) !== null &&
      requestType &&
      requestType.match(/move request/i) !== null,
    then: Yup.string().required('Please fill out the field.'),
    otherwise: Yup.string()
  })
})
const businessSizeValidation = Yup.object().shape({
  employeesCount: Yup.string().required('Please choose an option.')
})

const contactInfoValidation = Yup.object().shape({
  firstName: Yup.string()
    .required('Must provide a first name.')
    .matches(nameRegExp, 'Only alphanumeric characters, please.')
    .notOneOf(
      [Yup.ref('lastName'), null],
      'First and last name cannot be the same.'
    ),
  lastName: Yup.string()
    .required('Must provide a last name.')
    .matches(nameRegExp, 'Only alphanumeric characters, please.')
    .notOneOf(
      [Yup.ref('firstName'), null],
      'First and last name cannot be the same.'
    ),
  phoneNum: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Must provide a 10-digit phone number.'),
  emailAddy: Yup.string()
    .email('Email seems invalid.')
    .required('Must provide a valid email address.'),
  city: Yup.string().required('Must provide a city name.'),
  province: Yup.string().required('Must select a province.'),
  companyName: Yup.string()
    .required('Must provide a company name.')
    .matches(nameRegExp, 'Only alphanumeric characters, please.'),
  shawAccountNumber: Yup.string()
    .when('supportType', {
      is: support =>
        support &&
        support.match(/account management|cancel services/i) !== null,
      then: Yup.string().required('Must provide an account number.'),
      otherwise: Yup.string()
    })
    .startend('Must start and end in number.')
    .no2dash('Use single dash to format number.')
    .account('Your account number have either 4, 5, 10 or 11 digits.'),

  details: Yup.string().required('Must provide some comments.'),
  cancellationOption: Yup.string().when('supportType', {
    is: support => support && support.match(/cancel services/i) !== null,
    then: Yup.string().required('Must select an option.'),
    otherwise: Yup.string()
  }),
  requestType: Yup.string().when('supportType', {
    is: support => support && support.match(/account management/i) !== null,
    then: Yup.string().required('Must select an option.'),
    otherwise: Yup.string()
  }),
  moveDate: Yup.string().when(['supportType', 'businessType', 'requestType'], {
    is: (supportType, businessType, requestType) =>
      supportType.match(/technical support|account management/i) !== null &&
      businessType.match(/Small and Medium Business/i) === null &&
      requestType &&
      requestType.match(/move request/i) !== null,
    then: Yup.string().required('Must enter date.'),
    otherwise: Yup.string().typeError('Must enter date.')
  }),
  currentAddress: Yup.string().when('requestType', {
    is: requestType =>
      requestType && requestType.match(/move request/i) !== null,
    then: Yup.string().required('Must enter address.'),
    otherwise: Yup.string()
  }),
  newAddress: Yup.string().when('requestType', {
    is: requestType =>
      requestType && requestType.match(/move request/i) !== null,
    then: Yup.string().required('Must enter new address.'),
    otherwise: Yup.string()
  }),
  newServices: Yup.string().when('requestType', {
    is: requestType =>
      requestType && requestType.match(/move request/i) !== null,
    then: Yup.string().required('Please fill out the field.'),
    otherwise: Yup.string()
  })
})

const salesValidationSchema = [
  supportTypeValidation,
  businessTypeValidation,
  solutionsValidation,
  businessSizeValidation,
  contactInfoValidation
]
const validationSchema = [
  supportTypeValidation,
  businessTypeValidation,
  contactInfoValidation
]

const submissionMap = {
  Sales: { phone: {}, email: {} },
  'Technical Support': {
    phone: {
      'Small and Medium Business': {
        status: 'support',
        value: '1-877-742-9249',
        title: 'Technical Support'
      },
      'Enterprise/Franchise': {
        status: 'support',
        value: '1-877-742-9249',
        title: 'Technical Support'
      },
      Hotels: {
        status: 'support',
        value: '1-877-742-9249',
        title: 'Technical Support'
      },
      'Workforce Housing': {
        status: 'support',
        value: '1-877-742-9249',
        title: 'Technical Support'
      },
      'Community Living - Residents': {
        status: 'support',
        value: '1-800-861-0754',
        title: 'Technical Support'
      },
      'Community Living - Employees': {
        status: 'support',
        value: '1-800-861-0754',
        title: 'Technical Support'
      },
      'Business Satellite': {
        status: 'support',
        value: '1-877-742-9249',
        title: 'Technical Support'
      },
      Wholesale: {
        status: 'support',
        value: '1-877-742-9249',
        title: 'Technical Support'
      },
      'Partner Channel': {
        status: 'support',
        value: '1-844-620-7429',
        title: 'Technical Support'
      },
      'Public Sector': {
        status: 'support',
        value: '1-877-742-9249',
        title: 'Technical Support'
      }
    },
    email: {
      // 'Workforce Housing': 'ShawBusiness-WFHSupport@sjrb.ca',
      // 'Community Living - Residents': 'ShawBusiness-CommunityLiving@sjrb.ca',
      // 'Community Living - Employees': 'ShawBusiness-CommunityLiving@sjrb.ca',
      // Wholesale: 'ShawBusiness-WholesaleSupport@sjrb.ca',
      // 'Public Sector': 'ShawBusiness-EnterpriseSupport@sjrb.ca'
    }
  },
  'Account Management': {
    email: {
      'Small and Medium Business': 'inquiries@shawbusiness.ca',
      'Enterprise/Franchise': 'ShawBusiness-EnterpriseSupport@sjrb.ca',
      Hotels: 'ShawBusiness-HospitalitySupport@sjrb.ca',
      'Workforce Housing': 'ShawBusiness-WFHSupport@sjrb.ca',
      'Community Living - Employees': 'ShawBusiness-CommunityLiving@sjrb.ca',
      'Business Satellite': 'ShawBusiness-SatelliteSupport@sjrb.ca',
      Wholesale: 'ShawBusiness-WholesaleSupport@sjrb.ca',
      'Partner Channel': 'ShawBusiness-PartnerSupport@sjrb.ca',
      'Public Sector': 'ShawBusiness-EnterpriseSupport@sjrb.ca'
    },
    phone: {
      Disconnects: {
        status: 'account',
        value: '1-888-627-8608',
        title: 'Cancel Services'
      },
      'Community Living - Residents': {
        status: 'account',
        value: '1-888-472-2222',
        title: 'Account Management'
      }
    }
  },
  'Cancel Services': {
    phone: {
      'Small and Medium Business': {
        status: 'cancel',
        value: '1-877-742-9249',
        title: 'Cancel Services'
      },
      'Community Living - Residents': {
        status: 'cancel',
        value: '1-888-472-2222',
        title: 'Cancel Services'
      }
    },
    email: { default: 'cancellations@shawbusiness.ca' }
  }
}

async function submitFormToEmail(values, submitToEmail) {
  return await axios.post('/lead-form-submit', {
    ...values,
    submitToEmail,
    noFormat: true,
    replyToEmail: 'emailAddy',
    formSubmitTo: 'EMAIL',
    formId: 'Contact-Form'
  })
}
async function submitFormToSF(values, options = {}) {
  return await axios.post('/lead-form-submit', {
    ...values,
    formSubmitTo: 'SALESFORCE',
    formId: 'contactForm',
    contentType: 'ContactForm',
    options
  })
}
async function submitToSales(values) {
  return await submitFormToSF({
    ...values,
    shawAccountNumber: values.shawAccountNumber.replace(/-/g, ''),
    formType: 'sales'
  })
}

async function submitToTechSupport(values) {
  if (submissionMap['Technical Support'].email[values.businessType]) {
    return await submitFormToEmail(
      values,
      submissionMap['Technical Support'].email[values.businessType]
    )
  } else {
    return await submitFormToSF({
      ...values,
      shawAccountNumber: values.shawAccountNumber.replace(/-/g, ''),
      helpWith: 'support'
    })
  }
}

async function submitToManageAccount(values) {
  let ownerId = null
  if (values.businessType.match(/small and medium business/i)) {
    ownerId = 'SMB_Support'
  }
  if (values.businessType.match(/enterprise\/franchise/i)) {
    ownerId = 'Enterprise_Support'
  }
  if (values.businessType.match(/hotels/i)) {
    ownerId = 'Hospitality_Support'
  }
  if (values.businessType.match(/workforce housing/i)) {
    ownerId = 'Workforce_Housing_Support'
  }
  if (values.businessType.match(/community living - employees/i)) {
    ownerId = 'Community_Living_Support'
  }
  if (values.businessType.match(/business satellite/i)) {
    ownerId = 'Business_Satellite_Support'
  }
  if (values.businessType.match(/wholesale/i)) {
    ownerId = 'Wholesale_Support'
  }
  if (values.businessType.match(/partner channel/i)) {
    ownerId = 'Channel_Partner_Support'
  }
  if (values.businessType.match(/public sector/i)) {
    ownerId = 'Enterprise_Support'
  }

  return await submitFormToSF(
    {
      ...values,
      shawAccountNumber: values.shawAccountNumber.replace(/-/g, ''),
      helpWith: 'account'
    },
    { ownerId }
  )
}

async function submitToCancelServices(values) {
  return await submitFormToSF(
    {
      ...values,
      shawAccountNumber: values.shawAccountNumber.replace(/-/g, ''),
      helpWith: 'cancel'
    },
    { ownerId: 'Large_Business_Cancellations' }
  )
}

async function handleFormSubmit(values) {
  let status = null
  switch (values.supportType) {
    case 'Sales':
      status = await submitToSales(values)
      break
    case 'Technical Support':
      status = await submitToTechSupport(values)
      break
    case 'Account Management':
      status = await submitToManageAccount(values)
      break
    case 'Cancel Services':
      status = await submitToCancelServices(values)
  }

  return status
}

const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

const postalCodeMask = [
  /[a-zA-Z]/,
  /\d/,
  /[a-zA-Z]/,
  '-',
  /\d/,
  /[a-zA-Z]/,
  /\d/
]

const masks = { phoneMask, postalCodeMask }

export {
  salesValidationSchema,
  validationSchema,
  handleFormSubmit,
  submissionMap,
  businessTypeFieldsDefaults,
  masks
}
