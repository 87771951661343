import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import { MasterBoxModel } from 'app/modules/shared/MasterBox/MasterBox'
import ResponsiveImage, {
  ResponsiveImageModel
} from 'app/components/ResponsiveImage/ResponsiveImage'
import BasicTextBoxStack, {
  BasicTextBoxStackModel
} from 'app/components/BasicTextBoxStack/BasicTextBoxStack'

import './ImageStatic.scss'

const TextBoxOrientation = {
  Top: 'Top',
  Left: 'Left',
  Right: 'Right'
}

// Module model definition
export class ImageStaticModel extends Model {
  static props() {
    return {
      title: {
        type: String,
        required: false
      },
      titleSize: {
        type: String,
        default: 'small'
      },
      image: {
        type: ResponsiveImageModel.shape,
        required: true
      },
      textBoxOrientation: {
        type: String,
        required: true
      },
      showDividers: Boolean,
      button: {
        type: LinkModel.shape,
        required: false,
        default: null
      },
      button2: {
        type: LinkModel.shape,
        required: false,
        default: null
      },
      textBoxes: {
        type: MasterBoxModel.arrayOf,
        required: true
      },
      mobileLayout: {
        type: String,
        default: ''
      },
      desktopLayout: {
        type: String,
        default: ''
      },
      width: {
        type: String,
        default: 'narrow'
      },
      background: {
        type: String,
        default: 'transparent'
      }
    }
  }
}

// Module definition
export default class ImageStatic extends Component {
  constructor(props) {
    super(props, 'image-static')
  }

  isRowOrientation() {
    return (
      this.props.textBoxOrientation === TextBoxOrientation.Top &&
      this.props.textBoxes.length !== 4
    )
  }

  showButton() {
    return !!this.props.button
  }

  renderButton(button) {
    if (!button) return null
    if (this.showButton()) {
      const ctaLink = new LinkModel(button)
      const analyticsEvent = ctaLink.determineAnalyticsEvent()
      const analyticsValue = `${slug(button.name)}|${slug(ctaLink.text)}`
      const ctaButton = {
        ...ctaLink.asButton,
        design: button.style || 'secondary',
        analyticsEvent,
        analyticsValue
      }
      return (
        <span className={this.e('button')}>
          <Button {...ctaButton} icon={button.icon} />
        </span>
      )
    }
  }

  renderImage(image) {
    return (
      <div className={this.e('image-container')}>
        <ResponsiveImage
          {...image}
          className={this.e('image')}
          useImageSizes={false}
        />
      </div>
    )
  }

  renderTextBoxes(textBoxes) {
    let orientation = this.isRowOrientation() ? 'row' : 'column'

    const stackModel = new BasicTextBoxStackModel({
      showDividers: this.props.showDividers,
      textBoxes: textBoxes,
      orientation: orientation
    })

    return (
      <div className={this.e('text-box-container')}>
        <BasicTextBoxStack
          {...stackModel}
          button={this.renderButton(this.props.button)}
          button2={this.renderButton(this.props.button2)}
        />
      </div>
    )
  }

  render() {
    const {
      mobileLayout,
      desktopLayout,
      title,
      image,
      textBoxes,
      textBoxOrientation,
      width,
      background,
      titleSize
    } = this.props
    const b = Bem('image-static')
    return (
      <section
        className={b
          .m({
            scotch: true,
            [background]: true,
            [width]: true,
            [`title-${titleSize}`]: true,
            grid: textBoxes.length === 4,
            single: textBoxes.length === 1,
            row: this.isRowOrientation(),
            imageLeft:
              textBoxOrientation === TextBoxOrientation.Right &&
              textBoxes.length < 4,
            imageRight:
              textBoxOrientation === TextBoxOrientation.Left &&
              textBoxes.length < 4
          })
          .classes()}
      >
        <div
          className={`${this.e('container')} ${
            mobileLayout && this.e(mobileLayout)
          } ${desktopLayout && this.e(`desktop-${desktopLayout}`)} ${this.e(
            'container'
          )}--${width}`}
        >
          {title && (
            <RichText
              className={this.e('title')}
              markup={title}
              renderers={{ root: 'h2' }}
            />
          )}

          {this.renderImage(image)}
          {this.renderTextBoxes(textBoxes)}
        </div>
      </section>
    )
  }
}

ImageStatic.props(ImageStaticModel)
