import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import Chevron from 'app/components/Chevron/Chevron'
import RichText from 'app/components/RichText/RichText'
import BasicCTA, { BasicCTAModel } from 'app/modules/ppp/BasicCTA/BasicCTA'
import { BasicTextBoxModel } from 'app/components/BasicTextBox/BasicTextBox'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'
import FullBleedBackground from 'app/components/FullBleedBackground/FullBleedBackground'

import ThemePack, { ThemePackModel } from '../ThemePack/ThemePack'

import './ThemePackContainer.scss'

// Module model definition
export class ThemePackContainerModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        required: true,
        default: ''
      },
      background: {
        type: String,
        required: false,
        default: ''
      },
      jumpLinkId: {
        type: String,
        required: false
      },
      title: {
        type: String,
        required: true
      },
      intro: {
        type: String,
        required: false
      },
      headerImage: {
        type: ResponsiveImageModel.shape,
        required: false,
        default: null
      },
      summary: {
        type: BasicTextBoxModel.arrayOf,
        required: false,
        default: []
      },
      collapseLabel: {
        type: String,
        required: false
      },
      description: {
        type: String,
        required: false
      },
      collapsable: {
        type: Boolean,
        required: false
      },
      cta: {
        type: BasicCTAModel.shape,
        requried: false
      },
      themePacks: {
        type: ThemePackModel.shape,
        required: true
      }
    }
  }
}

// Module definition
export default class ThemePackContainer extends Component {
  constructor(props) {
    super(props)

    this.b = Bem('theme-pack-container')

    this.state = {
      isOpen: props.summary.length === 0 ? true : false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const {
      name,
      background,
      title,
      intro,
      cta,
      themePacks,
      summary,
      collapseLabel,
      headerImage,
      entryId,
      jumpLinkId
    } = this.props
    const { isOpen } = this.state
    const hasSummary = summary.length > 0
    const url = typeof window !== 'undefined' ? window.location.pathname : ''
    const tCta = new LinkModel(cta)
    return (
      <div
        id={jumpLinkId}
        className={this.b
          .m({
            'with-summary': hasSummary,
            white: background === 'white',
            grey: background === 'grey',
            premium: background === 'premium'
          })
          .classes()}
      >
        {headerImage && (
          <FullBleedBackground
            className={this.e('background')}
            image={headerImage}
            id={entryId}
          />
        )}
        {title && hasSummary && (
          <RichText
            className={this.b.e('title').classes()}
            markup={title}
            isMarkdown={false}
            renderers={{ root: 'h2' }}
          />
        )}
        <div
          className={this.b
            .e('content')
            .m({ 'with-summary': hasSummary })
            .classes()}
        >
          {title && !hasSummary && (
            <RichText
              className={this.b.e('title').classes()}
              markup={title}
              isMarkdown={false}
              renderers={{ root: 'h2' }}
            />
          )}

          {intro && !hasSummary && (
            <RichText
              className={this.b.e('intro').classes()}
              markup={intro}
              isMarkdown={false}
            />
          )}
          {hasSummary && (
            <div className={this.b.e('summary').classes()}>
              {summary?.map((item, i) =>
                item ? (
                  <div
                    key={item.entryId || i}
                    className={this.b.e('summary-item').classes()}
                  >
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                ) : null
              )}
            </div>
          )}

          {hasSummary && collapseLabel && (
            <span className={this.b.e('collapse-label').classes()}>
              {collapseLabel}
            </span>
          )}
          {cta && cta.contentType === 'BasicCTA' && <BasicCTA {...cta} />}

          {cta && cta.contentType === 'link' && tCta && (
            <div className={this.b.e('cta-container').classes()}>
              <Button
                className={this.b.e('cta').classes()}
                {...tCta.asButton}
              />
            </div>
          )}

          {hasSummary && (
            <div className={this.b.e('toggle-container').classes()}>
              <button
                className={this.b.e('toggle').classes()}
                onClick={this.toggle}
                aria-label="themepack toggle button"
                data-event={''}
                data-value={`${slug(url)}|${slug(name)}|${
                  isOpen ? 'contract' : 'expand'
                }`}
              >
                <span className={this.b.e('icon-container').classes()}>
                  <Chevron open={isOpen} blue />
                </span>
              </button>
            </div>
          )}
          <div className={this.b.e('themepacks').m({ open: isOpen }).classes()}>
            {themePacks?.map((themePack, i) => (
              <ThemePack
                key={themePack.entryId || i}
                {...themePack}
                collapsable={!hasSummary && themePack.collapsable}
                inContainer={hasSummary}
                showIndividualButton={!hasSummary}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}
