// Vendor packages
import isString from 'lodash/isString'
import toString from 'lodash/toString'

// Validation rules
const RULES = {
  required(value) {
    return toString(value) !== ''
  },

  isTrue(value) {
    return value === true || value === 'true'
  },

  regex(value, regex) {
    return toString(value).match(new RegExp(regex.slice(1, -1))) !== null
  },

  email(value) {
    return toString(value).match(/^\w[\w.-]*@([\w-]+\.)+[\w-]+$/) !== null
  },

  phone(value) {
    return (
      toString(value).match(
        /^([(]{0,1}?[\d]{3}[)\s]{0,2}?[\d]{3}[-]{0,1}[\d]{4})([\s]{0,1}[a-z]{1}[\s]{0,1}[\d]{2,4})?$/
      ) !== null
    )
  },

  zip(value) {
    return toString(value).match(/^\d{5}$|^\d{5}-\d{4}$}/) !== null
  },

  ssn(value) {
    return (
      toString(value).match(
        /^((\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)|((0[1-9]|[1-9]\d)-\d{7}))$/
      ) !== null
    )
  }
}

// Class definition
export default class FormRules {
  constructor() {
    this.clear()
  }

  add(field, rules) {
    const fieldRules = this.get(field)

    this.make(rules).map(rule => {
      rule = rule.split(':')

      const name = rule.shift()
      const options = rule.join(':')

      fieldRules[name] = function (value) {
        return RULES[name](value, options)
      }
    })

    return this
  }

  remove(field, rule) {
    if (rule) {
      delete this.rules[field][rule]
    } else {
      delete this.rules[field]
    }
  }

  get(field, rule) {
    const rules = (this.rules[field] = this.rules[field] || {})

    return rule ? rules[rule] : rules
  }

  clear() {
    this.rules = {}
  }

  make(rules) {
    if (isString(rules)) {
      return rules.split(/\|(?![^regex:/]*\/)/g)
    }

    return rules || []
  }

  empty(field, value) {
    return toString(value) === ''
  }

  required(field, value) {
    const required = this.get(field, 'required')

    return required ? required(value) : true
  }

  validate(field, value) {
    if (!this.required(field, value)) {
      return ['required']
    }

    const rules = this.get(field)
    const invalid = []
    for (const rule in rules) {
      if (!rules[rule](value)) {
        invalid.push(rule)
      }
    }

    return invalid
  }

  each(callback) {
    for (const field in this.rules) {
      callback(field, this.get(field))
    }
  }

  all() {
    return this.rules
  }

  static rule(name, rule) {
    if (RULES[name]) {
      return console.warn(`Form Rule [${name}] already exists.`)
    }

    RULES[name] = rule

    return this
  }
}
