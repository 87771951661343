import { ErrorMessage, Field } from 'formik'

import Bem from 'app/utils/bem-helper'
import React, { useCallback } from 'react'

export default function SupportType({
  onChange,
  values,
  setFieldValue,
  setSupportType
}) {
  const b = Bem('contact-form')
  const resetSomeValues = useCallback(
    e => {
      if (e.target.value.match(/sales|technical support/i)) {
        setFieldValue('cancellationOption', '')
        setFieldValue('shawAccountNumber', '')
        setFieldValue('requestType', '')
        setFieldValue('moveDate', '')
        setFieldValue('currentAddress', '')
        setFieldValue('newAddress', '')
        setFieldValue('newServices', '')
        if (
          e.target.value.match(/sales/i) &&
          values?.businessType?.match(
            /small and medium business|enterprise\/franchise|hotels/i
          ) === null
        ) {
          setFieldValue('businessType', '')
        }
      } else if (e.target.value.match(/account management/i)) {
        setFieldValue('cancellationOption', '')
      } else if (e.target.value.match(/cancel services/i)) {
        setFieldValue('requestType', '')
        setFieldValue('moveDate', '')
        setFieldValue('currentAddress', '')
        setFieldValue('newAddress', '')
        setFieldValue('newServices', '')
      }
      setSupportType(e.target.value)
    },
    [setSupportType]
  )

  return (
    <>
      <label
        className={b.e('radio-label').m({ single: true }).classes()}
        htmlFor="Sales"
      >
        <Field
          type="radio"
          name="supportType"
          value="Sales"
          id="Sales"
          onChange={e => {
            onChange(e)
            resetSomeValues(e)
          }}
        />
        <span>Sales</span>
      </label>
      <label
        className={b.e('radio-label').m({ single: true }).classes()}
        htmlFor="TechnicalSupport"
      >
        <Field
          type="radio"
          name="supportType"
          value="Technical Support"
          id="TechnicalSupport"
          onChange={e => {
            onChange(e)
            resetSomeValues(e)
          }}
        />
        <span>Technical Support</span>
      </label>
      <label
        className={b.e('radio-label').m({ single: true }).classes()}
        htmlFor="AccountManagement"
      >
        <Field
          type="radio"
          name="supportType"
          value="Account Management"
          id="AccountManagement"
          onChange={e => {
            onChange(e)
            resetSomeValues(e)
          }}
        />
        <span>Account Management</span>
      </label>
      <label
        className={b.e('radio-label').m({ single: true }).classes()}
        htmlFor="CancelServices"
      >
        <Field
          type="radio"
          name="supportType"
          value="Cancel Services"
          id="CancelServices"
          onChange={e => {
            onChange(e)
            resetSomeValues(e)
          }}
        />
        <span>Cancel Services</span>
      </label>

      <ErrorMessage
        name="supportType"
        component="div"
        className={b.e('error').classes()}
      />
    </>
  )
}
