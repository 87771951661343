import React from 'react'

import PropTypes from 'prop-types'

import { v1 as uuid } from 'uuid'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Link from 'app/components/Link/Link'
import ImageModel from 'app/models/Image/Image'
import ContentLinkModel from 'app/models/Link/Link'
import LinkListModel from 'app/models/LinkList/LinkList'
import Copyright from 'app/modules/shared/Copyright/Copyright'
import CollapsableDrawer from 'app/components/CollapsableDrawer/CollapsableDrawer'

import './Footer.scss'

// Module model definition
export class FooterModel {
  constructor(d) {
    const castLinks = item => {
      const castItem =
        item.contentType === 'link'
          ? new ContentLinkModel(item)
          : new LinkListModel(item)
      castItem.contentType = item.contentType

      return castItem
    }

    this.name = d.name
    this.theme = d.theme
    this.column1Label = d.column1Label
    this.column1Content = d.column1Content.map(castLinks)
    this.column2Label = d.column2Label
    this.column2Content = d.column2Content.map(castLinks)
    this.column3Label = d.column3Label
    this.column3Content = d.column3Content.map(castLinks)
    this.column4Label = d.column4Label
    this.column4Content = d.column4Content.map(castLinks)
    this.copyrightText = d.copyrightText
    this.socialMediaLabel = d.socialMediaLabel
    this.socialMediaContent = d.socialMediaContent.map(castLinks)
    this.image = new ImageModel(d.image)
  }

  static propTypes() {
    const columnContentPropTypes = PropTypes.arrayOf(
      ContentLinkModel.shape,
      LinkListModel.shape
    )

    return {
      column1Label: PropTypes.string,
      column1Content: columnContentPropTypes,
      column2Label: PropTypes.string,
      column2Content: columnContentPropTypes,
      column3Label: PropTypes.string,
      column3Content: columnContentPropTypes,
      column4Label: PropTypes.string,
      column4Content: columnContentPropTypes,
      copyrightText: PropTypes.string,
      socialMediaLabel: PropTypes.string,
      socialMediaContent: ContentLinkModel.arrayOf,
      image: ImageModel.shape
    }
  }

  static defaultProps() {
    return {
      theme: 'black',
      column1Label: 'Column 1',
      column1Content: [
        { label: 'Link 1', contentType: 'link' },
        { label: 'Link 2', contentType: 'link' },
        { label: 'Link 3', contentType: 'link' },
        { label: 'Link 4', contentType: 'link' },
        { label: 'Link 5', contentType: 'link' },
        { label: 'Link 6', contentType: 'link' },
        { label: 'Link 7', contentType: 'link' }
      ],
      column2Label: 'Column 2',
      column2Content: [
        { label: 'Link 1', contentType: 'link' },
        { label: 'Link 2', contentType: 'link' },
        { label: 'Link 3', contentType: 'link' },
        { label: 'Link 4', contentType: 'link' },
        { label: 'Link 5', contentType: 'link' },
        { label: 'Link 6', contentType: 'link' },
        { label: 'Link 7', contentType: 'link' },
        { label: 'Link 8', contentType: 'link' },
        { label: 'Link 9', contentType: 'link' }
      ],
      column3Label: 'Column 3',
      column3Content: [
        {
          contentType: 'footerLinksSection',
          label: 'Footer Section 1',
          collection: [{ target: { number: '0-000-000-0000' } }]
        },
        {
          contentType: 'footerLinksSection',
          label: 'Footer Section 2',
          collection: [
            { target: { number: '0-000-000-0000' } },
            {
              contentType: 'link',
              label: 'Link 1',
              href: '#'
            }
          ]
        },
        {
          contentType: 'footerLinksSection',
          label: 'Footer Section 3',
          collection: [{ target: { number: '0-000-000-0000' } }]
        },
        {
          contentType: 'footerLinksSection',
          label: 'Footer Section 4',
          collection: [
            {
              contentType: 'link',
              label: 'Link 1',
              href: '#'
            },
            {
              contentType: 'link',
              label: 'Link 2',
              href: '#'
            },
            {
              contentType: 'link',
              label: 'Link 3',
              href: '#'
            },
            {
              contentType: 'link',
              label: 'Link 4',
              href: '#'
            }
          ]
        }
      ],
      column4Label: 'Column 4',
      column4Content: [
        {
          contentType: 'footerLinksSection',
          label: 'Footer Section 1',
          collection: [
            { target: { number: '0-000-000-0000' } },
            {
              contentType: 'link',
              label: 'Link 1',
              href: '#'
            }
          ]
        },
        {
          contentType: 'footerLinksSection',
          label: 'Footer Section 2',
          collection: [{ target: { number: '0-000-000-0000' } }]
        }
      ],
      copyrightText: `Rogers Communications. All rights reserved.`,
      socialMediaLabel: 'Social Media Label',
      socialMediaContent: [
        {
          target: { url: '#', label: 'LinkedIn' },
          icon: { type: 'Global_LinkedIn' }
        },
        {
          target: { url: '#', label: 'YouTube' },
          icon: { type: 'Global_Youtube' }
        }
      ],
      image: {
        src: '//images.contentful.com/xsc9487shk09/wbUw8yM8Fic2qMYYuQgwG/a40d63a19743b0cc1aa74cd20ab4d025/SHAW_Robot.png',
        height: 0,
        width: 0,
        mime: '',
        type: '',
        name: '',
        title: '',
        description: ''
      }
    }
  }
}

// Module definition
export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.b = Bem('footer')
    this.mapColumnData = this.mapColumnData.bind(this)
  }

  mapColumnData(item) {
    switch (item.contentType) {
      case 'LinkList':
        return (
          <li key={uuid()} className={this.b.e('list-section').c()}>
            <div className={this.b.e('list-section-title').c()}>
              {item.label}
            </div>
            <ul className={this.b.e('list-section-list').c()}>
              {item.links.map(link => {
                return (
                  <li
                    key={uuid()}
                    className={this.b.e('list-section-list-item').c()}
                  >
                    <Link
                      tabIndex={'0'}
                      className={this.b.e('list-link').c()}
                      link={link}
                      analyticsValue={`${slug(this.props.name)}|${slug(
                        link.ariaLabel
                      )}`}
                    />
                  </li>
                )
              })}
            </ul>
          </li>
        )
      default:
        return (
          <li key={uuid()} className={this.b.e('list-item').c()}>
            <Link
              className={this.b.e('list-link').c()}
              tabIndex={'0'}
              link={item}
              analyticsValue={`${slug(this.props.name)}|${slug(
                item.ariaLabel
              )}`}
            />
          </li>
        )
    }
  }

  includeSocial(column) {
    if (column.social) {
      return (
        <div className={this.b.e('social-container').c()}>
          <div className={this.b.e('social-container-title').c()}>
            {this.props.socialMediaLabel}
          </div>
          <div className={this.b.e('social-container-icons').c()}>
            {column.social.content.map(icon => {
              return (
                <Link
                  key={uuid()}
                  className={this.b.e('social-container-link').c()}
                  link={icon}
                  analyticsEvent="socialAction"
                  analyticsValue={`${slug(this.props.name)}|${slug(
                    icon.ariaLabel
                  )}`}
                />
              )
            })}
          </div>
        </div>
      )
    }

    return null
  }

  static formatData(data) {
    return [
      {
        label: data.column1Label,
        data: data.column1Content
      },
      {
        label: data.column2Label,
        data: data.column2Content
      },
      {
        label: data.column3Label,
        data: data.column3Content
      },
      {
        label: data.column4Label,
        data: data.column4Content,
        social: {
          label: data.socialMediaLabel,
          content: data.socialMediaContent
        }
      }
    ]
  }

  render() {
    return (
      <footer className={this.b.m({ [this.props.theme]: true }).c()}>
        <nav aria-label="footer" className={this.b.e('container').c()}>
          <div className={this.b.e('container-row').c()}>
            {Footer.formatData(this.props).map(column => {
              return (
                <div
                  key={uuid(column.label)}
                  className={this.b.e('container-column').c()}
                >
                  <CollapsableDrawer title={column.label}>
                    <ul className={this.b.e('list').c()}>
                      {column.data.map(this.mapColumnData)}
                    </ul>
                  </CollapsableDrawer>
                  {this.includeSocial(column)}
                </div>
              )
            })}
          </div>
        </nav>
        <Copyright
          image={this.props.image}
          title={this.props.title}
          copyrightText={this.props.copyrightText}
        />
      </footer>
    )
  }
}

Footer.propTypes = FooterModel.propTypes()
Footer.defaultProps = FooterModel.defaultProps()
