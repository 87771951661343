import React, { useState, useEffect } from 'react'
import { loadJavaScript } from 'app/utils/dom-helpers'
import TimeHelpers from 'app/utils/time-helpers'

const ChatContext = React.createContext()
ChatContext.displayName = 'ChatContext'

const getChatButtonId = s => {
  var m = new RegExp(/#chat:([^:]+):([^:]+)(:([^:]+))?$/).exec(s)
  return m != null
    ? {
        buttonId: m[2],
        linkInstanceId: m[1],
        hideOnOnline: m[4] === 'offline',
        hideOnOffline: m[4] === 'online'
      }
    : null
}
const registerChatButtons = () => {
  const btns = window.chatButtons
  const keys = Object.keys(btns)
  for (let k in keys) {
    const btn = btns[keys[k]]
    btn.map(bi => {
      window.liveagent.showWhenOnline(bi.buttonId, bi.fon)
      window.liveagent.showWhenOffline(bi.buttonId, bi.foff)
    })
    window.liveagent.addButtonEventHandler(keys[k])
  }
}

const onChatClick = e => {
  e.preventDefault()
  const a = e.target.closest('a') || { className: '', href: '' }
  const cn = e.target + ' ' + a.className + ''
  const href = a.href || ''

  if (cn.search('chat-available') !== -1) {
    try {
      const b = getChatButtonId(href)
      window.liveagent.startChat(b.buttonId)
    } catch (error) {
      if (window?.LOG_LEVEL?.match(/info|verbose|debug|silly/)) {
        console.log(
          '------- chat start error --------->',
          error,
          '\n',
          cn,
          '\n',
          getChatButtonId(href)
        )
      }
    }
  }
}

const initChatButtons = (links, fakeLinksContainer) => {
  const btns = {}
  for (var i = 0, l = links.length; i < l; ++i) {
    const cbid = getChatButtonId(links[i].href || '')
    if (cbid === null) continue
    const btn = {
      link: links[i],
      linkLabel: links[i].innerText,
      ...cbid,
      linkDefaultCss: links[i].className,
      fon: document.createElement('span'),
      foff: document.createElement('span')
    }
    btn.fon.setAttribute('id', `cbid-${btn.linkInstanceId}-${btn.buttonId}-on`)
    btn.foff.setAttribute(
      'id',
      `cbid-${btn.linkInstanceId}-${btn.buttonId}-off`
    )
    links[i].onclick = onChatClick
    if (fakeLinksContainer) {
      fakeLinksContainer.appendChild(btn.fon)
      fakeLinksContainer.appendChild(btn.foff)
    }
    if (btns[btn.buttonId] === undefined) {
      btns[btn.buttonId] = []
    }
    btns[btn.buttonId].push(btn)
  }
  window.chatButtons = btns
}

export default function ChatProvider({ children, initialValue }) {
  const [liveagent, setLiveagent] = useState(initialValue)
  const [chatAvailable, setChatAvailable] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const body = document.getElementsByTagName('body')[0]
      const clickId =
        body.attributes['data-clickid'] !== undefined
          ? body.attributes['data-clickid'].value
          : ''
      const chatLinks = document.querySelectorAll('a[href*="#chat:"]')
      const fakeLinksContainer = document.getElementById('chat-fake-buttons')
      initChatButtons(chatLinks, fakeLinksContainer)
      const CHAT_DEPLOYMENT_URL = window.chatConfig.CHAT_DEPLOYMENT_URL
      const CHAT_INIT_URL = window.chatConfig.CHAT_INIT_URL
      const CHAT_INIT_DEPLOYMENT = window.chatConfig.CHAT_INIT_DEPLOYMENT
      const CHAT_INIT_ORG = window.chatConfig.CHAT_INIT_ORG

      loadJavaScript(CHAT_DEPLOYMENT_URL, () => {
        if (
          window.liveagent &&
          CHAT_INIT_URL &&
          CHAT_INIT_DEPLOYMENT &&
          CHAT_INIT_ORG
        ) {
          if (!window._laq) {
            window._laq = []
          }
          window._laq.push(() => registerChatButtons())
          const urlParams = new URLSearchParams(window?.location?.search)
          const logChat = urlParams?.get('logChat')
          if (process.env.NODE_ENV !== 'production' || logChat === 'true') {
            window.liveagent.enableLogging()
          }
          window.liveagent.addCustomDetail('theURL', document.URL)
          window.liveagent.addCustomDetail('PageRefID', clickId)
          window.liveagent.setChatWindowHeight(610)
          window.liveagent.setChatWindowWidth(400)
          window.liveagent.init(
            CHAT_INIT_URL,
            CHAT_INIT_DEPLOYMENT,
            CHAT_INIT_ORG
          )
          setLiveagent(window.liveagent)
        }
      })
    }
  }, [])

  useEffect(() => {
    const CHAT_BUSINESS_HOURS_START =
      typeof window !== 'undefined'
        ? window.chatConfig.CHAT_BUSINESS_HOURS_START
        : process.env.CHAT_BUSINESS_HOURS_START
    const CHAT_BUSINESS_HOURS_END =
      typeof window !== 'undefined'
        ? window.chatConfig.CHAT_BUSINESS_HOURS_END
        : process.env.CHAT_BUSINESS_HOURS_END
    const CHAT_UTC_OFFSET =
      typeof window !== 'undefined'
        ? parseInt(window.chatConfig.CHAT_UTC_OFFSET)
        : parseInt(process.env.CHAT_UTC_OFFSET)
    const isOpenForChat = TimeHelpers.isItBusinessTime(
      CHAT_BUSINESS_HOURS_START,
      CHAT_BUSINESS_HOURS_END,
      CHAT_UTC_OFFSET
    )
    setChatAvailable(isOpenForChat)
  }, [])

  return (
    <ChatContext.Provider value={{ liveagent, chatAvailable, setLiveagent }}>
      {children}
    </ChatContext.Provider>
  )
}
function useChat() {
  const context = React.useContext(ChatContext)
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider')
  }
  return context
}

export { ChatProvider, useChat }
