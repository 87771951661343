const preProcessVideoUrl = url => {
  if (!url) {
    return ''
  } else if (url.includes('vimeo.com')) {
    return url.replace('vimeo.com', 'player.vimeo.com/video')
  }

  const rules = [
    {
      pattern: /^(https:\/\/www\.youtube\.com\/watch\?v=)/gim,
      replace: 'https://www.youtube.com/embed/'
    },
    {
      pattern: /^(https:\/\/www\.youtube\.com\/embed\/)/gim,
      replace: 'https://www.youtube.com/embed/'
    },
    {
      pattern: /^(https:\/\/youtu\.be\/)/gim,
      replace: 'https://www.youtube.com/embed/'
    }
  ]

  let newUrl = ''

  for (var i = 0, l = rules.length; i < l; ++i) {
    const rg = new RegExp(rules[i].pattern)
    if (rg.test(url)) {
      newUrl = url.replace(rules[i].pattern, rules[i].replace)
      break
    }
  }

  return newUrl
}

export default preProcessVideoUrl
