// Vendor packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Scroll from 'react-scroll'

const Element = Scroll.Element || null

// Component definition
export default function withAnchor(WrappedCmpt) {
  class Anchorable extends React.Component {
    render() {
      if (this.props.isTarget) {
        return (
          <Element name={this.props.entryId}>
            <WrappedCmpt {...this.props} />
          </Element>
        )
      }

      return <WrappedCmpt {...this.props} />
    }
  }

  Anchorable.propTypes = {
    isTarget: PropTypes.bool
  }

  const mapStateToProps = ({ page }, ownProps) => {
    return {
      isTarget: page.anchors.includes(ownProps.entryId)
    }
  }

  return connect(mapStateToProps, null)(Anchorable)
}
