import React from 'react'

import omit from 'lodash/omit'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import { Hero } from '@shaw/react-component-library'
import { HeroModel } from 'app/components/Hero/Hero'
import GradientHero from 'app/components/GradientHero/GradientHero'
import {
  ResponsiveImageModel,
  formatImage
} from 'app/components/ResponsiveImage/ResponsiveImage'

import './FullBleedHero.scss'

// Component model definition
export class FullBleedHeroModel extends Model {
  constructor(d) {
    super(d)
    this.entryId = d.entryId
    this.image = d.responsiveImage || d.image || null // { large: d.image }
  }

  static props() {
    return {
      ...HeroModel.props(),
      entryId: String,
      image: {
        type: ResponsiveImageModel.shape
      }
    }
  }
}

// Component definition
export default class FullBleedHero extends Component {
  constructor(props) {
    super(props, 'full-bleed-hero')
  }

  render() {
    const heroProps = new HeroModel(this.props)
    const cta = this.props.cta ? new LinkModel(heroProps.cta) : null
    const responsiveImage = this.props.image
      ? {
          large: this.props.image.large
            ? formatImage(this.props.image.large.file.url, 'large')
            : null,
          medium: this.props.image.medium
            ? formatImage(this.props.image.medium.file.url, 'medium')
            : null,
          small: this.props.image.small
            ? formatImage(this.props.image.small.file.url, 'small')
            : null
        }
      : null
    const firstCta = cta && omit(cta.asButton, ['activeRoute'])

    if (this.props.image) {
      return (
        <Hero
          id={this.props.entryId}
          theme={this.props.theme}
          variant={this.props.variant}
          width={this.props.width}
          {...heroProps}
          cta={
            cta
              ? {
                  ...firstCta,
                  variant: cta.asButton.design,
                  theme: cta.asButton.variant,
                  'data-event': cta.determineAnalyticsEvent(),
                  'data-value': `${slug(heroProps.name)}|${slug(cta.text)}`
                }
              : null
          }
          responsiveImage={responsiveImage}
          heroClassName={this.props.heroClassName}
        />
      )
    } else {
      return (
        <GradientHero
          id={this.props.entryId}
          {...heroProps}
          heroClassName={this.props.heroClassName}
          theme={this.props.theme}
        />
      )
    }
  }
}

FullBleedHero.props(FullBleedHeroModel)
