import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import ImageSwitcher from 'app/components/ImageSwitcher/ImageSwitcher'
import ImageSelectorItemModel from 'app/models/ImageSelectorItem/ImageSelectorItem'
import ScrollableSelector from 'app/components/ScrollableSelector/ScrollableSelector'

import './ImageSelector.scss'

export class ImageSelectorModel extends Model {
  static props() {
    return {
      title: {
        type: String,
        required: true,
        default: 'title'
      },
      description: String,
      collection: {
        type: ImageSelectorItemModel.arrayOf,
        required: true,
        default: [ImageSelectorItemModel.defaultProps()]
      },
      cta: {
        type: LinkModel.shape,
        default: LinkModel.defaultProps()
      },
      fadesImage: {
        type: Boolean,
        default: false
      }
    }
  }
}

// Module definition
export default class ImageSelector extends Component {
  constructor(props) {
    super(props, 'image-selector')
    this.state = {
      selectedOption: 0,
      collectionSubcopy: props.collection[0].subCopy,
      collectionSubcopyBuffer: '',
      hideSubCopy: props.collection.length > 3,
      subCopyFade: false
    }
  }

  m() {
    return {
      'fades-image': this.props.fadesImage
    }
  }

  selectOption(e) {
    const { dataset } = e.currentTarget
    const index = Number(dataset.index)
    this.setState({
      selectedOption: index,
      subCopyFade: true,
      collectionSubcopyBuffer: dataset.subcopy
    })
  }

  swapSubcopyBuffer() {
    this.setState(prevState => {
      return Object.assign(prevState, {
        subCopyFade: false,
        collectionSubcopy: prevState.collectionSubcopyBuffer
      })
    })
  }

  renderButton(cta, withContainer = false) {
    const { ctaValidation } = this
    if (ctaValidation(cta)) {
      const link = new LinkModel(cta)
      const button = (
        <Button
          {...link.asButton}
          className={this.e('button')}
          type="link"
          analyticsEvent="navigationAction"
          analyticsValue={`${slug(this.props.title)}|${slug(
            link.asButton.text
          )}`}
        />
      )

      if (withContainer) {
        return <div className={this.e('button-container')}>{button}</div>
      } else {
        return button
      }
    }
  }

  renderSubCopy() {
    if (!this.state.hideSubCopy) {
      return (
        <div
          className={this.e('collection-subcopy', {
            'fade-out': this.state.subCopyFade
          })}
          onTransitionEnd={this.swapSubcopyBuffer}
        >
          <div className={this.e('collection-subcopy-container')}>
            {this.state.collectionSubcopy}
          </div>
        </div>
      )
    }
  }

  ctaValidation(cta) {
    const { href, target } = cta
    if (href || target) return true
    return false
  }

  render() {
    const { title, description, collection, cta } = this.props
    const { selectedOption, hideSubCopy } = this.state
    return (
      <section className={this.b()} aria-label={title}>
        <div className={this.e('container')}>
          <h2 className={this.e('title')}>{title}</h2>
          {description && (
            <p className={this.e('description')}>{description}</p>
          )}
          <div className={this.e('content')}>
            <div className={this.e('options-list')}>
              <ScrollableSelector
                collection={collection}
                selectHandler={this.selectOption}
                selectedIndex={selectedOption}
                hideSubCopy={hideSubCopy}
                parentModuleName={title}
              >
                {this.renderButton(cta)}
              </ScrollableSelector>
            </div>
            {this.renderSubCopy()}
            <div className={this.e('image-container')}>
              <ImageSwitcher
                className={this.e('image-switcher')}
                images={collection.map(item => item.image)}
                current={this.state.selectedOption}
              />
            </div>
            {this.renderButton(cta, true)}
          </div>
        </div>
      </section>
    )
  }
}

ImageSelector.props(ImageSelectorModel)
