import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'

import Model from 'app/models/Model'
import FormData from 'app/utils/form'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Icon from 'app/components/Icon/Icon'
import { icons } from 'app/utils/constants'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import { ClientDataLayer } from 'app/utils/DataLayer'
import { submitLeadForm } from 'app/actions/lead-form'
import RichText from 'app/components/RichText/RichText'
import Checkbox, { CheckboxModel } from 'app/components/Checkbox/Checkbox'
import FormGroup, {
  FormGroupModel
} from 'app/modules/leadForm/FormGroup/FormGroup'
import CheckboxGroup, {
  CheckboxGroupModel
} from 'app/components/CheckboxGroup/CheckboxGroup'
import MasterHero, {
  MasterHeroModel
} from 'app/modules/shared/MasterHero/MasterHero'
import IconBoxStatic, {
  IconBoxStaticModel
} from 'app/modules/landing/IconBoxStatic/IconBoxStatic'

import './Form.scss'

export class LeadFormResponseModel extends Model {
  static props() {
    return {
      errors: Object,
      statusCode: String,
      statusDescription: String,
      data: PropTypes.shape({
        requestNumber: PropTypes.string
      })
    }
  }
}

// Module model definition
export class FormModel extends Model {
  constructor(d) {
    super(d)

    this.hero = new MasterHeroModel(d.hero)

    this.iconBoxes =
      d.iconBoxContainer && new IconBoxStaticModel(d.iconBoxContainer)
    this.cta = new LinkModel(d.cta)
  }

  static props() {
    return {
      name: String,
      formId: { type: String, required: true },
      config: { type: Object, default: null },
      hero: MasterHeroModel.shape,
      description: String,
      saving: String,
      background: {
        type: String,
        default: 'soft-white'
      },
      incomplete: String,
      cta: LinkModel.shape,
      submitTo: {
        type: String,
        default: 'EMAIL-AND-SALESFORCE'
      },
      submitToEmail: String,
      submitToUrl: String,
      groups: {
        type: FormGroupModel.arrayOf,
        required: true
      },
      extraCheckboxes: {
        type: CheckboxGroupModel.arrayOf,
        required: false
      },
      emailOptIn: CheckboxModel.shape,
      submitLeadForm: {
        type: Function,
        default: () => {}
      },
      leadFormResponse: {
        type: LeadFormResponseModel.shape,
        default: {
          errors: null,
          statusCode: null,
          statusDescription: null,
          data: {
            requestNumber: null
          }
        }
      },
      noPurchaseEvent: {
        type: Boolean,
        default: false
      },
      showRequiredCopy: {
        type: Boolean,
        default: false
      },
      showCaptcha: {
        type: Boolean,
        default: false
      }
    }
  }
}

export class Form extends Component {
  constructor(props) {
    super(props, 'form')

    this.state = {
      form: new FormData(),
      valid: false,
      init: true,
      isUsingRecaptchaV2: false,
      v2Token: null,
      isSubmitting: false,
      keepForm: false
    }

    this.b = Bem('form')
  }

  componentDidMount() {
    if (this.props.emailOptIn) {
      this.state.form.set(
        this.props.emailOptIn.id,
        this.props.emailOptIn.initial
      )
    }

    this.setState({ init: false })
    if (this.props.showCaptcha) {
      window.setupRecaptchaV2 = () => {
        window.grecaptcha?.render('recaptcha-v2-badge', {
          sitekey: window?.RECAPTCHA_KEY_V2,
          callback: token => {
            this.setState({ v2Token: token, isSubmitting: false, error: false })
          },
          'expired-callback': () => {
            this.setState({ isSubmitting: true, error: false  })
          }
        })
      }

      window.setupRecaptchaV3 = () => {
        window.grecaptcha?.render('recaptcha-v3-badge', {
          badge: 'inline',
          sitekey: window?.RECAPTCHA_KEY_V3,
          size: 'invisible'
        })
      }

      this.injectRecaptchaScript(false)
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.leadFormResponse.statusCode === 'OK') {
      if (typeof window !== 'undefined') {
        window.location.replace(this.props.cta.url)
      }
    }
  }

  componentWillUnmount() {
    delete window.setupRecaptchaV2
    delete window.setupRecaptchaV3
  }

  injectRecaptchaScript = isRecaptchaV2 => {
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    const version = isRecaptchaV2 ? 'V2' : 'V3'
    script.setAttribute(
      'src',
      `https://www.google.com/recaptcha/api.js?onload=setupRecaptcha${version}&render=explicit`
    )
    head.append(script)
  }

  validateDependencies = (field, value, isBlur) => {
    let valid = true
    const names = ['firstName', 'lastName']
    const nameError = 'First and last name cannot be the same.'
    const firstName = this.state.form.get(names[0])
    const lastName = this.state.form.get(names[1])
    valid = this.state.form.silentlyValidateForm()
    const mergeErrors = (field, error) => {
      const currentError = this.state.form.errors.errors[field]
      if (currentError) {
        this.state.form.errors.remove(field)
        this.state.form.errors.add(field, ...new Set([...currentError, error]))
      } else {
        this.state.form.errors.remove(field)
        this.state.form.errors.add(field, [error])
      }
    }
    if (names.includes(field)) {
      if (
        firstName &&
        lastName &&
        firstName.toLowerCase() === lastName.toLowerCase()
      ) {
        mergeErrors(names[0], nameError)
        mergeErrors(names[1], nameError)
        valid = false
      } else {
        firstName && this.state.form.errors.remove(names[0])
        lastName && this.state.form.errors.remove(names[1])
        isBlur && this.state.form.validate(field)
        valid = this.state.form.silentlyValidateForm()
      }
    }
    return (
      valid &&
      this.state.form.errors.errors &&
      Object.keys(this.state.form.errors.errors).length <= 0
    )
  }

  onChange(field, value, isBlur) {
    const valid = this.validateDependencies(field, value, isBlur)
    const error = valid ? null : this.state.error
    this.setState({ valid, error })
  }

  onEmailOptInChange = (id, selected) => {
    this.state.form.set(id, selected)
  }

  verifyRecaptcha = async () => {
    return await window?.grecaptcha?.execute(0, { action: 'submit' })
  }

  submitForm = async (finalData, token = 'skip', version = 'v3') => {
    this.setState({ isSubmitting: true })
    this.props.submitLeadForm(
      { ...finalData, token, version },
      {
        success: response => {
          this.setState({ isSubmitting: false })
          if (!this.props.noPurchaseEvent) {
            try {
              const urlParams = window.location.search
                ? window.location.search.substr(1).split('&').join('|')
                : ''
              const formName = window.utag_data.lead_form_name || 'lead form'
              const leadFormName =
                urlParams.length > 0 ? `${formName}|${urlParams}` : formName
              const product = [
                ...(this.state.form.values.productInterest || []),
                ...(this.state.form.values.prodSelection || []),
                ...(this.state.form.values.productSelection || []),
                ...(this.state.form.values.tvProgramming || []),
                ...(this.state.form.values.reccSolutions || [])
              ]
              const leadData = {
                shawAccountNumber: this.state.form.values.shawAccountNumber,
                productInterest: product,
                employeesCount: this.state.form.values.employeesCount,
                orderId: response.data.requestNumber || response.id,
                leadFormName
              }

              // const cdl = new ClientDataLayer()
              // cdl.purchase(leadData)
              const leadDataString = JSON.stringify(leadData)

              const cookieOptions = {
                path: '/',
                expires: new Date(+new Date() + 12096e5)
              }

              this.props.cookies.set('leadData', leadDataString, cookieOptions)
            } catch (error) {
              if (process.env.LOG_LEVEL.match(/info|verbose|debug|silly/)) {
                console.log(
                  '------- form submission post processing --------->',
                  error
                )
              }
            }
          }

          if ((response.data && response.data.requestNumber) || response.id) {
            window.localStorage.setItem(
              'refId',
              (response.data && response.data.requestNumber) || response.id
            )
          }

          const qel = this.props.cta.url.search(/\?/) === -1 ? '?' : '&'
          const params = window.location.search
            ? qel + window.location.search.substr(1)
            : ''

          window.location.href = this.props.cta.url + params
        },
        failure: async data => {
          console.log('---> Failed to send data: ', data)
          this.setState({ isSubmitting: false })
          let errStr = ''

          if (this.props.showCaptcha && !this.state.isUsingRecaptchaV2) {
            this.setState({
              isUsingRecaptchaV2: true,
              isSubmitting: true,
              keepForm: true
            })
            await this.injectRecaptchaScript(true)
            return
          }
          if (data != null && data.errors) {
            const nf = new FormData()
            nf.fill(this.state.form.getAll())
            for (let e in data.errors) {
              errStr += `${e}=${data.errors[e]}|`
              nf.errors.add(e, data.errors[e][0])
            }
            if (!this.state.keepForm) {
              this.setState({
                form: nf
              })
            }
            const cdl = new ClientDataLayer()
            cdl.sendError(errStr)
          }
          this.setState({ error: this.props.saving })
        }
      }
    )
  }

  onSubmit = async e => {
    e.preventDefault()
    const valid = this.state.form.validate()
    const { isUsingRecaptchaV2, v2Token } = this.state

    this.setState({
      error: !valid && (this.props.incomplete || 'Form incomplete.') // Maybe: this.state.form.errors.all() or .first()
    })

    if (valid) {
      // send form request
      const data = this.state.form.getAll()
      const finalData = data.employeesCount
        ? data
        : { ...data, employeesCount: '0' }

      finalData.formId = this.props.formId
      finalData.config = this.props.config

      if (this.props.showCaptcha) {
        let token
        try {
          if (!isUsingRecaptchaV2) {
            token = await this.verifyRecaptcha()
            this.submitForm(finalData, token, 'v3')
          } else {
            token = v2Token
            this.setState({ isUsingRecaptchaV2: true })
            document
              ?.querySelector('button[type="submit"]')
              .removeAttribute('disabled')
            this.submitForm(finalData, token, 'v2')
          }
        } catch (e) {
          if (!token) {
            this.setState({ isUsingRecaptchaV2: true })
            await this.injectRecaptchaScript(true)
          }
        }
      } else {
        this.submitForm(finalData)
      }
    }
  }

  renderHero() {
    if (this.props.hero && Object.keys(this.props.hero).length < 1) {
      return null
    }

    return <MasterHero className={this.e('hero')} {...this.props.hero} />
  }

  renderDescription() {
    if (!this.props.description) {
      return null
    }

    return (
      <div className={this.e('description')}>
        <RichText
          className={this.e('desciption-body')}
          markup={this.props.description}
        />
      </div>
    )
  }

  renderIconBoxes() {
    if (!this.props.iconBoxes) {
      return null
    }
    return <IconBoxStatic bottomPadding={false} {...this.props.iconBoxes} />
  }
  renderGroup(group, index) {
    return (
      <FormGroup
        className={this.e('group')}
        onChange={this.onChange}
        form={this.state.form}
        key={index}
        {...group}
      />
    )
  }

  renderExtraCheckboxes() {
    if (!this.props.extraCheckboxes) {
      return null
    }

    const groups = this.props.extraCheckboxes.map((g, idx) => {
      return (
        <CheckboxGroup
          key={`extra-chk-${idx}`}
          {...g}
          onFormChange={this.onChange}
          form={this.state.form}
        />
      )
    })

    return (
      <div className={this.e('extra-checkboxes')}>
        <div className={this.e('extra-checkboxes-body')}>{groups}</div>
      </div>
    )
  }

  renderEmailOptIn() {
    if (!this.props.emailOptIn) {
      return null
    }
    const checkbox = new CheckboxModel(this.props.emailOptIn)
    return (
      <div className={this.e('email-opt-in')}>
        <Checkbox
          {...checkbox}
          name={checkbox.id}
          onChange={this.onEmailOptInChange}
          form={this.state.form}
          aria-label={checkbox.id}
          className={this.e('email-opt-in-checkbox')}
        />
      </div>
    )
  }

  render() {
    const { isUsingRecaptchaV2, error, isSubmitting } = this.state
    const { leadFormResponse, background } = this.props
    const hasError = error || leadFormResponse?.statusCode === 'Error'
    const cta = new LinkModel(this.props.cta)

    return (
      <div className={this.b.m({ [background]: background }).classes()}>
        {this.renderHero()}
        {this.renderDescription()}
        {this.renderIconBoxes()}
        <form
          onSubmit={this.onSubmit}
          className={`${this.b.e('form-el').classes()} transparency ${
            this.state.init ? 'transparent' : 'non-transparent'
          }`}
        >
          {this.props.showRequiredCopy && (
            <div className={this.b.e('group-required').classes()}>
              <p>*= required</p>
            </div>
          )}
          {this.props.groups.map(this.renderGroup)}
          {this.renderExtraCheckboxes()}
          {this.renderEmailOptIn()}

          <div className={this.b.e('button').classes()}>
            {this.props.cta && (
              <Button
                design={cta.style}
                variant={cta.variant}
                text={
                  isSubmitting
                    ? `${this.props.cta.text} ...`
                    : this.props.cta.text
                }
                analyticsEvent="submitAction"
                analyticsValue={`${slug(this.props.name)}|${
                  this.props.cta.ariaLabel
                }`}
                disabled={!this.state.valid || isSubmitting}
                type="submit"
              />
            )}
          </div>
        </form>

        {hasError && (
          <div className={this.b.e('error').classes()}>
            <Icon type={icons.alert} /> {this.state.error}
          </div>
        )}
        {this.props.showCaptcha && (
          <div className={this.b.e('recaptcha-group').classes()}>
            {isUsingRecaptchaV2 && (
              <div
                className={this.b.e('recaptcha-badge').classes()}
                id="recaptcha-v2-badge"
              />
            )}
            {!isUsingRecaptchaV2 && (
              <div
                className={this.b.e('recaptcha-badge').classes()}
                id="recaptcha-v3-badge"
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

Form.props(FormModel)

const mapStateToProps = ({ leadForm }) => ({
  leadFormResponse: leadForm
})

export default connect(mapStateToProps, { submitLeadForm })(withCookies(Form))
